define("darcy/tours/research", ["exports", "darcy/tours/scrollHandler"], function (_exports, _scrollHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Share Research with Your Team',
    image: '/images/research_tour_start.png',
    caption: 'We expose emerging trends and analyze changes across the industry to help our clients stay informed and up to speed on the latest technology solutions'
  }, {
    selector: '#research-index-filter',
    position: 'right-start',
    highlightClass: 'highlight-border',
    routeName: 'research.index',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'On the Research page, enter keywords or apply filters for published date, topic tags or content type to generate a list of research items you are interested in',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#social-buttons',
    position: 'top',
    highlightClass: 'highlight-border',
    routeName: 'research.view',
    scrollTo: true,
    scrollToHandler: function scrollToHandler(el) {
      return (0, _scrollHandler.scrollHandler)(el, 'top');
    },
    models: 'master-framework-power-utilities-utility-scale-renewables-energy-storage',
    title: '',
    image: '/images/research_tour_2.gif',
    caption: 'You can share all of our content with people inside and outside of your organization as well as join in the discussions taking place within your community',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#research-view-people-also-viewed-sidebar',
    position: 'left',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'Open up any piece of research and see related content in the side-bar',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#research-view-content',
    position: 'right',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    image: '/images/research_tour_3.gif',
    caption: 'Most of our research also has embedded hyperlinks to connect you to other content on the platform; items like innovator profiles. Simply follow the links to explore more!',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }];
  _exports.default = _default;
});