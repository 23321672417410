define("darcy/models/topic-tag", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    category: (0, _attr.default)('string'),
    tagOrder: (0, _attr.default)('number'),
    categoryOrder: (0, _attr.default)('number'),
    companies: (0, _relationships.hasMany)('company')
  });

  _exports.default = _default;
});