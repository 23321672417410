define("darcy/_pods/components/carousel-welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "91MF5Knx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"swiper-container py-8 lg:py-16\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"swiper-wrapper w-full\"],[9],[0,\"\\n    \"],[15,1,[[29,\"action\",[[24,0,[]],\"navigateToSlide\"],null]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"swiper-pagination\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"hidden sm:block\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"swiper-button-prev\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"swiper-button-next\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/carousel-welcome/template.hbs"
    }
  });

  _exports.default = _default;
});