define("darcy/_pods/components/stateful-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BPmkNOKr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[25,[\"media\",\"isMobile\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-popover\",[],[[\"@popperContainer\",\"@spacing\",\"@side\",\"@delay\",\"@tooltipClass\",\"@event\",\"@isShown\"],[\"body\",[23,\"spacing\"],[23,\"side\"],1000,[23,\"tooltipClass\"],[23,\"event\"],[23,\"isShown\"]]],{\"statements\":[[0,\"\\n    \"],[15,1,[[29,\"action\",[[24,0,[]],\"toggleState\"],null],[29,\"capture-event\",[\"show_me\"],[[\"destinationUrl\"],[[24,0,[\"visitLink\"]]]]],[29,\"action\",[[24,0,[]],\"openLink\"],null]]],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/stateful-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});