define("darcy/_pods/application/route", ["exports", "@babel/runtime/helpers/esm/defineProperty", "jquery", "ember-simple-auth/mixins/application-route-mixin", "ember-ajax/errors", "lodash"], function (_exports, _defineProperty2, _jquery, _applicationRouteMixin, _errors, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _actions;

  var AUTHENTICATOR = 'authenticator:credentials';
  var registerFlowRoutes = ['login', 'welcome', 'register', 'self-register', 'confirm'];
  var firstPath = window.location.pathname;
  var queryParams = window.location.search;

  if (firstPath.length > 1 && !registerFlowRoutes.find(function (route) {
    return (0, _lodash.startsWith)(firstPath.replace(/^\//, ''), route);
  })) {
    window.localStorage.setItem('last_visited_path', firstPath + queryParams);
  }

  ;
  var timeLimit = 5;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    router: Ember.inject.service(),
    moment: Ember.inject.service(),
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    tooltipStateManager: Ember.inject.service(),
    appTour: Ember.inject.service(),
    isShowingTryFreeTrialModal: false,
    isShowingFastTrackStorefrontModal: false,
    time: 0,
    beforeModel: function beforeModel() {
      var _this = this;

      this.moment.setTimeZone('UTC');
      return Ember.RSVP.all([this.currentUserService.load(true)]).then(function () {
        _this.setUserContext();
      });
    },
    init: function init() {
      var _this2 = this;

      this._super.apply(this, arguments);

      document.addEventListener('deviceready', function () {
        // Use router service to recognize path instead of duplicating routes for
        // IonicDeeplink to match. Also avoids bug in IonicDeeplink route
        // matching that has an unmerged pull-request:
        //
        // https://github.com/ionic-team/ionic-plugin-deeplinks/pull/242
        IonicDeeplink.route({}, function (match) {}, function (nomatch) {
          var path = nomatch.$link.path;

          if (Ember.isPresent(_this2.router.recognize(path))) {
            _this2.router.transitionTo(path);
          }
        });
      });
      var router = this.router;
      this.router.on('routeDidChange', function () {
        var page = router.currentURL;
        var title = router.currentRouteName || 'unknown';

        if (_this2.controller) {
          _this2.controller.set('isShowingMenu');
        }

        if (!_this2.currentUser) {
          _this2.startTimer();
        }

        _this2.metrics.trackPage({
          page: page,
          title: title
        });

        _this2.analytics.trackPageView();
      });
      this.session.on('authenticationSucceeded', this.setUserContext.bind(this));
      this.session.on('invalidationSucceeded', this.resetUserContext.bind(this));
      (0, _jquery.default)(window).on('mousemove keypress scroll click', function () {
        if (!_this2.currentUser) {
          _this2.time = 0;
        }
      });
    },
    setupController: function setupController(controller) {
      (0, _jquery.default)('#main-spinner').remove();

      this._super.apply(this, arguments);
    },
    setUserContext: function setUserContext() {
      var _this3 = this;

      Ember.run.later(function () {
        if (_this3.currentUser) {
          var _this3$currentUser$ge = _this3.currentUser.getProperties('name', 'email'),
              name = _this3$currentUser$ge.name,
              email = _this3$currentUser$ge.email;

          _this3.set('metrics.context.userName', name);

          _this3.set('metrics.context.email', email);

          _this3.stopTimer();
        } else {
          _this3.startTimer();
        }
      }, 1);
    },
    resetUserContext: function resetUserContext() {
      this.set('metrics.context.userName');
      this.set('metrics.context.email');
    },
    sessionInvalidated: function sessionInvalidated() {
      this._super.apply(this, arguments); // window.localStorage.removeItem('last_visited_path');

    },
    onTick: function onTick() {
      this.time++;

      if (this.time > timeLimit && !this.controller.isTimerStopped) {
        this.showRegistrationModal('createAccount');
      }
    },
    startTimer: function startTimer() {
      var _this4 = this;

      var routes = ['team', 'support', 'careers', 'terms', 'privacy', 'loading', 'reset-password', 'forgot-password', 'account.notification-settings'].concat(registerFlowRoutes);
      this.stopTimer();

      if (!routes.find(function (route) {
        return _this4.router.currentRouteName.includes(route);
      })) {
        this._timer = setInterval(this.onTick.bind(this), 1000);
      }
    },
    stopTimer: function stopTimer() {
      clearInterval(this._timer);
    },
    showRegistrationModal: function showRegistrationModal(tab) {
      this.controller.set('isShowingRegistrationModal', true);
      this.controller.set('visibleTab', tab);
    },
    checkIfMobile: function checkIfMobile() {
      var userAgent = this.get('userAgent');
      var mobileType = userAgent.get('os.info');

      if (userAgent.get('os.isAndroid') || userAgent.get('os.isIOS')) {
        this.analytics.captureEvent('mobile_device_detected', {
          url: window.location.href,
          meta: mobileType
        });
      }
    },
    actions: (_actions = {
      search: function search(query, filter) {
        if (!query) {
          return false;
        }

        var originUrl = window.location.origin + window.location.pathname;
        document.activeElement.blur();
        this.transitionTo('search', {
          queryParams: {
            query: query,
            filter: filter,
            page: 1,
            origin: originUrl
          }
        });
        return false;
      },
      back: function back() {
        window.history.back();
      },
      willTransition: function willTransition() {
        if (!this.currentUser) this.set('controller.isTimerStopped', false);
      },
      visitSelfRegister: function visitSelfRegister() {
        this.set('controller.isShowingNoAccessModal', false); // TODO: change to 'welcome' when other work is merged

        this.transitionTo('self-register');
      },
      showNoAccessModal: function showNoAccessModal() {
        var restrictedPath = window.location.pathname;
        var queryParams = window.location.search;

        if (restrictedPath.length > 1) {
          window.localStorage.setItem('last_visited_path', restrictedPath + queryParams);
        }

        ;
        this.set('controller.isShowingNoAccessModal', true);
      }
    }, (0, _defineProperty2.default)(_actions, "showNoAccessModal", function showNoAccessModal() {
      this.set('controller.isShowingNoAccessModal', true);
    }), (0, _defineProperty2.default)(_actions, "showRegistrationModal", function showRegistrationModal(tab) {
      this.showRegistrationModal(tab);
    }), (0, _defineProperty2.default)(_actions, "showFastTrackStorefrontModal", function showFastTrackStorefrontModal(companyId) {
      this.set('controller.isShowingFastTrackStorefrontModal', true);
      this.set('controller.fastTrackStorefrontModalCompanyId', companyId);
    }), (0, _defineProperty2.default)(_actions, "hideAllModals", function hideAllModals() {
      this.set('controller.isShowingNoAccessModal', false);
      this.set('controller.isShowingTryFreeTrialModal');
      this.set('controller.isShowingFastTrackStorefrontModal');
      this.set('appTour.isStartTourModalOpen');
    }), (0, _defineProperty2.default)(_actions, "invalidateSession", function invalidateSession() {
      this.session.invalidate();
    }), (0, _defineProperty2.default)(_actions, "error", function error(_error) {
      this.notifications.error(_error.message || 'Oops, something went wrong. Please try again or check back later.');
      return true;
    }), (0, _defineProperty2.default)(_actions, "login", function login(email, password) {
      var _this5 = this;

      return this.get('session').authenticate(AUTHENTICATOR, email, password).then(function () {
        _this5.checkIfMobile();

        _this5.controller.set('isShowingRegistrationModal', false);

        return _this5.currentUserService.load(true);
      }).catch(function (e) {
        if ((0, _errors.isUnauthorizedError)(e)) {
          var detail;

          try {
            detail = e.payload.errors[0].detail;
          } catch (err) {
            detail = '';
          }

          var message;

          if (/account is inactive/i.test(detail)) {
            message = 'Your account is not active—please contact Darcy’s client service team at darcy@darcypartners.com or send us a message in the chat.';
            Tawk_API.maximize();
          } else if (/no active membership agreements/i.test(detail)) {
            message = 'Your company has no active membership agreements.';
          } else if (/confirm/i.test(detail)) {
            return _this5.transitionTo('confirm', {
              queryParams: {
                email: email
              }
            });
          } else {
            message = 'You have entered an invalid username or password.';
          }

          throw new Error(message);
        } else {
          throw new Error('There was a server error, please try again.');
        }
      });
    }), _actions)
  });

  _exports.default = _default;
});