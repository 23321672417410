define("darcy/_pods/components/service-line-topic-tag-select/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOP_LEVEL_TOPIC_TAG_SERVICE_LINES = ['Oil & Gas', 'Sustainability', 'Power & Utilities', 'Energy Transition'];
  var EXECUTIVE_SERVICE_LINES = ['E&P Executives', 'Power Executives', 'E&P Business Development'];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    skipValidation: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.onTopicTagsValidate();
    },
    serviceLines: Ember.computed(function () {
      return this.store.findAll('service-line');
    }),
    topicTags: Ember.computed(function () {
      return this.store.findAll('topic-tag');
    }),
    topicTagOptions: Ember.computed('topicTags.[]', 'selectedServiceLines.@each.{name}', function () {
      var topicTags = this.topicTags;
      var topics = [];
      var serviceLines = this.selectedServiceLines.toArray();
      var serviceLineNames = [].concat(TOP_LEVEL_TOPIC_TAG_SERVICE_LINES, ['Service Lines']);

      if (serviceLines.some(function (serviceLine) {
        return TOP_LEVEL_TOPIC_TAG_SERVICE_LINES.includes(serviceLine.name);
      })) {
        topics.push.apply(topics, (0, _toConsumableArray2.default)(topicTags.filter(function (topic) {
          return serviceLines.findBy('name', topic.category);
        })));
      }

      if (serviceLines.some(function (serviceLine) {
        return EXECUTIVE_SERVICE_LINES.includes(serviceLine.name);
      })) {
        topics.push.apply(topics, (0, _toConsumableArray2.default)(topicTags.filterBy('name', 'Executive')));
      }

      if (serviceLines.findBy('name', 'Innovator')) {
        topics.push.apply(topics, (0, _toConsumableArray2.default)(topicTags.filterBy('name', 'Innovator')));
      }

      if (serviceLines.findBy('name', 'Public')) {
        topics.push.apply(topics, (0, _toConsumableArray2.default)(topicTags.filter(function (topic) {
          return serviceLineNames.includes(topic.category);
        })));
      }

      return topics.uniq();
    }),
    visibleSelectedTopicTags: Ember.computed('selectedTopicTags.[]', 'topicTagOptions.[]', function () {
      var _this = this;

      return this.topicTagOptions.filter(function (tt) {
        return _this.selectedTopicTags.includes(tt);
      });
    }),
    serviceLineTopicTags: Ember.computed('topicTags.[]', 'selectedServiceLines.[]', function () {
      var topicTags = this.topicTags;
      var serviceLines = this.selectedServiceLines.toArray();

      if (serviceLines.some(function (serviceLine) {
        return EXECUTIVE_SERVICE_LINES.includes(serviceLine.name);
      })) {
        return topicTags.filterBy('name', 'Executive');
      } else if (serviceLines.some(function (serviceLine) {
        return serviceLine.name === 'Innovator';
      })) {
        return topicTags.filterBy('name', 'Innovator');
      } else {
        return topicTags.filter(function (tt) {
          return serviceLines.mapBy('name').includes(tt.name);
        });
      }
    }),
    isValidTopicTags: Ember.computed('selectedServiceLines.[]', 'visibleSelectedTopicTags.[]', 'skipValidation', function () {
      var serviceLineNames = ['Darcy', 'Undefined', 'Innovation Management'];
      var serviceLines = this.selectedServiceLines.toArray();
      var hasServiceLines = serviceLines.some(function (serviceLine) {
        return serviceLineNames.includes(serviceLine.name);
      });

      if (hasServiceLines || this.visibleSelectedTopicTags.length || this.skipValidation) {
        return true;
      }

      return false;
    }),
    onTopicTagsValidate: Ember.observer('selectedServiceLines.[]', 'visibleSelectedTopicTags.[]', 'skipValidation', function () {
      Ember.run.debounce(this, this.validate, this.isValidTopicTags, 500);
    }),
    actions: {
      selectTopicTags: function selectTopicTags(subTopicTags) {
        var topicTags = (0, _toConsumableArray2.default)(this.serviceLineTopicTags.toArray());
        topicTags.push.apply(topicTags, (0, _toConsumableArray2.default)(subTopicTags));
        this.setTopicTags(topicTags);
        this.set('skipValidation', false);
      },
      selectServiceLines: function selectServiceLines(serviceLines) {
        var _this2 = this;

        this.setServiceLines(serviceLines);
        this.set('skipValidation', false);
        Ember.run.next(function () {
          var topicTags = _this2.selectedTopicTags.filter(function (topicTag) {
            return serviceLines.mapBy('name').includes(topicTag.category);
          });

          topicTags.pushObjects(_this2.serviceLineTopicTags);

          _this2.setTopicTags(topicTags);
        });
      },
      toggleValidation: function toggleValidation() {
        this.set('skipValidation', !this.skipValidation);
      }
    }
  });

  _exports.default = _default;
});