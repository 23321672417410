define("darcy/_pods/components/add-company-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OrY0bst2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"font-bold text-xl mb-5\"],[9],[0,\"Add Company\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"mb-4\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Company Name *\"],[10],[0,\"\\n  \"],[5,\"input\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"]],[[\"@value\"],[[24,0,[\"name\"]]]]],[0,\"\\n\"],[4,\"if\",[[24,0,[\"isShowingNameRequiredMsg\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"text-red-500 text-sm\"],[9],[0,\"Company Name is required\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"mb-4\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Company URL\"],[10],[0,\"\\n  \"],[5,\"input\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"]],[[\"@value\"],[[24,0,[\"url\"]]]]],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"mb-5\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Comments?\"],[10],[0,\"\\n  \"],[5,\"textarea\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"]],[[\"@value\"],[[24,0,[\"comments\"]]]]],[0,\"\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex justify-end\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"bg-highlight_green text-white font-bold rounded px-4 py-2\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"perform\",[[24,0,[\"requestAddCompanyTask\"]]],null]],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    Submit\\n\\n    \"],[5,\"ember-popover\",[],[[\"@isShown\",\"@event\",\"@tooltipClass\",\"@spacing\"],[[24,0,[\"showTooltip\"]],\"none\",\"tooltip-innovator-black ember-popover-black w-64 normal-case\",24]],{\"statements\":[[0,\"\\n      Your request to add this company has been received\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/add-company-form/template.hbs"
    }
  });

  _exports.default = _default;
});