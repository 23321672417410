define("darcy/services/video", ["exports", "js-video-url-parser", "darcy/config/environment"], function (_exports, _jsVideoUrlParser, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    rootURL: _environment.default.rootURL,

    get typeOptions() {
      return {
        vimeo: "Vimeo",
        youtube: "YouTube"
      };
    },

    getVideoID: function getVideoID(videoType, fullUrl) {
      var url = fullUrl;

      if (videoType === 'vimeo') {
        // Vimeo
        url = url.split("vimeo.com/");
        url = url[url.length - 1];
      } else if (videoType === 'youtube') {
        // YouTube
        // from https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url/27728417#27728417
        var ytRegex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        var idMatch = url.match(ytRegex);
        url = idMatch[1];
      }

      return url;
    },
    parseUrl: function parseUrl(url) {
      return !url ? '' : _jsVideoUrlParser.default.parse(url);
    },
    getThumbnail: function getThumbnail(provider, id) {
      if (!provider || !id) {
        return '';
      } // YouTube - https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api


      if (provider === 'youtube') {
        return "https://img.youtube.com/vi/".concat(id, "/mqdefault.jpg");
      }

      return this.rootURL + 'images/video-player.png';
    }
  });

  _exports.default = _default;
});