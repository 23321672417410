define("darcy/_pods/components/modal/connection-request/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    modals: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    connectionRequest: Ember.inject.service(),
    isAddingNote: false,
    message: null,
    connecteeId: null,
    _cancel: function _cancel() {
      this.set('isAddingNote', false);
      this.set('message', null);
      this.connectionRequest.reset();
    },
    actions: {
      createConnectionRequest: function createConnectionRequest() {
        var _this = this;

        var connectee = this.connectionRequest.get('connectee');
        var request = this.store.createRecord('connection-request', {
          connecterId: this.get('currentUser.id'),
          connecteeId: connectee.get('id'),
          message: this.message || null
        });
        request.save().then(function () {
          var eventTarget = _this.connectionRequest.get('analyticsEventMetaTarget');

          _this.connectionRequest.connectee.set('connectionStatus', 'pending');

          _this.analytics.captureEvent('user_connection_request_sent', {
            meta: {
              target: eventTarget
            }
          });

          _this.userPreview.setModel(_this.connectionRequest.connectee);

          _this.connectionRequest.reset();

          _this.modals.open('modal/success', {
            title: 'Your connection request has been sent!',
            autoCloseDuration: 3000
          });
        });
        this.set('isAddingNote', false);
        this.set('message', null);
      },
      addNote: function addNote() {
        this.set('isAddingNote', true);
      },
      cancel: function cancel() {
        this._cancel();
      }
    }
  });

  _exports.default = _default;
});