define("darcy/_pods/getting-started/welcome/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    afterModel: function afterModel() {
      var user = this.get('currentUser');

      if (!user.get('gettingStartedShownAt')) {
        user.set('gettingStartedShownAt', new Date());
        user.save();
      }
    }
  });

  _exports.default = _default;
});