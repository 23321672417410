define("darcy/_pods/components/header/desktop/landing/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    appTour: Ember.inject.service(),
    tagName: '',
    actions: {
      openStartTourModal: function openStartTourModal() {
        this.set('appTour.isStartTourModalOpen', true);
        this.analytics.captureEvent('take_user_tour');
      }
    }
  });

  _exports.default = _default;
});