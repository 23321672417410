define("darcy/_pods/confirm/token/route", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-local-storage", "darcy/config/environment"], function (_exports, _toConsumableArray2, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    tawk: Ember.inject.service(),
    redirects: (0, _emberLocalStorage.storageFor)('redirects'),
    model: function model(_ref) {
      var _this = this;

      var token = _ref.token;
      return this.ajax.put("".concat(API.host, "/email_confirmation"), {
        data: {
          token: token
        }
      }).then(function (response) {
        if (_this.currentUser.user) {
          return _this.currentUser.user.reload();
        } else {
          return response.data.attributes;
        }
      }).then(function (user) {
        var email = user.email;

        if (!_this.get('session.data.authenticated.userId')) {
          _this.transitionTo('login', {
            queryParams: {
              email: email
            }
          });

          return;
        }

        _this.tawk.addEmailValidatedEvent(user);

        _this.tawk.addTag('email-validated');

        var confirmRedirect = _this.redirects.get('confirm');

        if (confirmRedirect) {
          var name = confirmRedirect.name,
              params = confirmRedirect.params;
          var paramValues = Object.values(params);

          _this.redirects.set('confirm');

          _this.transitionTo.apply(_this, [name].concat((0, _toConsumableArray2.default)(paramValues)));
        } else {
          _this.transitionTo('getting-started');
        }
      }).catch(function (err) {
        _this.notifications.error('There was an error, please try again.');
      });
    }
  });

  _exports.default = _default;
});