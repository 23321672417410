define("darcy/_pods/components/carousel-welcome/component", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['w-full', 'relative', 'welcome-carousel'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var queryParamSlide = this.queryParamSlide ? Number(this.queryParamSlide) : 0;
      var component = this;
      var container = this.element.querySelector('.swiper-container');
      this.swiper = new _swiper.default(container, {
        loop: true,
        initialSlide: queryParamSlide,
        observer: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        on: {
          slideChange: function slideChange() {
            component.onSlideChange(this.realIndex);
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.swiper.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      navigateToSlide: function navigateToSlide(slideIndex) {
        var slideNumber = Number(slideIndex) + 1;
        this.swiper.slideTo(slideNumber);
      },
      slideLeft: function slideLeft() {
        this.swiper.slidePrev();
      },
      slideRight: function slideRight() {
        this.swiper.slideNext();
      }
    }
  });

  _exports.default = _default;
});