define("darcy/_pods/components/edit-communities/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    communities: [],
    account: [],
    communityItems: [],
    hasChangedCommunities: false,
    didInsertElement: function didInsertElement() {
      var userCommunities = this.get('account.communities') || [];
      var userCommunityIds = userCommunities.mapBy('id');
      var communities = this.get('communities').sortBy('isHidden').reverse();
      this.set('communityItems', communities.map(function (c) {
        return {
          community: c,
          checked: userCommunityIds.includes(c.get('id'))
        };
      }));
    },
    actions: {
      selectCommunity: function selectCommunity(item) {
        this.set('hasChangedCommunities', true);

        if (item.checked === false) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      }
    }
  });

  _exports.default = _default;
});