define("darcy/_pods/components/example-questions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OyCyqQMA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"mb-10 mt-4 text-2xl font-bold\"],[9],[0,\"\\n  Example questions you can ask Darcy:\\n\"],[10],[0,\"\\n\\n\"],[1,[29,\"example-questions/question\",null,[[\"icon\",\"title\",\"text\"],[\"globe\",\"Market Map / Landscape\",\"We are looking for solutions for real-time drilling fluid and rheology measurements at site. Are there any available solutions in the market you are aware of?\"]]],false],[0,\"\\n\\n\"],[1,[29,\"example-questions/question\",null,[[\"icon\",\"title\",\"text\"],[\"comment\",\"Problem Statement\u2028\",\"We drill over 200 wells/yr using a water based polymeric drilling fluid system (9 to 9.5 ppg) and regularly encounter loss circulation problems, ranging from small to total loses. We transverse high pressure zones (injection zone) as well as low pressure zones (thief zones). What solutions are available?\"]]],false],[0,\"\\n\\n\"],[1,[29,\"example-questions/question\",null,[[\"icon\",\"title\",\"text\"],[\"tech\",\"Tech Specific\u2028\",\"Just wondering if you guys had researched this company, applications and users by any chance: Acoustic Data. Is there anything your team would have summarized?\"]]],false],[0,\"\\n\\n\"],[1,[29,\"example-questions/question\",null,[[\"icon\",\"title\",\"text\"],[\"process\",\"Operational Process\",\"For unconsolidated formations what are the methods which have been used successfully to remove cores without losing integrity?\"]]],false],[0,\"\\n\\n\"],[1,[29,\"example-questions/question\",null,[[\"icon\",\"title\",\"text\"],[\"atom\",\"Framing / Scoping\",\"We are looking at ways to reduce seismic processing cycle times and wants to leverage Darcy to help them understand the technology landscape more comprehensively.\u2028\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/example-questions/template.hbs"
    }
  });

  _exports.default = _default;
});