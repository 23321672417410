define("darcy/_pods/components/blanket-ui/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VHgpfrX+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[24,0,[]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/blanket-ui/template.hbs"
    }
  });

  _exports.default = _default;
});