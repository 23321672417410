define("darcy/_pods/components/table/cell/markdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G7KTrxx3",
    "block": "{\"symbols\":[\"@rawValue\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-grow\"],[9],[0,\"\\n  \"],[1,[29,\"markdown-to-html\",[[24,1,[]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/markdown/template.hbs"
    }
  });

  _exports.default = _default;
});