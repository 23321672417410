define("darcy/_pods/components/nylas-schedule-editor-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    nylas: Ember.inject.service(),
    tagName: 'button',
    buttonType: 'button',
    attributeBindings: ['buttonType:type'],
    classNames: ['bg-blue-500', 'hover:bg-blue-700', 'text-center', 'text-white', 'font-bold', 'py-2', 'px-4', 'rounded', 'focus:outline-none', 'focus:shadow-outline'],
    click: function click() {
      this.nylas.launchScheduler(this.token);
    }
  });

  _exports.default = _default;
});