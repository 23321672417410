define("darcy/_pods/components/sidebar-topics-filter/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var promotedTopicTags = ['Innovation Management'];
  var FilterItem = Ember.Object.extend({
    topicTag: null,
    // can be null
    selected: null,
    // need to bind
    isShowingSubindex: false,
    label: Ember.computed.oneWay('topicTag.name'),
    id: Ember.computed.oneWay('topicTag.id'),
    parent: null,
    children: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.children = this.children || [];
    },
    isRoot: Ember.computed.equal('depth', 0),
    hasChildren: Ember.computed.gt('children.length', 0),
    depth: Ember.computed('parent.depth', function () {
      var parent = this.get('parent');
      if (!parent) return 0;
      return parent.get('depth') + 1;
    }),
    isSelected: Ember.computed('selected.[]', 'topicTag', 'children.@each.isSelected', function () {
      var topicTag = this.get('topicTag');
      var selected = this.get('selected');

      if (topicTag) {
        return selected ? selected.includes(topicTag) : false;
      } // "general" group case; status is determined by children


      return this.get('children').isEvery('isSelected');
    }),
    affectedTopicTags: Ember.computed('topicTag', 'chidren.@each.affectedTopicTags', function () {
      var topicTag = this.get('topicTag');
      if (topicTag) return [topicTag]; // "general" group case; toggle action descends to children

      return this.get('children').reduce(function (arr, item) {
        arr.addObjects(item.get('affectedTopicTags'));
        return arr;
      }, []);
    })
  });

  var _default = Ember.Component.extend({
    tagName: '',
    title: 'Filters',
    isExpanded: false,
    topicTags: null,
    selected: null,
    onChange: null,
    blacklist: null,
    // handles all irregularities of the filter list
    items: Ember.computed('topicTags.@each.{name,categoryOrder,tagOrder}', 'blacklist', function () {
      var _this = this;

      if (Ember.isEmpty(this.topicTags)) return [];
      var blacklist = this.blacklist || [];
      var remainingTopicTags = this.topicTags.reject(function (topicTag) {
        return blacklist.includes(topicTag.name);
      }); // classic binding hack

      var BoundFilterItem = FilterItem.extend({
        selected: Ember.computed.readOnly('component.selected'),
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        component: this
      });
      var serviceLineItems = this.topicTags.filterBy('category', 'Service Lines').sortBy('tagOrder').map(function (serviceLineTopicTag) {
        remainingTopicTags.removeObject(serviceLineTopicTag);
        var serviceLineItem = BoundFilterItem.create({
          topicTag: serviceLineTopicTag
        });

        _this.topicTags.filterBy('category', serviceLineTopicTag.name).sortBy('tagOrder').forEach(function (childTopicTag) {
          remainingTopicTags.removeObject(childTopicTag);
          var child = BoundFilterItem.create({
            parent: serviceLineItem,
            topicTag: childTopicTag
          });
          serviceLineItem.children.push(child);
        });

        return serviceLineItem;
      });
      var remainingItems = remainingTopicTags.sortBy('tagOrder').sortBy('categoryOrder').map(function (topicTag) {
        return BoundFilterItem.create({
          topicTag: topicTag
        });
      });
      return [].concat((0, _toConsumableArray2.default)(serviceLineItems), (0, _toConsumableArray2.default)(remainingItems));
    }),
    actions: {
      toggleItem: function toggleItem(item) {
        // don't mutate original array
        var selected = this.selected ? this.selected.toArray() : [];
        var affectedTopicTags = item.get('affectedTopicTags');

        if (item.get('isSelected')) {
          selected.removeObjects(affectedTopicTags);

          if (item.get('hasChildren')) {
            selected.removeObjects(item.get('children').map(function (c) {
              return c.get('affectedTopicTags')[0];
            }));
          }
        } else {
          selected.addObjects(affectedTopicTags);

          if (item.get('hasChildren')) {
            selected.addObjects(item.get('children').map(function (c) {
              return c.get('affectedTopicTags')[0];
            }));
          }
        } // pass _full_ array of selected topic tags back to controller


        this.onChange(selected);
      },
      toggleSubItems: function toggleSubItems(item) {
        item.set('isShowingSubindex', !item.get('isShowingSubindex')); // if (this.get('isShowingSubindex')){
        //   this.set('isShowingSubindex', false);
        // }else{
        // this.set('isShowingSubindex', true);
        // }
      }
    }
  });

  _exports.default = _default;
});