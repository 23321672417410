define("darcy/_pods/components/start-shell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    isWelcomePage: false,
    actions: {}
  });

  _exports.default = _default;
});