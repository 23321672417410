define("darcy/models/feed-item", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    feedItemType: (0, _attr.default)('string'),
    targetUrl: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    body: (0, _attr.default)('string'),
    isAccessible: (0, _attr.default)('boolean'),
    topicTagsList: (0, _attr.default)(),
    speakersList: (0, _attr.default)(),
    content: (0, _relationships.belongsTo)(),
    forum: (0, _relationships.belongsTo)(),
    companyProfile: (0, _relationships.belongsTo)()
  });

  _exports.default = _default;
});