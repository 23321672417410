define("darcy/_pods/account/notification-settings/controller", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;
  var VISIBLE_SERVICE_LINE_IDS = ['1', '2', '3', '9'];
  var HIDDEN_SERVICE_LINE_IDS = ['10', '11', '12'];

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    isShowingDisableAutoInvitesModal: false,
    currentServiceLine: null,
    visibleServiceLines: Ember.computed('serviceLines', 'serviceLines.[]', 'model.communities.[]', 'model.serviceLinesByActiveMembershipAgreements.[]', function () {
      if (Ember.isBlank(this.get('serviceLines'))) return [];
      var serviceLines = [];
      var visibleServiceLines = this.get('serviceLines').filter(function (sl) {
        return VISIBLE_SERVICE_LINE_IDS.includes(sl.id);
      });
      var hiddenServiceLines = this.get('serviceLines').filter(function (sl) {
        return HIDDEN_SERVICE_LINE_IDS.includes(sl.id);
      });
      var userCommunityNames = this.get('model.communities').mapBy('name');
      var userServiceLineIds = this.get('model.serviceLinesByActiveMembershipAgreements').mapBy('id');
      visibleServiceLines.forEach(function (sl) {
        serviceLines.push(sl);
      });
      hiddenServiceLines.forEach(function (sl) {
        if (userServiceLineIds.includes(sl.id) && userCommunityNames.includes(sl.name)) {
          serviceLines.push(sl);
        }
      });
      return serviceLines;
    }),
    save: function save() {
      var _this = this;

      var user = this.user;
      user.set('serviceLineIds', user.get('serviceLines').map(function (serviceLine) {
        return parseInt(serviceLine.get('id'));
      }));
      user.save().then(function () {
        _this.notifications.success("Notification Settings Updated");
      }).catch(function () {
        _this.notifications.error('There was an error updating your account information, please try again.');
      }).finally(function () {
        if (_this.controller) {
          _this.controller.set('isLoading', false);
        }
      });
    },
    unsubscribe: function unsubscribe() {
      var _this2 = this;

      var user = this.user;
      var token = this.unsubscribeToken;
      var service_line_ids = user.get('serviceLines').map(function (serviceLine) {
        return parseInt(serviceLine.get('id'));
      });
      return this.ajax.put("".concat(API.host, "/users/").concat(user.get('id'), "?unsubscribe_token=").concat(token), {
        data: {
          data: {
            id: user.get('id'),
            attributes: {
              notification_allow_weekly_emails: user.get('notificationAllowWeeklyEmails'),
              notification_allow_weekly_content_email: user.get('notificationAllowWeeklyContentEmail'),
              notification_allow_weekly_forum_email: user.get('notificationAllowWeeklyForumEmail'),
              disable_automated_emails: user.get('disableAutomatedEmails'),
              notification_allow_comment_mention_email: user.get('notificationAllowCommentMentionEmail'),
              notification_allow_comment_reply_email: user.get('notificationAllowCommentReplyEmail'),
              notification_allow_connection_request_email: user.get('notificationAllowConnectionRequestEmail'),
              notification_allow_connection_approval_email: user.get('notificationAllowConnectionApprovalEmail'),
              notification_allow_direct_email_message: user.get('notificationAllowDirectEmailMessage'),
              notification_allow_event_reminder_follow_up_email: user.get('notificationAllowEventReminderFollowUpEmail'),
              service_line_ids: service_line_ids
            },
            type: 'users'
          }
        }
      }).then(function () {
        _this2.notifications.success("Notification Settings Updated");
      }).catch(function () {
        _this2.notifications.error('There was an error updating Notification Settings, please try again.');
      });
    },
    actions: {
      save: function save() {
        if (this.unsubscribeToken) {
          this.unsubscribe();
        } else {
          this.save();
        }
      },
      toggleNotification: function toggleNotification(notification) {
        if (this.unsubscribeToken) {
          this.toggleProperty(notification);
          this.unsubscribe();
        } else {
          this.toggleProperty(notification);
          this.user.save();
        }

        if (notification === 'user.disableAutomatedEmails') {
          this.analytics.captureEvent('user_toggle_notification_setting', {
            meta: {
              'name': 'notificationAllowAutoInvites',
              'type': 'invites',
              'value': !this.get(notification)
            }
          });
        }
      },
      toggleServiceLine: function toggleServiceLine(serviceLine) {
        if (this.get('user.serviceLines').findBy('id', serviceLine.get('id'))) {
          this.get('user.serviceLines').removeObject(serviceLine);
          this.set('user.disableAutomatedEmails', false);

          if (this.unsubscribeToken) {
            this.unsubscribe();
          } else {
            this.save();
          }
        } else {
          if (!this.get('isShowingDisableAutoInvitesModal')) {
            this.set('isShowingDisableAutoInvitesModal', true);
            this.set('currentServiceLine', serviceLine);
          } else {
            this.get('user.serviceLines').pushObject(serviceLine);
            this.set('isShowingDisableAutoInvitesModal', false);
            this.set('currentServiceLine', null);

            if (this.unsubscribeToken) {
              this.unsubscribe();
            } else {
              this.save();
            }
          }
        }
      }
    }
  });

  _exports.default = _default;
});