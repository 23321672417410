define("darcy/_pods/components/title-box/add-a-company/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    title: '',
    titleClasses: 'bg-titlebox-blue',
    titleInfo: '',
    isShowingCompanyForm: false,
    actions: {
      showCompanyForm: function showCompanyForm(evt) {
        if (this.currentUser) {
          this.set('isShowingCompanyForm', true);
        } else {
          this.showNoAccessModal();
        }

        if (evt && typeof evt.stopPropagation === 'function') {
          evt.stopPropagation();
        }

        this.analytics.captureEvent('add_a_company_view');
      }
    }
  });

  _exports.default = _default;
});