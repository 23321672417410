define("darcy/_pods/innovators/view/vendor-materials/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile;

      this.transitionTo('research.view', transition.to.params.content_id, {
        queryParams: {
          sourceType: 'company-profile',
          sourceId: companyProfile.id
        }
      });
    }
  });

  _exports.default = _default;
});