define("darcy/_pods/components/content/thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GeoW2KKg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"content\",\"photoUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"img-ratio-sixteen-nine\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[25,[\"content\",\"photoUrl\"]]],[12,\"alt\",[30,[[25,[\"content\",\"name\"]],\" preview\"]]],[11,\"class\",\"w-full\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"content\",\"isVideo\"]]],null,{\"statements\":[[0,\"  \"],[5,\"content/thumbnail/video\",[],[[\"@url\",\"@videoType\"],[[25,[\"content\",\"url\"]],[25,[\"content\",\"videoType\"]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,0,[\"pdfThumbnail\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[24,0,[\"pdfThumbnail\"]]],[11,\"alt\",\"PDF Content\"],[11,\"class\",\"w-full\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"md:py-4 md:px-4\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/pdf.svg\"],null]]]],[11,\"class\",\"w-40 mx-auto\"],[11,\"alt\",\"PDF Content\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/content/thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});