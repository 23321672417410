define("darcy/_pods/logout/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    redirect: function redirect() {
      var _this = this;

      return this.get('session').invalidate().then(function () {
        return _this.transitionTo('login');
      });
    }
  });

  _exports.default = _default;
});