define("darcy/_pods/usage/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        usersChart: this.store.findRecord('chart', 'users'),
        eventParticipationChart: this.store.findRecord('chart', 'event-participation'),
        communityMembersChart: this.store.findRecord('chart', 'community-members'),
        innovationConciergeChart: this.store.findRecord('chart', 'innovation-concierge'),
        conciergeRequests: this.store.findAll('concierge-request')
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });

  _exports.default = _default;
});