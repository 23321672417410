define("darcy/_pods/components/table/cell/icon-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9gmHoTVV",
    "block": "{\"symbols\":[\"content\",\"item\",\"isAdmin\",\"isReadOnlyAdmin\",\"isDarcyUser\",\"@rawValue\",\"@column\",\"@row\"],\"statements\":[[4,\"let\",[[24,8,[\"content\"]]],null,{\"statements\":[[4,\"let\",[[29,\"cell-fn\",[[24,1,[]],[24,7,[\"getIcon\"]]],null],[29,\"get\",[[24,1,[]],[24,7,[\"isAdmin\"]]],null],[29,\"get\",[[24,1,[]],[24,7,[\"isReadOnlyAdmin\"]]],null],[29,\"get\",[[24,1,[]],[24,7,[\"isDarcyUser\"]]],null]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"d-flex\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex-grow-1 text-truncate\"],[9],[0,\"\\n        \"],[1,[24,6,[]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"text-nowrap\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"isSponsored\"]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"badge badge-pill badge-warning\"],[9],[0,\"Sponsored\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[29,\"or\",[[24,3,[]],[24,4,[]]],null]],null,{\"statements\":[[4,\"if\",[[24,4,[]]],null,{\"statements\":[[0,\"            \"],[7,\"span\"],[11,\"class\",\"badge badge-pill badge-primary\"],[9],[0,\"Read\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"span\"],[11,\"class\",\"badge badge-pill badge-primary\"],[9],[0,\"Admin\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,5,[]]],null,{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"badge badge-pill badge-primary\"],[9],[0,\"Darcy\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[12,\"class\",[30,[\"fa fa-\",[24,2,[\"icon\"]],\" \",[24,2,[\"color\"]]]]],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[2,3,4,5]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/icon-text/template.hbs"
    }
  });

  _exports.default = _default;
});