define("darcy/models/company-profile", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-data/model", "ember-data/attr", "ember-data/relationships", "darcy/config/environment"], function (_exports, _defineProperty2, _model, _attr, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  var APP = _environment.default.APP;

  var _default = _model.default.extend((_Model$extend = {
    video: Ember.inject.service(),
    description: (0, _attr.default)('string'),
    applicationsDescription: (0, _attr.default)('string'),
    tagline: (0, _attr.default)('string'),
    insightsDescription: (0, _attr.default)('string'),
    isDarcyPresenter: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isDarcyInsightsShown: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isVendorOverviewShown: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isVendorMaterialsShown: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isAccessible: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isPublished: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    websiteUrl: (0, _attr.default)('string'),
    founded: (0, _attr.default)('string'),
    headquarters: (0, _attr.default)('string'),
    geographicalFootprint: (0, _attr.default)('string'),
    employees: (0, _attr.default)('string'),
    customersDescription: (0, _attr.default)('string'),
    contactFullname: (0, _attr.default)('string'),
    contactPhone: (0, _attr.default)('string'),
    contactEmail: (0, _attr.default)('string'),
    vendorMeetingUrl: (0, _attr.default)('string'),
    interestRating: (0, _attr.default)('string'),
    noveltyRating: (0, _attr.default)('string'),
    techStage: (0, _attr.default)('string'),
    researchNotes: (0, _attr.default)('string'),
    introVideo: (0, _attr.default)('string'),
    headquartersCountry: (0, _attr.default)('string'),
    headquartersState: (0, _attr.default)('string'),
    techTags: (0, _attr.default)('string'),
    markets: (0, _attr.default)('string'),
    nylasToken: (0, _attr.default)('string'),
    nylasAccountId: (0, _attr.default)('string'),
    hasFreeTrialServiceLines: (0, _attr.default)('boolean'),
    hasActiveStorefrontAdminUsers: (0, _attr.default)('boolean'),
    isAssignedToCurrentUser: (0, _attr.default)('boolean'),
    isManagedByCurrentUser: (0, _attr.default)('boolean'),
    isLikedByCurrentUser: (0, _attr.default)('boolean'),
    isBookmarkedByCurrentUser: (0, _attr.default)('boolean'),
    isSharedByCurrentUser: (0, _attr.default)('boolean'),
    reactionsCount: (0, _attr.default)('number'),
    viewsCount: (0, _attr.default)('number'),
    likesCount: (0, _attr.default)('number'),
    sharesCount: (0, _attr.default)('number'),
    commentsCount: (0, _attr.default)('number'),
    followsCount: (0, _attr.default)('number'),
    isFollowable: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    canonicalUrl: (0, _attr.default)('string'),
    adminUrl: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    internalKeywords: (0, _attr.default)('string'),
    metaTitle: (0, _attr.default)('string'),
    metaDescription: (0, _attr.default)('string')
  }, (0, _defineProperty2.default)(_Model$extend, "isAccessible", (0, _attr.default)('boolean', {
    defaultValue: false
  })), (0, _defineProperty2.default)(_Model$extend, "name", (0, _attr.default)('string')), (0, _defineProperty2.default)(_Model$extend, "slug", (0, _attr.default)('string')), (0, _defineProperty2.default)(_Model$extend, "logoUrl", (0, _attr.default)('string')), (0, _defineProperty2.default)(_Model$extend, "companyId", (0, _attr.default)('string')), (0, _defineProperty2.default)(_Model$extend, "introVideoDetails", Ember.computed('introVideo', function () {
    return this.video.parseUrl(this.introVideo);
  })), (0, _defineProperty2.default)(_Model$extend, "introVideoProvider", Ember.computed.reads('introVideoDetails.provider')), (0, _defineProperty2.default)(_Model$extend, "introVideoId", Ember.computed.reads('introVideoDetails.id')), (0, _defineProperty2.default)(_Model$extend, "introVideoThumbnail", Ember.computed('introVideoProvider', 'introVideoId', function () {
    return this.video.getThumbnail(this.introVideoProvider, this.introVideoId);
  })), (0, _defineProperty2.default)(_Model$extend, "headquartersCityState", Ember.computed('headquarters', 'headquartersState', function () {
    var headquarters = this.headquarters,
        headquartersState = this.headquartersState;
    var hqCityState = headquarters;

    if (headquartersState) {
      if (headquarters) {
        hqCityState += ', ';
      }

      hqCityState += headquartersState;
    }

    return hqCityState;
  })), (0, _defineProperty2.default)(_Model$extend, "headquartersFull", Ember.computed('headquartersCountry', 'headquartersCityState', function () {
    var headquartersCountry = this.headquartersCountry,
        headquartersCityState = this.headquartersCityState;
    var hqFull = headquartersCityState;

    if (headquartersCountry) {
      hqFull += " ".concat(headquartersCountry);
    }

    return hqFull;
  })), (0, _defineProperty2.default)(_Model$extend, "isViewable", Ember.computed.or('isDarcyInsightsShown', 'isVendorOverviewShown', 'isVendorMaterialsShown')), (0, _defineProperty2.default)(_Model$extend, "hasContractors", Ember.computed('contractors', function () {
    return this.get('contractors') && this.get('contractors').length > 0;
  })), (0, _defineProperty2.default)(_Model$extend, "company", (0, _relationships.belongsTo)('company')), (0, _defineProperty2.default)(_Model$extend, "customers", (0, _relationships.hasMany)('company')), (0, _defineProperty2.default)(_Model$extend, "strategicPartners", (0, _relationships.hasMany)('company')), (0, _defineProperty2.default)(_Model$extend, "relatedInnovators", (0, _relationships.hasMany)('company')), (0, _defineProperty2.default)(_Model$extend, "contractors", (0, _relationships.hasMany)('user')), (0, _defineProperty2.default)(_Model$extend, "managers", (0, _relationships.hasMany)('user')), (0, _defineProperty2.default)(_Model$extend, "topicTags", (0, _relationships.hasMany)('topicTags')), (0, _defineProperty2.default)(_Model$extend, "serviceLines", (0, _relationships.hasMany)('serviceLines')), (0, _defineProperty2.default)(_Model$extend, "likes", (0, _relationships.hasMany)()), (0, _defineProperty2.default)(_Model$extend, "bookmarks", (0, _relationships.hasMany)()), (0, _defineProperty2.default)(_Model$extend, "comments", (0, _relationships.hasMany)()), (0, _defineProperty2.default)(_Model$extend, "productOverviews", (0, _relationships.hasMany)('product-overview')), (0, _defineProperty2.default)(_Model$extend, "carouselItems", (0, _relationships.hasMany)('carousel-item')), _Model$extend));

  _exports.default = _default;
});