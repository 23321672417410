define("darcy/_pods/components/tailwind-ui-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/4c9oeBR",
    "block": "{\"symbols\":[\"@on\",\"@dataTestToggleName\",\"@classNames\",\"@disabled\",\"@redToggle\",\"@onToggle\"],\"statements\":[[7,\"span\"],[11,\"class\",\"flex items-center\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"pr-3\"],[9],[0,\"ON\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"pr-3\"],[9],[0,\"OFF\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[7,\"span\"],[12,\"data-test-toggle\",[24,2,[]]],[11,\"role\",\"checkbox\"],[11,\"tabindex\",\"0\"],[11,\"aria-checked\",\"false\"],[12,\"class\",[29,\"concat\",[[29,\"if\",[[24,1,[]],[29,\"if\",[[24,5,[]],\"bg-highlight_red\",\"bg-highlight_green\"],null],\"bg-light-grey\"],null],[29,\"if\",[[24,4,[]],\" bg-light-grey cursor-not-allowed focus:shadow-none \"],null],\" relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline \",[24,3,[]]],null]],[12,\"onclick\",[29,\"unless\",[[24,4,[]],[29,\"action\",[[24,0,[]],[24,6,[]]],null]],null]],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[12,\"class\",[30,[[29,\"if\",[[24,1,[]],\"translate-x-5\",\"translate-x-0\"],null],\" inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200\"]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/tailwind-ui-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});