define("darcy/_pods/components/modal/fast-track-storefront/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+MlkCb9F",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"containerClass\"],[true,\"bg-white w-10/12 md:max-w-xl mx-auto rounded shadow-lg z-50 overflow-y-auto\"]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-30\"],[11,\"data-test-button\",\"close-modal\"],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[\"x\"],[[\"class\"],[\"fill-current text-black\"]]],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[29,\"route-action\",[\"hideAllModals\"],null]]],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"py-4 text-left px-6\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"isFastTrackingCompany\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"flex justify-between items-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"text-lg mb-4\"],[11,\"data-test-modal\",\"outside\"],[9],[0,\"\\n          This Storefront is on our to-do list, thank you for fast tracking.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"flex justify-between items-center\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"text-lg mb-4\"],[11,\"data-test-modal\",\"outside\"],[9],[0,\"\\n          This Storefront is on our to-do list, please request to Fast Track to move it up the list\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex justify-center mt-16 pt-2\"],[9],[0,\"\\n        \"],[1,[29,\"fast-track-button\",null,[[\"companyProfile\",\"companyId\",\"showTextButton\"],[[25,[\"companyProfile\"]],[25,[\"companyId\"]],true]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/modal/fast-track-storefront/template.hbs"
    }
  });

  _exports.default = _default;
});