define("darcy/_pods/components/scaled-logo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getScaling(width, height) {
    var prop;
    var ratio = 100 * height / width;

    if (ratio < 21) {
      prop = {
        scaleBy: "imgWidth",
        large: 300,
        medium: 200,
        small: 115,
        extraSmall: 25
      };
    } else if (ratio < 30) {
      prop = {
        scaleBy: "imgWidth",
        large: 213,
        medium: 142,
        small: 105,
        extraSmall: 22
      };
    } else if (ratio < 50) {
      prop = {
        scaleBy: "imgHeight",
        large: 64,
        medium: 42,
        small: 32,
        extraSmall: 9
      };
    } else if (ratio < 100) {
      prop = {
        scaleBy: "imgHeight",
        large: 82,
        medium: 54,
        small: 48,
        extraSmall: 16
      };
    } else {
      prop = {
        scaleBy: "imgHeight",
        large: 100,
        medium: 66,
        small: 60,
        extraSmall: 20
      };
    }

    return prop;
  }

  var _default = Ember.Component.extend({
    tagName: '',
    imgWidth: 0,
    imgHeight: 0,
    size: 'medium',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.imgUrl) {
        var image = new Image();
        image.src = this.imgUrl;
        image.onload = this.onImageLoad.bind(this);
      }
    },
    onImageLoad: function onImageLoad(evt) {
      var imageScale;

      try {
        var img = evt.path ? evt.path.firstObject : evt.target;
        imageScale = getScaling(img.width, img.height);
      } catch (e) {
        imageScale = getScaling(1, 1);
        console.error(e);
      }

      if (this.get('isDestroyed')) return;
      this.set('imgWidth', 'auto');
      this.set('imgHeight', 'auto');
      this.set(imageScale.scaleBy, "".concat(imageScale[this.size], "px"));
    }
  });

  _exports.default = _default;
});