define("darcy/_pods/components/comments-area/comment/component", ["exports", "@babel/runtime/helpers/esm/defineProperty", "lodash", "jquery"], function (_exports, _defineProperty2, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _actions;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    tagName: 'div',
    classNames: ['text-sm', 'my-1'],
    comment: null,
    newReply: null,
    collapseReplies: false,
    showingTruncatedComment: false,
    showingFullComment: false,
    likesPopoverIsShowing: false,
    popoverLikes: [],
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.comment.set('isReplying', false);
    },
    click: function click(e) {
      e.stopPropagation();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var commentElementId = window.location.hash.split("#")[1];
      var commentElement = document.getElementById(commentElementId);

      if (commentElement) {
        if (commentElement !== this.element) return;
        Ember.run.later(function () {
          var pos = commentElement.getBoundingClientRect();
          window.scrollTo(0, pos.y);
        }, 500);
      }
    },
    fetchCommentLikes: function fetchCommentLikes() {
      var popoverLikes = this.store.query('user', {
        reactable_id: this.comment.id,
        reactable_type: this.comment.constructor.modelName,
        reaction: 'like'
      });
      this.set('popoverLikes', popoverLikes);
    },
    replies: Ember.computed('collapseReplies', 'comment.replies.[]', function () {
      if (this.collapseReplies === true) {
        return Ember.isPresent(this.comment.replies) ? [this.comment.replies.firstObject] : [];
      }

      return this.comment.replies;
    }),
    commentMessage: Ember.computed('comment.userMentions', 'comment.message', 'showingTruncatedComment', 'showingFullComment', function () {
      var message = this.comment.message;

      if (this.comment.userMentions) {
        this.comment.userMentions.forEach(function (userMention) {
          message = (0, _lodash.replace)(message, userMention.mention, "\n          <b class=\"text-highlight_green cursor-pointer tooltip-target\" id=\"comment_user_id_".concat(userMention.id, "\">\n            ").concat(userMention.mention, "\n            <span class=\"tooltiptext tooltip-top\">View Profile</span>\n          </b>\n        "));
        });
      }

      if (Ember.isPresent(message) && !this.get('showingTruncatedComment')) {
        if (message.length > 500) {
          message = message.substr(0, 500);
          this.set('showingTruncatedComment', true);
        }
      }

      if (this.get('showingFullComment')) {
        return Ember.String.htmlSafe(message);
      }

      return Ember.String.htmlSafe(message);
    }),
    _openUserPreviewPopover: function _openUserPreviewPopover(id) {
      if (this.currentUser.id === id) return;
      var user = this.store.findRecord('user', id);
      this.userPreview.setModel(user);
    },
    actions: (_actions = {
      replyComment: function replyComment(comment) {
        comment.set('isReplying', true);
        this.set('newReply', this.store.createRecord('comment', {
          parent: comment,
          commentable_id: comment.commentable_id,
          commentable_type: Ember.String.classify(this.get('commentable.constructor.modelName'))
        }));
        var textareas = this.element.getElementsByTagName('textarea');
        textareas.length && textareas[0].focus();
      },
      cancelReply: function cancelReply(comment, reply) {
        if (Ember.isPresent(reply.message)) return;
        comment.set('isReplying', false);
        reply.destroyRecord();
      },
      toggleLikesPopover: function toggleLikesPopover() {
        this.toggleProperty('likesPopoverIsShowing');
        this.fetchCommentLikes();

        if (this.likesPopoverIsShowing === true) {//this.captureAnalyticsEvent();
        }

        this.fetchCommentLikes();
      },
      closePopover: function closePopover() {
        this.set('likesPopoverIsShowing', false);
      },
      openUserPreviewPopover: function openUserPreviewPopover(userId) {
        if (this.currentUser.id === userId) return;
        var user = this.store.findRecord('user', userId);
        this.userPreview.setModel(user);
      }
    }, (0, _defineProperty2.default)(_actions, "openUserPreviewPopover", function openUserPreviewPopover(userId) {
      this._openUserPreviewPopover(userId);
    }), (0, _defineProperty2.default)(_actions, "userMentionClick", function userMentionClick(e) {
      var userId = e.target.id;

      if (userId) {
        this._openUserPreviewPopover(userId.replace('comment_user_id_', ''));
      }
    }), _actions)
  });

  _exports.default = _default;
});