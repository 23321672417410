define("darcy/_pods/components/popup/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    buttonType: 'button',
    buttonClass: '',
    isShowingPopup: false,
    togglePopup: function togglePopup() {
      this.toggleProperty('isShowingPopup');

      if (this.onTogglePopup) {
        this.onTogglePopup();
      }
    },
    closePopup: function closePopup() {
      this.set('isShowingPopup', false);
    }
  });

  _exports.default = _default;
});