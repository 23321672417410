define("darcy/transforms/time", ["exports", "ember-data/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return (0, _moment.default)(serialized).utc().format('HH:mm');
    },
    serialize: function serialize(deserialized) {
      var parced = (0, _moment.default)(deserialized, 'HH:mm');
      return (0, _moment.default)().utc().set({
        hours: parced.get('hours'),
        minutes: parced.get('minutes')
      }).toJSON();
    }
  });

  _exports.default = _default;
});