define("darcy/_pods/components/table/column/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f3WEUgLA",
    "block": "{\"symbols\":[\"@extra\",\"@tableActions\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table-checkbox\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"disabled\",[29,\"not\",[[24,0,[\"filteredRows\"]]],null]],[12,\"checked\",[24,1,[\"isAllSelected\"]]],[12,\"onchange\",[24,2,[\"toggleAll\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"table-checkbox__checkmark\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/column/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});