define("darcy/_pods/events/route", ["exports", "darcy/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deactivate: function deactivate() {
      var controller = this.controllerFor('events.index');
      controller.clearAll();
      controller.set('sort', _constants.SORT_OPTIONS[0]);
      controller.set('query', null);
    }
  });

  _exports.default = _default;
});