define("darcy/_pods/components/search/company-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    tagName: 'article',
    classNames: ['w-full', 'mb-8', 'sm:mb-6'],
    safeExcerpt: Ember.computed('excerpt', function () {
      return Ember.String.htmlSafe(this.excerpt);
    }),
    highlightedExcerpt: Ember.computed('excerpt', 'query', function () {
      var search = this.query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      var re = new RegExp(search, 'gi');

      if (this.excerpt && search.length > 0) {
        return Ember.String.htmlSafe(this.excerpt.replace(re, "<strong class=\"text-black\">$&</strong>"));
      } else {
        return this.excerpt;
      }
    }),
    unpublishedCompanyId: Ember.computed('company.isStorefrontPublished', 'currentUser.classification', 'company.id', function () {
      var isPublished = this.company.isStorefrontPublished;
      var isCurrentUser = Ember.isPresent(this.currentUser);
      var isCurrentUserDarcy = isCurrentUser && this.currentUser.classification === 'darcy';
      var isCurrentUserContractor = isCurrentUser && this.currentUser.classification === 'contractor';
      var isCurrentUserCompany = isCurrentUser && this.currentUser.get('company.id') === this.company.id;
      var isCurrentUserStorefrontAdmin = isCurrentUser && this.currentUser.classification === 'innovator' && this.currentUser.isStorefrontAdmin && isCurrentUserCompany;

      if (!isPublished && !isCurrentUserDarcy && !isCurrentUserContractor && !isCurrentUserStorefrontAdmin) {
        return this.company.id;
      }
    }),
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering');
    }
  });

  _exports.default = _default;
});