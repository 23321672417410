define("darcy/_pods/innovators/view/admin/content/index/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/content"], function (_exports, _emberChangeset, _emberChangesetValidations, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model() {
      var _this = this;

      var company = this.modelFor('innovators.view.admin');
      var contents = this.store.query('content', {
        company_id: company.id
      }).then(function (contents) {
        if (!_this.currentUser.isDarcyUser) {
          return contents.rejectBy('fileType', 'markdown');
        }

        return contents;
      });
      return Ember.RSVP.hash({
        company: company,
        contents: contents
      });
    }
  });

  _exports.default = _default;
});