define("darcy/_pods/components/content/details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uEjORkZN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex flex-none md:pt-6 mb-6\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-shrink-0\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"openUserPreviewPopover\",[25,[\"blog\",\"firstCreator\"]]],null]],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"h-10 w-10 rounded-full\"],[12,\"src\",[25,[\"blog\",\"firstCreator\",\"photoUrl\"]]],[11,\"alt\",\"\"],[9],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"isMobile\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"ember-tooltip\",null,[[\"text\"],[\"View Profile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-xs lg:text-base text-gray-600 ml-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"leading-5 text-xs\"],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"openUserPreviewPopover\",[25,[\"blog\",\"firstCreator\"]]],null]],[11,\"class\",\"cursor-pointer\"],[9],[0,\"\\n        \"],[1,[25,[\"blog\",\"firstCreator\",\"name\"]],false],[0,\"\\n\"],[4,\"unless\",[[25,[\"isMobile\"]]],null,{\"statements\":[[0,\"          \"],[1,[29,\"ember-tooltip\",null,[[\"text\"],[\"View Profile\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\",\"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[1,[25,[\"blog\",\"firstCreator\",\"companyName\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/content/details/template.hbs"
    }
  });

  _exports.default = _default;
});