define("darcy/_pods/account/communities/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    analytics: Ember.inject.service(),
    actions: {
      toggleUserCommunity: function toggleUserCommunity(community) {
        var isSelected = this.get('accountChangeset.communities').toArray().map(function (c) {
          return c.id;
        }).includes(community.id);
        this.analytics.captureEvent(isSelected ? 'deselect_community' : 'select_community', {
          url: window.location.href,
          meta: {
            'community_id': community.id
          }
        });
        this.set('accountChangeset.communities', (0, _lodash.xor)(this.get('accountChangeset.communities').toArray(), [community]));
      },
      save: function save() {
        var _this = this;

        var user = this.get('accountChangeset');
        user.save().then(function () {
          _this.transitionToRoute('account.communities');
        }).catch(function () {
          _this.notifications.error('There was an error updating your account information, please try again.');
        }).finally(function () {
          if (_this.controller) {
            _this.controller.set('isLoading', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});