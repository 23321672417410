define("darcy/_pods/components/sidebar-content-filter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OujVehhO",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"h-12 bg-navy text-black text-xl flex flex-row items-center px-4 px-4 font-bold cursor-pointer lg:cursor-default\"],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[\"mr-2 lg:hidden transform \",[29,\"if\",[[25,[\"isExpanded\"]],\"rotate-90\"],null]]]],[9],[1,[29,\"svg-jar\",[\"triangle\"],[[\"class\"],[\"w-4\"]]],false],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"text-white\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"isExpanded\"]]],null],[29,\"not\",[[25,[\"isExpanded\"]]],null]]],[10],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[30,[\"px-6 lg:px-4 bg-white \",[29,\"if\",[[25,[\"isExpanded\"]],\"h-auto p-5\",\"h-0 overflow-hidden\"],null],\" lg:h-auto\"]]],[9],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"flex flex-wrap w-full pt-4 pb-2\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"filter w-full\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"filter flex w-full items-center\"],[9],[0,\"\\n          \"],[7,\"label\"],[11,\"class\",\"block w-full text-base flex items-start\"],[9],[0,\"\\n            \"],[1,[29,\"square-checkbox\",null,[[\"onToggle\",\"isActive\"],[[29,\"action\",[[24,0,[]],\"toggleItem\",[24,1,[]]],null],[24,1,[\"isSelected\"]]]]],false],[0,\"\\n\\n            \"],[7,\"div\"],[11,\"class\",\"w-full cursor-pointer ml-1 mt-px pt-px font-semibold\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleItem\",[24,1,[]]],null]],[9],[0,\"\\n\\n              \"],[7,\"div\"],[11,\"class\",\"pt-1\"],[9],[0,\"\\n                \"],[1,[24,1,[\"label\"]],false],[0,\"\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/sidebar-content-filter/template.hbs"
    }
  });

  _exports.default = _default;
});