define("darcy/_pods/components/video/vimeo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V9lWMPF1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"video-embed-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[24,0,[\"src\"]]],null,{\"statements\":[[0,\"    \"],[7,\"iframe\"],[12,\"src\",[24,0,[\"src\"]]],[11,\"class\",\"video-embed\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[11,\"title\",\"vimeo\"],[11,\"frameborder\",\"0\"],[11,\"allow\",\"autoplay; fullscreen\"],[11,\"allowfullscreen\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"script\"],[12,\"onload\",[29,\"action\",[[24,0,[]],\"initPlayer\"],null]],[11,\"src\",\"https://player.vimeo.com/api/player.js\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/video/vimeo/template.hbs"
    }
  });

  _exports.default = _default;
});