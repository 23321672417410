define("darcy/validations/attendee-detail", ["exports", "darcy/validators/time", "ember-changeset-validations/validators"], function (_exports, _time, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    arrivalTime: [// validatePresence({ presence: true, ignoreBlank: true, message: 'Please provide {description}' }),
    (0, _validators.validateFormat)({
      regex: /^([01]\d|2[0-3]):?([0-5]\d)$/,
      message: 'Please enter valid {description}'
    }), (0, _time.default)({
      before: 'departureTime'
    })],
    departureTime: [// validatePresence({ presence: true, ignoreBlank: true, message: 'Please provide {description}' }),
    (0, _validators.validateFormat)({
      regex: /^([01]\d|2[0-3]):?([0-5]\d)$/,
      message: 'Please enter valid {description}'
    }), (0, _time.default)({
      after: 'arrivalTime'
    })]
  };
  _exports.default = _default;
});