define("darcy/initializers/aws", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    window.AWS.config.region = _environment.default.AWS.REGION;
    window.AWS.config.credentials = new window.AWS.CognitoIdentityCredentials({
      'IdentityPoolId': _environment.default.AWS.POOL_ID
    });
  }

  var _default = {
    name: 'aws',
    initialize: initialize
  };
  _exports.default = _default;
});