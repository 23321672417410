define("darcy/_pods/components/table/cell/requestor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8ZxENcXq",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[4,\"if\",[[24,1,[\"content\",\"user\",\"email\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[30,[\"mailto:\",[24,1,[\"content\",\"user\",\"email\"]]]]],[9],[1,[24,1,[\"content\",\"user\",\"formalName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1,[\"content\",\"user\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[\"content\",\"user\",\"formalName\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,1,[\"content\",\"company\",\"shortName\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/requestor/template.hbs"
    }
  });

  _exports.default = _default;
});