define("darcy/_pods/components/title-box/base/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: '',
    titleClasses: 'bg-titlebox-blue',
    titleInfo: ''
  });

  _exports.default = _default;
});