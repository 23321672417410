define("darcy/_pods/events/view/logistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oL24gkZ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white w-full py-6 px-4 mb-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex w-100 justify-end pt-3 md:py-2 pr-3 md:pr-0\"],[9],[0,\"\\n      \"],[1,[29,\"share-button\",null,[[\"class\",\"model\",\"topic\",\"body\"],[\"bg-navy text-xs md:text-base text-white rounded py-1 px-2\",[25,[\"model\",\"forum\"]],\"Forum\",[29,\"concat\",[\"Here's a link to the forum from Darcy's \",[29,\"moment-format\",[[25,[\"model\",\"forum\",\"startDate\"]],\"dddd, MMMM D, YYYY\"],null],\" \",[25,[\"model\",\"forum\",\"name\"]]],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"model\",\"forum\",\"details\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"markdown-to-html\",[[29,\"intercept-data\",[[25,[\"model\",\"forum\",\"details\"]],[25,[\"model\"]]],null]],[[\"class\"],[\"markdown text-xs md:text-base px-6 pt-5\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/events/view/logistics/template.hbs"
    }
  });

  _exports.default = _default;
});