define("darcy/_pods/rsvp/attendee-survey/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qoB7d+uP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"py-4 text-left px-2\"],[9],[0,\"\\n \"],[7,\"div\"],[11,\"class\",\"flex justify-between items-center\"],[9],[0,\"\\n   \"],[7,\"p\"],[11,\"class\",\"leading-none text-3xl font-light mb-6\"],[9],[0,\"Pre-Forum Survey\"],[10],[0,\"\\n \"],[10],[0,\"\\n\\n \"],[7,\"p\"],[11,\"class\",\"pb-2 text-l leading-normal\"],[9],[0,\"Please click to take the Pre Forum survey and continue your registration.\"],[10],[0,\"\\n\\n \"],[7,\"div\"],[11,\"class\",\"flex justify-center pt-2\"],[9],[0,\"\\n   \"],[7,\"a\"],[12,\"href\",[23,\"model\"]],[11,\"class\",\"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline\"],[9],[0,\"Take Survey\"],[10],[0,\"\\n \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/rsvp/attendee-survey/template.hbs"
    }
  });

  _exports.default = _default;
});