define("darcy/_pods/register/invalid/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y79c05Wj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"my-8 mx-auto w-full max-w-2xl flex flex-col flex-1 justify-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white md:shadow-md md:rounded px-8 pt-6 pb-8 mb-4\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"leading-none text-4xl font-light mb-8 text-center\"],[9],[0,\"Error\"],[10],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"mb-4\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"pb-2 text-l leading-normal text-center\"],[9],[0,\"Sorry, this email invite link is no longer valid.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/register/invalid/template.hbs"
    }
  });

  _exports.default = _default;
});