define("darcy/tours/innovators", ["exports", "darcy/tours/scrollHandler"], function (_exports, _scrollHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: "Engage with Innovators and View Darcy's Insights",
    image: '/images/innovators_tour_start.png',
    caption: 'Connecting with the Innovators and users of their technologies helps our clients make the best decisions for their business'
  }, {
    selector: '#innovators-index-filter',
    position: 'auto-start',
    highlightClass: 'highlight-border',
    routeName: 'innovators.index',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'On the Innovators page, enter keywords or apply filters for last updated date, topic tags or technology state to generate a list of innovators you are interested in',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    routeName: 'innovators.view.index',
    models: 'darcy',
    selector: '#social-buttons',
    position: 'bottom',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: function scrollToHandler(el) {
      return (0, _scrollHandler.scrollHandler)(el, 'bottom');
    },
    title: '',
    image: '/images/innovators_tour_2.gif',
    caption: "You can follow an innovator's profile, share it with your peers, and ask a question to the community",
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    routeName: 'innovators.view.index',
    selector: '#innovators-view-take-action',
    position: 'left-start',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    image: '/images/innovators_tour_3.gif',
    caption: 'You can also message the innovator and schedule a direct meeting with them. Request to connect with a user of the technology or talk to a Darcy Analyst to learn more',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#innovators-view-tabs',
    position: 'top',
    highlightClass: 'highlight-border',
    scrollTo: false,
    title: '',
    caption: "Dive deeper into our research on the innovator by viewing insights, details and materials. You'll find everything from current and historical customers to video recordings and presentations from the innovator",
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }];
  _exports.default = _default;
});