define("darcy/_pods/rsvp/attendee-details/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // notifications: service(),
    onArrivalTimeChange: Ember.observer('changeset.arrivalTime', function () {
      var _this = this;

      Ember.run.next(function () {
        Ember.run.once(_this.changeset, 'validate');
      });
    }),
    onDepartureTimeChange: Ember.observer('changeset.departureTime', function () {
      var _this2 = this;

      Ember.run.next(function () {
        Ember.run.once(_this2.changeset, 'validate');
      });
    })
  });

  _exports.default = _default;
});