define("darcy/_pods/components/search/company-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aBKruw2Y",
    "block": "{\"symbols\":[],\"statements\":[[5,\"link-to\",[[13,\"onclick\",[29,\"capture-event\",[\"visit_search\"],[[\"query\",\"destinationUrl\",\"company_id\",\"search_query\",\"search_result_id\",\"search_result_type\"],[[25,[\"query\"]],[29,\"concat\",[\"innovators/\",[25,[\"company\",\"slug\"]]],null],[25,[\"company\",\"id\"]],[25,[\"query\"]],[25,[\"company\",\"id\"]],\"innovator\"]]]]],[[\"@route\",\"@model\",\"@query\",\"@class\"],[\"innovators.view\",[25,[\"company\",\"slug\"]],[29,\"hash\",null,[[\"unpublishedCompanyId\"],[[24,0,[\"unpublishedCompanyId\"]]]]],\"flex flex-col sm:flex-row sm:justify-between w-full\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pr-0 sm:pr-4 order-2 sm:order-1\"],[9],[0,\"\\n    \"],[7,\"h4\"],[12,\"class\",[30,[\"text-lg font-semibold \",[29,\"if\",[[25,[\"isHovering\"]],\"text-highlight_green\",\"text-black\"],null]]]],[9],[1,[25,[\"company\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"excerpt\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"search-excerpt leading-relaxed text-sm text-gray-600\"],[9],[0,\"…\"],[1,[23,\"safeExcerpt\"],false],[0,\"…\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sm:w-48 flex-shrink-0 px-0 sm:px-4 mb-2 sm:mt-0 order-1\"],[9],[0,\"\\n    \"],[1,[29,\"scaled-logo\",null,[[\"class\",\"imgUrl\",\"linkSlug\",\"companyType\",\"imgName\",\"size\"],[\"sm:mx-auto\",[25,[\"company\",\"logoUrl\"]],[25,[\"company\",\"slug\"]],[25,[\"company\",\"companyType\"]],[25,[\"company\",\"name\"]],\"medium\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search/company-result/template.hbs"
    }
  });

  _exports.default = _default;
});