define("darcy/_pods/components/search-with-autocomplete/company-profile-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CyGzkhqO",
    "block": "{\"symbols\":[\"@companyProfile\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pr-2px\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/storefront-gray.svg\"],null]]]],[11,\"class\",\"opacity-75 self-start\"],[11,\"alt\",\"icon\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-base leading-5\"],[9],[0,\"\\n    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[24,1,[\"tagline\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-xs leading-4\"],[9],[1,[24,1,[\"tagline\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[4,\"if\",[[24,1,[\"logoUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"img-center self-center\"],[9],[0,\"\\n    \"],[1,[29,\"scaled-logo\",null,[[\"imgUrl\",\"linkSlug\",\"companyType\",\"size\"],[[24,1,[\"logoUrl\"]],[24,1,[\"slug\"]],[24,1,[\"company\",\"companyType\"]],\"small\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-with-autocomplete/company-profile-result/template.hbs"
    }
  });

  _exports.default = _default;
});