define("darcy/_pods/components/popup-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j4R5/5WB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"ember-popover\",[],[[\"@isShown\",\"@event\",\"@tooltipClass\",\"@spacing\"],[[24,0,[\"isShowingPopup\"]],\"none\",[30,[\"tooltip-popup \",[24,0,[\"tooltipClass\"]]]],[24,0,[\"spacing\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pt-4 pr-5 pb-8 pl-5 sm:pr-10\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,0,[\"cancelBubbling\"]]],null]],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/popup-content/template.hbs"
    }
  });

  _exports.default = _default;
});