define("darcy/helpers/event-time", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventTime = eventTime;
  _exports.default = void 0;

  function eventTime(params, hash) {
    var startDate = hash.startDate,
        endDate = hash.endDate,
        timezone = hash.timezone;

    var tz = _moment.default.tz.guess();

    var startTimeString = _moment.default.tz(startDate, 'UTC').format('YYYY-MM-DDTHH:mm:SS');

    var startTime = _moment.default.tz(startTimeString, timezone);

    var endTimeString = _moment.default.tz(endDate, 'UTC').format('YYYY-MM-DDTHH:mm:SS');

    var endTime = _moment.default.tz(endTimeString, timezone);

    if (Ember.isBlank(timezone) || Ember.isBlank(startDate) || Ember.isBlank(endDate)) return null;
    return "".concat(startTime.tz(tz).format('h:mma'), " - ").concat(endTime.tz(tz).format('h:mma'), " ").concat(startTime.format('z'));
  }

  var _default = Ember.Helper.helper(eventTime);

  _exports.default = _default;
});