define("darcy/_pods/events/view/agenda/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lAB4mMxR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white w-full py-6 px-4 mb-4 rounded-r-lg rounded-bl-lg\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"mt-3\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"agendaItems\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"pb-6 zebra-stripe py-4 px-6 md:px-4 rounded\"],[9],[0,\"\\n          \"],[7,\"h5\"],[11,\"class\",\"text-xs md:text-base text-blue-600 mb-2 md:mb-0\"],[9],[1,[29,\"moment-format\",[[24,1,[\"startTime\"]],\"h:mm a\"],null],false],[0,\" - \"],[1,[29,\"moment-format\",[[24,1,[\"endTime\"]],\"h:mm a\"],null],false],[10],[0,\"\\n          \"],[7,\"h6\"],[11,\"class\",\"text-base md:text-lg font-bold\"],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n          \"],[1,[29,\"markdown-to-html\",[[24,1,[\"description\"]]],[[\"class\"],[\"markdown text-xs md:text-sm\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/events/view/agenda/template.hbs"
    }
  });

  _exports.default = _default;
});