define("darcy/_pods/innovators/view/admin/photos/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6seU6Iez",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"class\",\"leading-normal text-2xl font-light\"],[9],[0,\"Edit Item\"],[10],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"text-blue-700 underline\",\"innovators.view.admin.photos\",[25,[\"model\",\"company\",\"slug\"]]]],{\"statements\":[[0,\"  Back to List\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"img\"],[12,\"src\",[25,[\"model\",\"carouselItem\",\"thumbnailUrl\"]]],[11,\"class\",\"my-8\"],[9],[10],[0,\"\\n\\n\"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"submitTask\"]],[25,[\"model\",\"carouselItem\"]]],null]],null]],[11,\"class\",\"bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline\"],[9],[0,\"Delete\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/innovators/view/admin/photos/edit/template.hbs"
    }
  });

  _exports.default = _default;
});