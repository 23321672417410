define("darcy/helpers/split", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.split = split;
  _exports.default = void 0;

  function split(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        string = _ref2[0],
        _ref2$ = _ref2[1],
        splitWith = _ref2$ === void 0 ? ',' : _ref2$;

    return string ? string.split(splitWith) : '';
  }

  var _default = Ember.Helper.helper(split);

  _exports.default = _default;
});