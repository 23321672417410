define("darcy/_pods/components/logo-carousel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iDAxkYDT",
    "block": "{\"symbols\":[\"company\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex items-center mt-2 swiper-wrapper\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"customers\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"swiper-slide\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"logo-item-wrapper carousel-slide\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"flex-auto\"],[9],[0,\"\\n          \"],[1,[29,\"scaled-logo\",null,[[\"imgUrl\",\"linkSlug\",\"companyType\",\"isPublished\",\"imgName\",\"size\"],[[24,1,[\"logoUrl\"]],[24,1,[\"slug\"]],[24,1,[\"companyType\"]],[24,1,[\"isStorefrontPublished\"]],[24,1,[\"name\"]],\"large\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"left-nav transition-opacity delay-75 duration-300 ease-in-out\"],[9],[0,\"\\n  \"],[1,[29,\"svg-jar\",[\"triangle\"],[[\"class\"],[\"text-white w-3 -ml-1 transform rotate-180\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"right-nav transition-opacity duration-300 ease-in-out\"],[9],[0,\"\\n  \"],[1,[29,\"svg-jar\",[\"triangle\"],[[\"class\"],[\"text-white w-3 ml-1\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/logo-carousel/template.hbs"
    }
  });

  _exports.default = _default;
});