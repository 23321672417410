define("darcy/_pods/components/table/cell/topic-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HwcDVzTa",
    "block": "{\"symbols\":[\"tag\",\"@rawValue\"],\"statements\":[[4,\"if\",[[24,2,[\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"m-0 pl-0 italic\"],[9],[0,\"\\n\"],[4,\"each\",[[24,2,[]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/topic-tags/template.hbs"
    }
  });

  _exports.default = _default;
});