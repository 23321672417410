define("darcy/_pods/components/calendar-day/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['eventType', 'hasEvent'],
    classNames: ['eventType', 'calendar-event'],
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    store: Ember.inject.service(),
    hasEvent: Ember.computed('highlightedForums', function () {
      return Boolean(this.highlightedForums);
    }),
    daysForums: Ember.computed('day', 'forums.[]', function () {
      var _this = this;

      var forums = this.forums || [];
      return forums.filter(function (forum) {
        return (0, _moment.default)(_this.day.date).format('YYYY-MM-DD') === (0, _moment.default)(forum.startDate).format('YYYY-MM-DD');
      });
    }),
    highlightedForums: Ember.computed('daysForums.[]', function () {
      return this.daysForums.sortBy('startDate');
    }),
    eventType: Ember.computed('daysForums.[]', function () {
      return this.daysForums.sortBy('startDate').mapBy('eventType').get('firstObject');
    }),
    click: function click()
    /* event */
    {
      if (this.daysForums.length) {
        var slug = this.highlightedForums.get('firstObject').slug;
        this.analytics.set('referrerLinkId', 'event-calendar-day-link');
        this.router.transitionTo('events.view', slug);
      }
    }
  });

  _exports.default = _default;
});