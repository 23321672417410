define("darcy/_pods/nylas-login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    nylas: Ember.inject.service(),
    handleLinkingError: function handleLinkingError(e) {
      console.error(e);
      this.notifications.error('There was an error linking your calendar');
      this.transitionTo('index');
    },
    redirectUser: function redirectUser(slug, defaultSchedulePageCreated) {
      if (!(this.get('currentUser.isStorefrontAdmin') || this.get('currentUser.isDarcyUser'))) {
        // This shouldn't happen
        this.notifications.success('Your calendar has been successfully linked.');
        this.transitionTo('index');
      } else {
        var successMsg;

        if (defaultSchedulePageCreated) {
          successMsg = "Your calendar has been successfully linked and set to allow 30-minute meetings between 9a-5p. Click Manage Calendar if you'd like to adjust your settings.";
        } else {
          successMsg = "Your calendar has been successfully linked. Please click Manage Calendar to configure your meetings.";
        }

        this.notifications.success(successMsg);
        this.transitionTo('innovators.view.admin.index', slug);
      }
    },
    beforeModel: function beforeModel() {
      var _this = this;

      var params = this.paramsFor('nylas-login');
      var access_token = params.access_token,
          account_id = params.account_id,
          slug = params.state;

      if (!access_token || !slug) {
        return this.handleLinkingError('Lacking the data necessary to link');
      }

      return this.store.query('company', {
        slug: slug
      }).then(function (companies) {
        var company = companies.get('firstObject');

        if (company.userHasPermission || _this.get('currentUser.isAdmin') || _this.get('currentUser.isDarcyUser') || company.id === _this.get('currentUser.company.id') && _this.get('currentUser.isStorefrontAdmin')) {
          return company.get('companyProfile').then(function (profile) {
            profile.setProperties({
              nylasToken: access_token,
              nylasAccountId: account_id
            });
            profile.save().then(function () {
              var nylasDefaultSchedulePageConfig = _this.nylas.getInnovatorDefaultSchedulePageConfig(company);

              _this.nylas.createSchedulePageTask.perform(access_token, nylasDefaultSchedulePageConfig).then(function () {
                _this.redirectUser(slug, true);
              }).catch(function (e) {
                console.error(e);

                _this.redirectUser(slug);
              });
            }).catch(_this.handleLinkingError);
          }).catch(_this.handleLinkingError);
        } else {
          return _this.handleLinkingError('Permission denied');
        }
      }).catch(this.handleLinkingError);
    }
  });

  _exports.default = _default;
});