define("darcy/helpers/short-date", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shortDate = shortDate;
  _exports.default = void 0;

  function shortDate(params
  /*, hash*/
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        date = _params[0];

    if (Ember.isBlank(date)) return null;
    var now = (0, _moment.default)();

    var dateMoment = _moment.default.utc(date);

    var time = dateMoment.format('ha').slice(0, -1);

    if (dateMoment.isAfter(now.startOf('day'))) {
      if (dateMoment.isBefore(now.endOf('day'))) {
        return "TODAY AT ".concat(time);
      }

      if (dateMoment.isBefore(now.clone().add(1, 'day').endOf('day'))) {
        return "TOMORROW AT ".concat(time);
      }

      if (dateMoment.isBefore(now.endOf('week'))) {
        var wday = dateMoment.format('ddd').toUpperCase();
        return "".concat(wday, " AT ").concat(time);
      }
    }

    var d = dateMoment.format('MMM D').toUpperCase();
    return "".concat(d, " AT ").concat(time);
  }

  var _default = Ember.Helper.helper(shortDate);

  _exports.default = _default;
});