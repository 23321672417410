define("darcy/_pods/rsvp/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    overrideSurveyComplete: false,
    pass: false,
    steps: Ember.computed('forum.{isVideoIncluded,isSurveyIncluded,isCallIncluded}', 'model.invite.user.isRegistered', 'model.invite.{isProfileComplete,isVideoWatched,isVideoIncluded,isLogisticsComplete,isSurveyComplete,isCallScheduleComplete,isSurveyIncluded,isCallIncluded}', 'overrideSurveyComplete', function () {
      var _this$model = this.model,
          invite = _this$model.invite,
          forum = _this$model.forum;
      var requiresConfirmation = true;
      var steps = [];
      steps.push({
        name: 'Profile',
        isComplete: Ember.get(invite, 'user.isRegistered') || invite.isProfileComplete,
        routeName: 'forum.profile'
      });

      if (invite.isVideoIncluded && forum.introVideoUrl && forum.isVideoIncluded) {
        steps.push({
          name: 'Intro',
          isComplete: invite.isVideoWatched,
          routeName: 'rsvp.intro'
        });
      }

      if (!forum.isVirtual) {
        requiresConfirmation = false;
        steps.push({
          name: 'Logistics',
          isComplete: invite.isLogisticsComplete,
          routeName: 'rsvp.attendee-details'
        });
      }

      if (invite.isSurveyIncluded && forum.isSurveyIncluded) {
        requiresConfirmation = false;
        steps.push({
          name: 'Survey',
          isComplete: invite.isSurveyComplete || this.overrideSurveyComplete,
          routeName: 'rsvp.attendee-survey'
        });
      }

      if (invite.isCallIncluded && forum.isCallIncluded) {
        requiresConfirmation = false;
        steps.push({
          name: 'Call Schedule',
          isComplete: invite.isCallScheduleComplete,
          routeName: 'rsvp.schedule-call'
        });
      }

      if (requiresConfirmation) {
        steps.push({
          name: 'Confirm Attendance',
          isComplete: invite.isRsvpComplete,
          routeName: 'rsvp.confirm-attendance'
        });
      }

      return steps;
    }),
    currentStepName: Ember.computed('steps.@each.isComplete', 'router.currentRouteName', 'pass', function () {
      for (var i = 0; i < this.steps.length; i++) {
        var _this$steps$i = this.steps[i],
            routeName = _this$steps$i.routeName,
            name = _this$steps$i.name;

        if (routeName === this.router.currentRouteName) {
          return name;
        }
      }
    })
  });

  _exports.default = _default;
});