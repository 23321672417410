define("darcy/adapters/my-calendar", ["exports", "darcy/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    url: "my_calendar"
  });

  _exports.default = _default;
});