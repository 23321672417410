define("darcy/_pods/components/modal/nps-score/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ratingClasses = ['bg-red-800 px-4', 'bg-red-700 px-4', 'bg-red-700 px-4', 'bg-red-600 px-4', 'bg-red-600 px-4', 'bg-red-500 px-4', 'bg-red-400 px-4', 'bg-yellow-500 px-4', 'bg-yellow-400 px-4', 'bg-green-500 px-4', 'bg-green-600 px-3'];

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    ratingClasses: ratingClasses,
    isConfirmationShowing: false,
    selectedRating: null,
    feedback: null,
    actions: {
      selectRating: function selectRating(rating) {
        this.set('selectedRating', rating);
      },
      remindMeLater: function remindMeLater() {
        this.set('currentUser.npsScoreRequiredAt', moment().add(7, 'days').toDate());
        this.currentUser.save();
        this.analytics.captureEvent('nps_score_remind_me_later');
      },
      submit: function submit() {
        var _this = this;

        var meta = {
          'value': this.selectedRating
        };
        if (this.feedback) meta.feedback = this.feedback;
        this.analytics.captureEvent('nps_score_select', {
          meta: meta
        }).catch(function (e) {
          if (e.status === 420) {
            // duplicate nps_score_select event
            return;
          }

          throw e;
        });
        this.set('isConfirmationShowing', true);
        Ember.run.later(function () {
          _this.set('currentUser.npsScoreRequiredAt', null);

          _this.currentUser.save();
        }, 1500);
      }
    }
  });

  _exports.default = _default;
});