define("darcy/helpers/intercept-data", ["exports", "@babel/runtime/helpers/esm/typeof", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _typeof2, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mapKey = {
    'forum_date_time': function forum_date_time(model) {
      return (0, _moment.default)(Ember.get(model, 'forum.startDate')).format('dddd, MMMM D, YYYY h:mm a');
    },
    'forum_link': 'forum.link'
  };

  var _default = Ember.Helper.helper(function interceptData(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        text = _ref2[0],
        model = _ref2[1];

    return text && text.replace(/\{\{([a-zA-Z_-]*)\}\}/g, function (match, m1) {
      var key = mapKey[m1];

      switch ((0, _typeof2.default)(key)) {
        case 'string':
          return key.split('.')[0] in model ? Ember.get(model, key) : match;

        case 'function':
          return key(model);

        default:
          return match;
      }
    });
  });

  _exports.default = _default;
});