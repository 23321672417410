define("darcy/_pods/components/search-with-autocomplete/content-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CHnECfH1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"if\",[[29,\"eq\",[[25,[\"content\",\"contentType\"]],\"framework\"],null],\"pr-2px\"],null]]]],[9],[0,\"\\n  \"],[1,[29,\"svg-reports-icon\",null,[[\"fill\"],[\"#9F9F9F\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-base leading-5\"],[9],[0,\"\\n    \"],[1,[25,[\"content\",\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"content\",\"photoUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"img-center self-center\"],[9],[0,\"\\n    \"],[1,[29,\"scaled-logo\",null,[[\"imgUrl\",\"size\"],[[25,[\"content\",\"photoUrl\"]],\"small\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-with-autocomplete/content-result/template.hbs"
    }
  });

  _exports.default = _default;
});