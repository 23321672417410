define("darcy/_pods/components/advanced-filter/content-type/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "lodash", "darcy/utils/constants"], function (_exports, _toConsumableArray2, _lodash, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    tagName: '',
    contentTypeOptions: _constants.CONTENT_TYPE_OPTIONS,
    contentTypes: [],
    actions: {
      onTypeToggle: function onTypeToggle(type) {
        var contentTypes = (0, _toConsumableArray2.default)(this.contentTypes);
        contentTypes = (0, _lodash.xor)(contentTypes, [type]);
        this.onContentTypesChange(contentTypes);

        if (contentTypes.includes(type)) {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'content_type': type,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});