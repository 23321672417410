define("darcy/_pods/events/view/content/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xBJIBNzW",
    "block": "{\"symbols\":[\"group\",\"idx\",\"content\",\"index\"],\"statements\":[[4,\"each\",[[25,[\"groupedContent\"]]],null,{\"statements\":[[4,\"each\",[[24,1,[\"contents\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"list-item/research/default\",null,[[\"isFirst\",\"content\",\"isAccessible\",\"source\"],[[29,\"and\",[[29,\"eq\",[[24,2,[]],0],null],[29,\"eq\",[[24,4,[]],0],null]],null],[24,3,[]],[24,3,[\"isAccessible\"]],[25,[\"forum\"]]]]],false],[0,\"\\n\"]],\"parameters\":[3,4]},null]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/events/view/content/index/template.hbs"
    }
  });

  _exports.default = _default;
});