define("darcy/_pods/components/table/cell/action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+iybW6rj",
    "block": "{\"symbols\":[\"item\",\"@row\",\"@column\"],\"statements\":[[4,\"each\",[[24,3,[\"actions\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"action\",[[24,0,[]],[24,1,[\"action\"]]],null],[24,2,[\"content\"]]],null]],[11,\"class\",\"btn badge btn-sm btn-secondary\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/action/template.hbs"
    }
  });

  _exports.default = _default;
});