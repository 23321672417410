define("darcy/_pods/components/table/cell/toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NC+/Qisy",
    "block": "{\"symbols\":[\"@rawValue\",\"@row\",\"@column\"],\"statements\":[[7,\"div\"],[11,\"class\",\"toggle-wrapper\"],[12,\"onclick\",[29,\"fn\",[[24,3,[\"onToggle\"]],[24,2,[\"content\"]],[29,\"not\",[[24,1,[]]],null]],null]],[9],[0,\"\\n  \"],[1,[29,\"x-toggle\",null,[[\"theme\",\"disabled\",\"value\"],[\"ios\",[24,2,[\"content\",\"isSaving\"]],[24,1,[]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/toggle/template.hbs"
    }
  });

  _exports.default = _default;
});