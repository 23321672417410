define("darcy/_pods/components/advanced-filter/interests/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    interestId: [],
    interestsOptions: Ember.computed(function () {
      return this.store.query('community', {
        communityType: 'interest'
      });
    }),
    selected: Ember.computed('interestId.[]', function () {
      var _this = this;

      if (Ember.isEmpty(this.interestId)) return [];
      return this.interestId.map(function (id) {
        return _this.store.peekRecord('community', id);
      });
    }),
    actions: {
      selectInterests: function selectInterests(interests) {
        this.onInterestsChange(interests.mapBy('id'));
        this.analytics.captureEvent('add_filter', {
          meta: {
            'interests': interests.mapBy('name'),
            'target': 'advanced-filter'
          }
        });
      }
    }
  });

  _exports.default = _default;
});