define("darcy/_pods/getting-started/welcome/controller", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "lodash", "moment"], function (_exports, _toConsumableArray2, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    usages: [],
    actions: {
      toggleUsagePreferences: function toggleUsagePreferences(usage) {
        var usages = (0, _toConsumableArray2.default)(this.usages);
        usages = (0, _lodash.xor)(usages, [usage]);
        this.set('usages', usages);
      },
      goToNextPage: function goToNextPage() {
        this.currentUser.set('usagePreferences', this.usages);
        this.currentUser.save();
        this.analytics.captureEvent('getting_started_usage_select', {
          meta: {
            'value': this.usages.join(', ')
          }
        });
        this.analytics.set('referrerLinkId', 'next');
        this.transitionToRoute('getting-started.step-1');
      }
    }
  });

  _exports.default = _default;
});