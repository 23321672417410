define("darcy/helpers/includes", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "lodash"], function (_exports, _slicedToArray2, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._includes = _includes;
  _exports.default = void 0;

  function _includes(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        collection = _ref2[0],
        item = _ref2[1];

    // Convert potential ArrayProxy to native Array.
    return (0, _lodash.includes)(collection.toArray(), item);
  }

  var _default = Ember.Helper.helper(_includes);

  _exports.default = _default;
});