define("darcy/_pods/components/modal/message-vendor/component", ["exports", "darcy/config/environment", "ember-changeset", "ember-changeset-validations", "darcy/validations/direct-message"], function (_exports, _environment, _emberChangeset, _emberChangesetValidations, _directMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    modals: Ember.inject.service(),
    onSubmit: null,
    changeset: null,
    init: function init() {
      this._super.apply(this, arguments);

      var emailChangeset = new _emberChangeset.default({
        subject: null,
        body: null
      }, (0, _emberChangesetValidations.default)(_directMessage.default), _directMessage.default, {
        skipValidate: false
      });
      this.set('changeset', emailChangeset);
    },
    isDirty: Ember.computed('changeset.isPristine', function () {
      return this.get('changeset.isPristine') === false;
    }),
    sendComposedEmail: function sendComposedEmail(data) {
      var _this = this;

      this.ajax.post("".concat(_environment.default.API.host, "/mailer/message_vendor"), {
        data: data
      }).then(function (res) {
        _this.modals.open('modal/success', {
          title: 'Your Message Has Been Sent!',
          autoCloseDuration: 5000
        });

        _this.onClose();
      }).catch(function (e) {
        _this.notifications.error('There was an error sending your email.');

        console.log(e);
      });
    },
    actions: {
      sendEmail: function sendEmail() {
        this.changeset.validate();

        if (!this.changeset.isValid) {
          return;
        }

        ;
        var messageData = {
          company: this.company.get('id'),
          subject: this.changeset.get('subject'),
          body: this.changeset.get('body')
        };
        this.sendComposedEmail(messageData);
      },
      resetEmailModal: function resetEmailModal() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});