define("darcy/_pods/components/start-shell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4flQNdEY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100 px-4 pt-16 pb-8\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mx-auto max-w-6xl mt-8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"w-full bg-white align-center\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"mx-auto w-full\"],[12,\"src\",[29,\"img-src\",[\"images/welcome-banner.png\"],null]],[11,\"alt\",\"Welcome banner\"],[9],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"isWelcomePage\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"flex items-center border-b-2 border-gray-300 text-lg text-gray-800\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[30,[\"px-8 py-4 \",[29,\"if\",[[25,[\"step1\"]],\"border-b-2 border-gray-800\"],null]]]],[9],[0,\"STEP 1\"],[10],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[30,[\"px-8 py-4 \",[29,\"if\",[[25,[\"step2\"]],\"border-b-2 border-gray-800\"],null]]]],[9],[0,\"STEP 2\"],[10],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[30,[\"px-8 py-4 \",[29,\"if\",[[25,[\"step3\"]],\"border-b-2 border-gray-800\"],null]]]],[9],[0,\"STEP 3\"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"currentUser\",\"classification\"]],\"operator\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[12,\"class\",[30,[\"px-8 py-4 \",[29,\"if\",[[25,[\"step4\"]],\"border-b-2 border-gray-800\"],null]]]],[9],[0,\"STEP 4\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[15,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/start-shell/template.hbs"
    }
  });

  _exports.default = _default;
});