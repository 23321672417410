define("darcy/_pods/components/scroll-here/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      try {
        var options = this.options || {
          block: 'center'
        };
        this.element.scrollIntoView(options);
      } catch (_unused) {
        try {
          this.element.scrollIntoView(false);
        } catch (e) {
          console.error(e);
        }
      }
    }
  });

  _exports.default = _default;
});