define("darcy/_pods/components/stacked-bar-chart/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['relative'],
    labels: [],
    datasets: [],
    colors: [],
    hoverColors: [],
    options: Ember.computed('', function () {
      var _this = this;

      return {
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          mode: 'x',
          displayColors: false,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#000000',
          titleAlign: 'center',
          titleFontFamily: 'Open Sans, sans-serif',
          titleFontStyle: '600',
          titleFontSize: 16,
          bodyFontColor: '#000000',
          bodyAlign: 'center',
          bodyFontFamily: 'Open Sans, sans-serif',
          bodyFontSize: 12,
          bodySpacing: 4,
          titleMarginBottom: 8,
          xPadding: 20,
          yPadding: 16,
          cornerRadius: 0,
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowBlur: 7,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          caretSize: 12,
          caretPadding: 16,
          callbacks: {
            title: function title(tooltipItem, data) {
              var index = tooltipItem.firstObject.index;
              return _this.labels[index].format('MMMM YYYY');
            },
            label: function label(tooltipItem, data) {
              if (_this.onTooltipLabelRender) return _this.onTooltipLabelRender(tooltipItem);
              return _this.datasets.map(function (dataset) {
                return dataset.label + ': ' + tooltipItem.yLabel;
              });
            }
          }
        },
        scales: {
          yAxes: [{
            stacked: true,
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999'
            },
            ticks: {
              offset: true,
              maxTicksLimit: 3,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: 16,
              fontStyle: '500',
              padding: 16
            }
          }],
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              fontColor: '#4A4A4A',
              fontSize: 18,
              fontStyle: '500'
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999',
              fontStyle: '500'
            },
            ticks: {
              offset: true,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: 16,
              fontStyle: '500',
              padding: 16,
              callback: function callback(value) {
                return moment(value).format('MMM');
              }
            }
          }]
        },
        onClick: function onClick(e, elements) {
          if (_this.onClick === undefined) return;
          if (Ember.isEmpty(elements)) return _this.onClick(null);

          _this.onClick(elements.firstObject._index);
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
            offset: 0,
            labels: {
              title: {
                color: '#000000',
                font: {
                  family: 'Open Sans, sans-serif',
                  weight: '500',
                  size: 16
                }
              }
            },
            formatter: function formatter(value, context) {
              // Hide sum for datasets that aren't visually on top.
              if (context.datasetIndex !== _this.datasets.length - 1) return '';
              return _this.totals[context.dataIndex];
            }
          }
        }
      };
    }),
    data: Ember.computed('labels', 'datasets', 'colors', 'hoverColors', function () {
      var _this2 = this;

      return {
        labels: this.labels,
        datasets: this.datasets.map(function (dataset, i) {
          var colors = _this2.colors,
              hoverColors = _this2.hoverColors; // Support array of colors per dataset if array is array of colors.

          if (colors[i].isArray) {
            colors = colors[i];
          }

          return (0, _lodash.merge)(dataset, {
            backgroundColor: colors[i],
            hoverBackgroundColor: hoverColors[i]
          });
        })
      };
    }),
    totals: Ember.computed('datasets', function () {
      return (0, _lodash.unzip)(this.datasets.mapBy('data')).map(_lodash.sum);
    })
  });

  _exports.default = _default;
});