define("darcy/_pods/components/table/base/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    dir: 'asc',
    sort: '',
    toggleRow: function toggleRow(id, toggleTo) {
      var selectedItems = this.selectedItems;
      toggleTo = typeof toggleTo === 'boolean' ? toggleTo : !selectedItems.includes(id);

      if (toggleTo && !selectedItems.includes(id)) {
        selectedItems.pushObject(id);
      } else if (!toggleTo) {
        selectedItems.removeObject(id);
      }

      this.onSelectedChange();
      return toggleTo;
    },
    mergedColumns: Ember.computed('columns', function () {
      return this.columns;
    }),
    sortedModel: Ember.computed.sort('model', 'sortBy'),
    sortBy: Ember.computed('dir', 'sort', 'defaultSort', function () {
      var defaultSort = this.defaultSort || [];
      return ["".concat(this.sort, ":").concat(this.dir)].concat(defaultSort);
    }),
    table: Ember.computed('sortedModel', function () {
      var mergedColumns = this.mergedColumns,
          sortedModel = this.sortedModel;

      var table = _emberLightTable.default.create({
        columns: mergedColumns,
        rows: sortedModel
      });

      var sort = this.sort;

      if (sort) {
        var sortColumn = table.allColumns.findBy('valuePath', sort);

        if (sortColumn) {
          sortColumn.set('sorted', true);
          sortColumn.set('ascending', this.dir === 'asc');
        }
      }

      return table;
    }),
    sortedModelChange: Ember.observer('sortedModel.[]', 'table', function () {
      this.table.setRows(this.sortedModel);

      if (this.selectedItems) {
        this.onSelectedChange();
      }
    }),
    onSelectedChange: function onSelectedChange() {
      var selectedItems = this.selectedItems;
      this.table.rows.forEach(function (row) {
        var isSelected = selectedItems.includes(row.content.id);
        row.set('selected', isSelected);
      });
    },
    actions: {
      toggleAll: function toggleAll() {
        var _this = this;

        var toggleTo = !this.isAllSelected;
        this.model.forEach(function (param) {
          return _this.toggleRow(param.id, toggleTo);
        });
      },
      selectRow: function selectRow(row, e) {
        // e.preventDefault()
        e.stopPropagation();
        e.stopImmediatePropagation();
        var index1 = this.sortedModel.indexOf(row.content);
        var toggledBefore = this.lastCheckboxIndex !== -1 ? this.selectedItems.includes(this.sortedModel.objectAt(this.lastCheckboxIndex).id) : false;
        var toggleTo = this.toggleRow(row.content.id);

        if (e.shiftKey && this.lastCheckboxIndex !== -1 && index1 !== this.lastCheckboxIndex && toggleTo === toggledBefore) {
          var index2 = this.lastCheckboxIndex;
          var min = Math.min(index1, index2);
          var max = Math.max(index1, index2);

          for (var i = min; i < max; i++) {
            var content = this.sortedModel.objectAt(i);
            this.toggleRow(content.id, toggleTo);
          }
        }

        this.set('lastCheckboxIndex', index1);
      },
      onColumnClick: function onColumnClick(column) {
        if (column.sorted && this.sortChange) {
          this.sortChange(column.ascending, column.valuePath);
        }
      }
    }
  });

  _exports.default = _default;
});