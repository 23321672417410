define("darcy/_pods/components/svg-bookmark-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k0g1M7b8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"fill\",[23,\"fill\"]],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"version\",\"1.1\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"enable-background\",\"new 0 0 64 64\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[7,\"path\"],[11,\"d\",\"M52,3H12c-0.6,0-1,0.4-1,1v56c0,0.4,0.2,0.7,0.5,0.9s0.7,0.2,1,0L32,49.2l19.5,11.7C51.6,61,51.8,61,52,61s0.3,0,0.5-0.1  c0.3-0.2,0.5-0.5,0.5-0.9V4C53,3.4,52.6,3,52,3z\"],[9],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-bookmark-icon/template.hbs"
    }
  });

  _exports.default = _default;
});