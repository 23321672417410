define("darcy/_pods/innovators/view/darcy-activity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sPnII9WD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white w-full py-6 px-4 mb-4 rounded-r-lg rounded-bl-lg\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"overflow-auto\"],[9],[0,\"\\n      \"],[1,[29,\"table/base\",null,[[\"loading\",\"height\",\"dir\",\"sort\",\"limit\",\"class\",\"model\",\"columns\",\"sortChange\",\"isLoading\"],[[25,[\"loading\"]],[29,\"if\",[[29,\"media\",[\"isMobile\"],null],\"60vh\",\"30vh\"],null],[25,[\"dir\"]],[25,[\"sort\"]],[25,[\"limit\"]],\"notes-table mb-24\",[25,[\"records\"]],[25,[\"columns\"]],[29,\"action\",[[24,0,[]],\"sortChange\"],null],[25,[\"isLoading\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/innovators/view/darcy-activity/template.hbs"
    }
  });

  _exports.default = _default;
});