define("darcy/_pods/components/comments-area/reply/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'div',
    showingTruncatedReply: false,
    showingFullReply: false,
    likesPopoverIsShowing: false,
    popoverLikes: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var commentElementId = window.location.hash.split("#")[1];
      var commentElement = document.getElementById(commentElementId);

      if (commentElement) {
        if (commentElement !== this.element) return;
        Ember.run.later(function () {
          var pos = commentElement.getBoundingClientRect();
          window.scrollTo(0, pos.y);
        }, 500);
      }
    },
    fetchReplyLikes: function fetchReplyLikes() {
      var popoverLikes = this.store.query('user', {
        reactable_id: this.reply.id,
        reactable_type: this.reply.constructor.modelName,
        reaction: 'like'
      });
      this.set('popoverLikes', popoverLikes);
    },
    replyMessage: Ember.computed('reply.userMentions', 'reply.message', 'showingTruncatedReply', 'showingFullReply', function () {
      var message = this.reply.message;

      if (this.reply.userMentions) {
        this.reply.userMentions.forEach(function (userMention) {
          message = (0, _lodash.replace)(message, userMention.mention, "\n          <b class=\"text-highlight_green cursor-pointer tooltip-target\" id=\"reply_user_id_".concat(userMention.id, "\">\n            ").concat(userMention.mention, "\n            <span class=\"tooltiptext tooltip-top\">View Profile</span>\n          </b>\n        "));
        });
      }

      if (Ember.isPresent(message) && !this.get('showingTruncatedReply')) {
        //hide text longer than 500 characters
        if (message.length > 500) {
          message = message.substr(0, 500);
          this.set('showingTruncatedReply', true);
        }
      } //show hidden text


      if (this.get('showingFullReply')) {
        return Ember.String.htmlSafe(message);
      }

      return Ember.String.htmlSafe(message);
    }),
    actions: {
      toggleLikesPopover: function toggleLikesPopover() {
        this.toggleProperty('likesPopoverIsShowing');
        this.fetchReplyLikes();

        if (this.likesPopoverIsShowing === true) {//this.captureAnalyticsEvent();
        }

        this.fetchReplyLikes();
      },
      closePopover: function closePopover() {
        this.set('likesPopoverIsShowing', false);
      },
      userMentionClick: function userMentionClick(e) {
        var userId = e.target.id;

        if (userId) {
          this.openUserPreviewPopover(userId.replace('reply_user_id_', ''));
        }
      }
    }
  });

  _exports.default = _default;
});