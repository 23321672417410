define("darcy/helpers/join", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.join = join;
  _exports.default = void 0;

  function join(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        array = _ref2[0],
        _ref2$ = _ref2[1],
        joinWith = _ref2$ === void 0 ? ', ' : _ref2$;

    return array ? array.join(joinWith) : '';
  }

  var _default = Ember.Helper.helper(join);

  _exports.default = _default;
});