define("darcy/_pods/call-redirect/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model(_ref) {
      var organization_id = _ref.organization_id,
          service_id = _ref.service_id,
          call_id = _ref.call_id;

      if (organization_id && service_id) {
        return this.store.query('call', {
          organization_id: organization_id,
          service_id: service_id
        }).then(function (_ref2) {
          var firstObject = _ref2.firstObject;
          return firstObject;
        });
      } else {
        return this.store.findRecord('call', call_id);
      }
    },
    afterModel: function afterModel(model) {
      var _this = this;

      if (!model) {
        return this.transitionTo('forums');
      }

      var forum_id = model.get('forum.id');
      var user_id = this.get('currentUser.id');
      return this.store.query('invite', {
        user_id: user_id,
        forum_id: forum_id
      }).then(function (_ref3) {
        var firstObject = _ref3.firstObject;
        var invite = firstObject;

        if (!invite) {
          return _this.transitionTo('forum', model.get('forum.slug'));
        }

        return _this.confirmRequest(forum_id, invite);
      });
    },
    confirmRequest: function confirmRequest(forumId, invite) {
      var _this2 = this;

      if (invite.isRsvpComplete) {
        return this.transitionTo('forum.index', forumId);
      }

      return invite.confirm().then(function () {
        _this2.transitionTo('rsvp', forumId);
      }).catch(function (e) {
        console.error(e);

        _this2.notifications.error('There was a problem confirming your RSVP. Please click here to try again.', {
          autoClear: false,
          onClick: function onClick(notification) {
            _this2.notifications.removeNotification(notification);

            _this2.confirmRequest(forumId, invite);
          }
        });
      });
    }
  });

  _exports.default = _default;
});