define("darcy/_pods/components/progress-indicator/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    registrationCompleted: Ember.computed('steps.@each.isComplete', function () {
      return this.steps && this.steps.every(function (step) {
        return step.isComplete;
      });
    })
  });

  _exports.default = _default;
});