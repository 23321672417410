define("darcy/_pods/events/view/flyer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CC37hwlo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white w-full py-6 px-4 mb-4\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"-ml-3 -mr-3 -mb-3\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"model\",\"forum\",\"flyerPspdfkitId\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"pspdfkit\",null,[[\"documentId\",\"jwt\"],[[25,[\"model\",\"forum\",\"flyerPspdfkitId\"]],[25,[\"model\",\"forum\",\"flyerPspdfkitJwt\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/events/view/flyer/template.hbs"
    }
  });

  _exports.default = _default;
});