define("darcy/models/user-request", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    requestType: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    requestorEmail: (0, _attr.default)('string'),
    companyRelation: (0, _attr.default)('string'),
    details: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    // optional depending on `requestType``
    conciergeRequestType: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)(),
    relatedInnovators: (0, _relationships.hasMany)('companyProfile')
  });

  _exports.default = _default;
});