define("darcy/_pods/components/search/content-result/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['w-full', 'mb-4'],
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    safeExcerpt: Ember.computed('excerpt', function () {
      return Ember.String.htmlSafe(this.excerpt);
    }),
    pdfThumbnail: Ember.computed('content.{isPdf,pspdfkitId,jwt}', function () {
      var content = this.content;

      if (content.isPdf && content.pspdfkitId && content.jwt) {
        return "".concat(_environment.default.PSPDFKit.baseUrl, "/documents/").concat(content.pspdfkitId, "/cover?width=175&jwt=").concat(content.jwt);
      }

      return '';
    }),
    highlightedExcerpt: Ember.computed('excerpt', 'query', function () {
      var search = this.query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      var re = new RegExp(search, 'gi');

      if (this.excerpt && search.length > 0) {
        return Ember.String.htmlSafe(this.excerpt.replace(re, "<strong class=\"text-black\">$&</strong>"));
      } else {
        return this.excerpt;
      }
    }),
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering');
    },
    actions: {
      onClickInnovatorshowNoAccessModal: function onClickInnovatorshowNoAccessModal() {
        this.showNoAccessModal();
      },
      visit: function visit() {
        var search_query = this.query;
        var destinationUrl = "research/".concat(this.get('content.id'));
        this.analytics.captureEvent('visit_search', {
          destinationUrl: destinationUrl,
          meta: {
            search_query: search_query,
            search_result_id: this.get('content.id'),
            search_result_type: 'research'
          }
        });
        this.onVisit(this.get('content.id'), this.get('content.contentType'));
      }
    }
  });

  _exports.default = _default;
});