define("darcy/_pods/components/table/cell/text-overflow/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jNFJjTlA",
    "block": "{\"symbols\":[\"@column\",\"@rawValue\"],\"statements\":[[7,\"div\"],[11,\"class\",\"truncate\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"tooltip\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"ember-tooltip\",null,[[\"text\",\"side\"],[[24,2,[]],[29,\"if\",[[24,1,[\"tooltipSide\"]],[24,1,[\"tooltipSide\"]],\"left\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[24,2,[]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/text-overflow/template.hbs"
    }
  });

  _exports.default = _default;
});