define("darcy/_pods/rsvp/logistics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZlFkq/M1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"eq\",[false,[25,[\"forum\",\"isVirtual\"]]],null]],null,{\"statements\":[[1,[29,\"logistics-form\",null,[[\"onSubmit\",\"onReset\",\"changeset\",\"hasHappyHour\",\"happyHourStartsAt\"],[[29,\"route-action\",[\"save\",[25,[\"changeset\"]]],null],[29,\"route-action\",[\"reset\",[25,[\"changeset\"]]],null],[25,[\"changeset\"]],[25,[\"forum\",\"hasHappyHour\"]],[25,[\"forum\",\"happyHourStartsAt\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"forum\",\"details\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"markdown-to-html\",[[29,\"intercept-data\",[[25,[\"forum\",\"details\"]],[25,[\"model\"]]],null]],[[\"class\"],[\"markdown\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[25,[\"forum\",\"address\"]]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"my-10\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"leading-normal text-l mb-4\"],[9],[1,[25,[\"forum\",\"address\"]],false],[10],[0,\"\\n    \"],[1,[29,\"map-box\",null,[[\"addressQuery\"],[[25,[\"forum\",\"address\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/rsvp/logistics/template.hbs"
    }
  });

  _exports.default = _default;
});