define("darcy/authenticators/existing-session", ["exports", "ember-simple-auth/authenticators/base", "@bugsnag/js", "darcy/config/environment"], function (_exports, _base, _js, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    store: Ember.inject.service(),
    tawk: Ember.inject.service(),
    authenticate: function authenticate(authSession) {
      var _this = this;

      return Ember.RSVP.Promise.resolve().then(function () {
        var user = authSession.get('user');

        try {
          _this.tawk.setUser(user);
        } catch (e) {
          if (_environment.default.environment === 'production') {
            var bugsnagClient = (0, _js.default)({
              apiKey: _environment.default.KEYS.BUGSNAG,
              appVersion: _environment.default.APP.version
            });
            bugsnagClient.notify(e);
          }
        }

        return {
          token: authSession.get('token'),
          sessionId: authSession.get('id'),
          userId: authSession.get('user.id')
        };
      });
    },
    restore: function restore(data) {
      var _this2 = this;

      // verify session
      return this.get('store').query('auth-session', {
        token: data.token
      }).then(function (authSessions) {
        if (Ember.isEmpty(authSessions)) {
          throw new Error('invalid session token');
        }

        var user = authSessions.get('firstObject.user');

        try {
          _this2.tawk.setUser(user);
        } catch (e) {
          if (_environment.default.environment === 'production') {
            var bugsnagClient = (0, _js.default)({
              apiKey: _environment.default.KEYS.BUGSNAG,
              appVersion: _environment.default.APP.version
            });
            bugsnagClient.notify(e);
          }
        }

        return data;
      });
    }
  });

  _exports.default = _default;
});