define("darcy/_pods/components/search-navbar/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    sortOptions: null,
    sort: null,
    displayOption: null,
    displayOptions: [{
      value: 'Table'
    }, {
      value: 'Card'
    }],
    isEventsRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'events');
    }),
    isInnovatorsRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'innovators');
    }),
    isResearchRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'research');
    }),
    isSearchRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'search');
    }),
    actions: {
      setOption: function setOption(option) {
        this.setDisplayOption(option);
      },
      myItemsAnalytics: function myItemsAnalytics() {
        this.analytics.captureEvent('add_filter', {
          meta: {
            'page': 'search results',
            'filter': 'my items'
          }
        });
      },
      isShowingFilters: function isShowingFilters() {
        this.isShowingFilters();
      }
    }
  });

  _exports.default = _default;
});