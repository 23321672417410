define("darcy/_pods/components/bookmark-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3BiLNzIV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-button\",\"bookmark\"],[12,\"class\",[30,[\"text-social-actions-grey text-base font-medium uppercase focus:outline-none p-3 \",[29,\"if\",[[25,[\"bookmarkable\",\"isBookmarkedByCurrentUser\"]],\"text-bookmark-orange\"],null]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggle\"],null]],[9],[0,\"\\n  Save\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/bookmark-button/template.hbs"
    }
  });

  _exports.default = _default;
});