define("darcy/_pods/components/list-item/innovator/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    tagName: '',
    isMobile: _environment.default.isMobile,
    isShowingFasttrackTooltip: false,
    innovatorShareBody: Ember.computed('companyProfile.name', function () {
      var innovatorName = this.get('companyProfile.name');
      return "Here's a link to \"".concat(innovatorName, "\"");
    }),
    innovatorShareSubject: Ember.computed('companyProfile.name', function () {
      var innovatorName = this.get('companyProfile.name');
      return "Sharing Darcy's Innovator Storefront for ".concat(innovatorName, " with you");
    }),
    isCompanyViewable: Ember.computed.readOnly('companyProfile.isViewable'),
    showFasttrackTooltip: function showFasttrackTooltip() {
      var _this = this;

      this.set('isShowingFasttrackTooltip', true);
      Ember.run.later(function () {
        if (!_this.get('isDestroyed')) {
          _this.set('isShowingFasttrackTooltip', false);
        }
      }, 4000);
    },
    isFollowingCompany: Ember.computed('currentUserService.followedCompanyMap', 'companyProfile.companyId', function () {
      var companyId = this.get('companyProfile.companyId');
      return this.currentUserService.isFollowing(companyId);
    }),
    handleCardClick: function handleCardClick() {
      var _this$currentUser, _this$currentUser2;

      if (this.isCompanyViewable === false && ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.classification) !== 'darcy' && ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isInnovatorContractor) === false) {
        this.showFasttrackTooltip();
        return;
      }

      this.router.transitionTo('innovators.view', this.get('companyProfile.slug'));
    },
    actions: {
      closeTooltip: function closeTooltip(toggleState) {
        toggleState();
      }
    }
  });

  _exports.default = _default;
});