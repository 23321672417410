define("darcy/_pods/components/advanced-filter/event-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kHL3mbar",
    "block": "{\"symbols\":[\"speciality\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"font-semibold\"],[9],[0,\"Event Type\"],[10],[0,\"\\n    \"],[7,\"span\"],[9],[1,[29,\"information-tooltip\",null,[[\"text\"],[\"Invite only events targeted towards specific communities\"]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"ul\"],[11,\"class\",\"pl-4 pt-2\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"specialityOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"pb-2\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"block text-base flex\"],[9],[0,\"\\n          \"],[1,[29,\"square-checkbox\",null,[[\"onToggle\",\"isActive\"],[[29,\"action\",[[24,0,[]],\"selectSpeciality\",[24,1,[]]],null],[29,\"includes\",[[25,[\"selectedSpecialties\"]],[24,1,[]]],null]]]],false],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"cursor-pointer ml-2 mt-1\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"selectSpeciality\",[24,1,[]]],null]],[9],[0,\"\\n            \"],[1,[24,1,[]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/event-type/template.hbs"
    }
  });

  _exports.default = _default;
});