define("darcy/_pods/components/map-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UdkOk/rf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"iframe\"],[11,\"width\",\"100%\"],[11,\"height\",\"450\"],[11,\"frameborder\",\"0\"],[11,\"class\",\"border-0\"],[12,\"src\",[30,[\"https://www.google.com/maps/embed/v1/place?q=\",[23,\"addressQuery\"],\"&key=\",[23,\"apiKey\"]]]],[11,\"allowfullscreen\",\"\"],[9],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/map-box/template.hbs"
    }
  });

  _exports.default = _default;
});