define("darcy/_pods/components/events-list-welcome/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HytDSH8B",
    "block": "{\"symbols\":[\"event\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"w-full bg-white flex flex-col \",[29,\"if\",[[25,[\"isWelcomeScreen\"]],\"pl-4\",\"\"],null],\" \",[29,\"if\",[[25,[\"isWelcomeScreen\"]],\"\",\"mb-6\"],null],\"  \",[29,\"if\",[[25,[\"isWelcomeScreen\"]],\"\",\"md:mb-8\"],null]]]],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"isWelcomeScreen\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"h-12 bg-gray-800 text-white text-xl flex flex-row items-center px-6 lg:px-4 font-roboto font-bold\"],[9],[0,\"\\n      This Week on Connect\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pr-6 lg:pr-4 pl-1 mt-4 mb-6\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"isWelcomeScreen\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-gray-700 font-bold text-lg pb-4 pl-4\"],[9],[1,[23,\"currentDate\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex flex-col w-full\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"forumData\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"events-list-welcome/event\",null,[[\"event\",\"onClickEvent\"],[[24,1,[]],[29,\"capture-event\",[\"home_feed_sidebar_event_click\"],[[\"forum_id\"],[[24,1,[\"id\"]]]]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"mx-4 text-gray-700 font-medium\"],[9],[0,\"There are no scheduled events for this week.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/events-list-welcome/template.hbs"
    }
  });

  _exports.default = _default;
});