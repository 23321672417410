define("darcy/_pods/components/pspdfkit-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Kkx3tyK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pdf-image-wrapper\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"class\",\"pdf-image\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/pspdfkit-preview/template.hbs"
    }
  });

  _exports.default = _default;
});