define("darcy/_pods/research/view/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    tooltipStateManager: Ember.inject.service(),
    analytics: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    queryParams: ['chapter'],
    chapter: null,
    activeVideoUrl: null,
    videoLanguageTooltipState: 'open',
    isContentShown: true,
    forumsGroupedByMonth: Ember.computed('content.forums.[]', function () {
      return (0, _lodash.groupBy)(this.content.forums.sortBy('startDate').toArray(), function (forum) {
        return forum.startDateMoment.format('MMMM YYYY');
      });
    }),
    linkQueryParams: Ember.computed('source', function () {
      if (!this.source) return;
      return "?sourceId=".concat(this.sourceId, "&sourceType=").concat(this.sourceType);
    }),
    videoUrl: Ember.computed('content.{url,urlEs}', 'currentUser.preferredLanguage', 'activeVideoUrl', function () {
      if (this.activeVideoUrl) return this.activeVideoUrl;

      if (this.get('currentUser.preferredLanguage') === 'spanish' && this.content.urlEs) {
        return this.content.urlEs;
      }

      return this.content.url;
    }),
    hasSidebarItems: Ember.computed('content.{isVideo,chapters,forums,innovators}', function () {
      var _this$content = this.content,
          isVideo = _this$content.isVideo,
          chapters = _this$content.chapters,
          forums = _this$content.forums,
          innovators = _this$content.innovators;
      return isVideo && chapters.length || forums.length || innovators.length;
    }),
    resetPlayer: function resetPlayer() {
      var _this = this;

      this.set('isContentShown', false);
      Ember.run.schedule('afterRender', function () {
        _this.set('isContentShown', true);
      });
    },
    updateCurrentUserLanguage: function updateCurrentUserLanguage(language) {
      var _this2 = this;

      if (this.currentUser) {
        var preferredLanguage = language === 'es' ? 'spanish' : 'english';
        this.currentUser.set('preferredLanguage', preferredLanguage);
        this.currentUser.save().then(function () {
          _this2.analytics.captureEvent('user_change_preferred_language', {
            content: _this2.content,
            meta: {
              value: preferredLanguage
            }
          });
        });
      }
    },
    actions: {
      setLanguage: function setLanguage(language) {
        var url = this.content.url;

        if (language == 'es') {
          url = this.content.urlEs;
        }

        this.tooltipStateManager.setState('select_video_language', 'close');
        this.set('videoLanguageTooltipState', 'close');
        this.set('activeVideoUrl', url);
        this.resetPlayer();
        this.updateCurrentUserLanguage(language);
      },
      openUserPreviewPopover: function openUserPreviewPopover(user) {
        this.userPreview.setModel(user);
      }
    }
  });

  _exports.default = _default;
});