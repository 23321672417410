define("darcy/_pods/components/suggestion/dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    suggestions: null,
    currentIndex: 0,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('style', "top:".concat(this.position.top + 30, ";"));
    },
    goUp: Ember.observer('upClicked', function () {
      var len = this.get('suggestions.length');
      if (!len) return;
      var currentIndex = this.get('currentIndex') % len;

      if (currentIndex <= 0) {
        this.set('currentIndex', len - 1);
      } else {
        this.set('currentIndex', currentIndex - 1);
      }
    }),
    goDown: Ember.observer('downClicked', function () {
      var len = this.get('suggestions.length');
      if (!len) return;
      var currentIndex = this.get('currentIndex');

      if (currentIndex >= len - 1) {
        this.set('currentIndex', 0);
      } else {
        this.set('currentIndex', currentIndex + 1);
      }
    }),
    select: Ember.observer('enterClicked', function () {
      var currentIndex = this.get('currentIndex');

      if (currentIndex !== -1) {
        this.onSuggestionSelection(currentIndex);
      }
    })
  });

  _exports.default = _default;
});