define("darcy/_pods/rsvp/confirm-attendance/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m5lln3Hx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"loading-spinner\",null,[[\"centered\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"py-4 sm:py-8 sm:px-5 text-center\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"leading-normal text-sm sm:text-base font-light mb-4\"],[9],[0,\"Please confirm your attendance for \"],[7,\"i\"],[9],[1,[25,[\"invite\",\"forum\",\"name\"]],false],[10],[0,\" on \"],[1,[29,\"moment-format\",[[25,[\"invite\",\"forum\",\"startDate\"]],\"MMMM D \\\\at h:mm a\"],null],false],[0,\". You will be provided conference details after confirming.\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"-mb-5\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex items-center justify-end text-sm sm:text-base border-t pt-3\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-5 rounded focus:outline-none focus:shadow-outline\"],[12,\"onclick\",[29,\"route-action\",[\"confirm\"],null]],[9],[0,\"Accept\"],[10],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"inline-block align-baseline font-bold text-sm text-red-500 hover:text-blue-800 ember-view\",\"events\"]],{\"statements\":[[0,\"Decline\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/rsvp/confirm-attendance/template.hbs"
    }
  });

  _exports.default = _default;
});