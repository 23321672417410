define("darcy/_pods/components/advanced-filter/keyword/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    keyword: '',
    _keyword: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      document.getElementById("".concat(this.elementId, "-keyword")).addEventListener("search", function () {
        _this.onKeywordChange(null);
      });
    },
    _onKeywordChange: function _onKeywordChange() {
      this.onKeywordChange(this._keyword);
      this.analytics.captureEvent('add_filter', {
        meta: {
          'keyword': this._keyword,
          'target': 'advanced-filter'
        }
      });
    },
    actions: {
      selectKeyword: function selectKeyword(keyword) {
        this.set('_keyword', keyword);
      },
      onKeyDown: function onKeyDown(val, e) {
        var keyword = this.get('_keyword');

        if (e.keyCode === 8) {
          Ember.run.debounce(this, this._onKeywordChange, 500);
        } else {
          if (keyword.length > 2) {
            Ember.run.debounce(this, this._onKeywordChange, 500);
          }
        }
      }
    }
  });

  _exports.default = _default;
});