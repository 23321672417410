define("darcy/helpers/img-src", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "darcy/config/environment"], function (_exports, _slicedToArray2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.imgSrc = imgSrc;
  _exports.default = void 0;

  function imgSrc(params) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        src = _params[0];

    return _environment.default.rootURL + src;
  }

  var _default = Ember.Helper.helper(imgSrc);

  _exports.default = _default;
});