define("darcy/_pods/innovators/view/admin/photos/edit/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/content"], function (_exports, _emberChangeset, _emberChangesetValidations, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(_ref) {
      var id = _ref.id;
      var company = this.modelFor('innovators.view.admin');
      var carouselItem = this.store.findRecord('carouselItem', id);
      return Ember.RSVP.hash({
        carouselItem: carouselItem,
        company: company
      });
    }
  });

  _exports.default = _default;
});