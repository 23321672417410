define("darcy/_pods/rsvp/attendee-details/route", ["exports", "ember-changeset-validations", "ember-changeset", "darcy/validations/attendee-detail"], function (_exports, _emberChangesetValidations, _emberChangeset, _attendeeDetail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model() {
      return this.modelFor('rsvp').invite;
    },
    setupController: function setupController(controller, model) {
      var _this$modelFor = this.modelFor('rsvp'),
          forum = _this$modelFor.forum;

      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_attendeeDetail.default), _attendeeDetail.default);
      controller.set('forum', forum);
      controller.set('changeset', changeset);
    },
    actions: {
      reset: function reset(changeset) {
        changeset.rollback();
      },
      save: function save(changeset, e) {
        var _this = this;

        e.preventDefault();
        changeset.validate();

        if (!changeset.isValid) {
          return;
        }

        changeset.set('isLogisticsComplete', true);
        changeset.save().then(function () {
          _this.transitionTo('rsvp');
        }).catch(function (e) {
          throw e;
        });
      }
    }
  });

  _exports.default = _default;
});