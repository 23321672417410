define("darcy/models/chapter", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships"], function (_exports, _attr, _model, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    title: (0, _attr.default)('string'),
    tag: (0, _attr.default)('string'),
    timecode: (0, _attr.default)('string'),
    content: (0, _relationships.belongsTo)('content'),
    speakers: (0, _relationships.hasMany)()
  });

  _exports.default = _default;
});