define("darcy/_pods/components/advanced-filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    tagName: '',
    title: 'Filters',
    isExpanded: false,
    showClearAll: true,
    technologyState: null,
    selectedPublisher: null,
    companyId: null,
    interestId: null,
    actions: {
      clearAll: function clearAll() {
        this.onClearAll();
        this.analytics.captureEvent('reset_filter', {
          meta: {
            'target': 'advanced-filter'
          }
        });
      }
    }
  });

  _exports.default = _default;
});