define("darcy/_pods/components/forum-details-header/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tWC1K/Iy",
    "block": "{\"symbols\":[\"@forum\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"leading-none text-3xl font-light mb-2\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"md:flex md:items-start mb-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md:w-1/2 md:pt-2\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"leading-normal\"],[9],[1,[29,\"moment-format\",[[24,1,[\"startDate\"]],\"dddd, MMMM D, YYYY\"],null],false],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"leading-normal\"],[9],[1,[29,\"moment-format\",[[24,1,[\"startDate\"]],\"h:mm a\"],null],false],[0,\" - \"],[1,[29,\"moment-format\",[[24,1,[\"endDate\"]],\"h:mm a\"],null],false],[0,\" \"],[1,[24,1,[\"timezone\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"md:w-1/2 pt-2 lg:pr-2 md:text-right\"],[9],[0,\"\\n    \"],[1,[24,1,[\"formatedAddress\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/forum-details-header/template.hbs"
    }
  });

  _exports.default = _default;
});