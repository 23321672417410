define("darcy/services/social-reactions-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    model: null,
    targetId: null,
    isPopoverOpen: false,
    onModelSet: Ember.observer('model', function () {
      var _this = this;

      this.set('isPopoverOpen', false);
      Ember.run.next(function () {
        _this.set('isPopoverOpen', Ember.isPresent(_this.model));
      });
    }),
    setupPopover: function setupPopover(model, id) {
      this.set('model', model);
      this.set('targetId', id);
    },
    clearPopover: function clearPopover() {
      this.set('model', null);
      this.set('targetId', null);
    }
  });

  _exports.default = _default;
});