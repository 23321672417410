define("darcy/_pods/components/advanced-filter/file-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CGXFobB5",
    "block": "{\"symbols\":[\"fileType\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold\"],[9],[0,\"Format\"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"pl-4 pt-2\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"fileTypeOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"pb-2\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"inline-flex items-center\"],[9],[0,\"\\n          \"],[5,\"input\",[[13,\"id\",[30,[[23,\"elementId\"],\"-file-type-\",[24,1,[\"value\"]]]]],[13,\"name\",[30,[[23,\"elementId\"],\"-file-type\"]]],[13,\"class\",\"form-radio h-6 w-6 border-gray-600 text-gray-800 focus:shadow-none focus:border-gray-600\"],[13,\"checked\",[29,\"eq\",[[25,[\"selectedFileType\"]],[24,1,[\"value\"]]],null]],[13,\"onchange\",[29,\"action\",[[24,0,[]],\"selectFileType\",[24,1,[\"value\"]]],null]]],[[\"@type\"],[\"radio\"]]],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"ml-3\"],[9],[1,[24,1,[\"label\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/file-type/template.hbs"
    }
  });

  _exports.default = _default;
});