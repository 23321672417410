define("darcy/models/product-overview", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    overview: (0, _attr.default)('string'),
    businessModel: (0, _attr.default)('string'),
    technologyInnovations: (0, _attr.default)('string'),
    commercialDeployments: (0, _attr.default)('string'),
    competitorsFreeform: (0, _attr.default)('string'),
    darcyAlternativesFreeform: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company'),
    competitors: (0, _relationships.hasMany)('company'),
    submittedAlternatives: (0, _relationships.hasMany)('company'),
    darcyAlternatives: (0, _relationships.hasMany)('company')
  });

  _exports.default = _default;
});