define("darcy/_pods/components/popup-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    tooltipClass: '',
    spacing: 24,
    isShowingPopup: Ember.computed.readOnly('popup.isShowingPopup'),
    cancelBubbling: function cancelBubbling(evt) {
      try {
        evt.stopPropagation();
      } catch (err) {
        console.error(err);
      }
    }
  });

  _exports.default = _default;
});