define("darcy/_pods/register/route", ["exports", "ember-ajax/errors", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-changeset", "ember-changeset-validations", "darcy/validations/register"], function (_exports, _errors, _unauthenticatedRouteMixin, _emberChangeset, _emberChangesetValidations, _register) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AUTHENTICATOR = 'authenticator:existing-session';

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: {
      action: {
        refreshModel: true
      },
      invite_id: {
        refreshModel: true
      }
    },
    currentUserService: Ember.inject.service('current-user'),
    notifications: Ember.inject.service(),
    session: Ember.inject.service(),
    beforeModel: function beforeModel() {// intentionally disabled for better handling in `model()`
      // this._super(...arguments);
    },
    model: function model(_ref) {
      var _this = this;

      var token = _ref.token;
      var invite = this.store.findRecord('register-invite', token).catch(function () {
        return null;
      });
      return this.store.query('user', {
        token: token
      }).then(function (users) {
        var tokenUser = users.get('firstObject');

        var isAuthenticated = _this.get('session.isAuthenticated');

        if (!tokenUser) {
          // silently re-direct if token does not exist
          _this.transitionTo('/');
        }

        if (isAuthenticated) {
          return _this.currentUserService.load().then(function (currentUser) {
            if (currentUser.get('id') !== tokenUser.get('id')) {
              _this.notifications.error('Registration link belongs to another user. Sign out and try again.');
            }

            _this.transitionTo('/');
          });
        }

        if (tokenUser.get('isRegistered')) {
          _this.notifications.info('It looks like you have already created an account, please login');

          _this.transitionTo('login');

          return;
        }

        return tokenUser;
      }).then(function (user) {
        var domain = user.get('company.companyDomains.firstObject');
        return Ember.RSVP.hash({
          user: user,
          domain: domain,
          invite: invite
        });
      });
    },
    setupController: function setupController(controller, _ref2) {
      var user = _ref2.user,
          invite = _ref2.invite,
          domain = _ref2.domain;

      this._super.apply(this, arguments);

      var steps = [{
        name: 'Registration'
      }];

      if (domain) {
        user.set('companyName', domain.name);
        user.set('companyType', domain.companyType);
      }

      controller.set('steps', steps);
      controller.set('invite', invite);
      controller.set('domain', domain);
      var userChangeset = new _emberChangeset.default(user, (0, _emberChangesetValidations.default)(_register.default), _register.default);
      controller.set('userChangeset', userChangeset);
    },
    actions: {
      register: function register(userChangeset) {
        var _this2 = this;

        var action = this.controller.action;
        var _this$controller$mode = this.controller.model,
            user = _this$controller$mode.user,
            invite = _this$controller$mode.invite;
        var isInviteAccepted, isInviteDeclined;
        userChangeset.set('isRegistered', true);
        return userChangeset.save().then(function () {
          // Unset password fields after registering
          user.set('password');
          user.set('passwordConfirmation');
          user.set('token');
          var authSession = user.get('authSession');
          return _this2.get('session').authenticate(AUTHENTICATOR, authSession).then(function () {
            return user;
          });
        }).then(function () {
          _this2.set('session.data.authenticated.source', 'register');

          return _this2.currentUserService.load();
        }).then(function () {
          if (!invite) return;

          if (action === 'accept') {
            invite.set('isRsvpComplete', true);
            return invite.save().then(function () {
              isInviteAccepted = true;
            }).catch(function (err) {
              // fail silently to user
              console.error(err);
            });
          }

          if (action === 'decline') {
            invite.set('declinedAt', new Date());
            return invite.save().then(function () {
              isInviteDeclined = true;
            }).catch(function (err) {
              console.error(err);
            });
          }
        }).then(function () {
          if (invite) {
            var forumId = invite.belongsTo('forum').id();

            _this2.transitionTo('events.view', forumId);
          }
        }).catch(function (err) {
          console.error(err);
          var firstError = err && err.errors && err.errors[0];

          if (firstError && firstError.status == 422) {
            _this2.transitionTo('register.taken');
          } else if (err.name === 'TransitionAborted') {// likely due to forum permission error, fail silently
            // to allow correct redirect
          } else {
            _this2.notifications.error((firstError ? firstError.detail : firstError) || err);

            _this2.transitionTo('register.invalid');
          }
        });
      },
      error: function error(_error) {
        if ((0, _errors.isNotFoundError)(_error)) {
          this.transitionTo('register.invalid');
        } else {
          console.error(_error);
          this.transitionTo('login');
        }
      }
    }
  });

  _exports.default = _default;
});