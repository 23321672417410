define("darcy/_pods/components/sticky-sidebar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xC/5FYtS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[24,0,[\"elId\"]]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/sticky-sidebar/template.hbs"
    }
  });

  _exports.default = _default;
});