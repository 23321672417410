define("darcy/services/s3", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    uploadFile: function uploadFile(file) {
      var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      var _options$Bucket = options.Bucket,
          Bucket = _options$Bucket === void 0 ? _environment.default.AWS.S3_BUCKET : _options$Bucket,
          _options$Key = options.Key,
          Key = _options$Key === void 0 ? file.name : _options$Key,
          _options$Body = options.Body,
          Body = _options$Body === void 0 ? file : _options$Body,
          _options$ACL = options.ACL,
          ACL = _options$ACL === void 0 ? 'public-read' : _options$ACL;
      var upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: Bucket,
          Key: Key,
          Body: Body,
          ACL: ACL
        }
      });
      return upload.promise().then(function (data) {
        return data.Location;
      }, function (err) {
        return alert('There was an error uploading your file: ', err.message);
      });
    }
  });

  _exports.default = _default;
});