define("darcy/validations/user", ["exports", "ember-changeset-validations/validators", "darcy/validators/unique-email"], function (_exports, _validators, _uniqueEmail) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)(true)],
    lastName: [(0, _validators.validatePresence)(true)],
    title: [(0, _validators.validatePresence)(true)],
    companyName: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validateFormat)({
      type: 'email'
    })],
    password: [(0, _validators.validateLength)({
      min: 8
    })],
    passwordConfirmation: [(0, _validators.validateConfirmation)({
      on: 'password'
    })]
  };
  _exports.default = _default;
});