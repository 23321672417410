define("darcy/_pods/innovators/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    analytics: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    loading: Ember.inject.service(),
    metaTags: Ember.inject.service('meta-tags'),
    model: function model(_ref) {
      var _this = this;

      var slug = _ref.slug,
          unpublishedCompanyId = _ref.unpublishedCompanyId;

      // search/company-result pre-determines if is an unpublished company.
      if (unpublishedCompanyId) {
        this.transitionTo('innovators.index', {
          queryParams: {
            unpublishedCompany: unpublishedCompanyId
          }
        });
        return;
      }

      return this.store.queryRecord('company-profile', {
        slug: slug
      }).then(function (companyProfile) {
        var _this$currentUser, _this$currentUser2;

        var notes = [];

        if (((_this$currentUser = _this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.classification) === 'darcy' || ((_this$currentUser2 = _this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.classification) === 'contractor') {
          notes = _this.store.query('analytics-event', {
            company_profile_id: companyProfile.id,
            event_type: ['notes']
          }).then(function (notes) {
            return notes.toArray();
          });
        }

        return Ember.RSVP.hash({
          companyProfile: companyProfile,
          notes: notes
        });
      }).catch(function (error) {
        if (error.status === 404) {
          return _this.transitionTo('innovators.index', {
            queryParams: {
              showNoAccessModal: true
            }
          });
        } else {
          throw error;
        }
      });
    },
    afterModel: function afterModel(model) {
      if (model.companyProfile) {
        var headTags = this.metaTags.getHeadTags(model.companyProfile);
        this.set('headTags', headTags);
      }

      return model;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.companyProfile.isAccessible === false) {
        this.send('showNoAccessModal');
        this.analytics.captureEvent('free_account_notification', {
          url: window.location.href
        });
      }
    },
    actions: {
      didTransition: function didTransition() {
        this.set('loading.isActive', false);
      },
      onNoteCreated: function onNoteCreated(note) {
        this.controller.model.notes.pushObject(note);
      }
    }
  });

  _exports.default = _default;
});