define("darcy/_pods/components/table/row/events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lDNe5HxL",
    "block": "{\"symbols\":[\"request\",\"column\",\"@row\"],\"statements\":[[4,\"each\",[[25,[\"columns\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[29,\"concat\",[\"light-table/cells/\",[24,2,[\"cellType\"]]],null],[24,2,[]],[25,[\"row\"]]],[[\"table\",\"class\",\"rawValue\",\"tableActions\",\"extra\"],[[25,[\"table\"]],[29,\"concat\",[\"events-table-data\",[29,\"if\",[[24,3,[\"content\",\"isPreviewOnly\"]],\" is-preview-only\"],null]],null],[29,\"get\",[[25,[\"row\"]],[24,2,[\"valuePath\"]]],null],[25,[\"tableActions\"]],[25,[\"extra\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"let\",[[24,3,[\"content\"]]],null,{\"statements\":[],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/row/events/template.hbs"
    }
  });

  _exports.default = _default;
});