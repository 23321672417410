define("darcy/_pods/events/view/attendees/route", ["exports", "ember-changeset", "ember-changeset-validations"], function (_exports, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('events.view'),
          forum = _this$modelFor.forum;

      if (!this.currentUser || !forum.showAttendees) return this.transitionTo('events.view.index');
    },
    model: function model() {
      var _this$modelFor2 = this.modelFor('events.view'),
          forum = _this$modelFor2.forum;

      var allInvitedUsers = this.store.query('user', {
        forum_id: forum.id
      });
      var myCompanyInvites = this.store.query('invite', {
        forum_id: forum.id
      });
      return Ember.RSVP.hash({
        allInvitedUsers: allInvitedUsers,
        myCompanyInvites: myCompanyInvites
      });
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var _this$modelFor3 = this.modelFor('events.view'),
          forum = _this$modelFor3.forum;

      var isUpcoming = forum.isUpcoming;
      var myCompanyAttendees = [];
      var others = [];
      model.allInvitedUsers.forEach(function (user) {
        if (user.get('company.id') === _this.get('currentUser.company.id')) {
          myCompanyAttendees.pushObject(user);
        } else if (user.get('company.id')) {
          others.pushObject(user);
        }
      });
      others = others.sortBy('lastName');
      myCompanyAttendees = myCompanyAttendees.sortBy('lastName');
      var companies = others.uniqBy('company.id').sortBy('company.name').map(function (user) {
        var name = user.get('company.name');
        var id = user.get('company.id');
        var logoUrl = user.get('company.logoUrl');
        return {
          name: name,
          id: id,
          logoUrl: logoUrl
        };
      });
      var myCompanyAttendeesIds = myCompanyAttendees.mapBy('id');
      var myCompanyInvitees = model.myCompanyInvites.reject(function (invite) {
        return myCompanyAttendeesIds.includes(invite.get('user.id'));
      }).mapBy('user').sortBy('lastName');
      var emailData = {
        recipient: null,
        recipientName: null,
        emailSubject: null,
        emailBody: null
      };
      controller.setProperties({
        forum: forum,
        myCompanyInvitees: isUpcoming ? myCompanyInvitees.rejectBy('isInactive') : myCompanyInvitees,
        myCompanyAttendees: isUpcoming ? myCompanyAttendees.rejectBy('isInactive') : myCompanyAttendees,
        others: isUpcoming ? others.rejectBy('isInactive') : others,
        companies: companies,
        emailData: emailData,
        currentUser: this.currentUser
      });
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.resetEmailModal();
      }
    }
  });

  _exports.default = _default;
});