define("darcy/_pods/components/see-more-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zySMwoDC",
    "block": "{\"symbols\":[\"@loadMore\"],\"statements\":[[7,\"div\"],[12,\"onclick\",[24,1,[]]],[11,\"class\",\"flex justify-center w-full items-center text-gray-700 border-t-2 border-gray-500 pt-6 uppercase cursor-pointer\"],[9],[0,\"\\n  See More\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/see-more-pagination/template.hbs"
    }
  });

  _exports.default = _default;
});