define("darcy/models/register-invite", ["exports", "darcy/models/invite"], function (_exports, _invite) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invite.default.extend({});

  _exports.default = _default;
});