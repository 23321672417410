define("darcy/_pods/home/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model() {
      return this.modelFor('home');
    },
    setupController: function setupController(controller, model, transition) {
      this._super(controller, model);

      this.store.findAll('topic-tag').then(function (topicTags) {
        controller.set('topicTags', topicTags);
      });
      var from = transition.from;
      var loadData = (from === null || from === void 0 ? void 0 : from.name) !== 'research.view' && (from === null || from === void 0 ? void 0 : from.name) !== 'home.article' || (from === null || from === void 0 ? void 0 : from.name) === 'research.view' && (from === null || from === void 0 ? void 0 : from.queryParams.sourceId) || Ember.isEmpty(controller.records);
      if (!from || loadData) controller.fetchRecords.perform(true);
    }
  });

  _exports.default = _default;
});