define("darcy/_pods/components/blanket-ui/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['blanket-ui'],
    classNameBindings: ['isShowing'],
    shouldLockScroll: true,
    isEnabled: false,
    isShowing: false,
    didInsertElement: function didInsertElement() {
      this.onIsEnabledChange();
    },
    willDestroyElement: function willDestroyElement() {
      this.unlockScroll();
      this.set('isShowing', false);
    },
    onIsEnabledChange: Ember.observer('isEnabled', function () {
      if (this.isEnabled) {
        this.showBlanket();
      } else {
        this.hideBlanket();
      }
    }),
    lockScroll: function lockScroll() {
      if (this.shouldLockScroll) {
        (0, _jquery.default)('html').addClass('lock-scroll');
      }
    },
    unlockScroll: function unlockScroll() {
      (0, _jquery.default)('html').removeClass('lock-scroll');
    },
    showBlanket: function showBlanket() {
      this.lockScroll();
      this.set('isShowing', true);
    },
    hideBlanket: function hideBlanket() {
      this.unlockScroll();
      this.set('isShowing', false);
    }
  });

  _exports.default = _default;
});