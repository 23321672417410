define("darcy/_pods/team/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return [{
        name: 'Hossein Rokhsari',
        imgUrl: 'images/team/hossein.jpeg',
        email: 'mailto:hossein@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/hossein-rokhsari-31785412/'
      }, {
        name: 'Jeremy Sweek',
        imgUrl: 'images/team/jeremy-1.jpeg',
        email: 'mailto:jeremy@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/jeremy-sweek-5a341a21/'
      }, {
        name: 'Marcial Manzano',
        imgUrl: 'images/team/marcial.jpeg',
        email: 'mailto:marcial@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/marcial-manzano-330b663/'
      }, {
        name: 'Lennart Huijbers',
        imgUrl: 'images/team/lennart.jpeg',
        email: 'mailto:lennart@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/lennarthuijbers/'
      }, {
        name: 'Jack Blears',
        imgUrl: 'images/team/Jack-Blears-550.jpeg',
        email: 'mailto:jack@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/jack-blears/'
      }, {
        name: 'Marelyn Rios',
        imgUrl: 'images/team/Marelyn-Rios_small.jpeg',
        email: 'mailto:marelyn@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/marelyn-rios/'
      }, {
        name: 'Jeff Barr',
        imgUrl: 'images/team/Jeff-Barr-550.jpeg',
        email: 'mailto:jeff@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/barrjeff/'
      }, {
        name: 'Katie House',
        imgUrl: 'images/team/Katie-House_small.jpeg',
        email: 'mailto:katie@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/katie-house-a51b977b/'
      }, {
        name: 'Ruy Riavitz',
        imgUrl: 'images/team/Ruy.jpeg',
        email: 'mailto:ruy@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/ruy-riavitz-714807b/'
      }, {
        name: 'Susan Cunningham',
        imgUrl: 'images/team/susan.jpeg',
        email: 'mailto:susan@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/susan-cunningham-29988310/'
      }, {
        name: 'Corina Perez',
        imgUrl: 'images/team/Cori-1200x1199.png',
        email: 'mailto:corina@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/anacperezsiblesz/'
      }, {
        name: 'Florencia Warner',
        imgUrl: 'images/team/Flor-400x400.png',
        email: 'mailto:florencia@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/maria-florencia-warner-vieyra-6b9ab913b/'
      }, {
        name: 'Rahul Avadhoot',
        imgUrl: 'images/team/Rahul-400x400.png',
        email: 'mailto:rahul@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/rahulavadhoot/'
      }, {
        name: 'Claudia Oquendo',
        imgUrl: 'images/team/Claudia_OK-400x400.png',
        email: 'mailto:claudia@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/claudiaoquendoaponte/'
      }, {
        name: 'Fran Colombo',
        imgUrl: 'images/team/francisco.png',
        email: 'mailto:francisco@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/francisco-alvarez-colombo-b95ab6128/'
      }, {
        name: 'Andres Gibson',
        imgUrl: 'images/team/andres.jpeg',
        email: 'mailto:andres@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/andreslg/'
      }, {
        name: 'Florencia Hoffmann',
        imgUrl: 'images/team/flor.jpeg',
        email: 'mailto:mflorencia@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/mfhoffmann/'
      }, {
        name: 'Mora Fernández',
        imgUrl: 'images/team/Mora.jpg',
        email: 'mailto:mora@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/mora-f-jurado/'
      }, {
        name: 'David Wishnow',
        imgUrl: 'images/team/David.jpg',
        email: 'mailto:dwishnow@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/davidwishnow/'
      }, {
        name: 'Brad Davis',
        imgUrl: 'images/team/Brad.jpg',
        email: 'mailto:brad@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/contactbraddavis/'
      }, {
        name: 'Hannah Mitchell',
        imgUrl: 'images/team/Hannah_Mitchell.jpg',
        email: 'mailto:hannah@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/hannah-mitchell-engineer/'
      }, {
        name: 'Lindsey Motlow',
        imgUrl: 'images/team/Lindsey.jpg',
        email: 'mailto:lindseym@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/lindseymotlow/'
      }, {
        name: 'Lola Rojo',
        imgUrl: 'images/team/Lola_Rojo.jpg',
        email: 'mailto:dolores@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/lola-rojo/'
      }, {
        name: 'Carolina A Blanchet',
        imgUrl: 'images/team/Carolina.jpg',
        email: 'mailto:carolina@darcypartners.com',
        linkedin: 'https://www.linkedin.com/in/carolina-alvarez-c-blanchet-53669151/'
      }];
    }
  });

  _exports.default = _default;
});