define("darcy/services/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    model: null,
    modelName: null,
    isShareViaEmailModalOpen: Ember.computed('model', function () {
      return Ember.isPresent(this.model);
    }),
    setModel: function setModel(model, modelName) {
      this.set('model', model);
      if (modelName) this.set('modelName', modelName);
    },
    clearModel: function clearModel(model) {
      this.set('model');
    }
  });

  _exports.default = _default;
});