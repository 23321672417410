define("darcy/_pods/components/header/desktop/default/component", ["exports", "lodash", "darcy/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    router: Ember.inject.service(),
    notificationsService: Ember.inject.service('api-notifications'),
    analytics: Ember.inject.service(),
    appTour: Ember.inject.service(),
    session: Ember.computed.readOnly('currentUserService.session'),
    tagName: '',
    isMobile: _environment.default.isMobile,
    activeNavLink: Ember.computed('isDarkNavbar', function () {
      return this.isDarkNavbar ? '#FFF' : '#0F3B5E';
    }),
    inactiveNavLink: Ember.computed('isDarkNavbar', function () {
      return this.isDarkNavbar ? '#E2E8F0' : '#7F7F7F';
    }),
    hideHeaderItems: Ember.computed('currentUser.classification', 'router.currentRouteName', function () {
      return this.get('currentUser.classification') === 'contractor' || Ember.isPresent(this.router.currentRouteName) && this.router.currentRouteName.includes('getting-started');
    }),
    logoLinkRoute: Ember.computed('session.isAuthenticated', 'currentUser.id', 'currentUser.isInnovator', 'currentUser.classification', function () {
      var isAuthenticated = this.session.isAuthenticated;

      if (isAuthenticated && this.get('currentUser.isInnovator')) {
        return 'innovators.view';
      } else if (isAuthenticated && this.get('currentUser.classification') === 'contractor') {
        return 'innovators.index';
      } else if (isAuthenticated && this.get('currentUser.isInnovator') === false) {
        return 'home.index';
      } else {
        return 'welcome';
      }
    }),
    logoLinkModels: Ember.computed('session.isAuthenticated', 'currentUser.id', 'currentUser.isInnovator', 'currentUser.classification', function () {
      var isAuthenticated = this.session.isAuthenticated;
      var currentUser = this.currentUser;

      if (isAuthenticated && currentUser && currentUser.isInnovator) {
        return [currentUser.get('company.slug')];
      } else if (isAuthenticated && currentUser && currentUser.classification === 'contractor') {
        return [];
      } else if (isAuthenticated && currentUser && currentUser.isInnovator === false) {
        return [];
      } else {
        return [];
      }
    }),
    actions: {
      toggleDropdown: function toggleDropdown(isClickOutside) {
        this.toggleProfileDropdown(isClickOutside);
      },
      openStartTourModal: function openStartTourModal() {
        this.set('appTour.isStartTourModalOpen', true);
        this.analytics.captureEvent('take_user_tour');
      }
    }
  });

  _exports.default = _default;
});