define("darcy/_pods/events/view/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('events.view'),
          forum = _this$modelFor.forum;

      if (Ember.isPresent(forum) && forum.startDate < Date.now() && forum.canViewContents) {
        this.transitionTo('events.view.content');
      } else {
        this.transitionTo('events.view.intro');
      }
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});