define("darcy/_pods/components/content/details/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userPreview: Ember.inject.service(),
    actions: {
      openUserPreviewPopover: function openUserPreviewPopover(user, e) {
        e.preventDefault();
        e.stopPropagation();
        this.userPreview.setModel(user);
      }
    }
  });

  _exports.default = _default;
});