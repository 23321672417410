define("darcy/_pods/components/loading-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nH4K0vMP",
    "block": "{\"symbols\":[\"@class\",\"@centered\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"lds-ring \",[29,\"if\",[[24,2,[]],\"centered\"],null],\" \",[24,1,[]]]]],[9],[7,\"div\"],[9],[10],[7,\"div\"],[9],[10],[7,\"div\"],[9],[10],[7,\"div\"],[9],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/loading-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});