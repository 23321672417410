define("darcy/_pods/components/table/cell/date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ru4uw2VM",
    "block": "{\"symbols\":[\"@column\",\"@rawValue\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"moment-format\",[[24,2,[]],[29,\"or\",[[24,1,[\"format\"]],\"MM/DD/YYYY\"],null]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  -\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/date/template.hbs"
    }
  });

  _exports.default = _default;
});