define("darcy/_pods/innovators/view/admin/content/edit/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/content"], function (_exports, _emberChangeset, _emberChangesetValidations, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model(_ref) {
      var id = _ref.id;
      return this.store.findRecord('content', id);
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_content.default), _content.default, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});