define("darcy/_pods/components/register-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PHIZoDPN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-button\",\"register\"],[11,\"class\",\"inline-flex justify-center py-2 px-6 md:px-8 rounded-full border-transparent bg-orange-400 text-lg font-semibold text-white cursor-pointer\"],[12,\"disabled\",[25,[\"registerTask\",\"isRunning\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"register\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"registerTask\",\"isIdle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-calendar-check\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" Register\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"i\"],[11,\"class\",\"fa fa-circle-o-notch fa-spin mr-1\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" Registering...\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\\n\"],[5,\"ember-popover\",[],[[\"@side\",\"@event\",\"@isShown\",\"@spacing\",\"@tooltipClass\"],[[29,\"if\",[[29,\"media\",[\"isMobile\"],null],\"top-start\",\"bottom\"],null],\"none\",[23,\"isShowingThanksPopover\"],24,\"tooltip-innovator w-64 sm:w-40 md:w-56 lg:w-64 xl:w-96 sm:pb-8 md:pb-4\"]],{\"statements\":[[0,\"\\n  \"],[7,\"b\"],[9],[0,\"Thanks!\"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"You will receive a calendar invite shortly.\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/register-button/template.hbs"
    }
  });

  _exports.default = _default;
});