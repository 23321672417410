define("darcy/_pods/components/map-box/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GOOGLE_MAPS_API_KEY = _environment.default.KEYS.GOOGLE_MAPS;

  var _default = Ember.Component.extend({
    apiKey: GOOGLE_MAPS_API_KEY,
    tagName: ''
  });

  _exports.default = _default;
});