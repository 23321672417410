define("darcy/models/community", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    hex: (0, _attr.default)('string', {
      defaultValue: '#000000'
    }),
    isOpen: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isHidden: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    order: (0, _attr.default)('number'),
    communityType: (0, _attr.default)('string'),
    urlImage: (0, _attr.default)('string'),
    companyUserCount: (0, _attr.default)('number'),
    userHasPermission: (0, _attr.default)('boolean'),
    communityGroup: (0, _relationships.belongsTo)(),
    serviceLines: (0, _relationships.hasMany)()
  });

  _exports.default = _default;
});