define("darcy/models/user", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _toConsumableArray2, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    personalEmail: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    photoUrl: (0, _attr.default)('string'),
    preferredLanguage: (0, _attr.default)('string', {
      defaultValue: 'english'
    }),
    avatarBackgroundColor: (0, _attr.default)('string'),
    classification: (0, _attr.default)('string'),
    isAdmin: (0, _attr.default)('boolean'),
    isOperator: (0, _attr.default)('boolean'),
    isReadOnlyAdmin: (0, _attr.default)('boolean'),
    isStorefrontAdmin: (0, _attr.default)('boolean'),
    isDarcyUser: (0, _attr.default)('boolean'),
    isSponsor: (0, _attr.default)('boolean'),
    isInnovator: (0, _attr.default)('boolean'),
    isInnovatorContractor: (0, _attr.default)('boolean'),
    showExpirationWarning: (0, _attr.default)('boolean'),
    npsScoreRequiredAt: (0, _attr.default)('date'),
    reshowExpirationWarningAt: (0, _attr.default)('date'),
    disableAutomatedEmails: (0, _attr.default)('boolean'),
    notificationAllowWeeklyEmails: (0, _attr.default)('boolean'),
    notificationAllowWeeklyContentEmail: (0, _attr.default)('boolean'),
    notificationAllowWeeklyForumEmail: (0, _attr.default)('boolean'),
    notificationAllowConnectionRequestEmail: (0, _attr.default)('boolean'),
    notificationAllowConnectionApprovalEmail: (0, _attr.default)('boolean'),
    notificationAllowEventReminderFollowUpEmail: (0, _attr.default)('boolean'),
    allowConversationAppNotifications: (0, _attr.default)('boolean'),
    allowEventAppNotifications: (0, _attr.default)('boolean'),
    allowInnovatorAppNotifications: (0, _attr.default)('boolean'),
    notificationAllowCommentMentionEmail: (0, _attr.default)('boolean'),
    notificationAllowCommentReplyEmail: (0, _attr.default)('boolean'),
    notificationAllowDirectEmailMessage: (0, _attr.default)('boolean'),
    isMessageable: (0, _attr.default)('boolean'),
    connectionStatus: (0, _attr.default)('string'),
    hasActiveServiceLines: (0, _attr.default)('boolean'),
    confirmedAt: (0, _attr.default)('date'),
    confirmationSentAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    gettingStartedShownAt: (0, _attr.default)('date'),
    isGettingStartedRequired: (0, _attr.default)('boolean'),
    usagePreferences: (0, _attr.default)(),
    socialReaction: (0, _attr.default)('string'),
    token: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    isRegistered: (0, _attr.default)('boolean'),
    isInactive: (0, _attr.default)('boolean'),
    activeMember: (0, _attr.default)('boolean'),
    tawkHash: (0, _attr.default)('string'),
    followedCompanyIds: (0, _attr.default)(),
    fasttrackedCompanyIds: (0, _attr.default)(),
    followedCompanyNames: (0, _attr.default)(),
    hasFreeTrialMembershipAgreement: (0, _attr.default)('boolean'),
    isFeedRecommendedFilterVisible: (0, _attr.default)('boolean'),
    aBTestingGroup: (0, _attr.default)('string'),
    interests: (0, _attr.default)(),
    interestsCount: (0, _attr.default)('number'),
    topicTag: (0, _relationships.belongsTo)(),
    surveys: (0, _relationships.hasMany)('attendee-survey'),
    authSession: (0, _relationships.belongsTo)('auth-session'),
    company: (0, _relationships.belongsTo)('company'),
    communities: (0, _relationships.hasMany)(),
    uploads: (0, _relationships.hasMany)('content', {
      inverse: 'uploader'
    }),
    contracts: (0, _relationships.hasMany)('contract'),
    membershipAgreements: (0, _relationships.hasMany)(),
    serviceLines: (0, _relationships.hasMany)(),
    serviceLineIds: (0, _attr.default)(),
    bookmarksCount: (0, _attr.default)('number'),
    networkCount: (0, _attr.default)('number'),
    connectionRequests: (0, _relationships.hasMany)(),
    speakers: (0, _relationships.hasMany)(),
    interestsFormatted: Ember.computed('interests', function () {
      return this.interests.join(', ');
    }),
    showNpsScoreModal: Ember.computed('npsScoreRequiredAt', function () {
      if (this.npsScoreRequiredAt) {
        return moment(this.npsScoreRequiredAt).diff(moment(), 'days') < 1;
      }
    }),
    showExpirationWarningModal: Ember.computed('showExpirationWarning', 'reshowExpirationWarningAt', function () {
      if (this.reshowExpirationWarningAt) {
        return moment(this.reshowExpirationWarningAt).diff(moment(), 'days') < 1 && this.showExpirationWarning;
      }

      return this.showExpirationWarning;
    }),
    getCommunityByInterestType: Ember.computed('communities', function () {
      return this.get('communities').filter(function (c) {
        return c.get('communityType') === 'interest' && c.get('isOpen');
      });
    }),
    getCommunityByInterestTypeFormatted: Ember.computed('getCommunityByInterestType', function () {
      return this.get('getCommunityByInterestType').map(function (c) {
        return c.name;
      }).join(', ');
    }),
    followedCompanyNamesToString: Ember.computed('followedCompanyNames', function () {
      return this.get('followedCompanyNames').sort().join(', ');
    }),
    followedCompanyNamesFormatted: Ember.computed('followedCompanyNames', function () {
      var sortedNames = this.get('followedCompanyNames').sort();
      if (sortedNames.length > 10) return sortedNames.slice(0, 9).join(', ') + '...';else return sortedNames.join(', ');
    }),
    hasActiveCompanyMembershipAgreements: Ember.computed('company.id', 'company.membershipAgreements.[]', function () {
      if (Ember.isBlank(this.get('company.id') || Ember.isBlank(this.get('company.membershipAgreements')))) return false;
      return Ember.isPresent(this.get('company.membershipAgreements').toArray().filterBy('isDisabled', false));
    }),
    membershipAgreementsAndCompanyMembershipAgreements: Ember.computed('membershipAgreements.[]', 'company', function () {
      if (Ember.isPresent(this.get('company.id') && Ember.isPresent(this.get('company.membershipAgreements')))) {
        var membershipAgreements = this.get('membershipAgreements').toArray();
        var companyMembershipAgreements = this.get('company.membershipAgreements').toArray();
        return [].concat((0, _toConsumableArray2.default)(membershipAgreements), (0, _toConsumableArray2.default)(companyMembershipAgreements));
      } else {
        return this.get('membershipAgreements');
      }
    }),
    activeUserAndCompanyMembershipAgreements: Ember.computed('membershipAgreementsAndCompanyMembershipAgreements.@each.{isDisabled}', function () {
      return this.membershipAgreementsAndCompanyMembershipAgreements.filterBy('isDisabled', false);
    }),
    serviceLinesByActiveMembershipAgreements: Ember.computed('activeUserAndCompanyMembershipAgreements.[]', function () {
      return this.activeUserAndCompanyMembershipAgreements.reduce(function (accumulator, agreement) {
        accumulator.push.apply(accumulator, (0, _toConsumableArray2.default)(agreement.serviceLines.toArray()));
        return accumulator;
      }, []).filter(function (serviceLine, index, array) {
        return array.indexOf(serviceLine) === index;
      });
    }),
    hasFreeTrialMembershipAgreements: Ember.computed('membershipAgreements.@each.{isUsageTrial}', function () {
      return Ember.isPresent(this.get('membershipAgreements').filterBy('isUsageTrial', true));
    }),
    freeTrialMembershipAgreements: Ember.computed('membershipAgreements.@each.{isDisabled,isUsageTrial}', function () {
      return this.get('membershipAgreements').filterBy('isDisabled', false).filterBy('isUsageTrial', true);
    }),
    hasExpiredFreeTrialMembershipAgreement: Ember.computed('membershipAgreements.@each.{isDisabled,isUsageTrial}', function () {
      return Ember.isPresent(this.get('membershipAgreements').filterBy('isUsageTrial', true).filterBy('isDisabled', true));
    }),
    name: Ember.computed('firstName', 'lastName', {
      get: function get() {
        return "".concat(this.get('firstName') || '', " ").concat(this.get('lastName') || '');
      }
    }),
    formalName: Ember.computed('firstName', 'lastName', {
      get: function get() {
        return "".concat(this.get('lastName') + ',' || '', " ").concat(this.get('firstName') || '');
      }
    }),
    initials: Ember.computed('firstName', 'lastName', {
      get: function get() {
        return "".concat(this.get('firstName')[0] || '').concat(this.get('lastName')[0] || '');
      }
    }),
    confirmedAtMoment: Ember.computed('confirmedAt', function () {
      if (this.get('confirmedAt')) {
        return moment(this.get('confirmedAt'));
      }
    }),
    isPublicUser: Ember.computed('company', function () {
      return this.get('company.companyType') === 'public';
    }),
    canComment: Ember.computed('classification', function () {
      return ['darcy', 'operator'].includes(this.classification);
    }),
    canLike: Ember.computed('classification', function () {
      return ['darcy', 'operator'].includes(this.classification);
    })
  });

  _exports.default = _default;
});