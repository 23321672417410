define("darcy/_pods/components/like-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2o9z6zAn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"data-test-button\",\"like\"],[12,\"class\",[30,[[23,\"likeClasses\"],\" focus:outline-none font-medium uppercase \",[29,\"if\",[[25,[\"likeable\",\"isLikedByCurrentUser\"]],\"text-bluelight\"],null]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggle\"],null]],[9],[0,\"\\n  Like\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"likeable\",\"likesCount\"]],[25,[\"isCountVisible\"]]],null]],null,{\"statements\":[[0,\"    (\"],[1,[25,[\"likeable\",\"likesCount\"]],false],[0,\")\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/like-button/template.hbs"
    }
  });

  _exports.default = _default;
});