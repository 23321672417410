define("darcy/_pods/components/title-box/user-info/component", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    tagName: '',
    showFilePicker: false,
    isShowingProfilePicTooltip: true,
    actions: {
      showProfilePicEditor: function showProfilePicEditor() {
        var _this = this;

        this.set('showFilePicker', true);
        Ember.run.later(function () {
          _this.analytics.captureEvent('tooltip', {
            meta: {
              name: 'home_add_profile_photo',
              action_taken: 'Upload Now'
            }
          });
        }, 1);
      },
      setFileName: function setFileName(file) {
        // It's important to return a new file by the end of this function to set the file name.
        return (0, _objectSpread2.default)({}, file, {
          name: "user-".concat(this.currentUser.id)
        });
      },
      setProfileUrl: function setProfileUrl(result) {
        this.currentUser.set('photoUrl', result.filesUploaded[0].url);
        this.currentUser.save();
        this.analytics.captureEvent('user_add_profile_photo');
      },
      hideTooltip: function hideTooltip() {
        var _this2 = this;

        this.set('isShowingProfilePicTooltip', false);
        Ember.run.later(function () {
          _this2.analytics.captureEvent('tooltip', {
            meta: {
              name: 'home_add_profile_photo',
              action_taken: 'Maybe Later'
            }
          });
        }, 1);
      },
      closeTooltip: function closeTooltip(toggleState) {
        var _this3 = this;

        toggleState();
        Ember.run.later(function () {
          _this3.analytics.captureEvent('tooltip', {
            meta: {
              name: 'home_add_profile_photo',
              action_taken: 'Close'
            }
          });
        }, 1);
      }
    }
  });

  _exports.default = _default;
});