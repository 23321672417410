define("darcy/_pods/innovators/view/admin/photos/new/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/carousel-item"], function (_exports, _emberChangeset, _emberChangesetValidations, _carouselItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model(params) {
      var company = this.modelFor('innovators.view.admin');
      return Ember.RSVP.hash({
        company: company
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var company = model.company;
      var carouselItem = this.store.createRecord('carousel-item', {
        company: company
      });
      var changeset = new _emberChangeset.default(carouselItem, (0, _emberChangesetValidations.default)(_carouselItem.default), _carouselItem.default);
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});