define("darcy/_pods/components/title-box/innovation-concierge/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    isConciergeRequestModalOpen: false,
    isRequestModalOpen: false,
    modalTitle: '',
    modalText: '',
    modalItalicText: '',
    actions: {
      openModal: function openModal(request) {
        this.analytics.captureEvent('innovation_concierge_sidebar_request_type_click', {
          meta: {
            value: request
          }
        });

        switch (request) {
          case 'landscape':
            this.set('modalTitle', 'Market Map / Landscape');
            this.set('modalText', "Do you have a specific need and want to better understand the relevant vendors who play in the space? Submit a Market Map / Landscape request.");
            this.set('modalItalicText', "Example: We are looking at improving our emission monitoring capabilities. What the potential solutions for wide area methane detection?");
            this.set('isRequestModalOpen', true);
            break;

          case 'statement':
            this.set('modalTitle', 'Problem Statement');
            this.set('modalText', "Are you encountering a problem/issue and looking for how the problem is solved or want to better understand how others are solving it? Submit a Problem Statement to our Research Team and we'll help you find the best solution.");
            this.set('modalItalicText', "Example: We have a large distributed asset base. We want to decrease the amount of time our employees spend travelling from site-to-site for inspection and maintenance. How can we centralize our I&M processes and planning while ensuring that we don’t sacrifice quality?");
            this.set('isRequestModalOpen', true);
            break;

          case 'tech':
            this.set('modalTitle', 'Tech Specific');
            this.set('modalText', "Do you have questions around a specific technology vendor or category of solution? Submit a Tech Specific request.");
            this.set('modalItalicText', "Example: Our team came across xyz digital twin company and is looking at commencing a pilot – are there any alternatives or nuances we should be aware of? Can you put us in touch with a current customer?");
            this.set('isRequestModalOpen', true);
            break;

          case 'operational process':
            this.set('modalTitle', 'Operational Process');
            this.set('modalText', "Capturing the full value of some technologies may require a change to workflows or impact those which were historically manual processes. Submit an Operational Process request to our team to learn more.");
            this.set('modalItalicText', "Example: Our assets are well instrumented, but how are other companies using that data to drive improved uptime?");
            this.set('isRequestModalOpen', true);
            break;

          case 'scoping':
            this.set('modalTitle', 'Framing / Scoping');
            this.set('modalText', "Do you want to better understand what is possible with available solutions or understand the potential impacts they have? Submit a Framing / Scoping request with as many details as possible so our Research Team can provide the best response.");
            this.set('modalItalicText', "Example: We have a mandate to design autonomous assets. Can the Darcy team help us to understand the what the components of this are, who the relevant vendors are and if anyone has successfully deployed?");
            this.set('isRequestModalOpen', true);
            break;
        }
      },
      openConciergeRequestModal: function openConciergeRequestModal() {
        if (this.currentUser) {
          this.set('isConciergeRequestModalOpen', true);
          this.analytics.captureEvent('concierge_request_view');
        } else {
          this.showNoAccessModal();
        }
      }
    }
  });

  _exports.default = _default;
});