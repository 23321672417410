define("darcy/_pods/confirm/index/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      resend: function resend(email) {
        var _this = this;

        return this.ajax.post("".concat(API.host, "/email_confirmation"), {
          data: {
            email: email
          }
        }).then(function () {
          _this.notifications.success('A new confirmation email has been sent.');
        }).catch(function () {
          _this.notifications.error('There was an error, please try again.');
        }).finally(function () {
          _this.transitionTo('confirm');
        });
      }
    }
  });

  _exports.default = _default;
});