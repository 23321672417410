define("darcy/_pods/components/modal/share-via-email/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "an4yVWTH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"containerClass\"],[true,\"bg-white w-full lg:w-1/2 xl:w-1/3 mx-auto rounded-xl shadow-lg z-50 overflow-y-auto\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-wrap text-gray-700\"],[9],[0,\"\\n      \"],[7,\"section\"],[11,\"class\",\"flex w-full flex-wrap\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"w-full text-xl text-black mx-4 pb-4 pt-2 border-gray-500 border-b\"],[9],[0,\"\\n          Share Via Email\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"closeComposeEmailModal\"],null]],[11,\"class\",\"absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-6 text-white text-sm z-30\"],[9],[0,\"\\n          \"],[1,[29,\"svg-jar\",[\"x\"],[[\"class\"],[\"fill-current text-gray-600 rounded-full\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[29,\"email-form\",null,[[\"changeset\",\"addToEmail\",\"addBccEmail\",\"sendEmail\",\"onMessageTypeSelect\",\"onRecipientsSelect\",\"onSenderSelect\",\"onBccSelect\"],[[25,[\"changeset\"]],[29,\"action\",[[24,0,[]],\"addToEmail\"],null],[29,\"action\",[[24,0,[]],\"addBccEmail\"],null],[29,\"action\",[[24,0,[]],\"sendEmail\"],null],[29,\"action\",[[24,0,[]],\"selectMessageType\"],null],[29,\"action\",[[24,0,[]],\"selectRecipients\"],null],[29,\"action\",[[24,0,[]],\"selectSender\"],null],[29,\"action\",[[24,0,[]],\"selectBcc\"],null]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[29,\"route-action\",[\"hideAllModals\"],null]]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/modal/share-via-email/template.hbs"
    }
  });

  _exports.default = _default;
});