define("darcy/_pods/getting-started/step-4/controller", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TECHNICAL_SERVICE_LINE_IDS = ['1', '2', '3', '9'];
  var API = _environment.default.API;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    ajax: Ember.inject.service(),
    appTour: Ember.inject.service(),
    user: null,
    serviceLines: null,
    serviceLinesAndMembershipAgreement: Ember.computed('serviceLines.[]', 'user.activeUserAndCompanyMembershipAgreements.[]', function () {
      var _this = this;

      var serviceLines = this.get('serviceLines').filter(function (sl) {
        return TECHNICAL_SERVICE_LINE_IDS.includes(sl.id);
      });
      return serviceLines.sortBy('id').map(function (serviceLine) {
        var companyMembershipAgreement = _this.user.activeUserAndCompanyMembershipAgreements.find(function (agreement) {
          var isCompany = Ember.isPresent(agreement.company.get('id'));
          var isServiceLine = agreement.serviceLines.map(function (sl) {
            return sl.id;
          }).includes(serviceLine.id);
          return isCompany && isServiceLine;
        });

        var userMembershipAgreement = _this.user.activeUserAndCompanyMembershipAgreements.find(function (agreement) {
          var isUser = Ember.isBlank(agreement.company.get('id'));
          var isServiceLine = agreement.serviceLines.map(function (sl) {
            return sl.id;
          }).includes(serviceLine.id);
          return isUser && isServiceLine;
        });

        var membershipAgreement = Ember.isPresent(companyMembershipAgreement) ? companyMembershipAgreement : userMembershipAgreement;
        return {
          serviceLine: serviceLine,
          isActive: Ember.isPresent(membershipAgreement),
          membershipAgreement: membershipAgreement
        };
      });
    }),
    actions: {
      startIntroTour: function startIntroTour() {
        this.transitionToRoute('home');
        this.analytics.set('referrerLinkId', 'finish');
        this.set('appTour.isTakeATourModalOpen', true);
      },
      goBackAPage: function goBackAPage() {
        this.analytics.set('referrerLinkId', 'back');
        this.transitionToRoute('getting-started.step-3');
      },
      getFreeTrial: function getFreeTrial() {
        var _this2 = this;

        var userId = this.get('currentUser.id');
        this.analytics.captureEvent('get_free_trial_membership_access', {
          company: this.currentUser.get('company')
        });
        this.analytics.captureEvent('getting_started_request_trial');
        return this.ajax.post("".concat(API.host, "/membership_agreements/create_free_trial_membership_agreement"), {
          data: {
            user_id: userId
          }
        }).then(function () {
          _this2.set('trialMembershipAgreementReceived', true);

          _this2.set('currentUser.hasFreeTrialMembershipAgreement', true);

          _this2.currentUser.reload();
        }).catch(function (e) {
          console.error(e);

          _this2.notifications.error('There was an error, please try again.');
        });
      }
    }
  });

  _exports.default = _default;
});