define("darcy/validations/password", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    password: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      min: 8,
      message: 'Your new password is invalid. Your new password cannot be the same as your existing password and must be 8 characters long'
    })],
    passwordConfirmation: [(0, _validators.validatePresence)(true), (0, _validators.validateConfirmation)({
      on: 'password',
      message: 'Your new password is invalid. Your new password cannot be the same as your existing password and must be 8 characters long'
    })]
  };
  _exports.default = _default;
});