define("darcy/services/api-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: [],
    init: function init() {
      if (this.currentUser) {
        this.fetchNotifications();
      }
    },
    fetchNotifications: function fetchNotifications() {
      var _this = this;

      this.store.findAll('notification').then(function (notifications) {
        _this.set('notifications', notifications.toArray());
      });
    },
    markAllNotificationsRead: function markAllNotificationsRead() {
      this.unreadNotifications.forEach(function (notification) {
        notification.set('readAt', new Date());
        notification.save();
      });
    },
    unreadNotifications: Ember.computed('notifications.@each.readAt', function () {
      return this.notifications.filter(function (n) {
        return n.isRead === false;
      });
    })
  });

  _exports.default = _default;
});