define("darcy/_pods/components/svg-storefront-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r4uJkt8h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"fill\",[23,\"fill\"]],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"data-name\",\"Layer 1\"],[11,\"viewBox\",\"0 0 100 100\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[9],[7,\"title\"],[9],[0,\"12\"],[10],[7,\"g\"],[11,\"data-name\",\"Group\"],[9],[7,\"path\"],[11,\"data-name\",\"Compound Path\"],[11,\"d\",\"M90.19,12H9.81v9.71H8v7a9.44,9.44,0,0,0,1.82,5.56V88H90.19V34.28A9.44,9.44,0,0,0,92,28.72v-7H90.19ZM61.67,27.73H73v2.43a5.67,5.67,0,0,1-11.34,0Zm-17.34,0H55.67v2.43a5.67,5.67,0,0,1-11.34,0ZM27,28.72v-1H38.33v2.43a5.67,5.67,0,0,1-11.34,0Zm-13,0v-1h7v1a3.5,3.5,0,1,1-7,0ZM84.19,82H61.67V55.3H38.33V82H15.81V38.06a9.36,9.36,0,0,0,7.09-1.54,11.63,11.63,0,0,0,18.44,1.42,11.61,11.61,0,0,0,17.34,0A11.63,11.63,0,0,0,77.1,36.52a9.36,9.36,0,0,0,7.09,1.54ZM86,28.72a3.5,3.5,0,1,1-7,0v-1h7Z\"],[9],[10],[7,\"rect\"],[11,\"data-name\",\"Path\"],[11,\"x\",\"17.7\"],[11,\"y\",\"55.3\"],[11,\"width\",\"18.27\"],[11,\"height\",\"17.38\"],[9],[10],[7,\"rect\"],[11,\"data-name\",\"Path\"],[11,\"x\",\"64.03\"],[11,\"y\",\"55.3\"],[11,\"width\",\"18.27\"],[11,\"height\",\"17.38\"],[9],[10],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-storefront-icon/template.hbs"
    }
  });

  _exports.default = _default;
});