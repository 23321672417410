define("darcy/_pods/components/modal/fullscreen/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    elId: null,
    onOpenStateChange: Ember.observer('isOpen', function () {
      if (this.isOpen) {
        (0, _jquery.default)('html').addClass('lock-scroll');
      } else {
        (0, _jquery.default)('html').removeClass('lock-scroll');
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('elId', Ember.guidFor(this));
      var sidebar = document.getElementById(this.get('elId'));
      var manager = new Hammer.Manager(sidebar);
      var Swipe = new Hammer.Swipe({
        event: 'swipedown'
      });
      manager.add(Swipe);
      manager.on('swipedown', function (e) {
        _this.onClose();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('html').removeClass('lock-scroll');
    }
  });

  _exports.default = _default;
});