define("darcy/_pods/innovators/view/admin/content/index/controller", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isMobile: _environment.default.isMobile
  });

  _exports.default = _default;
});