define("darcy/_pods/events/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    loading: Ember.inject.service(),
    analytics: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        topicTags: this.store.findAll('topic-tag')
      });
    },
    setupController: function setupController(controller, model, transition) {
      var _transition$to$queryP;

      this._super.apply(this, arguments);

      controller.set('model', model);
      controller.get('fetchFeedItemsTask').perform(); // Assign topic tags for advanced filter based on query params.

      var selectedTopicTags = model.topicTags.filter(function (topicTag) {
        return controller.get('topicTagIds').includes(topicTag.id);
      });

      if (selectedTopicTags.length) {
        controller.set('topicTags', selectedTopicTags);
      } else if (this.get('currentUser.classification') === 'innovator') {
        controller.set('specialityEvent', ['Innovator']);
      }

      controller.set('displayOption', {
        value: 'Card'
      });

      if (((_transition$to$queryP = transition.to.queryParams) === null || _transition$to$queryP === void 0 ? void 0 : _transition$to$queryP.selectedDatePeriod) === 'anytime') {
        controller.set('startDate', null);
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.to.name === 'events.view.index') {
          this.set('loading.isActive', true);
        }
      }
    }
  });

  _exports.default = _default;
});