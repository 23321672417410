define("darcy/_pods/usage/event-participation/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    tableSortDir: 'desc',
    tableSortName: 'forum.startDate',
    tableFilter: null,
    company: Ember.computed.readOnly('currentUserService.user.company'),
    forums: Ember.computed('invites', function () {
      return this.invites.mapBy('forum').uniqBy('id');
    }),
    chartLabels: (0, _lodash.reverse)((0, _lodash.times)(6, function (i) {
      return moment().startOf('month').subtract(i, 'months');
    })),
    invitesChartDataset: Ember.computed('invites', function () {
      var _this = this;

      var data = this.chartLabels.map(function (month) {
        return _this.invites.filter(function (invite) {
          return moment(invite.forum.get('startDate')).isSameOrBefore(month, 'month');
        }).length;
      });
      return {
        label: 'Participants',
        data: data
      };
    }),
    eventsChartDataset: Ember.computed('forums', function () {
      var _this2 = this;

      var data = this.chartLabels.map(function (month) {
        return _this2.forums.filter(function (forum) {
          return moment(forum.get('startDate')).isSameOrBefore(month, 'month');
        }).length;
      });
      return {
        label: 'Events',
        data: data
      };
    }),
    chartDatasets: Ember.computed('invitesChartData', 'eventsChartData', function () {
      return [this.invitesChartDataset, this.eventsChartDataset];
    }),
    visibleChartDatasets: Ember.computed('chartDatasets', function () {
      return this.chartDatasets.filter(function (dataset) {
        return dataset.label === 'Participants';
      });
    }),
    tableFilterName: Ember.computed('tableFilter', function () {
      return this.tableFilter.format('MMMM YYYY');
    }),
    tableColumns: Ember.computed(function () {
      return [{
        label: 'Date',
        valuePath: 'forum.startDate',
        cellComponent: 'table/cell/date',
        width: '20%'
      }, {
        label: 'Name',
        cellClassNames: 'highlight truncate',
        valuePath: 'forum.name',
        cellComponent: 'table/cell/forum-link',
        width: '60%'
      }, {
        label: 'Event Seats',
        align: 'right',
        valuePath: 'seatCount',
        width: '20%'
      }];
    }),
    tableModel: Ember.computed('forums', 'tableFilter', function () {
      var _this3 = this;

      return this.get('forums').filter(function (forum) {
        if (Ember.isPresent(_this3.tableFilter)) {
          return _this3.tableFilter.isSame(moment(forum.get('startDate')), 'month');
        }

        return true;
      }).map(function (forum) {
        return {
          forum: forum,
          seatCount: _this3.get('invites').filterBy('forum.id', forum.get('id')).length
        };
      });
    }),
    actions: {
      onTooltipLabelRender: function onTooltipLabelRender(tooltipItem) {
        return this.chartDatasets.map(function (dataset) {
          return dataset.label + ': ' + dataset.data[tooltipItem.index];
        });
      },
      onFilter: function onFilter(barIndex) {
        if (barIndex === null) {
          return this.set('tableFilter', null);
        }

        var month = this.chartLabels[barIndex];
        this.set('tableFilter', month);
      },
      onClearFilter: function onClearFilter() {
        this.set('tableFilter', null);
      },
      onTableSort: function onTableSort(isAscending, sort) {
        var dir = isAscending ? 'asc' : 'desc';
        this.setProperties({
          tableSortName: sort,
          tableSortDir: dir
        });
      }
    }
  });

  _exports.default = _default;
});