define("darcy/_pods/components/advanced-filter/company/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    companyId: null,
    companyOptions: [],
    selected: Ember.computed('companyId', function () {
      if (Ember.isBlank(this.companyId)) return;
      return this.store.peekRecord('company', this.companyId);
    }),
    actions: {
      queryOptions: function queryOptions(searchTerm) {
        var _this = this;

        this.store.query('company', {
          search: searchTerm,
          show_all: true,
          limit: 10
        }).then(function (companies) {
          _this.set('companyOptions', companies);
        });
      },
      onInput: function onInput(text) {
        return text.length >= 3;
      },
      selectCompany: function selectCompany(company) {
        this.onCompanyChange(company.id);
        this.analytics.captureEvent('add_filter', {
          meta: {
            'company': company.name,
            'target': 'advanced-filter'
          }
        });
      }
    }
  });

  _exports.default = _default;
});