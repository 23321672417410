define("darcy/_pods/getting-started/step-1/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model() {
      return Ember.RSVP.hash({
        user: this.currentUser,
        communities: this.store.query('community', {
          communityType: 'interest'
        }),
        topicTags: this.store.findAll('topic-tag')
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('account', model.user);
      controller.set('topicTags', model.topicTags);
      controller.set('communities', model.communities); // this works because of the `store.findAll('community')` in model()

      controller.set('userCommunities', model.user.get('communities'));
      controller.set('userTopicTag', model.user.get('topicTag'));
    }
  });

  _exports.default = _default;
});