define("darcy/_pods/account/membership/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    beforeModel: function beforeModel() {
      if (this.get('currentUser.classification') === 'innovator') {
        this.transitionTo('account');
      }
    },
    model: function model() {
      var _this$modelFor = this.modelFor('account'),
          user = _this$modelFor.user;

      var serviceLines = this.store.findAll('service-line');
      return Ember.RSVP.hash({
        user: user,
        serviceLines: serviceLines
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties(model);
    }
  });

  _exports.default = _default;
});