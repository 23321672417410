define("darcy/validations/company-profile", ["exports", "ember-changeset-validations/validators", "darcy/validators/video-url"], function (_exports, _validators, _videoUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    description: [(0, _validators.validateLength)({
      max: 300
    })],
    tagline: [(0, _validators.validateLength)({
      max: 100
    })],
    contactPhone: [(0, _validators.validateFormat)({
      allowBlank: true,
      regex: /^\+?[0-9\(\)\-\s]*$/,
      message: 'Please enter valid phone number'
    })],
    contactEmail: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    })],
    introVideo: [(0, _videoUrl.default)()]
  };
  _exports.default = _default;
});