define("darcy/_pods/confirm/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    beforeModel: function beforeModel() {
      if (this.currentUser.user && this.currentUser.user.confirmedAt) {
        this.transitionTo('index');
      }
    }
  });

  _exports.default = _default;
});