define("darcy/_pods/components/horizontal-bar-chart/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "inWDaRoj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[23,\"class\"]],[12,\"style\",[23,\"style\"]],[9],[0,\"\\n  \"],[1,[29,\"ember-chart\",null,[[\"type\",\"class\",\"height\",\"data\",\"options\"],[\"horizontalBar\",\"bg-white\",\"80px\",[25,[\"chartData\"]],[25,[\"options\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isTooltipShown\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"absolute p-1 z-50 text-center\"],[12,\"style\",[23,\"tooltipStyle\"]],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-xs font-semibold\"],[9],[1,[23,\"tooltipBody\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/horizontal-bar-chart/template.hbs"
    }
  });

  _exports.default = _default;
});