define("darcy/_pods/events/view/content/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    model: function model() {
      var _this = this;

      var forum = this.modelFor('events.view').forum;
      return this.store.query('content', {
        forum_id: forum.id
      }).then(function (array) {
        return array.sortBy('name');
      }).catch(function (error) {
        if (/^5\d{2}$/.test(error.status)) {
          return _this.transitionTo('events.view.index');
        } else {
          throw error;
        }
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('forum', this.modelFor('events.view').forum);
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});