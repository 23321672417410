define("darcy/models/comment", ["exports", "ember-data/model", "ember-data/attr", "moment", "ember-data/relationships", "lodash"], function (_exports, _model, _attr, _moment, _relationships, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    commentable_id: (0, _attr.default)('number'),
    commentable_type: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    message: (0, _attr.default)('string'),
    canonicalUrl: (0, _attr.default)('string'),
    userId: (0, _attr.default)('string'),
    userLastName: (0, _attr.default)('string'),
    userFirstName: (0, _attr.default)('string'),
    userCompanyName: (0, _attr.default)('string'),
    userAvatarBackgroundColor: (0, _attr.default)('string'),
    userPhotoUrl: (0, _attr.default)('string'),
    userTitle: (0, _attr.default)('string'),
    isLikedByCurrentUser: (0, _attr.default)('boolean'),
    likes: (0, _relationships.hasMany)('like'),
    likesCount: (0, _attr.default)('number'),
    replies: (0, _relationships.hasMany)('comment', {
      inverse: 'parent'
    }),
    replyLevel: (0, _attr.default)('number'),
    mentionedUsers: (0, _relationships.hasMany)('user'),
    userMentions: (0, _attr.default)(),
    parent_id: (0, _attr.default)('number'),
    parent: (0, _relationships.belongsTo)('comment'),
    content: (0, _relationships.belongsTo)(),
    companyProfile: (0, _relationships.belongsTo)(),
    forum: (0, _relationships.belongsTo)(),
    userFullName: Ember.computed('userFirstName', 'userLastName', 'userCompanyName', function () {
      return "".concat(this.get('userFirstName') || '', " ").concat(this.get('userLastName') || '');
    }),
    createdAtMoment: Ember.computed('createdAt', function () {
      if (this.get('createdAt')) {
        return (0, _moment.default)(this.get('createdAt')).fromNow();
      }
    }),
    userInitials: Ember.computed('userFirstName', 'userLastName', function () {
      if (this.get('userFirstName') && this.get('userLastName')) {
        return "".concat(this.get('userFirstName')[0] || '').concat(this.get('userLastName')[0] || '');
      }
    })
  });

  _exports.default = _default;
});