define("darcy/_pods/survey/thanks/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    model: function model(_ref) {
      var _this = this;

      var forum_id = _ref.forum_id;
      return this.store.query('invite', {
        forum_id: forum_id,
        user_id: this.get('currentUserService.user.id')
      }).then(function (content) {
        var invite = content.get('firstObject');
        var p;

        if (invite) {
          invite.set('isSurveyComplete', true);
          p = invite.save();
        } else {
          p = Ember.RSVP.resolve();
        }

        p.then(function () {
          return _this.transitionTo('rsvp.schedule-call', forum_id, {
            queryParams: {
              pass: true
            }
          });
        });
      });
    }
  });

  _exports.default = _default;
});