define("darcy/services/bugsnag", ["exports", "@bugsnag/js", "darcy/config/environment"], function (_exports, _js, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notify: function notify(e) {
      if (_environment.default.environment !== 'production') return;
      var bugsnagClient = (0, _js.default)({
        apiKey: _environment.default.KEYS.BUGSNAG,
        appVersion: _environment.default.APP.version
      });
      return bugsnagClient.notify(e);
    }
  });

  _exports.default = _default;
});