define("darcy/_pods/events/view/flyer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('events.view'),
          forum = _this$modelFor.forum,
          currentUser = _this$modelFor.currentUser;

      if (!currentUser || !forum.showFlyer) {
        this.transitionTo('events.view.index');
      }
    },
    activate: function activate() {
      this.controllerFor('events.view').set('displayFullWidth', true);
    },
    deactivate: function deactivate() {
      this.controllerFor('events.view').set('displayFullWidth', false);
    }
  });

  _exports.default = _default;
});