define("darcy/_pods/components/advanced-filter/content-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VZg1Y01H",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold\"],[9],[0,\"Content Type\"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"pl-4 pt-2\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"contentTypeOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"pb-2\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"block text-base flex\"],[9],[0,\"\\n          \"],[1,[29,\"square-checkbox\",null,[[\"onToggle\",\"isActive\"],[[29,\"action\",[[24,0,[]],\"onTypeToggle\",[24,1,[\"value\"]]],null],[29,\"includes\",[[25,[\"contentTypes\"]],[24,1,[\"value\"]]],null]]]],false],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[24,1,[\"value\"]],\"framework\"],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n              \"],[7,\"div\"],[11,\"class\",\"cursor-pointer ml-2 mt-1\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onTypeToggle\",[24,1,[\"value\"]]],null]],[9],[0,\"\\n                \"],[1,[24,1,[\"label\"]],false],[0,\"\\n              \"],[10],[0,\"\\n              \"],[7,\"span\"],[11,\"class\",\"mt-1\"],[9],[1,[29,\"information-tooltip\",null,[[\"text\"],[\"Content prepared by the Darcy research team which condenses, highlights or compares the potential solutions or challenges in a particular area.\"]]],false],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"cursor-pointer ml-2 mt-1\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onTypeToggle\",[24,1,[\"value\"]]],null]],[9],[0,\"\\n              \"],[1,[24,1,[\"label\"]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/content-type/template.hbs"
    }
  });

  _exports.default = _default;
});