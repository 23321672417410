define("darcy/_pods/components/advanced-filter/interests/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0nONpETg",
    "block": "{\"symbols\":[\"interest\"],\"statements\":[[7,\"div\"],[11,\"class\",\"people-filter pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"Interests\"],[10],[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"triggerClass\",\"searchField\",\"options\",\"selected\",\"placeholder\",\"onchange\"],[\"w-full my-2\",\"name\",[25,[\"interestsOptions\"]],[25,[\"selected\"]],\"Interests\",[29,\"action\",[[24,0,[]],\"selectInterests\"],null]]],{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/interests/template.hbs"
    }
  });

  _exports.default = _default;
});