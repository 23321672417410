define("darcy/_pods/components/advanced-filter/publisher/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PUBLISHER_OPTIONS = [{
    label: 'All',
    value: null
  }, {
    label: 'Darcy',
    value: 'darcy'
  }, {
    label: 'Innovator',
    value: 'innovator'
  }, {
    label: 'Other',
    value: 'other'
  }];

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    publisherOptions: Ember.computed('', function () {
      var options = PUBLISHER_OPTIONS;

      if (this.hideOtherOption) {
        options = options.filter(function (opt) {
          return opt.value !== 'other';
        });
      }

      return options;
    }),
    selectedPublisher: null,
    actions: {
      selectPublisher: function selectPublisher(publisher) {
        this.onPublisherChange(publisher);

        if (publisher) {
          this.analytics.captureEvent('add_filter', {
            url: window.location.href,
            meta: {
              'publisher': publisher,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});