define("darcy/_pods/rsvp/schedule-call/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    setupController: function setupController(controller, _ref) {
      var forum = _ref.forum,
          user = _ref.user,
          invite = _ref.invite;
      var scheduleCallUrl = forum.get('call.url');

      var _user$getProperties = user.getProperties('email', 'name'),
          email = _user$getProperties.email,
          name = _user$getProperties.name;

      var redirectLocation = "".concat(scheduleCallUrl, "?email=").concat(email, "&skip_fields&field__full-name=").concat(name);
      controller.set('invite', invite);
      controller.set('redirectLocation', redirectLocation);
    },
    actions: {
      skip: function skip() {
        var _this = this;

        var _this$modelFor = this.modelFor('rsvp'),
            invite = _this$modelFor.invite,
            forum = _this$modelFor.forum;

        this.controller.set('isLoading', true);
        return invite.skipCallSchedule().then(function () {
          _this.transitionTo('forum.index', forum.slug);
        }).catch(function (e) {
          console.error(e);

          _this.notifications.error('There was a problem skipping call schedule. Please click here to try again.', {
            autoClear: false,
            onClick: function onClick(notification) {
              _this.get('notifications').removeNotification(notification);
            }
          });
        }).finally(function () {
          _this.controller.set('isLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});