define("darcy/_pods/team/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I/s6z+wk",
    "block": "{\"symbols\":[\"person\"],\"statements\":[[1,[29,\"page-title\",[\"Team\"],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"w-full px-10 md:px-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mx-auto max-w-6xl table my-10\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb-8\"],[9],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"text-2xl md:text-3xl text-gray-700 font-bold\"],[9],[0,\"\\n        The Darcy Partners Team\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[5,\"team-profile-card\",[],[[\"@name\",\"@imgUrl\",\"@email\",\"@linkedin\"],[[24,1,[\"name\"]],[24,1,[\"imgUrl\"]],[24,1,[\"email\"]],[24,1,[\"linkedin\"]]]]],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/team/template.hbs"
    }
  });

  _exports.default = _default;
});