define("darcy/_pods/components/table/cell/presenter/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XIG2+/5t",
    "block": "{\"symbols\":[\"@rawValue\"],\"statements\":[[4,\"if\",[[24,1,[\"firstObject\",\"logoUrl\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"pointer-cursor m-auto w-24\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[30,[[24,1,[\"firstObject\",\"logoUrl\"]]]]],[11,\"style\",\"max-height: 3rem\"],[11,\"class\",\"m-auto\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-12 m-auto\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/table-calendar.svg\"],null]]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/presenter/template.hbs"
    }
  });

  _exports.default = _default;
});