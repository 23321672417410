define("darcy/models/company", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment"], function (_exports, _toConsumableArray2, _model, _attr, _relationships, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    shortName: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    allowSelfRegister: (0, _attr.default)('boolean'),
    expirationWarningDays: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    shortDescription: (0, _attr.default)('string'),
    logoUrl: (0, _attr.default)('string'),
    membershipExpiresAt: (0, _attr.default)('date'),
    showExpirationWarning: (0, _attr.default)('boolean'),
    isStorefrontPublished: (0, _attr.default)('boolean'),
    companyType: (0, _attr.default)('string'),
    isPublic: Ember.computed.equal('companyType', 'public'),
    isDarcy: Ember.computed.equal('companyType', 'darcy'),
    isOperator: Ember.computed.equal('companyType', 'operator'),
    isInnovator: Ember.computed.equal('companyType', 'innovator'),
    isUndefined: Ember.computed.equal('companyType', 'undefined'),
    //Computed on the API
    //Does not affect record data yet, just determines if link-tos work
    userHasPermission: (0, _attr.default)('boolean'),
    contents: (0, _relationships.hasMany)('content', {
      inverse: 'company'
    }),
    carouselItems: (0, _relationships.hasMany)('carousel-item', {
      inverse: null
    }),
    companyProfile: (0, _relationships.belongsTo)('companyProfile', {
      inverse: 'company'
    }),
    productOverviews: (0, _relationships.hasMany)('productOverviews', {
      inverse: 'company'
    }),
    companyDomains: (0, _relationships.hasMany)('company-domain'),
    membershipAgreements: (0, _relationships.hasMany)(),
    diffDaysMembershipExpiresAt: Ember.computed('membershipExpiresAt', function () {
      return this.get('membershipExpiresAt') ? (0, _moment.default)(this.get('membershipExpiresAt')).diff((0, _moment.default)(), 'days') : null;
    }),
    isMembershipExpirationDateInDisplayRange: Ember.computed('diffDaysMembershipExpiresAt', 'expirationWarningDays', function () {
      return this.get('membershipExpiresAt') ? (0, _moment.default)(this.get('membershipExpiresAt')).diff((0, _moment.default)(), 'days') <= this.get('expirationWarningDays') && (0, _moment.default)(this.get('membershipExpiresAt')).diff((0, _moment.default)(), 'days') > 0 : false;
    }),
    displayName: Ember.computed('name', 'shortName', function () {
      return Ember.String.capitalize(this.shortName || this.name || '');
    }),
    pdfs: Ember.computed.filterBy('contents', 'fileType', 'pdf'),
    videos: Ember.computed.filterBy('contents', 'fileType', 'video'),
    membershipAgreementsAndParentMembershipAgreements: Ember.computed('membershipAgreements.[]', 'parent', function () {
      if (Ember.isPresent(this.get('parent.id') && Ember.isPresent(this.get('parent.membershipAgreements')))) {
        var membershipAgreements = this.get('membershipAgreements').toArray();
        var parentMembershipAgreements = this.get('parent.membershipAgreements').toArray();
        return [].concat((0, _toConsumableArray2.default)(membershipAgreements), (0, _toConsumableArray2.default)(parentMembershipAgreements));
      } else {
        return this.get('membershipAgreements');
      }
    }),
    serviceLinesByActiveMembershipAgreements: Ember.computed('membershipAgreementsAndParentMembershipAgreements.[]', function () {
      return this.membershipAgreementsAndParentMembershipAgreements.filterBy('isDisabled', false).reduce(function (accumulator, agreement) {
        accumulator.push.apply(accumulator, (0, _toConsumableArray2.default)(agreement.serviceLines.toArray()));
        return accumulator;
      }, []).filter(function (serviceLine, index, array) {
        return array.indexOf(serviceLine) === index;
      });
    })
  });

  _exports.default = _default;
});