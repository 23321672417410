define("darcy/_pods/components/advanced-filter/title/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    selectedTitle: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      document.getElementById("".concat(this.elementId, "-title")).addEventListener("search", function () {
        _this.onTitleChange(null);
      });
    },
    actions: {
      selectTitle: function selectTitle(title) {
        if (title.length > 2) {
          this.set('selectedTitle', title);
          this.onTitleChange(title);
          this.analytics.captureEvent('add_filter', {
            meta: {
              'title': title,
              'target': 'advanced-filter'
            }
          });
        }

        if (title.length === 0) {
          this.onTitleChange(null);
        }
      }
    }
  });

  _exports.default = _default;
});