define("darcy/_pods/-calendar/forum/content/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      var forum = this.paramsFor('-calendar.forum');
      this.transitionTo('research.view', model.id, {
        queryParams: {
          sourceType: 'forum',
          sourceId: forum.forum_id
        }
      });
    }
  });

  _exports.default = _default;
});