define("darcy/_pods/components/table/cell/event-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GOXE/bSw",
    "block": "{\"symbols\":[\"@rawValue\",\"@row\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex w-full\"],[9],[0,\"\\n\"],[4,\"if\",[[24,2,[\"content\",\"presenters\",\"firstObject\",\"logoUrl\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"events-list-logo\"],[9],[0,\"\\n      \"],[1,[29,\"scaled-logo\",null,[[\"imgUrl\",\"linkSlug\",\"companyType\",\"size\",\"class\"],[[24,2,[\"content\",\"presenters\",\"firstObject\",\"logoUrl\"]],[24,2,[\"content\",\"presenters\",\"firstObject\",\"slug\"]],[24,2,[\"content\",\"presenters\",\"firstObject\",\"companyType\"]],\"small\",\"m-auto\"]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"inline-block w-12 mx-6\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/table-calendar.svg\"],null]]]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"flex-1 ml-4 font-semibold hover:underline line-clamp-2 self-center\",\"events.view\",[24,2,[\"content\",\"slug\"]]]],{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/event-name/template.hbs"
    }
  });

  _exports.default = _default;
});