define("darcy/helpers/time-duration", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeDuration = timeDuration;
  _exports.default = void 0;
  var TIME_FORMAT = "h [hours] m [minutes] s [seconds]";

  function timeDuration(_ref
  /*, hash*/
  ) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        timecode = _ref2[0];

    var seconds = _moment.default.duration(timecode).asSeconds();

    return _moment.default.duration(seconds, "seconds").format({
      template: TIME_FORMAT
    });
  }

  var _default = Ember.Helper.helper(timeDuration);

  _exports.default = _default;
});