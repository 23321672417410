define("darcy/mirage/scenarios/default", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    var extraInviteProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var extraUserProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    var userProps = {
      firstName: 'Bob',
      lastName: 'Dylan',
      title: '',
      companyName: 'Isle of Code',
      email: 'dylan@darcypartners.com',
      phone: '1-888-455-4444',
      password: 'qwerty',
      passwordConfirmation: 'qwerty',
      name: 'Bob Dylan',
      isRegistered: true,
      isAdmin: false,
      isDarcyUser: false
    };
    var user = server.create('user', Ember.assign({}, userProps, extraUserProps));
    var agendaItem = server.create('agenda-item', {
      index: 1,
      duration: 60,
      title: 'Introduction',
      description: 'Overview of Darcy and the forum'
    });
    var forum = server.create('forum', {
      name: 'great forum',
      startDate: (0, _moment.default)().add(2, 'day').toDate(),
      endDate: (0, _moment.default)().add(3, 'day').toDate(),
      flyerUrl: 'http://forum.com/flyer',
      details: 'Annual forum',
      modelImage: 'http://forum.com/modelImg',
      aboutDarcyLink: '',
      introVideoUrl: 'https://www.youtube.com/watch?v=hdDfV4GT0r',
      introVideoTranscript: 'Welcome to forum...',
      tips: 'Learn [fun fact #1 from research]',
      description: 'Test forum',
      address: '5 King Ave., Austin, Texas',
      city: 'Austin',
      state: 'Texas',
      timezone: 'America/Texas',
      code: 'DRI',
      sequenceNumber: 22,
      optionalSequenceNumber: 1,
      hasHappyHour: false,
      showAttendees: true,
      isPublished: true,
      isVideoIncluded: true,
      happyHourName: 'Happy Hour',
      happyHourDescription: 'Music Bar',
      happyHourStartsAt: '',
      happyHourEndsAt: '',
      happyHourAddress: '123 Fake St., Austin, Texas'
    });
    var inviteProps = {
      forum: forum,
      user: user,
      isLogisticsComplete: false,
      isSurveyComplete: false,
      isCallScheduleComplete: false,
      isRsvpComplete: false,
      isVideoWatched: false,
      isSurveyIncluded: false,
      isProfileComplete: true,
      isCallIncluded: false,
      isVideoIncluded: false,
      isCallScheduleOptional: false,
      isCallScheduleSkipped: false,
      disableAutomatedEmails: false,
      isCheckedIn: false,
      notes: 'test invite notes',
      sponsorName: 'Sponsor Company',
      checkinCalled: 'Late',
      checkinParking: 'yes',
      declinedAt: '',
      acceptedAt: '',
      inviteEmailSentAt: '',
      preInviteEmailSentAt: '',
      isAttendingPreForum: false,
      arrivalTime: (0, _moment.default)('07:00', 'H:mm').toDate(),
      departureTime: (0, _moment.default)('19:00', 'H:mm').toDate(),
      arrivalComments: 'arrived on time'
    };
    var invite = server.create('invite', Ember.assign({}, inviteProps, extraInviteProps));
    var call = server.create('call', {
      forum: forum,
      organizationId: 1,
      serviceId: 1,
      url: 'https://www.appointlet.com'
    });
    var survey = server.create('survey', {
      name: 'Pre-forum Survey',
      url: 'https://formstack.com'
    });
    server.create('attendee-survey', {
      user: user,
      survey: survey,
      uuid: '1234'
    });
    server.create('auth-session', {
      user: user,
      token: '1',
      email: user.email,
      password: 'password'
    });
    server.create('attendee-call', {
      user: user,
      call: call
    });
  }
});