define("darcy/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    timeAfter: '{description} must be after {afterAttr}',
    timeBefore: '{description} must be before {beforeAttr}',
    present: "{description} required",
    tooShort: "{description} is too short (min is {min} characters)",
    confirmation: "Passwords do not match" // assuming confirmation used only for passwords

  };
  _exports.default = _default;
});