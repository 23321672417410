define("darcy/_pods/events/view/intro/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      preserveScrollPosition: 'psp'
    }],
    preserveScrollPosition: false,
    emptyIntro: Ember.computed('model.forum.{introVideoMarkdownBefore,introVideoMarkdownAfter,introVideoUrl,introVideoTranscript}', function () {
      return Ember.isBlank(this.model.forum.introVideoMarkdownBefore) && Ember.isBlank(this.model.forum.introVideoMarkdownAfter) && Ember.isBlank(this.model.forum.introVideoUrl) && Ember.isBlank(this.model.forum.introVideoTranscript);
    })
  });

  _exports.default = _default;
});