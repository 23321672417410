define("darcy/_pods/components/suggestion/dropdown/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7ncqRKak",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"div\"],[11,\"class\",\"suggestion-dropdown\"],[12,\"style\",[24,0,[\"style\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"suggestions\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"suggestions-list text-gray-800\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"suggestions\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[12,\"onmousedown\",[29,\"action\",[[24,0,[]],[25,[\"onSuggestionSelection\"]],[24,2,[]]],null]],[12,\"class\",[30,[[29,\"if\",[[29,\"eq\",[[24,2,[]],[25,[\"currentIndex\"]]],null],\"current\"],null]]]],[9],[0,\"\\n          \"],[7,\"span\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"text-sm text-gray-500\"],[9],[1,[24,1,[\"companyName\"]],false],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/suggestion/dropdown/template.hbs"
    }
  });

  _exports.default = _default;
});