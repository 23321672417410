define("darcy/_pods/innovators/view/admin/content/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/company-profile"], function (_exports, _emberChangeset, _emberChangesetValidations, _companyProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user')
  });

  _exports.default = _default;
});