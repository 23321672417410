define("darcy/_pods/components/expandable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H/WLXFw2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"id\",\"sticky\"],[11,\"class\",\"hidden sm:block\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"h-12 bg-light-blue-gray text-black text-xl flex flex-row items-center px-4 px-4 font-roboto font-bold cursor-pointer md:cursor-default\"],[9],[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[30,[\"mr-2 md:hidden transform \",[29,\"if\",[[25,[\"isExpanded\"]],\"rotate-90\"],null]]]],[9],[1,[29,\"svg-jar\",[\"triangle\"],[[\"class\"],[\"w-4\"]]],false],[10],[0,\"\\n    \"],[7,\"span\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"isExpanded\"]]],null],[29,\"not\",[[25,[\"isExpanded\"]]],null]]],[10],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"md:pb-4 px-6 bg-white \",[29,\"if\",[[25,[\"isExpanded\"]],\"h-auto px-5 pb-5\",\"h-0 overflow-hidden\"],null],\" md:h-auto\"]]],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/expandable/template.hbs"
    }
  });

  _exports.default = _default;
});