define("darcy/_pods/components/table/cell/checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dZXuc6x+",
    "block": "{\"symbols\":[\"@row\",\"@tableActions\"],\"statements\":[[7,\"div\"],[11,\"class\",\"table-checkbox\"],[9],[0,\"\\n  \"],[7,\"input\"],[12,\"disabled\",[24,1,[\"content\",\"isNew\"]]],[12,\"checked\",[24,1,[\"selected\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,2,[\"selectRow\"]],[24,1,[]]],null]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"table-checkbox__checkmark\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});