define("darcy/_pods/components/forum-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isViewing: false,
    currentContent: null,
    actions: {
      toggleView: function toggleView(content) {
        this.toggleProperty('isViewing');
        this.set('currentContent', content);
      }
    }
  });

  _exports.default = _default;
});