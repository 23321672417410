define("darcy/_pods/components/advanced-filter/company/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q6UPyI95",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"people-filter pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"Company\"],[10],[0,\"\\n\\n\"],[4,\"power-select\",null,[[\"id\",\"triggerClass\",\"options\",\"selected\",\"searchEnabled\",\"searchField\",\"search\",\"oninput\",\"placeholder\",\"noMatchesMessage\",\"onchange\"],[\"companySearch\",\"w-full my-2 text-red\",[25,[\"companyOptions\"]],[25,[\"selected\"]],true,\"name\",[29,\"action\",[[24,0,[]],\"queryOptions\"],null],[29,\"action\",[[24,0,[]],\"onInput\"],null],\"Company Name\",\"No Matches found\",[29,\"action\",[[24,0,[]],\"selectCompany\"],null]]],{\"statements\":[[4,\"if\",[[24,1,[\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/company/template.hbs"
    }
  });

  _exports.default = _default;
});