define("darcy/_pods/events/view/logistics/route", ["exports", "darcy/helpers/has-role"], function (_exports, _hasRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('events.view'),
          forum = _this$modelFor.forum,
          currentUser = _this$modelFor.currentUser,
          invite = _this$modelFor.invite;

      var isUserAllowed = (0, _hasRole.hasRole)(currentUser, 'sponsor') || invite && invite.isRsvpComplete;

      if (!currentUser || !(forum.details && isUserAllowed)) {
        this.transitionTo('events.view.index');
      }
    }
  });

  _exports.default = _default;
});