define("darcy/_pods/innovators/view/admin/content/new/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/content"], function (_exports, _emberChangeset, _emberChangesetValidations, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model() {
      var company = this.modelFor('innovators.view.admin');
      var companies = this.store.query('company', {
        is_storefront_published: true
      });
      var topicTags = this.store.findAll('topic-tag');
      return Ember.RSVP.hash({
        company: company,
        topicTags: topicTags,
        companies: companies
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var company = model.company;
      var content = this.store.createRecord('content', {
        company: company,
        isPublished: true,
        isConfidential: false,
        isPrivate: false,
        contentOwner: 'innovator'
      });
      var changeset = new _emberChangeset.default(content, (0, _emberChangesetValidations.default)(_content.default), _content.default, {
        skipValidate: true
      });
      controller.set('changeset', changeset);
    }
  });

  _exports.default = _default;
});