define("darcy/_pods/components/advanced-filter/my-events/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xu2Dji67",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"people-filter pt-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"My Events\"],[10],[0,\"\\n    \"],[7,\"span\"],[9],[1,[29,\"information-tooltip\",null,[[\"text\"],[\"Events you’ve attended\"]]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"power-select\",null,[[\"id\",\"triggerClass\",\"options\",\"selected\",\"allowClear\",\"searchEnabled\",\"placeholder\",\"noMatchesMessage\",\"onopen\",\"onchange\"],[\"companySearch\",\"w-full my-2 text-red\",[25,[\"eventOptions\"]],[25,[\"selected\"]],true,true,\"Events Attended Together\",\"You have not attended any events\",[29,\"action\",[[24,0,[]],\"onOpen\"],null],[29,\"action\",[[24,0,[]],\"selectEvent\"],null]]],{\"statements\":[[4,\"if\",[[24,1,[\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/my-events/template.hbs"
    }
  });

  _exports.default = _default;
});