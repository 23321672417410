define("darcy/_pods/account/masquerade/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eOpcOz93",
    "block": "{\"symbols\":[\"user\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-white p-6 md:py-10 md:px-8 rounded-r-lg rounded-bl-lg\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-sm md:text-base leading-5 text-gray_dark mb-6\"],[9],[0,\"\\n    Select a user to log in on their behalf:\\n  \"],[10],[0,\"\\n\\n\"],[4,\"power-select\",null,[[\"triggerClass\",\"searchEnabled\",\"searchField\",\"placeholder\",\"options\",\"selected\",\"loading\",\"search\",\"oninput\",\"onchange\"],[\"mb-3\",true,\"name\",\"User Search\",[25,[\"searchResultUsers\"]],[25,[\"selectedUser\"]],[25,[\"searchUsers\",\"isRunning\"]],[29,\"perform\",[[25,[\"searchUsers\"]]],null],[29,\"action\",[[24,0,[]],\"onSearchInput\"],null],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"selectedUser\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[24,1,[\"id\"]],false],[0,\". \"],[1,[24,1,[\"name\"]],false],[0,\" (\"],[1,[24,1,[\"email\"]],false],[0,\")\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"masqueradeAsUser\",[25,[\"selectedUser\"]]],null]],[12,\"disabled\",[29,\"not\",[[25,[\"selectedUser\"]]],null]],[11,\"class\",\"inline-flex justify-center py-1 md:py-2 px-4 md:px-6 border border-transparent text-sm leading-5 font-medium text-white bg-highlight_green hover:bg-highlight_light_green rounded\"],[11,\"type\",\"button\"],[9],[0,\"\\n    Masquerade\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/account/masquerade/template.hbs"
    }
  });

  _exports.default = _default;
});