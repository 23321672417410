define("darcy/_pods/account/masquerade/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    beforeModel: function beforeModel() {
      if (!this.get('currentUser.isDarcyUser') || !this.get('currentUser.isAdmin')) {
        this.transitionTo('account');
      }
    }
  });

  _exports.default = _default;
});