define("darcy/_pods/self-register/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/registration", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _emberChangeset, _emberChangesetValidations, _registration, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    model: function model(params) {
      var domainId = params.domainId,
          email = params.email;
      if (!email) this.transitionTo('welcome');
      var domain = domainId ? this.store.findRecord('company-domain', domainId) : null;
      return Ember.RSVP.hash({
        domain: domain,
        register: this.store.createRecord('register', {}),
        communities: this.store.findAll('community')
      });
    },
    setupController: function setupController(controller, model) {
      var register = model.register,
          communities = model.communities,
          domain = model.domain;

      if (domain) {
        register.set('companyName', domain.name);
        register.set('companyType', domain.companyType);
      }

      register.set('email', controller.email);
      var changeset = new _emberChangeset.default(register, (0, _emberChangesetValidations.default)(_registration.default), _registration.default, {
        skipValidate: true
      });
      var communityGroups;

      if (!domain) {
        communityGroups = this.store.peekAll('community-group');
      }

      controller.setProperties({
        changeset: changeset,
        communities: communities,
        communityGroups: communityGroups,
        domain: domain
      });
      Ember.run.next(function () {
        controller.setProperties({
          email: null,
          domainId: null
        });
      });
    },
    actions: {
      register: function register(changeset) {
        var _this = this;

        changeset.validate();
        if (!changeset.isValid) return;
        return changeset.save().then(function (user) {
          return _this.currentUserService.load().then(function () {
            return user;
          });
        }).then(function (user) {
          if (!user.confirmedAt && user.companyType !== 'operator') {
            _this.notifications.success('Welcome to Darcy Connect, please confirm your email so that we can help you create your storefront!');
          }

          var email = user.email;
          return _this.transitionTo('confirm', {
            queryParams: {
              email: email
            }
          });
        }).catch(function (err) {
          var msg = Ember.get(err, 'payload.errors.0.details');

          if (err.status == 409) {
            var userExistsMessage = 'That email already has an account, please log in below.'; // TODO: does the 409 return the email? should be passed to login with a query param

            return _this.transitionTo('login');
          }

          console.error(err);

          _this.notifications.error(msg || 'There was an issue creating your account, please try again.');
        });
      }
    }
  });

  _exports.default = _default;
});