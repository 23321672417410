define("darcy/_pods/usage/innovation-concierge/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tableSortDir: 'asc',
    tableSortName: 'status',
    tableFilter: null,
    requests: Ember.computed.alias('conciergeRequests'),
    requestsTotal: Ember.computed('requests', function () {
      return this.requests.length;
    }),
    chartLabels: (0, _lodash.reverse)((0, _lodash.times)(12, function (i) {
      return moment().startOf('month').subtract(i, 'months');
    })),
    cumulativeChartDataset: Ember.computed('conciergeRequests', function () {
      var _this = this;

      var data = this.chartLabels.map(function (month) {
        return _this.conciergeRequests.filter(function (request) {
          return moment(request.get('date')).isBefore(month, 'month');
        }).length;
      });
      return {
        label: 'Cumulative Total',
        data: data
      };
    }),
    monthlyChartDataset: Ember.computed('conciergeRequests', function () {
      var _this2 = this;

      var data = this.chartLabels.map(function (month) {
        return _this2.conciergeRequests.filter(function (request) {
          return moment(request.get('date')).isSame(month, 'month');
        }).length;
      });
      return {
        label: 'Monthly Total',
        data: data
      };
    }),
    chartDatasets: Ember.computed('invitesChartData', 'eventsChartData', function () {
      return [this.cumulativeChartDataset, this.monthlyChartDataset];
    }),
    chartColors: Ember.computed('chartLabels', 'tableFilter', function () {
      var filterIndex = this.chartLabels.indexOf(this.tableFilter);
      return [this.chartLabels.map(function (l, index) {
        if (filterIndex > -1 && index === filterIndex) return 'hsl(0 0% 75%)';
        return '#E7E7E7';
      }), this.chartLabels.map(function (l, index) {
        if (filterIndex > -1 && index === filterIndex) return 'hsl(34deg 73% 44%)';
        return '#F89C23';
      })];
    }),
    tableFilterName: Ember.computed('tableFilter', function () {
      return this.tableFilter.format('MMMM YYYY');
    }),
    tableColumns: [{
      label: 'Requestor',
      valuePath: '',
      cellClassNames: 'highlight truncate',
      cellComponent: 'table/cell/requestor',
      width: '15%'
    }, {
      label: 'Name',
      cellClassNames: 'truncate',
      valuePath: 'name',
      width: '40%'
    }, {
      label: 'Date Received',
      align: 'right',
      valuePath: 'date',
      cellComponent: 'table/cell/date',
      width: '15%'
    }, {
      label: 'Topics',
      align: 'left',
      valuePath: 'topicTags',
      width: '20%'
    }, {
      label: 'Status',
      align: 'left',
      valuePath: 'status',
      width: '10%'
    }],
    tableModel: Ember.computed('tableFilter', function () {
      var _this3 = this;

      return this.requests.filter(function (request) {
        if (request.isConfidential) return false;
        if (Ember.isEmpty(_this3.tableFilter)) return true;
        return _this3.tableFilter.isSame(request.date, 'month');
      });
    }),
    actions: {
      onTooltipLabelRender: function onTooltipLabelRender(tooltipItem) {
        if (tooltipItem.datasetIndex == 0) {
          var cumulative = this.cumulativeChartDataset;
          var monthly = this.monthlyChartDataset;
          var totalNumber = cumulative.data[tooltipItem.index] + monthly.data[tooltipItem.index];
          return cumulative.label + ': ' + totalNumber;
        }

        return '';
      },
      onFilter: function onFilter(barIndex) {
        if (barIndex === null) {
          return this.set('tableFilter', null);
        }

        var month = this.chartLabels[barIndex];
        this.set('tableFilter', month);
      },
      onClearFilter: function onClearFilter() {
        this.set('tableFilter', null);
      },
      onTableSort: function onTableSort(isAscending, sort) {
        var dir = isAscending ? 'asc' : 'desc';
        this.setProperties({
          tableSortName: sort,
          tableSortDir: dir
        });
      }
    }
  });

  _exports.default = _default;
});