define("darcy/models/content", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "darcy/config/environment"], function (_exports, _attr, _model, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var APP = _environment.default.APP;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    contentType: (0, _attr.default)('string'),
    fileType: (0, _attr.default)('string'),
    videoType: (0, _attr.default)('string'),
    description: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    urlEs: (0, _attr.default)('string'),
    photoUrl: (0, _attr.default)('string'),
    pspdfkitId: (0, _attr.default)('string'),
    jwt: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    publishedAt: (0, _attr.default)('date'),
    markdown: (0, _attr.default)('string'),
    contentOwner: (0, _attr.default)('string'),
    canonicalUrl: (0, _attr.default)('string'),
    adminUrl: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    isDarcyDemo: (0, _attr.default)('boolean'),
    internalKeywords: (0, _attr.default)('string'),
    metaTitle: (0, _attr.default)('string'),
    metaDescription: (0, _attr.default)('string'),
    isPublished: (0, _attr.default)('boolean'),
    isPrivate: (0, _attr.default)('boolean'),
    isConfidentialFromInnovator: (0, _attr.default)('boolean'),
    isAccessible: (0, _attr.default)('boolean'),
    isVideo: Ember.computed.equal('fileType', 'video'),
    isPdf: Ember.computed.equal('fileType', 'pdf'),
    isMarkdown: Ember.computed.equal('fileType', 'markdown'),
    isVimeo: Ember.computed.equal('videoType', 'vimeo'),
    isYoutube: Ember.computed.equal('videoType', 'youtube'),
    company: (0, _relationships.belongsTo)('company'),
    uploader: (0, _relationships.belongsTo)('user', {
      inverse: 'uploads'
    }),
    forums: (0, _relationships.hasMany)('forum'),
    topicTags: (0, _relationships.hasMany)('topicTags'),
    creators: (0, _relationships.hasMany)('user'),
    presenters: (0, _relationships.hasMany)('user'),
    operators: (0, _relationships.hasMany)('company'),
    innovators: (0, _relationships.hasMany)('company'),
    communities: (0, _relationships.hasMany)('community'),
    chapters: (0, _relationships.hasMany)('chapter'),
    serviceLines: (0, _relationships.hasMany)(),
    firstCreator: Ember.computed('creators.length', function () {
      return this.get('creators.firstObject');
    }),
    isLikedByCurrentUser: (0, _attr.default)('boolean'),
    isBookmarkedByCurrentUser: (0, _attr.default)('boolean'),
    isSharedByCurrentUser: (0, _attr.default)('boolean'),
    likes: (0, _relationships.hasMany)('like'),
    bookmarks: (0, _relationships.hasMany)('bookmark'),
    reactionsCount: (0, _attr.default)('number'),
    viewsCount: (0, _attr.default)('number'),
    likesCount: (0, _attr.default)('number'),
    sharesCount: (0, _attr.default)('number'),
    commentsCount: (0, _attr.default)('number'),
    isCommenteddByCurrentUser: (0, _attr.default)('boolean'),
    comments: (0, _relationships.hasMany)('comment')
  });

  _exports.default = _default;
});