define("darcy/helpers/set-referrer-link-id", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    analytics: Ember.inject.service(),
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          linkId = _ref2[0];

      if (!linkId) return;
      return function () {
        return _this.analytics.set('referrerLinkId', linkId);
      };
    }
  });

  _exports.default = _default;
});