define("darcy/_pods/components/date-range-selector/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isShowingDateSelector: false,
    dateRangeStart: null,
    dateRangeEnd: null,
    selectedDate: null,
    selectedDateType: null,
    isInvalidDateRange: Ember.computed('dateRangeStart', 'dateRangeEnd', function () {
      var dateRangeStart = this.dateRangeStart,
          dateRangeEnd = this.dateRangeEnd;

      if (dateRangeStart && dateRangeEnd) {
        return (0, _moment.default)(dateRangeEnd).startOf('day').diff((0, _moment.default)(dateRangeStart).startOf('day')) < 0;
      }
    }),
    actions: {
      showDateSelector: function showDateSelector(dateType) {
        this.set('selectedDateType', dateType);
        this.set('selectedDate', this.get(dateType));
        this.set('isShowingDateSelector', true);
      },
      selectDay: function selectDay(day) {
        var selectedDateType = this.selectedDateType;
        this.onSelect(selectedDateType, day.date);
        this.set('isShowingDateSelector', false);
      }
    }
  });

  _exports.default = _default;
});