define("darcy/_pods/components/modal/take-a-tour/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    appTour: Ember.inject.service(),
    actions: {
      goToStartTour: function goToStartTour() {
        this.set('appTour.isStartTourModalOpen', true);
        this.set('appTour.isTakeATourModalOpen', false);
      },
      closeModal: function closeModal() {
        this.set('appTour.isTakeATourModalOpen', false);
      }
    }
  });

  _exports.default = _default;
});