define("darcy/helpers/group-topic-tags", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupTopicTags = groupTopicTags;
  _exports.default = void 0;

  function groupTopicTags(params
  /*, hash*/
  ) {
    if (Ember.isEmpty(params)) return null;

    var _params = (0, _slicedToArray2.default)(params, 1),
        topicTags = _params[0];

    var grouped = topicTags.toArray().reduce(function (sum, topic) {
      var group = sum.findBy('groupName', topic.category);

      if (!group) {
        group = {
          groupName: topic.category,
          options: [],
          categoryOrder: topic.categoryOrder
        };
        sum.push(group);
      }

      group.options.push(topic);
      return sum;
    }, []).sortBy('categoryOrder');
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = grouped[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var sub = _step.value;
        sub.options.sort(function (a, b) {
          a.tag_order - b.tag_order;
        });
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return grouped;
  }

  var _default = Ember.Helper.helper(groupTopicTags);

  _exports.default = _default;
});