define("darcy/_pods/innovators/view/overview/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      preserveScrollPosition: 'psp'
    }],
    preserveScrollPosition: false
  });

  _exports.default = _default;
});