define("darcy/_pods/components/comment-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kFDDX1Mz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"displayTextarea\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"suggestion/textarea\",null,[[\"value\",\"mentionedUsers\",\"class\",\"placeholder\",\"rows\",\"addUser\",\"removeUser\"],[[25,[\"message\"]],[25,[\"mentionedUsers\"]],\"shadow appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none border-none bg-light-gray shadow-none resize-none\",[25,[\"placeholder\"]],\"4\",[29,\"action\",[[24,0,[]],\"addMentionedUser\"],null],[29,\"action\",[[24,0,[]],\"removeMentionedUser\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"loading-spinner\",null,[[\"class\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"message\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"flex self-end pl-1\"],[9],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"save\"],null]],[11,\"class\",\"text-sm text-white rounded-md bg-highlight_green hover:bg-highlight_light_green px-3 py-px\"],[9],[0,\"\\n          Post\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex items-center\"],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/comments-badge-gray.svg\"],null]]]],[11,\"class\",\"w-6\"],[11,\"alt\",\"Comment\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"data-test-comment-btn\",\"\"],[11,\"class\",\"ml-2 mr-2\"],[9],[1,[23,\"buttonLabel\"],false],[1,[29,\"if\",[[25,[\"buttonText\"]],[29,\"concat\",[\" \",[25,[\"buttonText\"]]],null]],null],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/comment-box/template.hbs"
    }
  });

  _exports.default = _default;
});