define("darcy/_pods/research/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    loading: Ember.inject.service(),
    analytics: Ember.inject.service(),
    queryParams: {
      file_type: {
        refreshModel: true
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.store.findAll('topicTag').then(function (topicTags) {
        controller.set('topicTagOptions', topicTags);
        var selectedTagIds = controller.get('topicTagIds');
        var selectedTags = controller.get('topicTagOptions').reject(function (a) {
          if (selectedTagIds.indexOf(a.id) >= 0) {
            return false;
          } else {
            return true;
          }
        });
        controller.set('topicTags', selectedTags);
      });
      controller.fetchRecords.perform(true);
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting) {
        this.controller.clearAll();
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.to.name === 'research.view') {
          this.set('loading.isActive', true);
        }
      }
    }
  });

  _exports.default = _default;
});