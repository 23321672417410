define("darcy/_pods/call-redirect/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['organization_id', 'service_id', 'call_id'],
    organization_id: null,
    service_id: null
  });

  _exports.default = _default;
});