define("darcy/_pods/components/content-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CONTENT_TYPES = ['presentation', 'report'];
  var FILE_TYPES = ['pdf', 'video', 'markdown'];
  var VIDEO_TYPES = ['vimeo', 'youtube'];

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    contentTypeOptions: Ember.computed(function () {
      return CONTENT_TYPES;
    }),
    videoTypeOptions: Ember.computed(function () {
      return VIDEO_TYPES;
    }),
    fileTypeOptions: Ember.computed('currentUser.isDarcyUser', function () {
      if (!this.currentUser.isDarcyUser) {
        return FILE_TYPES.without('markdown');
      }

      return FILE_TYPES;
    }),
    actions: {
      onFileChange: function onFileChange(e) {
        if (e.target.files.length > 0) {
          this.changeset.set('file', e.target.files[0]);
        }
      },
      updateMarkdownField: function updateMarkdownField(valueKey, mdown) {
        if (this.get(valueKey) !== mdown) {
          this.set(valueKey, mdown);
        }
      }
    }
  });

  _exports.default = _default;
});