define("darcy/_pods/components/header/mobile/landing/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    router: Ember.inject.service(),
    notificationsService: Ember.inject.service('api-notifications'),
    tagName: '',
    isShowingMenu: false,
    actions: {
      back: function back() {
        window.history.back();
      },
      toggleDropdown: function toggleDropdown(isClickOutside) {
        this.toggleProfileDropdown(isClickOutside);
      },
      toggleMenu: function toggleMenu() {
        this.toggleProperty('isShowingMenu');
      }
    }
  });

  _exports.default = _default;
});