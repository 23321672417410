define("darcy/_pods/components/advanced-filter/contributors/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "lodash"], function (_exports, _toConsumableArray2, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    contributorOptions: [{
      label: 'Technology Exposure',
      value: 'user'
    }, {
      label: 'Darcy Presenter (Innovator)',
      value: 'innovator'
    }, {
      label: 'Event Speaker',
      value: 'general'
    }],
    selectedContributors: [],
    actions: {
      selectContributor: function selectContributor(contributor) {
        var selectedContributors = (0, _toConsumableArray2.default)(this.selectedContributors);
        selectedContributors = (0, _lodash.xor)(selectedContributors, [contributor]);
        this.onContributorChange(selectedContributors);

        if (contributor === 'user') {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'technology_exposure': true
            }
          });
        } else if (contributor === 'innovator') {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'darcy_presenter': true
            }
          });
        } else {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'event_speaker': true
            }
          });
        }
      },
      clearContributor: function clearContributor() {
        this.onContributorChange();
      }
    }
  });

  _exports.default = _default;
});