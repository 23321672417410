define("darcy/_pods/components/follow-button/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    tagName: 'button',
    buttonType: 'button',
    attributeBindings: ['buttonType:type'],
    stopBubble: true,
    isTrendingInnovator: false,
    company: null,
    companyId: null,
    companyProfile: null,
    isFollowingCompany: Ember.computed('currentUserService.followedCompanyMap', 'companyProfile.companyId', function () {
      var companyId = this.get('companyProfile.companyId');
      return this.currentUserService.isFollowing(companyId);
    }),
    click: function click() {
      if (Ember.isBlank(this.currentUser)) return;
      this.toggleCompanyFollow();
      return !this.stopBubble;
    },
    toggleCompanyFollow: function toggleCompanyFollow() {
      var currentUser = this.currentUser;
      var companyId = this.get('companyProfile.companyId');
      var eventType = this.isFollowingCompany ? 'company_unfollow' : 'company_follow';

      if (currentUser && companyId) {
        currentUser.set('followedCompanyIds', (0, _lodash.xor)(currentUser.followedCompanyIds, [companyId]));
        currentUser.save();

        if (this.isFollowingCompany) {
          this.companyProfile.incrementProperty('reactionsCount');
        } else {
          this.companyProfile.decrementProperty('reactionsCount');
        }

        this.analytics.captureEvent(eventType, {
          companyProfile: this.companyProfile,
          url: window.location.href,
          meta: {
            target: this.isTrendingInnovator ? 'trending-innovator-sidebar-button' : 'list-item-button'
          }
        });
      }
    }
  });

  _exports.default = _default;
});