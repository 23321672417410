define("darcy/_pods/components/carousel-gallery/carousel-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X7QqFisz",
    "block": "{\"symbols\":[\"@thumbnail\"],\"statements\":[[7,\"div\"],[11,\"class\",\"photo-item-wrapper carousel-slide\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[24,1,[\"thumbnailUrl\"]]],[12,\"alt\",[24,1,[\"imageAlt\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/carousel-gallery/carousel-item/template.hbs"
    }
  });

  _exports.default = _default;
});