define("darcy/_pods/innovators/view/admin/products/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/product-overview"], function (_exports, _emberChangeset, _emberChangesetValidations, _productOverview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    beforeModel: function beforeModel() {
      this.transitionTo('innovators.view.admin.index');
    },
    model: function model() {
      var company = this.modelFor('innovators.view.admin');
      var productOverviews = company.get('productOverviews');
      return Ember.RSVP.hash({
        company: company,
        productOverviews: productOverviews
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var company = model.company,
          productOverviews = model.productOverviews;
      var productOverview = productOverviews.get('firstObject');
      var productOverviewRecord = productOverview || this.store.createRecord('product-overview', {
        company: company
      });
      var productOverviewChangeset = new _emberChangeset.default(productOverviewRecord, (0, _emberChangesetValidations.default)(_productOverview.default), _productOverview.default);
      controller.set('changeset', productOverviewChangeset);
    }
  });

  _exports.default = _default;
});