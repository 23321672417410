define("darcy/_pods/components/invite-list-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    dateStatusClass: Ember.computed('invite.forum.startDate', function () {
      return this.get('invite.forum.startDate') > Date.now() ? 'bg-green-500' : 'bg-gray-500';
    }),
    status: Ember.computed('invite.isRsvpComplete', {
      get: function get() {
        var isRsvpComplete = this.invite.isRsvpComplete;

        if (isRsvpComplete) {
          return 'rsvp confirmed';
        } else {
          return 'invited';
        }
      }
    }),
    statusClass: Ember.computed('invite.isRsvpComplete', {
      get: function get() {
        var isRsvpComplete = this.invite.isRsvpComplete;

        if (isRsvpComplete) {
          return 'bg-green-500';
        } else {
          return 'bg-blue-500';
        }
      }
    })
  });

  _exports.default = _default;
});