define("darcy/_pods/components/dropdown-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YxePd1q7",
    "block": "{\"symbols\":[\"option\",\"@parentDivClass\",\"@buttonClass\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"relative inline-block text-left \",[24,2,[]]]]],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[30,[\"inline-flex justify-center w-full rounded-md shadow-sm \",[24,3,[]]]]],[11,\"id\",\"options-menu\"],[11,\"aria-haspopup\",\"true\"],[11,\"aria-expanded\",\"true\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[23,\"prefixButtonName\"],false],[0,\" \"],[1,[25,[\"selectedOption\",\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"displayMenu\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"origin-top-right absolute left-0 z-20 mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-400\"],[11,\"role\",\"menu\"],[11,\"aria-orientation\",\"vertical\"],[11,\"aria-labelledby\",\"options-menu\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"a\"],[12,\"class\",[30,[\"block px-4 py-2 text-xs hover:bg-navy_nav hover:text-white cursor-pointer uppercase \",[29,\"if\",[[29,\"eq\",[[25,[\"selectedOption\",\"key\"]],[24,1,[\"key\"]]],null],\"bg-navy_nav text-white\",\"text-navy_nav\"],null]]]],[11,\"role\",\"menuitem\"],[9],[1,[24,1,[\"name\"]],false],[3,\"action\",[[24,0,[]],\"select\",[24,1,[]]]],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/dropdown-menu/template.hbs"
    }
  });

  _exports.default = _default;
});