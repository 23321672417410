define("darcy/_pods/components/advanced-filter/technology-state/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TECHNOLOGY_STATE_OPTIONS = [{
    label: 'All States',
    value: null
  }, {
    label: 'Research/POC',
    value: 'Research/POC'
  }, {
    label: 'Early Development',
    value: 'Early Development'
  }, {
    label: 'Piloting',
    value: 'Piloting'
  }, {
    label: 'Commercialized',
    value: 'Commercialized'
  }, {
    label: 'Scaling',
    value: 'Scaling'
  }];

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    technologyStateOptions: TECHNOLOGY_STATE_OPTIONS,
    selectedTechnologyState: null,
    actions: {
      selectTechnologyState: function selectTechnologyState(technologyState) {
        this.onTechnologyStateChange(technologyState);

        if (technologyState) {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'technology_state': technologyState,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});