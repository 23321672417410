define("darcy/_pods/components/advanced-filter/title/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Nvtzpqap",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"Title\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-full inline-flex items-center py-2\"],[9],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"type\",\"id\",\"class\",\"onchange\",\"value\",\"placeholder\",\"autocomplete\",\"input\"],[\"search\",[29,\"concat\",[[25,[\"elementId\"]],\"-title\"],null],\"w-full form-text rounded border border-gray-600 text-membership_light focus:shadow-none focus:outline-none p-2\",[29,\"action\",[[24,0,[]],\"selectTitle\",[25,[\"title\",\"value\"]]],null],[25,[\"selectedTitle\"]],\"i.e. Engineer, Manager, etc.\",\"off\",[29,\"action\",[[24,0,[]],\"selectTitle\"],[[\"value\"],[\"target.value\"]]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/title/template.hbs"
    }
  });

  _exports.default = _default;
});