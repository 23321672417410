define("darcy/_pods/components/pspdfkit-preview/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pdf-preview'],
    minWidth: 768,
    width: null,
    documentId: null,
    jwt: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var width = Math.max(this.$().width(), this.minWidth);
      this.set('width', width);
      this.onParamsChange();
    },
    onParamsChange: Ember.observer('documentId', 'jwt', function () {
      var img = this.$('.pdf-image')[0];

      if (!this.documentId || !this.jwt) {
        img.src = null;
        return;
      }

      img.src = "".concat(_environment.default.PSPDFKit.baseUrl, "/documents/").concat(this.documentId, "/cover?width=").concat(2 * this.width, "&jwt=").concat(this.jwt);
      img.width = this.width;
    })
  });

  _exports.default = _default;
});