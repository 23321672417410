define("darcy/_pods/components/square-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rHQx2YgF",
    "block": "{\"symbols\":[\"@isActive\",\"@onToggle\",\"@activeClass\",\"@activeColor\",\"@disabled\",\"@hasActiveChildren\"],\"statements\":[[7,\"span\"],[11,\"role\",\"checkbox\"],[11,\"tabindex\",\"0\"],[12,\"aria-checked\",[24,1,[]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,2,[]]],null]],[12,\"class\",[30,[[29,\"if\",[[29,\"and\",[[24,6,[]],[29,\"not\",[[24,1,[]]],null]],null],\"bg-gray-400\",\"bg-transparent\"],null],\" \",[29,\"if\",[[24,5,[]],\"pointer-events-none border-gray-500 \",[29,\"concat\",[\"text-\",[29,\"or\",[[24,4,[]],\"navy\"],null],\" border-gray-600\"],null]],null],\" border rounded p-px inline-block mr-1 my-1 cursor-pointer \",[29,\"if\",[[24,1,[]],[24,3,[]]],null]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[12,\"class\",[30,[[29,\"if\",[[24,1,[]],\"bg-current\",\"bg-transparent\"],null],\" m-px w-4 h-4 block rounded\"]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/square-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});