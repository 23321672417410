define("darcy/_pods/innovators/view/admin/content/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bEw3e501",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"class\",\"leading-normal text-2xl font-light\"],[9],[0,\"Creating New Content\"],[10],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"text-blue-700 underline\",\"innovators.view.admin.content\",[25,[\"model\",\"company\",\"slug\"]]]],{\"statements\":[[0,\"Back to Content List\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[29,\"content-form\",null,[[\"changeset\",\"submitTask\"],[[25,[\"changeset\"]],[25,[\"submitTask\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/innovators/view/admin/content/new/template.hbs"
    }
  });

  _exports.default = _default;
});