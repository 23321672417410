define("darcy/_pods/components/modal/success/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.runTimer();
    },
    runTimer: function runTimer() {
      var _this = this;

      setTimeout(function () {
        _this.close();
      }, this.data.autoCloseDuration);
    }
  });

  _exports.default = _default;
});