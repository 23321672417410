define("darcy/_pods/components/advanced-filter/date-range/component", ["exports", "moment", "darcy/utils/constants"], function (_exports, _moment, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    periodOptions: Ember.computed('filterUpcoming', function () {
      var options = _constants.PERIOD_OPTIONS;

      if (!this.filterUpcoming) {
        options = options.filter(function (opt) {
          return opt.value !== 'upcoming';
        });
      }

      if (this.showAnytime === false) {
        options = options.filter(function (opt) {
          return opt.value !== 'anytime';
        });
      }

      if (this.hideDateRangeSelector) {
        options = options.filter(function (opt) {
          return opt.value !== 'custom_date_range';
        });
      }

      return options;
    }),
    dateRangeLabel: 'Date',
    dateRangeStart: null,
    dateRangeEnd: null,
    selectedDatePeriod: null,
    filterUpcoming: false,
    showAnytime: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._selectDatePeriod({
        value: this.selectedDatePeriod
      }, true, true);
    },
    _onDateRangeChange: function _onDateRangeChange(start, end, initialRender) {
      var startDate = start ? (0, _moment.default)(start).format('YYYY-MM-DD') : '-';
      var endDate = end ? (0, _moment.default)(end).format('YYYY-MM-DD') : '-';
      var range = null;

      if (startDate !== '-' || end !== '-') {
        range = "".concat(startDate, "...").concat(endDate);
      }

      if (range === '-...-') {
        range = null;
      }

      if (!initialRender) {
        this.onDateRangeChange(range, this.selectedDatePeriod);
      }
    },
    _selectDatePeriod: function _selectDatePeriod(period) {
      var disableAnalytics = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var initialRender = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      if (!period) return;
      var range = null;
      var today = new Date();
      var startDate = (0, _moment.default)(today).subtract(1, 'years').toDate();
      var endDate = today;

      switch (period.value) {
        case 'anytime':
          startDate = null;
          endDate = null;
          break;

        case 'upcoming':
          startDate = today;
          endDate = null;
          break;

        case 'past_30':
          startDate = (0, _moment.default)(today).subtract(30, 'days').toDate();
          endDate = today;
          break;

        case 'past_60':
          startDate = (0, _moment.default)(today).subtract(60, 'days').toDate();
          endDate = today;
          break;

        case 'past_90':
          startDate = (0, _moment.default)(today).subtract(90, 'days').toDate();
          endDate = today;
          break;

        case 'past_year':
          startDate = (0, _moment.default)(today).subtract(1, 'years').toDate();
          endDate = today;
          break;

        case 'custom_date_range':
          break;
      }

      this.set('selectedDatePeriod', period.value);

      this._onDateRangeChange(startDate, endDate, initialRender);

      if (!disableAnalytics) {
        this.captureAnalytics(period.label);
      }
    },
    captureAnalytics: function captureAnalytics(period) {
      this.analytics.captureEvent('add_filter', {
        meta: {
          'date': period,
          'target': 'advanced-filter'
        }
      });
    },
    actions: {
      selectDatePeriod: function selectDatePeriod(period) {
        this._selectDatePeriod(period);
      },
      selectDate: function selectDate(prop, value) {
        this.set(prop, value);

        this._onDateRangeChange(this.dateRangeStart, this.dateRangeEnd);

        this.captureAnalytics();
      }
    }
  });

  _exports.default = _default;
});