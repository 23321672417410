define("darcy/_pods/-storefronts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var path = window.location.pathname.split("storefronts")[1] || "";

      if (path) {
        window.location.pathname = "innovators".concat(path);
      } else {
        window.location.pathname = "innovators";
      }
    }
  });

  _exports.default = _default;
});