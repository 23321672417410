define("darcy/_pods/components/table/cell/events-topic-tags/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GjGp4zUQ",
    "block": "{\"symbols\":[\"@rawValue\"],\"statements\":[[4,\"if\",[[24,1,[\"length\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"line-clamp-2\"],[9],[0,\"\\n     \"],[1,[23,\"topicTags\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/events-topic-tags/template.hbs"
    }
  });

  _exports.default = _default;
});