define("darcy/_pods/account/notification-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VeSCvVe+",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",[\"Notifications\"],null],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"bg-white flex flex-col p-6 md:py-10 px-12 rounded-r-lg rounded-bl-lg\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pb-8 border-b-2\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"unsubscribeToken\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-2xl font-semibold pb-4\"],[9],[0,\"\\n        Email & Invite Subscriptions\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"text-sm md:text-base text-gray-600 pb-4\"],[9],[0,\"\\n        Email: \"],[7,\"span\"],[11,\"class\",\"underline text-blue-500\"],[9],[1,[25,[\"user\",\"email\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"text-sm md:text-base text-gray-600\"],[9],[0,\"\\n        You can change your subscription settings here:\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"text-xsm text-gray-600 pb-6\"],[9],[0,\"\\n        (or from your profile on Darcy Connect)\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-2xl font-semibold\"],[9],[0,\"\\n        Communications\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"Choose how you get notified about what's important to you\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[1,[29,\"notification-settings\",null,[[\"unsubscribeToken\",\"user\",\"visibleServiceLines\",\"isShowingDisableAutoInvitesModal\",\"currentServiceLine\",\"onToggleServiceLine\",\"onToggleNotification\"],[[25,[\"unsubscribeToken\"]],[25,[\"user\"]],[25,[\"visibleServiceLines\"]],[25,[\"isShowingDisableAutoInvitesModal\"]],[25,[\"currentServiceLine\"]],[29,\"action\",[[24,0,[]],\"toggleServiceLine\"],null],[29,\"action\",[[24,0,[]],\"toggleNotification\"],null]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/account/notification-settings/template.hbs"
    }
  });

  _exports.default = _default;
});