define("darcy/_pods/innovators/view/admin/photos/index/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/content"], function (_exports, _emberChangeset, _emberChangesetValidations, _content) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    beforeModel: function beforeModel() {
      this.transitionTo('innovators.view.admin.index');
    },
    model: function model() {
      var company = this.modelFor('innovators.view.admin');
      var carouselItems = company.get('carouselItems');
      return Ember.RSVP.hash({
        company: company,
        carouselItems: carouselItems
      });
    }
  });

  _exports.default = _default;
});