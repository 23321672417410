define("darcy/models/chart", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    labels: (0, _attr.default)(),
    datasets: (0, _attr.default)()
  });

  _exports.default = _default;
});