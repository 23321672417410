define("darcy/validations/carousel-item", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: [(0, _validators.validatePresence)({
      presence: true
    })],
    contentType: [(0, _validators.validatePresence)({
      presence: true
    })],
    file: [function (key, newValue, oldValue, changes, content) {
      if (changes.contentType !== 'video') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }],
    url: [function (key, newValue, oldValue, changes, content) {
      if (changes.contentType === 'video') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }]
  };
  _exports.default = _default;
});