define("darcy/_pods/events/view/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    loading: Ember.inject.service(),
    metaTags: Ember.inject.service('meta-tags'),
    queryParams: {
      action: {
        refreshModel: true
      },
      invite_id: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var forum_id = _ref.forum_id,
          action = _ref.action;
      var similarForums;
      var agenda;
      var invite;
      return this.store.findRecord('forum', forum_id).catch(function (error) {
        if (error.status === 404) {
          return null;
        } else {
          throw error;
        }
      }).then(function (forum) {
        if (_this.currentUser && Ember.isPresent(forum)) {
          agenda = _this.store.query('agenda-item', {
            forum_id: forum.id
          });
          similarForums = _this.store.query('forum', {
            filter: 'next_similar',
            id: forum.id
          }).then(function (f) {
            return f;
          }).catch(function (e) {
            console.error(e);
            return [];
          });
          invite = _this.fetchInvite(forum.id, _this.currentUser.id);
        }

        return Ember.RSVP.hash({
          forum: forum,
          invite: invite,
          action: action,
          agenda: agenda,
          similarForums: similarForums
        });
      });
    },
    afterModel: function afterModel(model) {
      if (model.forum) {
        var headTags = this.metaTags.getHeadTags(model.forum);
        this.set('headTags', headTags);
      }
    },
    redirect: function redirect(model, transition) {
      // redirect if using a legacy id route
      if (Ember.isPresent(model.forum.slug)) {
        this.transitionTo(transition.to.name, model.forum.slug);
      }
    },
    restrictionNotice: Ember.on('activate', function () {
      var _this$modelFor = this.modelFor(this.routeName),
          invite = _this$modelFor.invite,
          forum = _this$modelFor.forum;

      var user = this.get('currentUser');

      if (Ember.isBlank(forum) || forum.isPreviewOnly) {
        if (((user === null || user === void 0 ? void 0 : user.classification) === 'operator' || (user === null || user === void 0 ? void 0 : user.classification) === 'public_sl_alias') && forum.hasFreeTrialServiceLines && !(user === null || user === void 0 ? void 0 : user.hasFreeTrialMembershipAgreements)) {
          this.analytics.captureEvent('show_free_trial_membership_access_popup', {
            company: user.get('company')
          });
        } else {
          user && this.analytics.captureEvent('free_account_notification', {
            url: window.location.href
          });
        }

        this.send('showNoAccessModal');
      }
    }),
    setupController: function setupController(controller, model) {
      var _this2 = this;

      var forum = model.forum;

      if (!forum) {
        this.send('showNoAccessModal');
        this.get('currentUser') && this.analytics.captureEvent('free_account_notification', {
          url: window.location.href
        });
        return this.transitionTo('events');
      }

      this._super(controller, model);

      if (this.currentUser) {
        var invite = model.invite,
            action = model.action;
        new Ember.RSVP.Promise(function (resolve) {
          if (!invite && model.forum.isAvailable && model.forum.isPreviewOnly === false && Ember.isPresent(action)) {
            return resolve(_this2.createInvite(model.forum, _this2.currentUser));
          }

          return resolve(invite);
        }).then(function (invite) {
          if (invite && action === 'accept') {
            return _this2.acceptInvite(invite);
          }

          if (invite && action === 'decline') {
            return _this2.declineInvite(invite);
          }

          controller.set('invite', invite);
        });
      }
    },
    fetchInvite: function fetchInvite(forumId, userId) {
      return this.store.query('invite', {
        user_id: userId,
        forum_id: forumId,
        show_declined: true
      }).then(function (invites) {
        return invites.get('firstObject');
      });
    },
    createInvite: function createInvite(forum, user) {
      var invite = this.store.createRecord('invite', {
        forum: forum,
        user: user
      });
      return invite.save();
    },
    acceptInvite: function acceptInvite(invite) {
      var _this3 = this;

      invite.set('declinedAt', null);
      invite.set('isRsvpComplete', true);
      return invite.save().then(function () {
        var forumId = invite.belongsTo('forum').id();

        _this3.transitionTo('events.view.intro', forumId, {
          queryParams: {
            action: null,
            invite_id: null
          }
        });
      });
    },
    declineInvite: function declineInvite(invite) {
      var _this4 = this;

      if (!invite.declinedAt) {
        invite.set('declinedAt', new Date());
      }

      invite.set('isRsvpComplete', false);
      return invite.save().then(function (invite) {
        var forumId = invite.belongsTo('forum').id();

        _this4.transitionTo('events.view.intro', forumId, {
          queryParams: {
            action: null,
            invite_id: null
          }
        });
      });
    },
    actions: {
      didTransition: function didTransition() {
        this.set('loading.isActive', false);
      },
      back: function back() {
        window.history.back();
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});