define("darcy/_pods/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zCSOkSNN",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"my-8 mx-auto w-full max-w-2xl flex flex-col flex-1 justify-center\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"steps\",\"length\"]],1],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"progress-indicator\",null,[[\"currentStepName\",\"steps\"],[\"Create Account\",[25,[\"steps\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"bg-white md:shadow-md md:rounded px-8 pt-6 pb-8 mb-4\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"leading-none text-4xl text-center font-light mb-6\"],[9],[0,\"Accept Invite\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-gray-800 mb-8 leading-relaxed\"],[9],[0,\"You are accepting an invite for \"],[7,\"span\"],[11,\"class\",\"text-black\"],[9],[1,[25,[\"userChangeset\",\"email\"]],false],[10],[0,\" to Darcy Connect. Not \"],[1,[25,[\"userChangeset\",\"firstName\"]],false],[0,\"? \"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"bg-blue-400 hover:bg-blue-500 text-sm text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline\",\"self-register\"]],{\"statements\":[[0,\"That's Not Me\"]],\"parameters\":[]},null],[10],[0,\"\\n    \"],[5,\"user-form\",[],[[\"@domain\",\"@isNewRegistration\",\"@showTerms\",\"@isInvited\",\"@changeset\",\"@onSubmit\"],[[23,\"domain\"],true,true,true,[24,0,[\"userChangeset\"]],[29,\"route-action\",[\"register\"],null]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/register/template.hbs"
    }
  });

  _exports.default = _default;
});