define("darcy/_pods/account/communities/route", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      var _this$modelFor = this.modelFor('account'),
          user = _this$modelFor.user;

      var communities = this.store.query('community', {});
      var userCommunities = user.get('communities');
      return Ember.RSVP.hash({
        user: user,
        communities: communities,
        userCommunities: userCommunities
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var changeset = new _emberChangeset.default(model.user);
      controller.set('accountChangeset', changeset);
      controller.set('communities', model.communities);
      controller.set('communityGroups', this.store.peekAll('community-group'));
    },
    actions: {
      willTransition: function willTransition(transition) {
        var hasChanges = this.get('controller.accountChangeset.isDirty');

        if (hasChanges && !confirm('You have unsaved data. Are you sure you want to continue?')) {
          transition.abort();
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});