define("darcy/_pods/account/membership/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TECHNICAL_SERVICE_LINE_IDS = ['1', '2', '3', '9'];

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    user: null,
    serviceLines: null,
    serviceLinesAndMembershipAgreement: Ember.computed('serviceLines.[]', 'user.activeUserAndCompanyMembershipAgreements.[]', function () {
      var _this = this;

      var serviceLines = this.get('serviceLines').filter(function (sl) {
        return TECHNICAL_SERVICE_LINE_IDS.includes(sl.id);
      });
      return serviceLines.sortBy('id').map(function (serviceLine) {
        var companyMembershipAgreement = _this.user.activeUserAndCompanyMembershipAgreements.find(function (agreement) {
          var isCompany = Ember.isPresent(agreement.company.get('id'));
          var isServiceLine = agreement.serviceLines.map(function (sl) {
            return sl.id;
          }).includes(serviceLine.id);
          return isCompany && isServiceLine;
        });

        var userMembershipAgreement = _this.user.activeUserAndCompanyMembershipAgreements.find(function (agreement) {
          var isUser = Ember.isBlank(agreement.company.get('id'));
          var isServiceLine = agreement.serviceLines.map(function (sl) {
            return sl.id;
          }).includes(serviceLine.id);
          return isUser && isServiceLine;
        });

        var membershipAgreement = Ember.isPresent(companyMembershipAgreement) ? companyMembershipAgreement : userMembershipAgreement;
        var moreInfoUrl;

        switch (serviceLine.name) {
          case 'Oil & Gas':
            moreInfoUrl = 'https://darcy-partners-assets.s3-us-west-2.amazonaws.com/Darcy+-+O%26G.pdf';
            break;

          case 'Sustainability':
            moreInfoUrl = 'https://darcy-partners-assets.s3-us-west-2.amazonaws.com/Darcy+-+Sus.pdf';
            break;

          case 'Power & Utilities':
            moreInfoUrl = 'https://darcy-partners-assets.s3-us-west-2.amazonaws.com/Darcy+-+Power.pdf';
            break;

          case 'Energy Transition':
            moreInfoUrl = 'https://darcy-partners-assets.s3-us-west-2.amazonaws.com/Darcy+-+ET.pdf';
            break;
        }

        return {
          serviceLine: serviceLine,
          isActive: Ember.isPresent(membershipAgreement),
          membershipAgreement: membershipAgreement,
          moreInfoUrl: moreInfoUrl
        };
      });
    }),
    actions: {
      requestTrial: function requestTrial(id) {
        this.send('showNoAccessModal');
        this.analytics.captureEvent('account_membership_request_trial', {
          url: window.location.href,
          meta: {
            service_line_id: id
          }
        });
      }
    }
  });

  _exports.default = _default;
});