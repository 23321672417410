define("darcy/_pods/usage/content/view/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    shareBody: Ember.computed('model.{name,company.name}', function () {
      var companyName = this.get('model.company.name');
      var contentName = this.get('model.name');
      return "Here's a link to \"".concat(contentName, "\" from ").concat(companyName);
    }),
    pdfTitle: Ember.computed('model.name', 'model.publishedAt', function () {
      if (Ember.isBlank(this.model.publishedAt)) return;
      return "".concat(this.model.name, " (").concat(moment(this.model.publishedAt).format('MMM D, YYYY'), ")");
    }),
    actions: {
      onPDFShare: function onPDFShare() {
        $('.pdf-share-button').click();
      }
    }
  });

  _exports.default = _default;
});