define("darcy/adapters/activity-feed", ["exports", "darcy/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    shouldReloadRecord: function shouldReloadRecord(store, snapshot) {
      return true;
    },
    shouldReloadAll: function shouldReloadAll(store, snapshot) {
      return true;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord(store, snapshot) {
      return true;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll(store, snapshot) {
      return true;
    }
  });

  _exports.default = _default;
});