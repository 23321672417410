define("darcy/_pods/components/list-item/home/forum-today/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    userPreview: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    isAccessible: null,
    topicTagsList: [],
    speakersList: [],
    actions: {
      openUserPreviewPopover: function openUserPreviewPopover(id, e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.currentUser.id === id) return;
        var user = this.store.findRecord('user', id);
        this.userPreview.setModel(user);
      }
    }
  });

  _exports.default = _default;
});