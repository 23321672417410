define("darcy/_pods/components/notification-settings/app/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    isShowingSettings: false,
    actions: {
      toggleView: function toggleView() {
        this.toggleProperty('isShowingSettings');
      },
      toggleNotification: function toggleNotification(notification) {
        this.toggleProperty(notification);
        this.user.save();
        this.analytics.captureEvent('user_toggle_notification_setting', {
          meta: {
            type: 'app',
            name: notification.replace('user.', ''),
            value: this.get(notification)
          }
        });
      }
    }
  });

  _exports.default = _default;
});