define("darcy/services/connection-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    connectee: null,
    analyticsEventMetaTarget: null,
    isModalOpen: Ember.computed('connectee', function () {
      return Ember.isPresent(this.connectee);
    }),
    setConnectee: function setConnectee(connectee, eventTarget) {
      this.set('connectee', connectee);
      if (eventTarget) this.set('analyticsEventMetaTarget', eventTarget);
    },
    reset: function reset() {
      this.set('connectee', null);
      this.set('analyticsEventMetaTarget', null);
    }
  });

  _exports.default = _default;
});