define("darcy/_pods/components/edit-communities/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ga/EVsXR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"sm:col-span-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"max-w-lg\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mt-4\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"communityItems\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[11,\"class\",\"mt-4 flex items-center\"],[9],[0,\"\\n            \"],[5,\"input\",[[13,\"class\",\"form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out\"],[13,\"disabled\",[24,1,[\"community\",\"isHidden\"]]],[13,\"onchange\",[29,\"action\",[[24,0,[]],\"selectCommunity\",[24,1,[]]],null]]],[[\"@type\",\"@checked\"],[\"checkbox\",[24,1,[\"checked\"]]]]],[0,\"\\n            \"],[7,\"label\"],[11,\"for\",\"push_email\"],[11,\"class\",\"ml-3\"],[9],[0,\"\\n              \"],[7,\"span\"],[11,\"class\",\"block text-sm leading-5 font-medium text-gray-700\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"community\",\"isHidden\"]]],null,{\"statements\":[[0,\"                  Private:  \\n\"]],\"parameters\":[]},null],[0,\"                \"],[1,[24,1,[\"community\",\"name\"]],false],[0,\"\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/edit-communities/template.hbs"
    }
  });

  _exports.default = _default;
});