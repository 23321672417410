define("darcy/_pods/components/advanced-filter/file-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FILE_TYPE_OPTIONS = [{
    label: 'All',
    value: null
  }, {
    label: 'Videos',
    value: 'video'
  }, {
    label: 'PDFs',
    value: 'pdf'
  }];

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    fileTypeOptions: FILE_TYPE_OPTIONS,
    selectedFileType: null,
    actions: {
      selectFileType: function selectFileType(fileType) {
        this.onFileTypeChange(fileType);

        if (fileType) {
          this.analytics.captureEvent('add_filter', {
            url: window.location.href,
            meta: {
              'file_type': fileType,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});