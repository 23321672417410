define("darcy/_pods/components/suggestion/textarea/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4pranHfp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"id\",\"suggestion-textarea\"],[12,\"class\",[30,[\"relative w-full \",[29,\"if\",[[25,[\"isReply\"]],\"reply-box\",\"comment-box\"],null]]]],[9],[0,\"\\n  \"],[7,\"textarea\"],[11,\"data-test\",\"suggestion-textarea\"],[11,\"spellcheck\",\"false\"],[12,\"value\",[24,0,[\"value\"]]],[12,\"oninput\",[29,\"action\",[[24,0,[]],\"onInput\"],null]],[12,\"onkeydown\",[29,\"action\",[[24,0,[]],\"onKeyDown\"],null]],[12,\"class\",[23,\"class\"]],[12,\"placeholder\",[23,\"placeholder\"]],[12,\"id\",[24,0,[\"elId\"]]],[12,\"cols\",[23,\"cols\"]],[12,\"rows\",[23,\"rows\"]],[9],[0,\"  \"],[3,\"autoresize\"],[10],[0,\"\\n\"],[4,\"if\",[[24,0,[\"suggestions\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[5,\"suggestion/dropdown\",[],[[\"@position\",\"@suggestions\",\"@onSuggestionSelection\",\"@enterClicked\",\"@upClicked\",\"@downClicked\"],[[24,0,[\"caretPosition\"]],[24,0,[\"suggestions\"]],[29,\"action\",[[24,0,[]],\"onSuggestionSelection\"],null],[24,0,[\"enterClicked\"]],[24,0,[\"upClicked\"]],[24,0,[\"downClicked\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/suggestion/textarea/template.hbs"
    }
  });

  _exports.default = _default;
});