define("darcy/_pods/components/modal/fullscreen/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TGEHbyFb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"fullscreen-modal \",[29,\"if\",[[25,[\"isOpen\"]],\"show-modal\"],null],\" transition-all delay-75 duration-300 ease-in-out\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"absolute top-0 flex justify-between w-full bg-white shadow z-30 px-8\"],[11,\"style\",\"padding-top: env(safe-area-inset-top);\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-1 text-xl py-4\"],[9],[1,[23,\"headerTitle\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex-none w-20 h-1 bg-gray-500 rounded mt-3\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"onclick\",[23,\"headerAction\"]],[11,\"class\",\"flex-1 cursor-pointer self-center text-highlight_green text-right text-lg\"],[9],[0,\"\\n      \"],[1,[23,\"headerActionLabel\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"absolute w-full h-full overflow-y-auto px-8 pt-32 pb-24\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"absolute bottom-0 w-full pt-4 px-6 flex bg-white justify-center shadow-md-t\"],[11,\"style\",\"padding-bottom: max(env(safe-area-inset-bottom), 1rem);\"],[9],[0,\"\\n    \"],[7,\"button\"],[12,\"onclick\",[23,\"footerAction\"]],[11,\"class\",\"w-full text-md font-semibold text-center cursor-pointer border border-gray-500 sm:border-0 bg-gray-400 rounded-full px-3 py-2 mx-auto my-auto\"],[11,\"type\",\"button\"],[9],[0,\"\\n      \"],[1,[23,\"footerActionLabel\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/modal/fullscreen/template.hbs"
    }
  });

  _exports.default = _default;
});