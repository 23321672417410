define("darcy/_pods/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ember-ajax/errors"], function (_exports, _unauthenticatedRouteMixin, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AUTHENTICATOR = 'authenticator:credentials';

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    analytics: Ember.inject.service(),
    queryParams: {
      email: {
        refreshModel: true
      }
    },
    checkIfMobile: function checkIfMobile() {
      var userAgent = this.get('userAgent');
      var mobileType = userAgent.get('os.info');

      if (userAgent.get('os.isAndroid') || userAgent.get('os.isIOS')) {
        this.analytics.captureEvent('mobile_device_detected', {
          url: window.location.href,
          meta: mobileType
        });
      }
    },
    actions: {
      login: function login(email, password) {
        var _this = this;

        return this.get('session').authenticate(AUTHENTICATOR, email, password).then(function () {
          _this.checkIfMobile();

          return _this.currentUserService.load(true);
        }).catch(function (e) {
          if ((0, _errors.isUnauthorizedError)(e)) {
            var detail;

            try {
              detail = e.payload.errors[0].detail;
            } catch (err) {
              detail = '';
            }

            var message;

            if (/account is inactive/i.test(detail)) {
              message = 'Your account is not active—please contact Darcy’s client service team at darcy@darcypartners.com or send us a message in the chat.';
              Tawk_API.maximize();
            } else if (/no active membership agreements/i.test(detail)) {
              message = 'Your company has no active membership agreements.';
            } else if (/confirm/i.test(detail)) {
              return _this.transitionTo('confirm', {
                queryParams: {
                  email: email
                }
              });
            } else {
              message = 'You have entered an invalid username or password.';
            }

            throw new Error(message);
          } else {
            throw new Error('There was a server error, please try again.');
          }
        });
      }
    }
  });

  _exports.default = _default;
});