define("darcy/_pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    session: Ember.inject.service(),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      // need to refresh user model because fields like `confirmedAt` can be
      // updated server-side without the client's knowledge
      return this.currentUserService.load(true).then(function (currentUser) {
        var _transition$from, _transition$from2;

        var source = _this.get('session.data.authenticated.source');

        var savedUrlPath = window.localStorage.getItem('last_visited_path');
        var isConnectionRequestFlow = /network\?(a|i)=/.test(savedUrlPath);
        var nonAllUserCommunities = [];

        if (currentUser) {
          nonAllUserCommunities = currentUser.communities.filter(function (community) {
            return !/^All Users/.test(community.name);
          });
        }

        if (currentUser && !currentUser.confirmedAt) {
          _this.transitionTo('confirm');
        } else if ((source === 'login' || source === 'register') && currentUser.isGettingStartedRequired && Ember.isBlank(currentUser.gettingStartedShownAt) && !isConnectionRequestFlow) {
          _this.transitionTo('getting-started.welcome');

          _this.set('session.data.authenticated.source', 'getting-started');
        } else if ((source === 'login' || source === 'register' || source === 'getting-started') && savedUrlPath) {
          _this.transitionTo(savedUrlPath);

          window.localStorage.removeItem('last_visited_path');
        } else if (currentUser && currentUser.isInnovator && currentUser.get('company.isStorefrontPublished') === true) {
          _this.transitionTo('innovators.view', currentUser.get('company.slug'));
        } else if ((currentUser === null || currentUser === void 0 ? void 0 : currentUser.classification) === 'contractor') {
          _this.transitionTo('innovators.index');
        } else if (!currentUser && isConnectionRequestFlow) {
          _this.transitionTo('login');
        } else if (!currentUser) {
          _this.transitionTo('welcome');
        } else if ((transition === null || transition === void 0 ? void 0 : (_transition$from = transition.from) === null || _transition$from === void 0 ? void 0 : _transition$from.name) === 'confirm.token') {// ignore for proper redirect to forum or setup communities
        } else if ((transition === null || transition === void 0 ? void 0 : (_transition$from2 = transition.from) === null || _transition$from2 === void 0 ? void 0 : _transition$from2.name) === 'register') {// ignore for proper redirect to forum
        } else {
          _this.transitionTo('home');
        }
      });
    }
  });

  _exports.default = _default;
});