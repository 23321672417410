define("darcy/models/location", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    forums: (0, _relationships.hasMany)(),
    fullName: Ember.computed('city', 'state', function () {
      var city = this.get('city');
      var state = this.get('state');

      if (!state || state === "") {
        return city;
      } else {
        return "".concat(city, ", ").concat(state);
      }
    })
  });

  _exports.default = _default;
});