define("darcy/_pods/components/events-list-welcome/event/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eaJqt6Wi",
    "block": "{\"symbols\":[\"@event\",\"@onClickEvent\"],\"statements\":[[5,\"link-to\",[[13,\"class\",\"flex flex-1 my-2\"],[13,\"onclick\",[24,2,[]]]],[[\"@route\",\"@model\"],[\"events.view\",[24,1,[\"slug\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-none w-16 text-center border-r border-gray-700\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"block font-semibold text-base text-gray-800 uppercase\"],[9],[1,[29,\"moment-format\",[[24,1,[\"startDate\"]],\"ddd\"],null],false],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"block font-bold text-3xl leading-7 text-gray-800 uppercase\"],[9],[1,[29,\"moment-format\",[[24,1,[\"startDate\"]],\"DD\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"flex line-clamp-2 font-semibold text-gray-700 self-center hover:underline ml-3\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/events-list-welcome/event/template.hbs"
    }
  });

  _exports.default = _default;
});