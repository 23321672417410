define("darcy/_pods/authenticate/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model(_ref) {
      var token = _ref.token;
      return this.store.query('auth-session', {
        token: token
      }).then(function (authSessions) {
        return authSessions.get('firstObject');
      });
    },
    afterModel: function afterModel(model) {
      // HACK: doing this directly avoids some erratic routing issues that
      // occur when calling `session.authenticate()`
      localStorage['ember_simple_auth-session'] = "{\"authenticated\":{\"authenticator\":\"authenticator:credentials\",\"token\":\"".concat(model.token, "\",\"sessionId\":\"").concat(model.get('id'), "\",\"userId\":\"").concat(model.get('user.id'), "\"}}");
      window.location.href = '/';
    }
  });

  _exports.default = _default;
});