define("darcy/_pods/-innovators/view/admin/photos/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      var companyProfile = this.paramsFor('-innovators.view');
      this.transitionTo('innovators.view.admin.photos.edit', companyProfile.slug, model.id);
    }
  });

  _exports.default = _default;
});