define("darcy/validators/video-url", ["exports", "js-video-url-parser"], function (_exports, _jsVideoUrlParser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateVideoUrl;
  // import buildMessage from 'ember-changeset-validations/utils/validation-errors';
  // import validationError from 'ember-validators/utils/validation-error';
  var SUPPORTED_PROVIDERS = ['vimeo', 'youtube'];

  function validateVideoUrl() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (key, newValue, oldValue, changes, content) {
      if (!newValue) {
        return true;
      }

      var videoInfo = _jsVideoUrlParser.default.parse(newValue);

      if (!videoInfo) {
        return 'Unable to process Video URL, format may not be supported';
      }

      if (!SUPPORTED_PROVIDERS.includes(videoInfo.provider)) {
        return "Videos from ".concat(videoInfo.provider, " are not supported");
      }

      return true;
    };
  }
});