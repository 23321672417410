define("darcy/_pods/components/content/thumbnail/video/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JzXqxYgM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"relative\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"absolute absolute-center w-12 sm:w-72\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/play-chapters-gray.svg\"],null]]]],[11,\"class\",\"my-10 sm:my-8 lg:mx-24 sm:mx-16\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[24,0,[\"videoThumbnail\"]]],[11,\"alt\",\"Video Content\"],[11,\"class\",\"w-full\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/content/thumbnail/video/template.hbs"
    }
  });

  _exports.default = _default;
});