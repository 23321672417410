define("darcy/validations/invitee", ["exports", "ember-changeset-validations/validators", "darcy/validators/unique-email", "darcy/validators/time"], function (_exports, _validators, _uniqueEmail, _time) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)(true)],
    lastName: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validateFormat)({
      type: 'email'
    })]
  };
  _exports.default = _default;
});