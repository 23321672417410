define("darcy/models/analytics-event", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    eventType: (0, _attr.default)('string'),
    originUrl: (0, _attr.default)('string'),
    destinationUrl: (0, _attr.default)('string'),
    timestamp: (0, _attr.default)('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    userFullName: (0, _attr.default)('string'),
    userId: (0, _attr.default)('number'),
    userClassification: (0, _attr.default)('string'),
    url: (0, _attr.default)('string'),
    route: (0, _attr.default)('string'),
    referrerUrl: (0, _attr.default)('string'),
    referrerRoute: (0, _attr.default)('string'),
    referrerLinkId: (0, _attr.default)('string'),
    // only for sharing events
    shareUrl: (0, _attr.default)('string'),
    meta: (0, _attr.default)(),
    // optional depending on `eventType``
    company: (0, _relationships.belongsTo)(),
    companyProfile: (0, _relationships.belongsTo)(),
    content: (0, _relationships.belongsTo)(),
    forum: (0, _relationships.belongsTo)(),
    comment: (0, _relationships.belongsTo)(),
    chapter: (0, _relationships.belongsTo)()
  });

  _exports.default = _default;
});