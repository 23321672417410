define("darcy/_pods/components/stateful-tooltip/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOOLTIP_STATES = {
    CLOSE: 'close',
    OPEN: 'open'
  };

  var _default = Ember.Component.extend({
    tagName: '',
    tooltipStateManager: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    media: Ember.inject.service(),
    name: null,
    visitLink: null,
    dependentOn: null,
    delay: 3000,
    isShown: Ember.computed('state', function () {
      return this.state === 'open';
    }),
    tooltipsStateChanged: Ember.observer('tooltipStateManager.states.@each.state', function () {
      var _this = this;

      Ember.run.later(function () {
        var tooltip = _this.tooltipStateManager.states.find(function (el) {
          return el.name === _this.name;
        });

        if (!tooltip) {
          if (_this.dependentOn) {
            var tooltipClosed = Ember.isPresent(_this.tooltipStateManager.states.find(function (state) {
              return state.name === _this.dependentOn && state.state === 'close';
            }));
            if (!tooltipClosed) return;
          }

          _this.getState();
        }
      }, this.delay);
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      if (this.dependentOn) {
        var tooltipClosed = Ember.isPresent(this.tooltipStateManager.states.find(function (state) {
          return state.name === _this2.dependentOn && state.state === 'close';
        }));
        if (!tooltipClosed) return;
      }

      this.getState();
    },
    getState: function getState() {
      var _this3 = this;

      if (this.currentUser) {
        this.tooltipStateManager.getState(this.name).then(function (state) {
          _this3.set('state', state);
        });
      }
    },
    actions: {
      openLink: function openLink(link) {
        window.open(link, "target=_blank");
      },
      toggleState: function toggleState() {
        this.set('state', this.state === TOOLTIP_STATES.OPEN ? TOOLTIP_STATES.CLOSE : TOOLTIP_STATES.OPEN); //if (this.state === TOOLTIP_STATES.CLOSE) this.analytics.captureEvent(`dismiss_tooltip`, {});

        this.tooltipStateManager.setState(this.name, this.state);
      }
    }
  });

  _exports.default = _default;
});