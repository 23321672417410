define("darcy/helpers/is-even", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEven = isEven;
  _exports.default = void 0;

  function isEven(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        num = _ref2[0];

    return num % 2 === 0;
  }

  var _default = Ember.Helper.helper(isEven);

  _exports.default = _default;
});