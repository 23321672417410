define("darcy/services/nylas-api", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "@babel/runtime/helpers/esm/defineProperty", "darcy/config/environment"], function (_exports, _objectSpread2, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import { readOnly, or } from '@ember/object/computed';
  // This is intended to be a lower-level service. Really only the
  // Nylas service should ever use this.
  // import Nylas from 'nylas';
  // The official node sdk seems to have some issues in an ember environment.
  // Not sure if the issue is with broccoli, ember-auto-import, something else,
  // or all of the above. But for now, we only need to work with a couple of simple
  // requests, so doing it manually isn't too hard.
  var _ENV$KEYS = _environment.default.KEYS,
      NYLAS_CLIENT_ID = _ENV$KEYS.NYLAS_CLIENT_ID,
      NYLAS_CLIENT_SECRET = _ENV$KEYS.NYLAS_CLIENT_SECRET;
  var NYLAS_HOST = 'https://api.nylas.com';
  var AUTH_TYPES = {
    BASIC_TOKEN: 'basicToken',
    BASIC_ENCODED_SECRET: 'basicEncodedSecret'
  };

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),

    get urls() {
      var base = NYLAS_HOST;
      var clientId = this.clientId;
      return {
        base: base,
        hostedAuth: {
          authorize: "".concat(base, "/oauth/authorize")
        },
        accounts: {
          current: "".concat(base, "/account"),
          cancel: "".concat(base, "/a/").concat(clientId, "/accounts/{id}/downgrade"),
          revokeTokens: "".concat(base, "/a/").concat(clientId, "/accounts/{id}/revoke-all")
        },
        scheduler: {
          pages: 'https://schedule.api.nylas.com/manage/pages'
        }
      };
    },

    get authTypes() {
      return (0, _defineProperty2.default)({}, this.urls.accounts.cancel, AUTH_TYPES.BASIC_ENCODED_SECRET);
    },

    get clientId() {
      return NYLAS_CLIENT_ID;
    },

    // Endpoint logic
    getEndpoint: function getEndpoint(endpoint, id) {
      var url = this.get("urls.".concat(endpoint)) || endpoint;

      if (id) {
        url = url.replace('/{id}', "/".concat(id));
      }

      return url;
    },
    // Auth logic
    getAuthType: function getAuthType(endpoint) {
      return this.get("authTypes.".concat(endpoint));
    },
    getAuthHeader: function getAuthHeader(endpoint, token) {
      var authType = this.getAuthType(endpoint);

      if (authType === AUTH_TYPES.BASIC_TOKEN) {
        return "Basic ".concat(token, ":");
      }

      if (authType === AUTH_TYPES.BASIC_ENCODED_SECRET) {
        var b64Secret = window.btoa("".concat(NYLAS_CLIENT_SECRET));
        return "Basic ".concat(b64Secret, ":");
      }

      return "Bearer ".concat(token);
    },
    // Request logic
    request: function request() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          endpoint = _ref2.endpoint,
          id = _ref2.id,
          token = _ref2.token,
          method = _ref2.method,
          data = _ref2.data,
          options = _ref2.options;

      var reqOptions = options ? (0, _objectSpread2.default)({}, options) : {};
      var endpointUrl = this.getEndpoint(endpoint, id); // Headers

      reqOptions.headers = reqOptions.headers || {};
      reqOptions.headers['Authorization'] = this.getAuthHeader(endpointUrl, token); // Method

      reqOptions.method = method || 'GET'; // Content Type

      reqOptions.contentType = reqOptions.contentType || 'application/json';

      if (data) {
        reqOptions.data = data;
      }

      return this.ajax.request(endpointUrl, reqOptions);
    },
    post: function post() {
      var requestArgs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return this.request((0, _objectSpread2.default)({}, requestArgs, {
        method: 'POST'
      }));
    }
  });

  _exports.default = _default;
});