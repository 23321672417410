define("darcy/_pods/getting-started/step-3/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VISIBLE_SERVICE_LINE_IDS = ['1', '2', '3', '9'];
  var HIDDEN_SERVICE_LINE_IDS = ['10', '11', '12'];

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    appTour: Ember.inject.service(),
    analytics: Ember.inject.service(),
    isShowingDisableAutoInvitesModal: false,
    currentServiceLine: null,
    serviceLines: [],
    visibleServiceLines: Ember.computed('serviceLines.[]', 'currentUser.communities.[]', 'currentUser.serviceLinesByActiveMembershipAgreements.[]', function () {
      var serviceLines = [];
      var visibleServiceLines = this.get('serviceLines').filter(function (sl) {
        return VISIBLE_SERVICE_LINE_IDS.includes(sl.id);
      });
      var hiddenServiceLines = this.get('serviceLines').filter(function (sl) {
        return HIDDEN_SERVICE_LINE_IDS.includes(sl.id);
      });
      var userCommunityNames = this.get('currentUser.communities').mapBy('name');
      var userServiceLineIds = this.get('currentUser.serviceLinesByActiveMembershipAgreements').mapBy('id');
      visibleServiceLines.forEach(function (sl) {
        serviceLines.push(sl);
      });
      hiddenServiceLines.forEach(function (sl) {
        if (userServiceLineIds.includes(sl.id) && userCommunityNames.includes(sl.name)) {
          serviceLines.push(sl);
        }
      });
      return serviceLines;
    }),
    save: function save() {
      var _this = this;

      var user = this.get('currentUser');
      user.set('serviceLineIds', user.get('serviceLines').map(function (serviceLine) {
        return parseInt(serviceLine.get('id'));
      }));
      user.save().then(function () {
        _this.notifications.success("Notification Settings Updated");
      }).catch(function () {
        _this.notifications.error('There was an error updating your account information, please try again.');
      }).finally(function () {
        if (_this.controller) {
          _this.controller.set('isLoading', false);
        }
      });
    },
    actions: {
      toggleProperty: function toggleProperty(propertyName) {
        this.toggleProperty(propertyName);
      },
      toggleWeeklyEmailNotification: function toggleWeeklyEmailNotification(notificationName) {
        this.toggleProperty(notificationName);

        if (this.get(notificationName)) {
          this.set('currentUser.notificationAllowWeeklyEmails', true);
        }
      },
      startIntroTour: function startIntroTour() {
        var user = this.get('currentUser');
        user.save();
        this.analytics.captureEvent('getting_started_notification_preferences');
        this.analytics.set('referrerLinkId', 'finish');
        this.transitionToRoute('home');
        this.set('appTour.isTakeATourModalOpen', true);
      },
      goToNextPage: function goToNextPage() {
        this.analytics.captureEvent('getting_started_notification_preferences');
        this.analytics.set('referrerLinkId', 'next');
        this.transitionToRoute('getting-started.step-4');
      },
      goBackAPage: function goBackAPage() {
        this.analytics.set('referrerLinkId', 'back');
        this.transitionToRoute('getting-started.step-2');
      },
      save: function save() {
        this.save();
      },
      toggleNotification: function toggleNotification(notification) {
        this.toggleProperty(notification);
        var user = this.get('currentUser');
        user.save();
      },
      toggleServiceLine: function toggleServiceLine(serviceLine) {
        if (this.get('currentUser.serviceLines').findBy('id', serviceLine.get('id'))) {
          this.get('currentUser.serviceLines').removeObject(serviceLine);
          this.set('currentUser.disableAutomatedEmails', false);
          this.save();
        } else {
          if (!this.get('isShowingDisableAutoInvitesModal')) {
            this.set('isShowingDisableAutoInvitesModal', true);
            this.set('currentServiceLine', serviceLine);
          } else {
            this.get('currentUser.serviceLines').pushObject(serviceLine);
            this.set('isShowingDisableAutoInvitesModal', false);
            this.set('currentServiceLine', null);
            this.save();
          }
        }
      }
    }
  });

  _exports.default = _default;
});