define("darcy/_pods/components/list-item/research/default/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    router: Ember.inject.service(),
    tagName: '',
    isMobile: _environment.default.isMobile,
    isAccessible: null,
    source: null,
    query: Ember.computed('source', function () {
      return Ember.isPresent(this.source) ? {
        sourceType: this.source.constructor.modelName,
        sourceId: this.source.id
      } : null;
    })
  });

  _exports.default = _default;
});