define("darcy/services/player", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    currentPlayer: null,
    setPlayer: function setPlayer(player) {
      var _this = this;

      this.set('currentPlayer', player);
      this.currentPlayer.on('timeupdate', function (time) {
        _this.trigger('timeupdate', parseInt(time.seconds));
      });
      this.currentPlayer.on('seeked', function (time) {
        _this.trigger('seeked', parseInt(time.seconds));
      });
    },
    removePlayer: function removePlayer() {
      this.set('currentPlayer', null);
      this.trigger('player-removed');
    }
  });

  _exports.default = _default;
});