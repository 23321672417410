define("darcy/_pods/components/forum-content/viewer/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "biWU7wfr",
    "block": "{\"symbols\":[\"@toggleView\"],\"statements\":[[7,\"section\"],[11,\"class\",\"absolute sm:static inset-0 content-view z-20 sm:border-b\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white h-full overflow-hidden\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex border-b text-center p-4 relative\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex-initial -my-4 -ml-4 border-r\"],[9],[0,\"\\n        \"],[7,\"button\"],[12,\"onclick\",[24,1,[]]],[11,\"class\",\"h-full focus:outline-none pr-5 pl-4\"],[9],[0,\"\\n          \"],[7,\"i\"],[11,\"class\",\"fa fa-chevron-left\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"flex-grow truncate self-center px-4 text-base sm:text-lg md:text-xl text-gray-800\"],[9],[0,\"\\n        \"],[1,[25,[\"content\",\"name\"]],false],[0,\" (\"],[1,[29,\"moment-format\",[[25,[\"content\",\"publishedAt\"]],\"MMM D, YYYY\"],null],false],[0,\")\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"content\",\"forum\",\"isAvailable\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"pspdfkit\",null,[[\"documentId\",\"jwt\"],[[25,[\"content\",\"pspdfkitId\"]],[25,[\"content\",\"jwt\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"pspdfkit-preview\",null,[[\"documentId\",\"jwt\"],[[25,[\"content\",\"pspdfkitId\"]],[25,[\"content\",\"jwt\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/forum-content/viewer/template.hbs"
    }
  });

  _exports.default = _default;
});