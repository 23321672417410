define("darcy/serializers/user", ["exports", "darcy/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize: function serialize()
    /* snapshot, options */
    {
      var json = this._super.apply(this, arguments);

      if (Ember.isBlank(json.data.attributes.password)) {
        delete json.data.attributes.password;
      }

      delete json.data.attributes['password_confirmation']; // do not strip token; it is needed for invite registration flow

      return json;
    },
    normalize: function normalize(model, data) {
      var json = this._super.apply(this, arguments);

      json.data.attributes.aBTestingGroup = data.attributes.a_b_testing_group;
      return json;
    }
  });

  _exports.default = _default;
});