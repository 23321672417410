define("darcy/_pods/innovators/view/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ndYaFsi4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"w-full flex flex-col flex-1 justify-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white md:shadow-md rounded-r-lg rounded-bl-lg p-6 sm:p-8 mb-4\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"leading-normal text-4xl font-light mb-12\"],[9],[1,[25,[\"model\",\"name\"]],false],[0,\" Innovator Admin\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"scrollable sm:-mx-3\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"flex border-b px-4 md:px-8\"],[9],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"-mb-px mr-1\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"activeClass\",\"class\",\"route\",\"model\"],[\"font-bold text-navy border-gray-300 bg-white\",\"border-l border-t border-r border-transparent rounded-t inline-block py-2 px-4 font-semibold text-gray-600 hover:text-blue-600\",\"innovators.view.admin.index\",[25,[\"model\",\"slug\"]]]],{\"statements\":[[0,\"            Overview\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"-mb-px mr-1\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"activeClass\",\"class\",\"route\",\"model\"],[\"font-bold text-navy border-gray-300 bg-white\",\"border-l border-t border-r border-transparent rounded-t inline-block py-2 px-4 font-semibold text-gray-600 hover:text-blue-600\",\"innovators.view.admin.content\",[25,[\"model\",\"slug\"]]]],{\"statements\":[[0,\"            Content\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"w-full my-8\"],[9],[0,\"\\n      \"],[1,[23,\"outlet\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/innovators/view/admin/template.hbs"
    }
  });

  _exports.default = _default;
});