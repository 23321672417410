define("darcy/tours/events", ["exports", "darcy/tours/scrollHandler"], function (_exports, _scrollHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [{
    title: 'Find & Attend Upcoming Events or View Historical Event Content',
    image: '/images/event_tour_start.png',
    caption: 'Attending leading industry events with peers and experts helps our clients find the best solutions to their technology challenges'
  }, {
    selector: '#event-index-filter',
    position: 'right-start',
    highlightClass: 'highlight-border',
    routeName: 'events.index',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'On the Events page, enter keywords or apply filters for event date, topic tags or event status to generate a list of events you are interested in',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    routeName: 'events.view.intro',
    models: 'darcy-connect-platform-demo',
    title: '',
    image: '/images/event_tour_2.gif',
    caption: "Your event status will show in the top-right corner of an event. You'll be able to register and join events here"
  }, {
    routeName: 'events.view.intro',
    selector: '#event-view-tabs',
    position: 'right',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    image: '/images/event_tour_3.gif',
    caption: 'On every event, you can view the summary, content and participants list to learn more about the topic and connect with others who attended',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#research-view-video-chapters',
    position: 'left',
    highlightClass: 'highlight-border',
    routeName: 'research.view',
    models: 'darcy-connect-platform-demo-recording',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'If you missed the event, no worries! We record all of our events so you can watch them in your own time. You can even skip through to the sections you care most about by following the chapter breaks',
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }, {
    selector: '#research-view-video-language',
    position: 'top',
    highlightClass: 'highlight-border',
    scrollTo: true,
    scrollToHandler: _scrollHandler.scrollHandler,
    title: '',
    caption: 'And choose your default language to be English or Spanish!',
    cancelIcon: false,
    popperOptions: {
      modifiers: [{
        name: 'offset',
        options: {
          offset: [0, 12]
        }
      }]
    }
  }];
  _exports.default = _default;
});