define("darcy/_pods/components/follow-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Fly1NvyY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[25,[\"isFollowingCompany\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"isFollowingCompany\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex justify-center w-full text-white text-xs font-semibold transition-colors ease-in-out duration-300 text-center border-2 border-highlight_green bg-highlight_green hover:bg-gray-500 hover:bg-highlight_light_green rounded-full cursor-pointer px-2 py-1\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Following\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex justify-center w-full text-white text-xs font-semibold transition-colors ease-in-out duration-300 text-center border-2 border-gray-400 bg-gray-400 hover:bg-gray-500 hover:bg-gray-500 rounded-full cursor-pointer px-2 py-1\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Follow\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/follow-button/template.hbs"
    }
  });

  _exports.default = _default;
});