define("darcy/_pods/components/table/column/checkbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    filteredRows: Ember.computed('table.rows.@each.id', function () {
      return this.table.rows.filterBy('id').length;
    })
  });

  _exports.default = _default;
});