define("darcy/_pods/components/svg-lock-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QLhbfZok",
    "block": "{\"symbols\":[\"@fill\"],\"statements\":[[7,\"svg\"],[11,\"version\",\"1.1\"],[11,\"id\",\"Layer_1\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[11,\"viewBox\",\"0 0 56.3 68.1\"],[11,\"enable-background\",\"new 0 0 56.3 68.1\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[0,\"\\n\"],[7,\"path\"],[12,\"fill\",[24,1,[]]],[11,\"d\",\"M48.4,27.6h-1.5v-6.9c0-10.3-8.3-18.7-18.6-18.7C18,1.9,9.6,10.3,9.6,20.7v6.9H8.1c-3.1,0-5.7,2.6-5.7,5.7\\n  v27.1c0,3.2,2.5,5.7,5.7,5.7h40.3c3.1,0,5.7-2.6,5.7-5.7V33.3C54,30.1,51.5,27.6,48.4,27.6z M30.6,47.2v3.5c0,0.5-0.4,1-1,1h-2.8\\n  c-0.5,0-0.9-0.4-0.9-1v-3.5c-1.3-0.8-2.2-2.2-2.2-3.9c0-2.5,2-4.6,4.5-4.6c2.5,0,4.5,2,4.5,4.6C32.8,45,31.9,46.4,30.6,47.2z\\n   M38.7,27.6H17.8v-6.9c0-5.8,4.7-10.5,10.4-10.5c5.8,0,10.4,4.7,10.4,10.5V27.6z\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-lock-icon/template.hbs"
    }
  });

  _exports.default = _default;
});