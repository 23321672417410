define("darcy/_pods/rsvp/confirm-attendance/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('rsvp'),
          forum = _this$modelFor.forum;

      if (!forum.isVirtual) {
        this.transitionTo('forum.index');
      }
    },
    model: function model() {
      var _this$modelFor2 = this.modelFor('rsvp'),
          forum = _this$modelFor2.forum;

      return this.store.query('invite', {
        forum_id: forum.id
      }, {
        reload: true
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('invite', model.get('firstObject'));
    }
  });

  _exports.default = _default;
});