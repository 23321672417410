define("darcy/_pods/components/video/youtube/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3XRtMuOI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"video-embed-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[24,0,[\"src\"]]],null,{\"statements\":[[0,\"    \"],[7,\"iframe\"],[11,\"title\",\"YouTube\"],[11,\"class\",\"video-embed\"],[11,\"width\",\"100%\"],[11,\"height\",\"100%\"],[12,\"src\",[24,0,[\"src\"]]],[11,\"frameborder\",\"0\"],[11,\"allow\",\"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture\"],[11,\"allowfullscreen\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/video/youtube/template.hbs"
    }
  });

  _exports.default = _default;
});