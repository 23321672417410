define("darcy/_pods/components/svg-filter-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q8HLDkqt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"fill\",[30,[[23,\"fill\"]]]],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"viewBox\",\"0 0 64 64\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[9],[7,\"title\"],[9],[0,\"filter, settings, configuration\"],[10],[7,\"g\"],[9],[7,\"path\"],[11,\"d\",\"M54,31H50.34a5.43,5.43,0,0,0-10.68,0H10a1,1,0,0,0,0,2H39.66a5.43,5.43,0,0,0,10.68,0H54a1,1,0,0,0,0-2Zm-9,4.44A3.44,3.44,0,1,1,48.44,32,3.44,3.44,0,0,1,45,35.44Zm9,10H37.34a5.43,5.43,0,0,0-10.68,0H10a1,1,0,0,0,0,2H26.66a5.43,5.43,0,0,0,10.68,0H54a1,1,0,0,0,0-2ZM32,49.89a3.44,3.44,0,1,1,3.44-3.44A3.44,3.44,0,0,1,32,49.89ZM10,18.55h3.66a5.43,5.43,0,0,0,10.68,0H54a1,1,0,0,0,0-2H24.34a5.43,5.43,0,0,0-10.68,0H10a1,1,0,0,0,0,2Zm9-4.44a3.44,3.44,0,1,1-3.44,3.44A3.44,3.44,0,0,1,19,14.11Z\"],[9],[10],[10],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-filter-icon/template.hbs"
    }
  });

  _exports.default = _default;
});