define("darcy/_pods/components/footer/mobile/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tokVmKRm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[11,\"class\",\"bg-darkgray py-3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"container mx-auto px-8 sm:text-left\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-xs text-gray-500 leading-normal text-center\"],[9],[0,\"© Copyright \"],[1,[29,\"moment-format\",[[29,\"now\",null,null],\"YYYY\"],null],false],[0,\" Darcy Partners, LLC | All Rights Reserved | \"],[7,\"a\"],[11,\"target\",\"_blank\"],[11,\"href\",\"http://darcypartners.com/terms\"],[11,\"class\",\"underline\"],[9],[0,\"Terms of Use\"],[10],[0,\" | \"],[7,\"a\"],[11,\"class\",\"underline\"],[11,\"target\",\"_blank\"],[11,\"href\",\"http://darcypartners.com/privacy\"],[9],[0,\"Privacy Policy\"],[10],[0,\" | \"],[7,\"a\"],[11,\"class\",\"underline\"],[11,\"target\",\"_blank\"],[11,\"href\",\"http://darcypartners.com/support\"],[9],[0,\"Support\"],[10],[0,\" | \"],[7,\"a\"],[11,\"class\",\"underline\"],[11,\"target\",\"_blank\"],[11,\"href\",\"http://darcypartners.com/contact\"],[9],[0,\"Contact Darcy\"],[10],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/footer/mobile/default/template.hbs"
    }
  });

  _exports.default = _default;
});