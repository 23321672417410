define("darcy/_pods/components/table/cell/text-with-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Uk8LKj6l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-grow truncate\"],[9],[0,\"\\n  \"],[1,[29,\"or\",[[25,[\"value\"]],\"-\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"url\"]],[25,[\"currentUser\",\"isDarcyUser\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[23,\"url\"]],[11,\"target\",\"_blank\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-external-link float-right text-blue-700\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/text-with-link/template.hbs"
    }
  });

  _exports.default = _default;
});