define("darcy/_pods/components/example-questions/question/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVwjRLqL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-xs md:text-lg mb-2 text-gray-900 flex flex-no-wrap\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-none\"],[9],[0,\"\\n    \"],[1,[29,\"svg-jar\",[[25,[\"icon\"]]],[[\"class\"],[\"ml-2 mr-5 my-2\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex-grow\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"leading-9\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"font-bold\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n      \"],[7,\"p\"],[9],[1,[23,\"text\"],false],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"target\",\"_top\"],[11,\"class\",\"font-semibold text-highlight_light_green cursor-pointer mb-8\"],[9],[0,\"Ask a similar question >\"],[3,\"action\",[[24,0,[]],\"openTawk\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/example-questions/question/template.hbs"
    }
  });

  _exports.default = _default;
});