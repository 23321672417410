define("darcy/_pods/components/views-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r0Xr9eMZ",
    "block": "{\"symbols\":[\"@count\"],\"statements\":[[7,\"span\"],[11,\"class\",\"text-xs border-b border-black\"],[9],[0,\"\\n  \"],[1,[24,1,[]],false],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"ml-1\"],[9],[0,\"VIEWS\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/views-count/template.hbs"
    }
  });

  _exports.default = _default;
});