define("darcy/_pods/getting-started/step-1/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXCLUDED_TOPIC_TAG_IDS = [1, 9, 11, 20, 26, 27, 28];

  var _default = Ember.Controller.extend({
    analytics: Ember.inject.service(),
    selectedTopicTag: null,
    expertiseOptions: Ember.computed('topicTags', function () {
      return this.topicTags.filter(function (topicTag) {
        return EXCLUDED_TOPIC_TAG_IDS.includes(Number(topicTag.id)) === false;
      }).map(function (topicTag) {
        return {
          label: topicTag.name,
          topicTag: topicTag
        };
      }).sortBy('label').concat({
        label: 'Other'
      });
    }),
    communtiesByGroup: Ember.computed('communities', function () {
      var result = [];
      var orderedGroupIds = ['1', '2', '9', '3'];
      this.get('communities').forEach(function (community) {
        var hasGroup = result.findBy('id', community.get('communityGroup.id'));
        if (!hasGroup && !Ember.isEmpty(community.get('communityGroup.name'))) result.pushObject(Ember.Object.create({
          id: community.get('communityGroup.id'),
          name: community.get('communityGroup.name'),
          communities: []
        }));
        !community.get('isHidden') && result.findBy('id', community.get('communityGroup.id')).get('communities').pushObject(community);
      });
      return orderedGroupIds.map(function (id) {
        return result.findBy('id', id);
      });
    }),
    actions: {
      selectExpertise: function selectExpertise(expertiseOption) {
        this.set('selectedTopicTag', expertiseOption.topicTag);
      },
      onInterestToggle: function onInterestToggle(interest) {
        this.set('userCommunities', (0, _lodash.xor)(this.userCommunities.toArray(), [interest]));
      },
      goToNextPage: function goToNextPage() {
        var _this$selectedTopicTa;

        var user = this.get('account');
        user.set('communities', this.userCommunities);
        user.set('topicTag', this.selectedTopicTag);
        user.save();
        this.analytics.captureEvent('getting_started_expertise_select', {
          meta: {
            'topic_tag_id': (_this$selectedTopicTa = this.selectedTopicTag) === null || _this$selectedTopicTa === void 0 ? void 0 : _this$selectedTopicTa.id
          }
        });
        this.analytics.set('referrerLinkId', 'next');
        this.transitionToRoute('getting-started.step-2');
      },
      goBackAPage: function goBackAPage() {
        this.analytics.set('referrerLinkId', 'back');
        this.transitionToRoute('getting-started.welcome');
      }
    }
  });

  _exports.default = _default;
});