define("darcy/_pods/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      action: 'a',
      // null, 'accept', or 'decline'
      invite_id: 'i'
    }]
  });

  _exports.default = _default;
});