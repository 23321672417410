define("darcy/services/meta-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getHeadTags: function getHeadTags(model) {
      var _model$get;

      var headTags = [{
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: model.get('canonicalUrl')
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'description',
          content: model.get('metaDescription') || model.get('description')
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.get('metaTitle') || model.get('name')
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: model.get('metaDescription') || model.get('description')
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'image',
          property: 'og:image',
          content: model.get('photoUrl')
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'author',
          content: model.get('creators.firstObject.name')
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'publish-date',
          content: (_model$get = model.get('publishedAt')) === null || _model$get === void 0 ? void 0 : _model$get.toISOString()
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: window.location.href
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:card',
          content: 'summary_large_image'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:site',
          content: '@darcypartners'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:title',
          content: model.get('metaTitle') || model.get('name')
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:description',
          content: model.get('metaDescription') || model.get('description')
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'twitter:image',
          content: model.get('photoUrl')
        }
      }, model.get('internalKeywords') ? {
        type: 'meta',
        attrs: {
          property: 'keywords',
          content: model.get('internalKeywords')
        }
      } : null].filter(function (val) {
        return val;
      });
      return headTags;
    }
  });

  _exports.default = _default;
});