define("darcy/validations/direct-message", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    body: [(0, _validators.validatePresence)(true)],
    subject: [(0, _validators.validatePresence)({
      presence: true,
      message: 'Message required'
    })]
  };
  _exports.default = _default;
});