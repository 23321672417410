define("darcy/_pods/research/route", ["exports", "darcy/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    deactivate: function deactivate() {
      var controller = this.controllerFor('research.index');
      controller.set('query', null);
    }
  });

  _exports.default = _default;
});