define("darcy/models/concierge-request", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    shortDescription: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    isConfidential: (0, _attr.default)('boolean'),
    topicTags: (0, _attr.default)('string'),
    date: (0, _attr.default)('date'),
    company: (0, _relationships.belongsTo)(),
    user: (0, _relationships.belongsTo)(),
    contents: (0, _relationships.hasMany)()
  });

  _exports.default = _default;
});