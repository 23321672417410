define("darcy/_pods/confirm/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lmWUCyGL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"my-8 mx-auto w-full max-w-md flex flex-col flex-1 justify-center\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"leading-none font-bold text-2xl lg:text-3xl mx-4 mb-4 px-1\"],[9],[0,\"Welcome\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"p-4\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"p-4 lg:p-8 max-w-xl w-full h-full lg:h-auto bg-white lg:pt-8\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"leading-normal\"],[9],[0,\"Thank you for creating an account. Please confirm your email before using the platform. \"],[7,\"strong\"],[9],[0,\"Check your email for a link to verify your email address.\"],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"p-4 flex flex-col items-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"leading-normal\"],[9],[0,\"Didn't receive the email?\"],[10],[0,\"\\n\"],[4,\"unless\",[[25,[\"email\"]]],null,{\"statements\":[[0,\"      \"],[7,\"input\"],[11,\"id\",\"email\"],[11,\"class\",\"appearance-none border border-gray-600 rounded w-full py-4 lg:py-3 px-3 text-gray-700 mb-2 text-lg leading-tight focus:outline-none focus:shadow-outline mt-3\"],[11,\"placeholder\",\"Email Address\"],[12,\"value\",[23,\"resendEmail\"]],[12,\"oninput\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"resendEmail\"]]],null]],[[\"value\"],[\"target.value\"]]]],[11,\"type\",\"email\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"button\"],[11,\"class\",\"mt-4 bg-highlight_green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline\"],[11,\"type\",\"button\"],[9],[0,\"Resend Email\"],[3,\"action\",[[24,0,[]],[29,\"route-action\",[\"resend\",[25,[\"emailForResend\"]]],null]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/confirm/index/template.hbs"
    }
  });

  _exports.default = _default;
});