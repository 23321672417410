define("darcy/models/forum", ["exports", "@babel/runtime/helpers/esm/defineProperty", "ember-data/model", "ember-data/attr", "moment", "ember-data/relationships", "darcy/config/environment"], function (_exports, _defineProperty2, _model, _attr, _moment, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Model$extend;

  var APP = _environment.default.APP;

  var _default = _model.default.extend((_Model$extend = {
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    icon: (0, _attr.default)('string'),
    details: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    isVirtual: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    address: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    link: (0, _attr.default)('string'),
    timezone: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    hasHappyHour: (0, _attr.default)('boolean'),
    showAttendees: (0, _attr.default)('boolean'),
    showOnlyCompany: (0, _attr.default)('boolean'),
    canViewContents: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isPublishedToCalendar: (0, _attr.default)('boolean'),
    eventType: (0, _attr.default)('string'),
    canonicalUrl: (0, _attr.default)('string'),
    adminUrl: (0, _attr.default)('string'),
    slug: (0, _attr.default)('string'),
    internalKeywords: (0, _attr.default)('string'),
    metaTitle: (0, _attr.default)('string'),
    metaDescription: (0, _attr.default)('string'),
    isVideoIncluded: (0, _attr.default)(''),
    isSurveyIncluded: (0, _attr.default)(''),
    isCallIncluded: (0, _attr.default)('boolean'),
    introVideoUrl: (0, _attr.default)('string'),
    introVideoTranscript: (0, _attr.default)('string'),
    introVideoMarkdownBefore: (0, _attr.default)('string'),
    introVideoMarkdownAfter: (0, _attr.default)('string'),
    showFlyer: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    flyerPspdfkitId: (0, _attr.default)('string'),
    flyerPspdfkitJwt: (0, _attr.default)('string'),
    showVirtualRecording: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    virtualRecordingUrl: (0, _attr.default)('string'),
    // can be null if user cannot access
    hasVirtualRecordingUrl: (0, _attr.default)('boolean'),
    happyHourName: (0, _attr.default)('string'),
    happyHourDescription: (0, _attr.default)('string'),
    happyHourStartsAt: (0, _attr.default)('date'),
    happyHourEndsAt: (0, _attr.default)('date'),
    happyHourAddress: (0, _attr.default)('string'),
    isAvailable: (0, _attr.default)('boolean'),
    isPreviewOnly: (0, _attr.default)('boolean'),
    isPrivate: (0, _attr.default)('boolean'),
    currentUserAttendeeStatus: (0, _attr.default)('string'),
    hasFreeTrialServiceLines: (0, _attr.default)('boolean'),
    attendees: (0, _relationships.hasMany)('user'),
    topicTags: (0, _relationships.hasMany)('topic-tag'),
    survey: (0, _relationships.belongsTo)('survey'),
    call: (0, _relationships.belongsTo)('call'),
    location: (0, _relationships.belongsTo)(),
    communities: (0, _relationships.hasMany)(),
    presenters: (0, _relationships.hasMany)('company'),
    isLikedByCurrentUser: (0, _attr.default)('boolean'),
    isSharedByCurrentUser: (0, _attr.default)('boolean'),
    isRegisterable: (0, _attr.default)('boolean'),
    likes: (0, _relationships.hasMany)('like'),
    isBookmarkedByCurrentUser: (0, _attr.default)('boolean'),
    bookmarks: (0, _relationships.hasMany)('bookmark'),
    speakers: (0, _relationships.hasMany)(),
    isCommenteddByCurrentUser: (0, _attr.default)('boolean'),
    comments: (0, _relationships.hasMany)('comment'),
    reactionsCount: (0, _attr.default)('number'),
    likesCount: (0, _attr.default)('number'),
    viewsCount: (0, _attr.default)('number'),
    commentsCount: (0, _attr.default)('number'),
    sharesCount: (0, _attr.default)('number'),
    firstPresentationPdfContentSlug: (0, _attr.default)('string'),
    firstPresentationVideoContentSlug: (0, _attr.default)('string'),
    serviceLines: (0, _relationships.hasMany)(),
    isJoinable: Ember.computed('isToday', 'isVirtual', function () {
      return this.isToday && this.isVirtual;
    }),
    isNotAvailable: Ember.computed.not('isAvailable'),
    isExpired: Ember.computed('startDate', function () {
      return this.startDate < (0, _moment.default)().startOf('day').toDate();
    }),
    isUpcoming: Ember.computed('startDate', function () {
      return this.startDate > (0, _moment.default)().endOf('day').toDate();
    }),
    isToday: Ember.computed('isUpcoming', 'isExpired', function () {
      return !this.get('isUpcoming') && !this.get('isExpired');
    }),
    formatedAddress: Ember.computed('address', function () {
      var address = this.get('address');
      return (address || '').split(',').join('<br/>');
    }),
    introVideo: Ember.computed('introVideoUrl', function () {
      var videoId = this.get('introVideoUrl');
      return "https://player.vimeo.com/video/" + videoId;
    }),
    virtualRecording: Ember.computed('virtualRecordingUrl', function () {
      var videoId = this.get('virtualRecordingUrl');
      return "https://player.vimeo.com/video/" + videoId;
    })
  }, (0, _defineProperty2.default)(_Model$extend, "city", Ember.computed.alias('location.city')), (0, _defineProperty2.default)(_Model$extend, "state", Ember.computed.alias('location.state')), (0, _defineProperty2.default)(_Model$extend, "startDateMoment", Ember.computed('startDate', function () {
    return (0, _moment.default)(this.get('startDate'));
  })), _Model$extend));

  _exports.default = _default;
});