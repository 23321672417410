define("darcy/_pods/components/advanced-filter/strategic-partner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    strategicPartner: null,
    companyOptions: [],
    selected: Ember.computed('strategicPartner', function () {
      if (Ember.isBlank(this.strategicPartner)) return;
      return this.store.findRecord('company', this.strategicPartner);
    }),
    actions: {
      queryOptions: function queryOptions(searchTerm) {
        var _this = this;

        this.store.query('company', {
          search: searchTerm,
          show_all: true,
          limit: 10,
          is_strategic_partner: true
        }).then(function (companies) {
          _this.set('companyOptions', companies);
        });
      },
      onInput: function onInput(text) {
        return text.length >= 3;
      },
      selectCompany: function selectCompany(company) {
        this.onStrategicPartnerChange(company === null || company === void 0 ? void 0 : company.id);

        if (company) {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'strategic_partner': company.name,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});