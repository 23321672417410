define("darcy/_pods/components/search-navbar/view-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VAGfB8/R",
    "block": "{\"symbols\":[\"option\",\"index\",\"@onChange\",\"@selectedView\",\"@viewOptions\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex bg-white text-lg transition-colors ease-in-out duration-300 text-center font-semibold sm:font-bold hover:bg-gray-500 rounded-full cursor-pointer mx-2\"],[9],[0,\"\\n\"],[4,\"each\",[[24,5,[]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,3,[]],[24,1,[]]],null]],[12,\"class\",[30,[[29,\"if\",[[29,\"eq\",[[24,4,[\"value\"]],[24,1,[\"value\"]]],null],\"bg-orange-400\"],null],\" text-lg transition-colors ease-in-out duration-300 text-center font-semibold sm:font-bold hover:outline-none rounded-full cursor-pointer focus:outline-none px-4 py-2 \",[29,\"if\",[[29,\"eq\",[[24,2,[]],0],null],\"-mr-2\"],null]]]],[9],[0,\"\\n      \"],[7,\"span\"],[9],[1,[24,1,[\"value\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-navbar/view-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});