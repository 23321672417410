define("darcy/_pods/forum/intro/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var params = this.paramsFor('forum');
      this.transitionTo('events.view.intro', params.forum_id, {
        queryParams: params
      });
    }
  });

  _exports.default = _default;
});