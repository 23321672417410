define("darcy/_pods/components/search-suggestions/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    actions: {
      setSearchQuery: function setSearchQuery(query) {
        this.setQuery(query);
        this.analytics.captureEvent('search_suggestion_select', {
          meta: {
            'search_query': this.query,
            'value': query
          }
        });
      }
    }
  });

  _exports.default = _default;
});