define("darcy/models/membership-agreement", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    membershipType: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    isDisabled: (0, _attr.default)('boolean'),
    isUsageTrial: (0, _attr.default)('boolean'),
    company: (0, _relationships.belongsTo)(),
    serviceLines: (0, _relationships.hasMany)()
  });

  _exports.default = _default;
});