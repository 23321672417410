define("darcy/app", ["exports", "ember-resolver", "ember-load-initializers", "darcy/config/environment", "@bugsnag/js"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, _js) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var appOptions = {
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _emberResolver.default
  };

  if (_environment.default.environment === 'production') {
    var bugsnagClient = (0, _js.default)({
      apiKey: _environment.default.KEYS.BUGSNAG,
      appVersion: _environment.default.APP.version
    });

    Ember.onerror = function (error) {
      console.error(error);
      bugsnagClient.notify(error);
    };
  }

  if (_environment.default.environment === 'development') {
    appOptions.LOG_TRANSITIONS = true;
    appOptions.LOG_TRANSITIONS_INTERNAL = true;
  }

  var App = Ember.Application.extend(appOptions);
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});