define("darcy/_pods/innovators/view/notes/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      noteType: {
        refreshModel: true
      },
      customerNames: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile;

      var analyticsEvent;

      if (params.id === 'new') {
        analyticsEvent = this.store.createRecord('analytics-event', {
          eventType: 'notes',
          url: window.location.href,
          companyProfile: companyProfile,
          company: companyProfile.company,
          meta: {
            model: 'companyProfile',
            id: companyProfile.id,
            note_title: '',
            note_body: '',
            note_type: params.noteType,
            impact_type: '',
            status_type: '',
            source_type: '',
            customers: Ember.isPresent(params.customerNames) ? params.customerNames.split(',') : []
          }
        });
      } else {
        analyticsEvent = this.store.findRecord('analytics-event', params.id);
      }

      return Ember.RSVP.hash({
        analyticsEvent: analyticsEvent,
        companyProfile: companyProfile
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });

  _exports.default = _default;
});