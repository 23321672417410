define("darcy/_pods/research/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    loading: Ember.inject.service(),
    metaTags: Ember.inject.service('meta-tags'),
    queryParams: {
      sourceType: {
        refreshModel: true
      },
      sourceId: {
        refreshModel: true
      }
    },
    model: function model(_ref) {
      var _this = this;

      var id = _ref.id,
          sourceType = _ref.sourceType,
          sourceId = _ref.sourceId,
          isBlog = _ref.isBlog;
      var source;
      var content = this.store.findRecord('content', id, {
        reload: true
      }).catch(function (error) {
        if (error.status === 401 || error.status === 404) {
          if (isBlog) {
            return _this.transitionTo('home.index', {
              queryParams: {
                showNoAccessModal: true
              }
            });
          } else {
            _this.notifications.error('Research Not Found');

            return _this.transitionTo('research');
          }
        } else {
          throw error;
        }
      });

      if (sourceType && sourceId) {
        source = this.store.findRecord(sourceType, sourceId);
      }

      return Ember.RSVP.hash({
        content: content,
        sourceType: sourceType,
        source: source
      });
    },
    redirect: function redirect(model) {
      if (Ember.isPresent(model.content.slug)) {
        this.transitionTo('research.view', model.content.slug);
      }
    },
    afterModel: function afterModel(model) {
      var relatedContentQuery = {
        related_to_content_id: model.content.id,
        sort: 'related',
        per_page: 5
      };

      if (this.get('currentUser.classification') === 'innovator') {
        relatedContentQuery.is_confidential_from_innovator = true;
      }

      model.relatedContent = this.store.query('content', relatedContentQuery);

      if (model.content) {
        var headTags = this.metaTags.getHeadTags(model.content);
        this.set('headTags', headTags);
      }

      if (!model.content || !model.content.isAccessible) {
        this.send('showNoAccessModal');
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model, transition) {
      var content = model.content,
          relatedContent = model.relatedContent,
          source = model.source,
          sourceType = model.sourceType;
      var from = transition.from;
      controller.setProperties({
        content: content,
        relatedContent: relatedContent,
        source: source,
        sourceType: sourceType,
        transitionFrom: from === null || from === void 0 ? void 0 : from.name
      });
      window.prerenderReady = true;
    },
    resetController: function resetController(controller) {
      controller.set('chapter', null);
    },
    actions: {
      didTransition: function didTransition() {
        this.set('loading.isActive', false);
      }
    }
  });

  _exports.default = _default;
});