define("darcy/_pods/notifications/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o3YZ3RDY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-100 md:px-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mx-auto max-w-6xl mt-8 mb-8\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-wrap md:flex-no-wrap justify-between\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"mb-8 ml-8 text-xl md:text-2xl font-bold\"],[9],[0,\"\\n          Notifications\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/notifications/template.hbs"
    }
  });

  _exports.default = _default;
});