define("darcy/_pods/innovators/view/notes/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile,
          notes = _this$modelFor.notes;

      if (Ember.isPresent(notes)) {
        var latestNote = notes.sortBy('timestamp').lastObject;
        return this.transitionTo('innovators.view.notes.edit', companyProfile.id, latestNote.id);
      }
    }
  });

  _exports.default = _default;
});