define("darcy/_pods/components/social-reactions/popover/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    session: Ember.computed.readOnly('currentUserService.session'),
    media: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    socialReactionsPopover: Ember.inject.service(),
    classNames: ['inline-flex'],
    model: undefined,
    targetId: null,
    popoverUsers: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.fetchUsersByReaction();
    },
    popoverIsShowing: Ember.computed('model', function () {
      return Ember.isPresent(this.model);
    }),
    fetchUsersByReaction: function fetchUsersByReaction(reaction) {
      var model = this.model; // Unwrap ObjectProxy

      if (Ember.isPresent(model.content)) {
        model = model.content;
      }

      this.set('tab', reaction);
      var popoverUsers = this.store.query('user', {
        reactable_id: model.id,
        reactable_type: model.constructor.modelName,
        reaction: reaction
      });
      this.set('popoverUsers', popoverUsers);
    },
    fetchUsersByView: function fetchUsersByView() {
      var model = this.model; // Unwrap ObjectProxy

      if (Ember.isPresent(model.content)) {
        model = model.content;
      }

      var popoverUsers = this.store.query('user', {
        viewable_id: this.model.get('id'),
        viewable_type: this.model.get('constructor.modelName')
      });
      this.set('popoverUsers', popoverUsers);
    },
    actions: {
      onClick: function onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      },
      closePopover: function closePopover() {
        this.socialReactionsPopover.clearPopover();
      },
      fetchUsersByReaction: function fetchUsersByReaction(reaction) {
        this.fetchUsersByReaction(reaction);
      },
      fetchUsersByView: function fetchUsersByView() {
        this.fetchUsersByView();
      },
      openUserPreviewPopover: function openUserPreviewPopover(user) {
        if (this.currentUser.id === user.id) return;
        this.userPreview.setModel(user);
      }
    }
  });

  _exports.default = _default;
});