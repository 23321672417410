define("darcy/_pods/components/table/cell/forum-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TtU4HjUW",
    "block": "{\"symbols\":[\"@row\"],\"statements\":[[4,\"if\",[[24,1,[\"forum\",\"name\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"forum.agenda\",[24,1,[\"forum\",\"slug\"]]]],{\"statements\":[[1,[24,1,[\"forum\",\"name\"]],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/forum-link/template.hbs"
    }
  });

  _exports.default = _default;
});