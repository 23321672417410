define("darcy/utils/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  var input;

  function createInput(text) {
    input = document.createElement('input');
    input.value = text;
    input.setAttribute('readonly', '');
    input.style.position = 'absolute';
    input.style.left = '-9999px';
    document.body.appendChild(input);
  }

  function selectText() {
    var isOS = navigator.userAgent.match(/ipad|iphone/i);
    var range, selection;

    if (isOS) {
      range = document.createRange();
      range.selectNodeContents(input);
      selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      input.setSelectionRange(0, 999999);
    } else {
      input.select();
    }
  }

  function copyToClipboard() {
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  function _default(text) {
    createInput(text);
    selectText();
    copyToClipboard();
  }
});