define("darcy/_pods/components/modal/no-access/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    trialMembershipAgreementReceived: false,
    isAccessibleWithFreeTrial: Ember.computed('router.currentRoute', function () {
      var currentRoute = this.router.currentRoute.name;

      if (currentRoute.includes('events.view')) {
        var forumRoute = Ember.getOwner(this).lookup('route:events.view');
        var forum = forumRoute.currentModel.forum;
        return forum.hasFreeTrialServiceLines && forum.isPrivate === false;
      } else {
        return true;
      }
    }),
    actions: {
      getFreeTrial: function getFreeTrial() {
        var _this = this;

        var userId = this.get('currentUser.id');
        var currentRoute = this.router.currentRoute.parent.name;
        this.analytics.captureEvent('get_free_trial_membership_access', {
          company: this.currentUser.get('company')
        });
        return this.ajax.post("".concat(API.host, "/membership_agreements/create_free_trial_membership_agreement"), {
          data: {
            user_id: userId
          }
        }).then(function () {
          _this.set('trialMembershipAgreementReceived', true);

          _this.set('currentUser.hasFreeTrialMembershipAgreement', true);

          _this.currentUser.reload();

          if (currentRoute === 'innovators') {
            // perform fetchProfilesTask from innovators controller to update isAccessible state of companyProfiles
            var innovatorsController = Ember.getOwner(_this).lookup('controller:innovators.index');
            innovatorsController.fetchProfilesTask.perform(true);
          } else if (currentRoute === 'events.view' || currentRoute === 'events.view.content') {
            // reload forum model to update forum.isPreviewOnly state
            var forumRoute = Ember.getOwner(_this).lookup('route:events.view');
            forumRoute.currentModel.forum.reload();
          } else if (currentRoute === 'research') {
            // Reload model to update isAccessible state
            var researchRoute = Ember.getOwner(_this).lookup('route:research.view');
            researchRoute.currentModel.content.reload();
          }
        }).catch(function (e) {
          console.error(e);

          _this.notifications.error('There was an error, please try again.');
        });
      }
    }
  });

  _exports.default = _default;
});