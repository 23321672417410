define("darcy/_pods/components/truncate-description/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: [],
    shouldTruncate: Ember.computed('text', function () {
      var text = this.text || '';
      return text.length > 175;
    }),
    truncatedText: Ember.computed('text', function () {
      return this.text.match(/^.{175}\w*/);
    })
  });

  _exports.default = _default;
});