define("darcy/_pods/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      showNoAccessModal: {
        replace: true
      }
    },
    model: function model() {
      return this.store.query('company-profile', {
        page: 1,
        per_page: 10,
        sort: 'trending_last_7_days'
      });
    },
    actions: {
      didTransition: function didTransition() {
        var controller = this.controller;

        if (controller.showNoAccessModal) {
          this.send('showNoAccessModal');
          controller.set('showNoAccessModal', null);
        }
      }
    }
  });

  _exports.default = _default;
});