define("darcy/_pods/rsvp/attendee-details/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwmmzQJa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[11,\"class\",\"leading-none text-xl font-light mt-6\"],[9],[0,\"Please confirm your arrival/departure time for the Forum day:\"],[10],[0,\"\\n\\n\"],[1,[29,\"logistics-form\",null,[[\"onSubmit\",\"onReset\",\"changeset\",\"isNew\",\"hasHappyHour\",\"happyHourStartsAt\"],[[29,\"route-action\",[\"save\",[25,[\"changeset\"]]],null],[29,\"route-action\",[\"reset\",[25,[\"changeset\"]]],null],[25,[\"changeset\"]],true,[25,[\"forum\",\"hasHappyHour\"]],[25,[\"forum\",\"happyHourStartsAt\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/rsvp/attendee-details/template.hbs"
    }
  });

  _exports.default = _default;
});