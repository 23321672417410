define("darcy/_pods/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NjIdP3PA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"my-8 lg:mt-20 mx-auto w-full h-full lg:h-auto max-w-sm flex flex-col flex-1 justify-center px-2\"],[9],[0,\"\\n  \"],[5,\"login-form\",[],[[\"@onSubmit\",\"@prefilledEmail\"],[[29,\"route-action\",[\"login\"],null],[24,0,[\"email\"]]]]],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"mb-20 mt-auto lg:mt-20 lg:mb-auto mx-auto w-full max-w-sm flex flex-col items-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-lg text-gray-700\"],[9],[0,\"Don't have an account?\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-lg text-highlight_green\"],[9],[0,\"\\n      \"],[7,\"button\"],[12,\"onclick\",[29,\"route-action\",[\"showRegistrationModal\",\"createAccount\"],null]],[11,\"type\",\"button\"],[9],[0,\"Create an Account\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/login/template.hbs"
    }
  });

  _exports.default = _default;
});