define("darcy/_pods/components/search-with-autocomplete/event-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SCUr2Exy",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pr-2px\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/calendar-gray.svg\"],null]]]],[11,\"class\",\"opacity-75 self-start\"],[11,\"alt\",\"icon\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-base leading-5\"],[9],[0,\"\\n    \"],[1,[24,1,[\"name\"]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-xs font-light leading-4\"],[9],[0,\"\\n    \"],[1,[29,\"moment-format\",[[24,1,[\"startDate\"]],\"MMM D, YYYY\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-with-autocomplete/event-result/template.hbs"
    }
  });

  _exports.default = _default;
});