define("darcy/adapters/company-profile", ["exports", "darcy/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    queryRecord: function queryRecord(store, type, query) {
      return this.findRecord(store, type, query.slug);
    }
  });

  _exports.default = _default;
});