define("darcy/_pods/rsvp/attendee-survey/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isTest = _environment.default.environment === 'test';

  function makeSurveyLink(forum, currentUser, uuid) {
    var surveyId = forum.get('survey.id');
    var firstName = currentUser.firstName,
        lastName = currentUser.lastName,
        email = currentUser.email;
    var baseUrl = forum.get('survey.url');
    var forumId = forum.get('id');
    var location = "".concat(baseUrl, "?id=").concat(uuid, "&forum_id=").concat(forumId, "&survey_id=").concat(surveyId, "&email=").concat(email, "&first_name=").concat(firstName, "&last_name=").concat(lastName);
    return location;
  }

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    model: function model() {
      var _this$modelFor = this.modelFor('rsvp'),
          attendeeSurvey = _this$modelFor.attendeeSurvey,
          forum = _this$modelFor.forum,
          invite = _this$modelFor.invite;

      var user = this.get('currentUserService.user');
      return isTest ? '' : makeSurveyLink(forum, user, attendeeSurvey ? attendeeSurvey.get('uuid') : invite.id);
    }
  });

  _exports.default = _default;
});