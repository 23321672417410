define("darcy/_pods/application/controller", ["exports", "darcy/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var isSocialBot = /LinkedInBot|Googlebot|TwitterBot/.test(navigator.userAgent);

  var _default = Ember.Controller.extend({
    isMobile: _environment.default.isMobile,
    fullscreen: Ember.inject.service(),
    analytics: Ember.inject.service(),
    router: Ember.inject.service(),
    notificationsService: Ember.inject.service('api-notifications'),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    session: Ember.computed.readOnly('currentUserService.session'),
    share: Ember.inject.service(),
    loading: Ember.inject.service(),
    appTour: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    socialReactionsPopover: Ember.inject.service(),
    connectionRequest: Ember.inject.service(),
    isShowingNoAccessModal: false,
    isNotificationsSidebarOpen: false,
    isShowingRegistrationModal: false,
    isTakeATourModalOpen: false,
    visibleTab: 'login',
    isTimerStopped: false,
    isSearching: false,
    isOverlayHidden: true,
    isSocialBot: isSocialBot,
    isUserPreviewModalOpen: Ember.computed('userPreview.isModalOpen', function () {
      return this.userPreview.isModalOpen;
    }),
    isShowingShareViaEmailModal: Ember.computed('share.isShareViaEmailModalOpen', function () {
      return this.share.isShareViaEmailModalOpen;
    }),
    isSocialReactionsPopoverOpen: Ember.computed('socialReactionsPopover.isPopoverOpen', function () {
      return this.socialReactionsPopover.isPopoverOpen;
    }),
    isDarkNavbar: Ember.computed('currentUser.aBTestingGroup', function () {
      return true;
    }),
    scopes: Ember.computed('isHomeRoute', 'isUsageRoute', 'isReportRoute', 'isEventsRoute', 'isInnovatorsRoute', function () {
      if (this.isHomeRoute) {
        return ['blog', 'forum', 'company-profile', 'framework'];
      } else if (this.isEventsRoute) {
        return ['forum'];
      } else if (this.isInnovatorsRoute) {
        return ['company-profile'];
      } else if (this.isUsageRoute) {
        return ['forum', 'company-profile', 'blog', 'framework'];
      } else if (this.isReportRoute) {
        return ['content'];
      } else {
        return ['blog'];
      }
    }),
    isHomeRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'home');
    }),
    isNetworkRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'network');
    }),
    isReportRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'research');
    }),
    isEventsRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'events');
    }),
    isInnovatorsRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'innovators');
    }),
    isLoginRoute: Ember.computed('router.currentRouteName', function () {
      return (0, _lodash.startsWith)(this.router.currentRouteName, 'login');
    }),
    isLandingPage: Ember.computed('router.currentRouteName', 'currentUser', function () {
      var landingPageRoutes = ['team', 'support.index', 'careers', 'welcome'];

      if (this.router.currentRouteName === 'loading') {
        return !this.currentUser;
      } else {
        return landingPageRoutes.includes(this.router.currentRouteName);
      }
    }),
    actions: {
      toggleMenu: function toggleMenu() {
        // 'isShowingMenu' not used anywhere
        this.toggleProperty('isShowingMenu');
      },
      toggleDropdown: function toggleDropdown(isClickOutside) {
        if (isClickOutside === true) {
          this.set('isShowingDropdown', false);
        } else {
          this.toggleProperty('isShowingDropdown');
        }
      },
      toggleNotificationsSidebar: function toggleNotificationsSidebar(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        this.toggleProperty('isNotificationsSidebarOpen');

        if (this.isNotificationsSidebarOpen) {
          this.analytics.captureEvent('notifications_sidebar_open', {
            url: window.location.href
          });
        }
      },
      setSearchState: function setSearchState(state) {
        var _this = this;

        if (state) {
          this.set('isOverlayHidden', false);
          Ember.run.later(function () {
            _this.set('isSearching', true);
          }, 1);
        } else {
          this.set('isSearching', false);
          Ember.run.later(function () {
            _this.set('isOverlayHidden', true);
          }, 400);
        }
      },
      dismissRegistrationModal: function dismissRegistrationModal() {
        this.set('isShowingRegistrationModal', false);
        this.set('isTimerStopped', true);
      },
      closeConnectionRequest: function closeConnectionRequest() {
        this.connectionRequest.reset();
      }
    }
  });

  _exports.default = _default;
});