define("darcy/_pods/rsvp/schedule-call/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fsOWt73T",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"py-4 text-left px-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex justify-between items-center\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"leading-none text-3xl font-light mb-6\"],[9],[0,\"Schedule Your Pre-Forum Call\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"p\"],[11,\"class\",\"pb-2 text-l leading-normal\"],[9],[0,\"Please click to choose a time. The purpose of this call is to go over the logistics and agenda for the day as well as the presenting technologies.\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex justify-center pt-2\"],[9],[0,\"\\n    \"],[7,\"a\"],[12,\"disabled\",[23,\"isLoading\"]],[12,\"href\",[23,\"redirectLocation\"]],[11,\"class\",\"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline\"],[9],[0,\"Schedule\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"invite\",\"isCallScheduleOptional\"]]],null,{\"statements\":[[0,\"      \"],[7,\"button\"],[12,\"disabled\",[23,\"isLoading\"]],[11,\"data-test-skip-call-step\",\"\"],[12,\"onclick\",[29,\"route-action\",[\"skip\"],null]],[11,\"class\",\"bg-blue-300 hover:bg-blue-600 text-white font-bold mx-4 py-2 px-4 rounded focus:outline-none focus:shadow-outline pointer\"],[9],[0,\"Skip\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"loading-spinner\",null,[[\"centered\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/rsvp/schedule-call/template.hbs"
    }
  });

  _exports.default = _default;
});