define("darcy/validations/content", ["exports", "ember-changeset-validations/validators", "darcy/validators/unique-email", "darcy/validators/video-url"], function (_exports, _validators, _uniqueEmail, _videoUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIMEO_REGEX = /vimeo\.com/;
  var YOUTUBE_REGEX = /youtu(\.be|be\.com)/;
  var _default = {
    name: [(0, _validators.validatePresence)(true)],
    fileType: [(0, _validators.validatePresence)(true)],
    contentType: [(0, _validators.validatePresence)(true)],
    description: [(0, _validators.validatePresence)(true), (0, _validators.validateLength)({
      max: 200
    })],
    url: [function (key, newValue, oldValue, changes, content) {
      if (changes.fileType === 'video' || content.fileType === 'video') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    } // disabled while we allow for raw videoIds
    //
    // function(key, newValue, oldValue, changes, content) {
    //   if ((changes.fileType) === 'video' || content.fileType === 'video') {
    //     return validateVideoUrl()(...arguments);
    //   }
    //   return true;
    // }
    ],
    file: [function (key, newValue, oldValue, changes, content) {
      if (changes.fileType === 'pdf') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }],
    videoType: [function (key, newValue, oldValue, changes, content) {
      if (changes.fileType === 'video' || content.fileType === 'video') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }, function (key, newValue, oldValue, changes, content) {
      var url = changes.url || content.url;
      var fileType = changes.fileType || content.fileType;

      if (newValue && url && fileType === 'video') {
        if (VIMEO_REGEX.test(url) && newValue !== 'vimeo' || YOUTUBE_REGEX.test(url) && newValue !== 'youtube') {
          return 'video type does not match url';
        }
      }

      return true;
    }],
    markdown: [function (key, newValue, oldValue, changes, content) {
      if (changes.fileType === 'markdown') {
        return (0, _validators.validatePresence)(true).apply(void 0, arguments);
      }

      return true;
    }]
  };
  _exports.default = _default;
});