define("darcy/_pods/components/svg-home-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDR3PHNl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"version\",\"1.1\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[11,\"viewBox\",\"0 0 16 16\"],[11,\"style\",\"enable-background:new 0 0 16 16;\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[7,\"polygon\"],[11,\"points\",\"13,5.375 13,2 11,2 11,3.625 8,1 0,8 2,8 2,15 6,15 6,10 10,10 10,15 14,15 14,8 16,8 \"],[12,\"fill\",[23,\"fill\"]],[9],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-home-icon/template.hbs"
    }
  });

  _exports.default = _default;
});