define("darcy/_pods/components/table/column/with-count/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kExOnGDd",
    "block": "{\"symbols\":[\"@column\",\"@sortIcons\"],\"statements\":[[1,[24,1,[\"label\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"badge badge-secondary\"],[9],[1,[23,\"count\"],false],[10],[0,\"\\n\"],[7,\"i\"],[12,\"class\",[30,[\"lt-sort-icon \",[29,\"if\",[[24,1,[\"sorted\"]],[29,\"if\",[[24,1,[\"ascending\"]],[24,2,[\"iconAscending\"]],[24,2,[\"iconDescending\"]]],null],[24,2,[\"iconSortable\"]]],null]]]],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/column/with-count/template.hbs"
    }
  });

  _exports.default = _default;
});