define("darcy/_pods/confirm/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['email'],
    email: null,
    emailForResend: Ember.computed('email', 'resendEmail', function () {
      return this.email || this.resendEmail;
    })
  });

  _exports.default = _default;
});