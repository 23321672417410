define("darcy/_pods/components/calendar-day/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8YNiHs5",
    "block": "{\"symbols\":[\"forum\"],\"statements\":[[7,\"span\"],[11,\"class\",\"circle\"],[9],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"number\"],[9],[1,[25,[\"day\",\"number\"]],false],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"highlightedForums\"]]],null,{\"statements\":[[0,\"  \"],[5,\"ember-popover\",[],[[\"@event\",\"@tooltipClass\"],[\"hover\",\"popover-tooltip\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[25,[\"highlightedForums\"]]],null,{\"statements\":[[0,\"      \"],[5,\"link-to\",[[13,\"class\",\"block hover:text-blue-400 hover:underline\"],[13,\"onclick\",[29,\"set-referrer-link-id\",[\"event-calendar-day-tooltip-link\"],null]]],[[\"@route\",\"@model\"],[\"events.view\",[24,1,[\"slug\"]]]],{\"statements\":[[0,\"\\n        \"],[1,[24,1,[\"name\"]],false],[0,\"\\n      \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/calendar-day/template.hbs"
    }
  });

  _exports.default = _default;
});