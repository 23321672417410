define("darcy/_pods/components/table/cell/text-with-link/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADMIN = _environment.default.ADMIN;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    classNames: ['flex items-end'],
    url: Ember.computed('column.routePath', 'column.id', function () {
      var routePath = this.get('column.routePath');
      var model = this.get('row.content');
      var id = model.get(this.get('column.id'));
      return "".concat(ADMIN.host, "/#/admin/").concat(routePath, "/").concat(id);
    })
  });

  _exports.default = _default;
});