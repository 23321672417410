define("darcy/helpers/capture-event", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    analytics: Ember.inject.service(),
    compute: function compute(_ref, params) {
      var _this = this;

      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          eventType = _ref2[0];

      var destinationUrl;
      var company;
      var companyProfile;
      var forum;
      var content;
      var meta = params ? Object.assign({}, params) : undefined;

      if ('destinationUrl' in meta) {
        destinationUrl = meta.destinationUrl;
        delete meta.destinationUrl;
      }

      if ('company' in meta) {
        company = meta.company;
        delete meta.company;
      }

      if ('companyProfile' in meta) {
        companyProfile = meta.companyProfile;
        delete meta.companyProfile;
      }

      if ('forum' in meta) {
        forum = meta.forum;
        delete meta.forum;
      }

      if ('content' in meta) {
        content = meta.content;
        delete meta.content;
      }

      return function () {
        return _this.analytics.captureEvent(eventType, {
          company: company,
          companyProfile: companyProfile,
          forum: forum,
          content: content,
          destinationUrl: destinationUrl,
          meta: meta
        });
      };
    }
  });

  _exports.default = _default;
});