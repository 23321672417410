define("darcy/_pods/reset-password/route", ["exports", "darcy/config/environment", "ember-changeset", "ember-changeset-validations", "darcy/validations/password"], function (_exports, _environment, _emberChangeset, _emberChangesetValidations, _password) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var passwordChangeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_password.default), _password.default, {
        skipValidate: true
      });
      controller.set('passwordChangeset', passwordChangeset);
    },
    actions: {
      submit: function submit(passwordChangeset, e) {
        var _this = this;

        e.preventDefault();

        var _this$paramsFor = this.paramsFor('reset-password'),
            token = _this$paramsFor.token;

        var password = passwordChangeset.get('password');
        passwordChangeset.validate();

        if (passwordChangeset.isInvalid) {
          return false;
        }

        this.set('loading', true);
        this.ajax.put("".concat(API.host, "/password_reset/").concat(token), {
          data: {
            password: password
          }
        }).then(function () {
          _this.transitionTo('login');

          _this.notifications.success('Password successfully changed! Please login now.');
        }).catch(function (err, data) {
          if (err.status === 404) {
            _this.notifications.error('The reset token is no longer valid. Please request a new password reset email and try again.');
          } else {
            _this.notifications.error('There was an error, please try again.');
          }
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});