define("darcy/_pods/components/table/cell/status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    status: Ember.computed('rawValue', function () {
      return this.column.getStatus(this.rawValue, this.row.content);
    })
  });

  _exports.default = _default;
});