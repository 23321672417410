define("darcy/services/analytics", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    referrerUrl: null,
    referrerRoute: null,
    referrerLinkId: null,
    trackPageView: function trackPageView() {
      var currentRoute = this.router.currentRouteName;
      var isAccessible = Ember.getOwner(this).lookup('controller:application').isShowingNoAccessModal === false;
      var sort = Ember.getOwner(this).lookup("controller:".concat(currentRoute)).sort;
      if (currentRoute === this.referrerRoute) return;
      var attrs = {
        eventType: 'page_view',
        url: window.location.href,
        route: currentRoute,
        referrerUrl: this.referrerUrl,
        referrerRoute: this.referrerRoute,
        referrerLinkId: this.referrerLinkId,
        meta: {
          is_accessible: isAccessible
        }
      };

      if (currentRoute.includes('events.view')) {
        var _EmberGetOwner$looku;

        attrs.forum = (_EmberGetOwner$looku = Ember.getOwner(this).lookup('route:events.view').currentModel) === null || _EmberGetOwner$looku === void 0 ? void 0 : _EmberGetOwner$looku.forum;
      } else if (currentRoute.includes('innovators.view')) {
        var _EmberGetOwner$looku2;

        attrs.companyProfile = (_EmberGetOwner$looku2 = Ember.getOwner(this).lookup('route:innovators.view').currentModel) === null || _EmberGetOwner$looku2 === void 0 ? void 0 : _EmberGetOwner$looku2.companyProfile;
      } else if (currentRoute.includes('research.view')) {
        var _EmberGetOwner$looku3;

        attrs.content = (_EmberGetOwner$looku3 = Ember.getOwner(this).lookup('route:research.view').currentModel) === null || _EmberGetOwner$looku3 === void 0 ? void 0 : _EmberGetOwner$looku3.content;
      } else if (currentRoute === 'usage.content.view') {
        attrs.content = Ember.getOwner(this).lookup('route:usage.content.view').currentModel;
      }

      if (Ember.isPresent(this.currentUser)) {
        attrs.meta.is_authenticated = true;
      } else {
        attrs.meta.is_authenticated = false;
      }

      if (sort) {
        attrs.meta.sort = sort.label || sort;
      }

      this.store.createRecord('analytics-event', attrs).save();
      this.setProperties({
        referrerUrl: window.location.href,
        referrerRoute: currentRoute,
        referrerLinkId: null
      });
    },
    captureEvent: function captureEvent(eventType) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          company = _ref.company,
          url = _ref.url,
          companyProfile = _ref.companyProfile,
          forum = _ref.forum,
          content = _ref.content,
          shareUrl = _ref.shareUrl,
          destinationUrl = _ref.destinationUrl,
          comment = _ref.comment,
          chapter = _ref.chapter,
          meta = _ref.meta;

      var attrs = {
        eventType: eventType,
        originUrl: url ? url.indexOf('http') == -1 ? "".concat(window.location.origin, "/").concat(url.replace('.', '/')) : url : window.location.href,
        destinationUrl: destinationUrl ? destinationUrl.indexOf('http') == -1 ? "".concat(window.location.origin, "/#/").concat(destinationUrl.replace('.', '/')) : destinationUrl : null,
        shareUrl: shareUrl,
        company: company,
        companyProfile: companyProfile,
        forum: forum,
        content: content,
        comment: comment,
        chapter: chapter,
        meta: meta
      };

      if (eventType === 'add_filter' && attrs.originUrl.includes('q=')) {
        attrs.meta.page = 'search results';
      }

      if (Ember.isPresent(this.currentUser)) {
        var event = this.store.createRecord('analytics-event', attrs);
        return event.save();
      } else if (_environment.default.environment === 'production') {
        delete attrs.eventType;

        if (attrs.forum) {
          attrs.forum_id = attrs.forum.id;
          delete attrs.forum;
        }

        if (attrs.companyProfile) {
          attrs.company_profile_id = attrs.companyProfile.id;
          delete attrs.companyProfile;
        }

        if (attrs.content) {
          attrs.content_id = attrs.content.id;
          delete attrs.content;
        }

        mixpanel.track(eventType, attrs);
      }
    }
  });

  _exports.default = _default;
});