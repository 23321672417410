define("darcy/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3h/vWWjp",
    "block": "{\"symbols\":[],\"statements\":[[5,\"head-tags\",[],[[\"@headTags\"],[[24,0,[\"model\",\"headTags\"]]]]],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"model\",\"apiNotifications\",\"unreadNotifications\"]]],null,{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"link\"],[11,\"rel\",\"shortcut icon\"],[11,\"id\",\"favicon-red\"],[12,\"href\",[30,[[29,\"img-src\",[\"images/favicon1red.png\"],null]]]],[11,\"type\",\"image/png\"],[9],[10],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[0,\"  \"],[7,\"link\"],[11,\"rel\",\"icon\"],[11,\"id\",\"favicon\"],[12,\"href\",[30,[[29,\"img-src\",[\"images/favicon.png\"],null]]]],[11,\"type\",\"image/png\"],[9],[10],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/templates/head.hbs"
    }
  });

  _exports.default = _default;
});