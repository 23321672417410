define("darcy/_pods/innovators/view/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      preserveScrollPosition: {
        // true results in the transition aborting and then retrying which causes
        // the transitionTo in app-tour.js#beforeShowPromise to resolve and show
        // the tour step before the model has actually finished loading.
        refreshModel: false
      }
    },
    model: function model() {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile;

      var contents = this.store.query('content', {
        company_id: companyProfile.companyId,
        only_darcy_content: true,
        visible: true
      });
      return Ember.RSVP.hash({
        companyProfile: companyProfile,
        contents: contents
      });
    },
    afterModel: function afterModel(model) {
      var _this$currentUser, _this$currentUser2, _this$currentUser3, _this$currentUser4, _this$currentUser5;

      var companyProfile = model.companyProfile;
      var isPublished = companyProfile.isPublished;
      var isCurrentUserDarcy = ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.classification) === 'darcy';
      var isCurrentUserContractor = ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.classification) === 'contractor';
      var isCurrentUserCompany = ((_this$currentUser3 = this.currentUser) === null || _this$currentUser3 === void 0 ? void 0 : _this$currentUser3.get('company.id')) === companyProfile.companyId;
      var isCurrentUserStorefrontAdmin = ((_this$currentUser4 = this.currentUser) === null || _this$currentUser4 === void 0 ? void 0 : _this$currentUser4.classification) === 'innovator' && ((_this$currentUser5 = this.currentUser) === null || _this$currentUser5 === void 0 ? void 0 : _this$currentUser5.isStorefrontAdmin) && isCurrentUserCompany;

      if (!isCurrentUserDarcy && !isCurrentUserContractor && !isCurrentUserStorefrontAdmin && !isPublished) {
        this.transitionTo('innovators.index', {
          queryParams: {
            unpublishedCompany: companyProfile.companyId
          }
        });
        return;
      }

      if (!companyProfile.get('isDarcyInsightsShown')) {
        if (companyProfile.get('isVendorOverviewShown')) {
          this.transitionTo('innovators.view.overview');
        } else if (companyProfile.get('isVendorMaterialsShown')) {
          this.transitionTo('innovators.view.vendor-materials');
        } else if (!companyProfile.get('isViewable')) {
          this.transitionTo('innovators.view');
        } else {
          this.transitionTo('innovators.view.take-action');
        }
      }

      return model;
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});