define("darcy/_pods/innovators/view/admin/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/company-profile"], function (_exports, _emberChangeset, _emberChangesetValidations, _companyProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!(this.get('currentUser.isStorefrontAdmin') || this.get('currentUser.classification') === 'darcy' || this.get('currentUser.classification') === 'operator' || this.get('currentUser.isInnovatorContractor'))) {
        return this.transitionTo('index');
      }
    },
    model: function model() {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile;

      return this.store.find('company', companyProfile.companyId);
    },
    afterModel: function afterModel(model) {
      var companyId = model.id;

      if (!(this.get('currentUser.isDarcyUser') || this.get('currentUser.isInnovatorContractor') || this.get('currentUser.classification') === 'operator' || this.get('currentUser.company.id') == companyId)) {
        return this.transitionTo('index');
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var profile = model.get('companyProfile.content');
      var profileChangeset = new _emberChangeset.default(profile, (0, _emberChangesetValidations.default)(_companyProfile.default), _companyProfile.default, {
        skipValidate: true
      });
      controller.set('changeset', profileChangeset);
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});