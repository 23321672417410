define("darcy/_pods/nylas-login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bu3JGEBY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[11,\"class\",\"leading-none text-4xl font-light my-8 text-center\"],[9],[0,\"Linking Calendar...\"],[10],[0,\"\\n\"],[1,[23,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/nylas-login/template.hbs"
    }
  });

  _exports.default = _default;
});