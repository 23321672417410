define("darcy/validators/unique-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateUniqueness;

  function validateUniqueness(opts) {
    return function (key, newValue, oldValue, changes, content) {
      return new Ember.RSVP.Promise(function (resolve) {
        // validation logic
        // resolve with `true` if valid || error message string if invalid
        resolve(true);
      });
    };
  }
});