define("darcy/validations/edit-profile", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    firstName: [(0, _validators.validatePresence)(true)],
    lastName: [(0, _validators.validatePresence)(true)],
    title: [(0, _validators.validatePresence)(true)],
    companyName: [(0, _validators.validatePresence)(true)],
    email: [(0, _validators.validateFormat)({
      type: 'email'
    })],
    personalEmail: [(0, _validators.validateFormat)({
      allowBlank: true,
      type: 'email'
    })],
    password: [(0, _validators.validateLength)({
      allowBlank: true,
      min: 8
    })],
    passwordConfirmation: [function (key, newValue, oldValue, changes, content) {
      // Fixes issue where Chrome autofills password and emptying the field
      // still requires password confirmation.
      if (Ember.isPresent(changes.password)) {
        return (0, _validators.validateConfirmation)({
          on: 'password'
        }).apply(void 0, arguments);
      }

      return true;
    }]
  };
  _exports.default = _default;
});