define("darcy/helpers/has-role", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasRole = hasRole;
  _exports.default = void 0;

  function hasRole(user) {
    if (!user) {
      return false;
    }

    var allowSelfRegister = user.get('company.allowSelfRegister');

    for (var _len = arguments.length, roleNames = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      roleNames[_key - 1] = arguments[_key];
    }

    if (roleNames.includes('self-register')) {
      return !!allowSelfRegister;
    } else {
      return user.isAdmin || roleNames.any(function (name) {
        return !!user.get('is' + Ember.String.capitalize(name));
      });
    }
  }

  var _default = Ember.Helper.extend({
    currentUser: Ember.inject.service(),
    userChanged: Ember.observer('currentUser.user.id', function () {
      this.recompute();
    }),
    compute: function compute(roleNames) {
      var user = this.get('currentUser.user');
      return hasRole.apply(void 0, [user].concat((0, _toConsumableArray2.default)(roleNames)));
    }
  });

  _exports.default = _default;
});