define("darcy/_pods/self-register/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      domainId: 'd',
      email: 'e'
    }],
    actions: {
      toggleUserCommunity: function toggleUserCommunity(community) {
        this.set('changeset.communities', (0, _lodash.xor)(this.get('changeset.communities').toArray(), [community]));
      }
    }
  });

  _exports.default = _default;
});