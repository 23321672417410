define("darcy/_pods/account/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      unsubscribeToken: {
        as: 'unsubscribe_token',
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var token = transition.to.queryParams.unsubscribe_token;

      if (token) {
        if (this.currentUser) {
          this.transitionTo('account.notification-settings', {
            queryParams: {
              unsubscribeToken: null
            }
          });
        }
      } else {
        return this._super.apply(this, arguments);
      }
    },
    model: function model(_ref) {
      var unsubscribeToken = _ref.unsubscribeToken;
      var user;

      if (unsubscribeToken && !this.currentUser) {
        user = this.store.query('user', {
          unsubscribe_token: unsubscribeToken
        }).then(function (user) {
          return user.get('firstObject');
        });
      } else {
        user = this.currentUser;
      }

      return Ember.RSVP.hash({
        user: user,
        communities: this.store.findAll('community')
      });
    }
  });

  _exports.default = _default;
});