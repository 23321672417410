define("darcy/_pods/home/article/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metaTags: Ember.inject.service('meta-tags'),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    beforeModel: function beforeModel() {
      var _this$paramsFor = this.paramsFor('home.article'),
          id = _this$paramsFor.id;

      this.transitionTo('research.view', id, {
        queryParams: {
          isBlog: true
        }
      });
    },
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('content', params.id).catch(function (e) {
        if (e.status === 401 || e.status === 404) {
          _this.transitionTo('home.index', {
            queryParams: {
              showNoAccessModal: true
            }
          });
        } else {
          throw e;
        }
      });
    },
    afterModel: function afterModel(model) {
      if (model) {
        var headTags = this.metaTags.getHeadTags(model);
        this.set('headTags', headTags);
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      controller.set('article', model);
      window.prerenderReady = true;
    }
  });

  _exports.default = _default;
});