define("darcy/_pods/components/advanced-filter/network-keyword/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    selectedKeyword: '',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      document.getElementById("".concat(this.elementId, "-keyword")).addEventListener("search", function () {
        _this.onNetworkKeywordChange(null);
      });
    },
    actions: {
      selectKeyword: function selectKeyword(keyword) {
        if (keyword.length > 2) {
          this.set('selectedKeyword', keyword);
          this.onNetworkKeywordChange(keyword);
          this.analytics.captureEvent('add_filter', {
            meta: {
              'keyword': keyword
            }
          });
        }

        if (keyword.length === 0) {
          this.onNetworkKeywordChange(null);
        }
      },
      onClick: function onClick() {
        if (this.isAccessible === false) {
          this.showNoAccessModal();
        }
      }
    }
  });

  _exports.default = _default;
});