define("darcy/_pods/components/expandable/component", ["exports", "sticky-sidebar-v2"], function (_exports, _stickySidebarV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    tagName: '',
    title: 'Filters',
    isExpanded: false,
    isSticky: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.isSticky && !this.media.isMobile) {
        this.stickySidebar = new _stickySidebarV.default('#sticky', {
          containerSelector: '.main-content',
          topSpacing: 150,
          bottomSpacing: 10
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.isSticky && !this.media.isMobile) {
        var _this$stickySidebar;

        (_this$stickySidebar = this.stickySidebar) === null || _this$stickySidebar === void 0 ? void 0 : _this$stickySidebar.destroy();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});