define("darcy/_pods/components/svg-network-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3RmV931L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[12,\"fill\",[23,\"fill\"]],[11,\"xmlns:x\",\"http://ns.adobe.com/Extensibility/1.0/\"],[11,\"xmlns:i\",\"http://ns.adobe.com/AdobeIllustrator/10.0/\"],[11,\"xmlns:graph\",\"http://ns.adobe.com/Graphs/1.0/\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"xmlns:xlink\",\"http://www.w3.org/1999/xlink\",\"http://www.w3.org/2000/xmlns/\"],[11,\"version\",\"1.1\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[11,\"viewBox\",\"-949 951 100 100\"],[11,\"style\",\"enable-background:new -949 951 100 100;\"],[11,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[9],[7,\"g\"],[9],[7,\"g\"],[11,\"i:extraneous\",\"self\"],[9],[7,\"g\"],[9],[7,\"path\"],[11,\"d\",\"M-861.6,996.1l-2.2-0.1c-1-0.1-2,0.2-2.8,0.7c-2.9,1.9-6.3,3-9.9,3c-3.7,0-7.1-1.1-9.9-3c-0.8-0.5-1.8-0.7-2.8-0.7     l-2.2,0.1c-0.7,0-1.4,0.2-2.1,0.3c1.4,2.4,2.1,5.2,2,8.1v0.1l-2.6,29.9c0,0.7-0.2,1.4-0.4,2c0.1,0,0.2,0,0.2,0     c6.2,0.5,11.1,0.8,17.7,0.8c6.6,0,11.5-0.3,17.7-0.8c2.6-0.2,4.6-2.3,4.7-4.8l2.5-24.3C-851.2,1001.6-855.7,996.5-861.6,996.1z\"],[9],[10],[7,\"circle\"],[11,\"cx\",\"-876.5\"],[11,\"cy\",\"981.5\"],[11,\"r\",\"15\"],[9],[10],[7,\"path\"],[11,\"d\",\"M-905.1,992.6l-2.2-0.1c-1-0.1-2,0.2-2.9,0.7c-3,2-6.5,3.1-10.3,3.1c-3.8,0-7.3-1.1-10.3-3.1c-0.8-0.6-1.9-0.8-2.9-0.7     l-2.2,0.1c-6.2,0.4-10.9,5.7-10.5,11.8l2.6,29.9c0.2,2.7,2.3,4.8,4.9,5c6.5,0.6,11.6,0.9,18.4,0.9c6.9,0,12-0.3,18.5-0.9     c2.7-0.2,4.8-2.4,4.9-5l2.6-29.9C-894.3,998.3-898.9,993-905.1,992.6z\"],[9],[10],[7,\"circle\"],[11,\"cx\",\"-920.6\"],[11,\"cy\",\"977.4\"],[11,\"r\",\"15.6\"],[9],[10],[10],[10],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-network-icon/template.hbs"
    }
  });

  _exports.default = _default;
});