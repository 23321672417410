define("darcy/_pods/events/view/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: [{
      action: 'a',
      invite_id: 'i'
    }],
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    userPreview: Ember.inject.service(),
    action: null,
    inviteId: null,
    innovatorList: Ember.computed('model.forum.presenters', function () {
      return this.get('model.forum.presenters').reject(function (i) {
        if (i.get('id') === "5104") {
          return true;
        }

        if (i.get('isStorefrontPublished')) {
          return false;
        }

        return true;
      });
    }),
    canViewIntro: Ember.computed('model.forum.isVideoIncluded', 'model.forum.introVideoMarkdownBefore', function () {
      var forum = this.model.forum;
      return forum.isVideoIncluded || forum.introVideoMarkdownBefore;
    }),
    canViewAgenda: Ember.computed('model.agenda', function () {
      return this.get('model.agenda.length') > 0;
    }),
    canViewInvites: Ember.computed.readOnly('model.forum.isAvailable'),
    canViewLogistics: Ember.computed('model.forum.isExpired', function () {
      var forum = this.model.forum;
      return !forum.isExpired;
    }),
    canViewRecording: Ember.computed('model.forum.{showVirtualRecording,hasVirtualRecordingUrl,isExpired}', function () {
      var forum = this.model.forum;
      return forum.isExpired && forum.showVirtualRecording && forum.hasVirtualRecordingUrl;
    }),
    canViewContent: Ember.computed('model.forum.canViewContents', 'model.forum.isPreviewOnly', function () {
      var forum = this.model.forum;
      return forum.canViewContents && forum.isPreviewOnly === false;
    }),
    canViewAttendees: Ember.computed('model.forum.showAttendees', function () {
      var forum = this.model.forum;
      return forum.showAttendees;
    }),
    openTawk: function openTawk() {
      window.open('https://tawk.to/chat/5e38b92fa89cda5a1883f101/default', '_blank', 'width=640,height=480');
    },
    actions: {
      contactUs: function contactUs(e) {
        e.preventDefault();
        this.openTawk();
      },
      openUserPreviewPopover: function openUserPreviewPopover(user) {
        this.userPreview.setModel(user);
      }
    }
  });

  _exports.default = _default;
});