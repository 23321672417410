define("darcy/services/tawk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    analytics: Ember.inject.service(),
    isAPILoaded: false,
    _queue: null,
    _tawkAPI: Tawk_API,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this._queue = [];

      if (this._tawkAPI.toggle) {
        // API is already loaded
        this._onAPILoad();
      } else {
        // preserve any existing `onLoad` handler
        var onLoad = this._tawkAPI.onLoad;
        var onChatMaximized = this._tawkAPI.onChatMaximized;

        this._tawkAPI.onLoad = function () {
          if (onLoad) onLoad();

          _this._onAPILoad();
        };

        this._tawkAPI.onChatMaximized = function () {
          if (onChatMaximized) onChatMaximized();

          _this.analytics.captureEvent('visit_live_chat', {
            url: window.location.href
          });
        };
      }
    },
    _onAPILoad: function _onAPILoad() {
      this.set('isAPILoaded', true);

      this._processQueue();
    },
    _processQueue: function _processQueue() {
      if (!this.get('isAPILoaded')) return;

      while (this._queue.length > 0) {
        var job = this._queue.shift();

        job();
      }
    },
    setUser: function setUser(user) {
      var _this2 = this;

      var attributes = {
        name: user.get('name'),
        email: user.get('email')
      };
      var tawkHash = user.get('tawkHash');

      if (tawkHash) {
        attributes.tawkHash = tawkHash;
      }

      var job = function job() {
        _this2._tawkAPI.setAttributes(attributes, function (error) {
          if (error) {
            console.error('failed to identify tawk user with attributes:', attributes, error);
          }
        });
      };

      this._queue.push(job);

      this._processQueue();
    },
    addEmailValidatedEvent: function addEmailValidatedEvent(user) {
      var _this3 = this;

      var meta;

      if (user) {
        meta = {
          email: user.get('email')
        };
      }

      var job = function job() {
        _this3._tawkAPI.addEvent('email-validated', meta, function (error) {
          if (error) {
            console.error('failed to add tawk event "email-validated":', error);
          }
        });
      };

      this._queue.push(job);

      this._processQueue();
    },
    addTag: function addTag(tag) {
      var _this4 = this;

      var job = function job() {
        _this4._tawkAPI.addTags([tag], function (error) {
          if (error) {
            console.error("failed to add tawk tag \"".concat(tag, "\":"), error);
          }
        });
      };

      this._queue.push(job);

      this._processQueue();
    }
  });

  _exports.default = _default;
});