define("darcy/_pods/rsvp/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "darcy/mixins/confirmed-user-route-mixin", "ember-ajax/errors"], function (_exports, _authenticatedRouteMixin, _confirmedUserRouteMixin, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function isEnabled(form, prop) {
    return form[prop] === undefined || form[prop] === null ? !form.isVirtual : form[prop];
  }

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _confirmedUserRouteMixin.default, {
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      overrideSurveyComplete: {
        as: 'pass'
      }
    },
    resetController: function resetController(controller) {
      controller.set('overrideSurveyComplete', false);
    },
    model: function model(_ref) {
      var _this = this;

      var forum_id = _ref.forum_id;
      var user_id = this.get('currentUser.id');
      return Ember.RSVP.hash({
        forum: this.store.findRecord('forum', forum_id),
        invite: this.store.query('invite', {
          forum_id: forum_id
        }).then(function (_ref2) {
          var firstObject = _ref2.firstObject;
          return firstObject;
        })
      }).then(function (_ref3) {
        var forum = _ref3.forum,
            invite = _ref3.invite;
        var call_id = forum.get('call.id');
        var survey_id = forum.get('survey.id');
        return Ember.RSVP.hash({
          attendeeSurveys: _this.store.query('attendee-survey', {
            survey_id: survey_id
          })
        }).then(function (_ref4) {
          var attendeeSurveys = _ref4.attendeeSurveys,
              attendeeCalls = _ref4.attendeeCalls;
          return {
            forum: forum,
            invite: invite,
            user: invite && invite.user,
            attendeeSurvey: attendeeSurveys.get('firstObject')
          };
        });
      });
    },
    afterModel: function afterModel(model, transition) {
      var invite = model.invite,
          forum = model.forum;
      var to = transition.to;
      var pass = to.queryParams.pass;
      to = to.name;
      if (!invite) return this.transitionTo('forums');

      if (forum.isExpired) {
        return this.transitionTo('forums');
      }

      console.log(to.indexOf('rsvp'));

      if (to.indexOf('rsvp') <= -1) {
        console.log(to);
        return;
      }

      if (invite.isRsvpComplete) {
        this.transitionTo('forum.index', forum.slug);
      } else if (forum.isVideoIncluded && invite.isVideoIncluded && !invite.isVideoWatched && forum.introVideoUrl) {
        this.transitionTo('rsvp.intro');
      } else if (!forum.isVirtual && !invite.isLogisticsComplete) {
        this.transitionTo('rsvp.attendee-details');
      } else if (isEnabled(forum, 'isSurveyIncluded') && invite.isSurveyIncluded && !invite.isSurveyComplete && !pass) {
        this.transitionTo('rsvp.attendee-survey');
      } else if (isEnabled(forum, 'isCallIncluded') && invite.isCallIncluded) {
        if (invite.isCallScheduleComplete) {
          var call_id = forum.get('call.id');
          this.transitionTo('call-redirect', {
            queryParams: {
              call_id: call_id
            }
          });
        } else {
          this.transitionTo('rsvp.schedule-call');
        }
      } else if (forum.isVirtual && !invite.isRsvpComplete) {
        this.transitionTo('rsvp.confirm-attendance');
      } else {
        this.confirm(invite);
      }
    },
    confirm: function confirm(invite) {
      var _this2 = this;

      var isVirtual = invite.get('forum.isVirtual');

      if (this.controller) {
        this.controller.set('isLoading', true);
      }

      return invite.confirm().then(function () {}).catch(function () {}).finally(function () {
        if (_this2.controller) {
          _this2.controller.set('isLoading', false);
        }
      });
    },
    actions: {
      confirm: function confirm() {
        var _this3 = this;

        this.confirm(this.currentModel.invite).then(function () {
          // there is error when transition directly to forum.index
          return _this3.transitionTo('forums').then(function () {
            _this3.transitionTo('forum.index', _this3.currentModel.forum.slug);
          });
        });
      },
      willTransition: function willTransition(transition) {
        this.afterModel(this.currentModel, transition);
      },
      error: function error(_error) {
        if ((0, _errors.isNotFoundError)(_error)) {
          this.transitionTo('forums');
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});