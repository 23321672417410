define("darcy/_pods/components/carousel/component", ["exports", "swiper", "moment"], function (_exports, _swiper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['carousel', 'swiper-container'],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.swiper = new _swiper.default(this.element, {
        // loop: true,
        observer: true,
        slidesPerView: 4,
        spaceBetween: 15,
        navigation: {
          nextEl: '.right-nav',
          prevEl: '.left-nav'
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 3,
            spaceBetween: 5
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 4,
            spaceBetween: 5
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 22
          }
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.swiper.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      slideLeft: function slideLeft() {
        this.swiper.slidePrev();
      },
      slideRight: function slideRight() {
        this.swiper.slideNext();
      }
    }
  });

  _exports.default = _default;
});