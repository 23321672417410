define("darcy/_pods/usage/community-members/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    media: Ember.inject.service(),
    tableSortDir: 'asc',
    tableSortName: 'user.confirmedAt',
    tableFilter: null,
    company: Ember.computed.readOnly('currentUserService.user.company'),
    communitiesWithUsers: Ember.computed('communities', function () {
      return this.get('communities').filter(function (community) {
        return community.get('companyUserCount') > 0 && community.get('id') !== '22';
      });
    }),
    chartLabels: Ember.computed('communitiesWithUsers', function () {
      return this.communitiesWithUsers.sortBy('companyUserCount').map(function (community) {
        return community.name;
      });
    }),
    chartDatasets: Ember.computed('communitiesWithUsers', function () {
      var data = this.communitiesWithUsers.sortBy('companyUserCount').mapBy('companyUserCount');
      return [{
        label: 'Active Members',
        data: data
      }];
    }),
    chartStyle: Ember.computed('communitiesWithUsers', function () {
      var multiplier = this.media.isMobile ? 54 : 32;
      return 'height: ' + this.communitiesWithUsers.length * multiplier + 'px';
    }),
    chartColors: Ember.computed('communitiesWithUsers', 'tableFilter', function () {
      var _this = this;

      return this.communitiesWithUsers.sortBy('companyUserCount').map(function (community) {
        if (_this.tableFilter === community) {
          return '#2E5F82';
        }

        if (community.isOpen === false) {
          return 'rgba(94, 164, 208, .75)';
        }

        return '#5EA4D0';
      });
    }),
    tableColumns: Ember.computed(function () {
      return [{
        label: 'Name',
        valuePath: 'user.formalName',
        cellClassNames: 'highlight truncate',
        cellComponent: 'table/cell/mailto',
        width: '30%'
      }, {
        label: 'Title',
        cellClassNames: 'truncate',
        valuePath: 'user.title',
        width: '30%',
        breakpoints: ['tablet', 'desktop', 'jumbo']
      }, {
        label: 'Events',
        align: 'center',
        valuePath: 'seatCount',
        width: '15%'
      }, {
        label: this.media.isMobile ? 'Comm.' : 'Communities',
        align: 'center',
        valuePath: 'communitiesCount',
        width: '15%'
      }, {
        label: 'Joined At',
        align: 'right',
        valuePath: 'user.confirmedAt',
        cellComponent: 'table/cell/date',
        width: '15%',
        breakpoints: ['desktop', 'jumbo']
      }];
    }),
    tableModel: Ember.computed('tableFilter', function () {
      var _this2 = this;

      var users = this.users.filterBy('isRegistered');

      if (Ember.isPresent(this.tableFilter)) {
        users = users.filter(function (user) {
          return user.communities.includes(_this2.tableFilter);
        });
      }

      return users.map(function (user) {
        return {
          user: user,
          seatCount: _this2.get('invites').filterBy('user.id', user.get('id')).length,
          communitiesCount: user.communities.filter(function (c) {
            return c.name !== 'All Users';
          }).length
        };
      });
    }),
    actions: {
      onTooltipLabelRender: function onTooltipLabelRender(tooltipModel) {
        return tooltipModel.body[0].lines[0].match(/\d+/)[0];
      },
      onFilter: function onFilter(barIndex) {
        if (barIndex === null) {
          return this.set('tableFilter', null);
        }

        var communityName = this.chartLabels[barIndex].replace('*', '');
        var community = this.communities.find(function (c) {
          return c.name === communityName;
        });
        this.set('tableFilter', community);
      },
      onClearFilter: function onClearFilter() {
        this.set('tableFilter', null);
      },
      onTableSort: function onTableSort(isAscending, sort) {
        var dir = isAscending ? 'asc' : 'desc';
        this.setProperties({
          tableSortName: sort,
          tableSortDir: dir
        });
      }
    }
  });

  _exports.default = _default;
});