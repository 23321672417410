define("darcy/_pods/components/list-item/research/default/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SoFzSc4p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"list-item\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"bg-white border-b cursor-pointer hover:bg-gray-100 px-5 hover:shadow-outline \",[29,\"if\",[[25,[\"isFirst\"]],\"rounded-r-lg rounded-bl-lg\",\"rounded-lg\"],null]]]],[9],[0,\"\\n    \"],[5,\"link-to\",[[13,\"class\",\"list-item\"]],[[\"@route\",\"@model\",\"@query\"],[\"research.view\",[25,[\"content\",\"slug\"]],[23,\"query\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"relative w-full capitalize text-lg text-gray-700 font-semibold border-b-3 border-gray-300 pb-2 pt-3 mb-4 px-1\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"isAccessible\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/lock.svg\"],null]]]],[11,\"class\",\"absolute right-0 w-6 h-6\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[25,[\"content\",\"contentType\"]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[5,\"list-item/home/content-publish/default/content-body\",[],[[\"@content\"],[[23,\"content\"]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"border-t-3 border-gray-300 mt-4\"],[9],[0,\"\\n      \"],[5,\"social-actions\",[],[[\"@model\",\"@modelName\",\"@route\"],[[23,\"content\"],\"content\",\"research.view\"]]],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/list-item/research/default/template.hbs"
    }
  });

  _exports.default = _default;
});