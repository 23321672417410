define("darcy/models/connection-request", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    createdAt: (0, _attr.default)('date'),
    updatedAt: (0, _attr.default)('date'),
    acceptedAt: (0, _attr.default)('date'),
    connecterId: (0, _attr.default)('string'),
    connecteeId: (0, _attr.default)('string'),
    message: (0, _attr.default)('string')
  });

  _exports.default = _default;
});