define("darcy/services/app-tour", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var backBtn = {
    classes: 'py-2 px-6 ml-3 leading-5 rounded text-black cursor-pointer direction-buttons shadow-2xl',
    text: 'BACK',
    type: 'back'
  };
  var nextBtn = {
    classes: 'py-2 px-6 ml-3 leading-5 rounded text-black cursor-pointer direction-buttons shadow-2xl',
    text: 'NEXT',
    type: 'next'
  };
  var startBtn = {
    classes: 'py-1 md:py-2 px-4 md:px-6 ml-3 text-sm leading-5 font-medium rounded text-black bg-highlight_green cursor-pointer',
    text: "LET'S GO",
    type: 'next'
  };
  var finishBtn = {
    classes: 'py-1 md:py-2 px-4 md:px-6 ml-3 text-sm leading-5 font-medium rounded text-black bg-highlight_green cursor-pointer',
    text: 'FINISH',
    type: 'next'
  };

  var getButtons = function getButtons(length, index) {
    var btns = [];

    if (length > 1) {
      if (index === 0) {
        btns.push(startBtn);
      } else if (index === length - 1) {
        btns.push(backBtn, finishBtn);
      } else if (length === index + 1) {
        btns.push(backBtn);
      } else {
        btns.push(backBtn, nextBtn);
      }
    }

    return btns;
  };

  var _default = Ember.Service.extend({
    tour: Ember.inject.service(),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    isStartTourModalOpen: false,
    isTakeATourModalOpen: false,
    previousStep: null,
    init: function init() {
      var _this = this;

      this.get('tour').set('defaultStepOptions', {
        classes: '',
        scrollTo: true,
        cancelIcon: {
          enabled: true
        }
      });
      this.get('tour').set('modal', true);
      this.get('tour').on('next', function () {
        var previousIndex = _this.previousIndex;

        var steps = _this.get('tour.tourObject.steps');

        var stepIndex = steps.indexOf(_this.get('tour.tourObject.currentStep'));

        if (previousIndex !== stepIndex) {
          _this.set('previousIndex', stepIndex);
        }

        if (stepIndex === 1) {
          _this.analytics.captureEvent('user_tour_start', {
            url: window.location.href,
            meta: {
              name: _this.get('tourName')
            }
          });
        } else if (previousIndex === steps.length - 1) {
          _this.analytics.captureEvent('user_tour_click', {
            url: window.location.href,
            meta: {
              name: _this.get('tourName'),
              target: 'finish',
              value: steps.length - 1
            }
          });
        } else {
          _this.analytics.captureEvent('user_tour_click', {
            url: window.location.href,
            meta: {
              name: _this.get('tourName'),
              target: 'next',
              value: stepIndex - 1
            }
          });
        }
      });
      this.get('tour').on('back', function () {
        var stepIndex = _this.get('tour.tourObject.steps').indexOf(_this.get('tour.tourObject.currentStep'));

        if (stepIndex > 0) {
          _this.analytics.captureEvent('user_tour_click', {
            url: window.location.href,
            meta: {
              name: _this.get('tourName'),
              target: 'back',
              value: stepIndex + 1
            }
          });
        }
      });
      this.get('tour').on('cancel', function () {
        var stepIndex = _this.get('tour.tourObject.steps').indexOf(_this.get('tour.tourObject.currentStep'));

        _this.analytics.captureEvent('user_tour_click', {
          url: window.location.href,
          meta: {
            name: _this.get('tourName'),
            target: 'close',
            value: stepIndex
          }
        });
      });
    },
    start: function start(steps, tourName) {
      var _this2 = this;

      this.set('tourName', tourName);
      this.get('tour').addSteps(steps.map(function (step, index) {
        return Object.assign(step, {
          attachTo: {
            element: step.selector,
            on: step.position
          },
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              var _this2$router;

              var transitionToArgs;

              if (step.models) {
                transitionToArgs = [step.routeName, step.models];
              } else {
                transitionToArgs = [step.routeName];
              }

              (_this2$router = _this2.router).transitionTo.apply(_this2$router, (0, _toConsumableArray2.default)(transitionToArgs).concat([{
                queryParams: {
                  g: false
                }
              }])).finally(function () {
                Ember.run.scheduleOnce('afterRender', _this2, function () {
                  resolve();
                });
              });
            });
          },
          buttons: getButtons(steps.length, index),
          classes: 'darcy-tour-modal',
          id: Ember.String.dasherize(step.title),
          text: "".concat(step.image ? '<img src=' + step.image + ' style="margin: 0 auto; width: 100%;" alt=""> <br> ' : '', " ").concat(step.caption),
          when: {
            show: function show() {
              if (this.tour.steps.indexOf(this.tour.currentStep) !== 0) {
                var currentStepElement = this.el;
                var footer = currentStepElement.querySelector('.shepherd-footer');
                var progress = document.createElement('span');
                progress.className = 'shepherd-progress';
                progress.innerText = "Step ".concat(this.tour.steps.indexOf(this.tour.currentStep), "/").concat(this.tour.steps.length - 1);
                footer.insertBefore(progress, currentStepElement.querySelector('.shepherd-button:last-child'));
              }
            }
          }
        });
      })).then(function () {
        _this2.get('tour').start();
      });
    }
  });

  _exports.default = _default;
});