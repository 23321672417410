define("darcy/_pods/survey/thanks/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['forum_id', 'survey_id'],
    forum_id: null,
    survey_id: null
  });

  _exports.default = _default;
});