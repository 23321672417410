define("darcy/_pods/components/advanced-filter/employees/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6HqtBxpY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"Number of Employees\"],[10],[0,\"\\n\\n  \"],[1,[29,\"range-slider\",null,[[\"class\",\"start\",\"min\",\"max\",\"step\",\"tooltips\",\"pips\",\"on-change\",\"on-start\",\"on-end\"],[\"mt-12 mb-12\",[25,[\"employees\"]],0,100,1,true,[29,\"hash\",null,[[\"mode\",\"values\",\"density\"],[\"values\",[29,\"array\",[0,100],null],100]]],[29,\"action\",[[24,0,[]],\"selectEmployees\"],null],[29,\"action\",[[24,0,[]],\"toggleTooltip\"],null],[29,\"action\",[[24,0,[]],\"toggleTooltip\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/employees/template.hbs"
    }
  });

  _exports.default = _default;
});