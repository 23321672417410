define("darcy/_pods/components/advanced-filter/keyword/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OFDZsVFU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold mb-2\"],[9],[0,\"Keyword\"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"w-full inline-flex items-center pl-4 py-2\"],[9],[0,\"\\n    \"],[1,[29,\"input\",null,[[\"type\",\"id\",\"name\",\"class\",\"value\",\"placeholder\",\"autocomplete\",\"input\",\"key-down\"],[\"search\",[29,\"concat\",[[25,[\"elementId\"]],\"-keyword\"],null],[29,\"concat\",[[25,[\"elementId\"]],\"-keyword\"],null],\"w-full form-text rounded border border-gray-600 text-gray-800 focus:shadow-none focus:outline-none p-2\",[29,\"readonly\",[[25,[\"keyword\"]]],null],\"Keyword\",\"off\",[29,\"action\",[[24,0,[]],\"selectKeyword\"],[[\"value\"],[\"target.value\"]]],[29,\"action\",[[24,0,[]],\"onKeyDown\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/keyword/template.hbs"
    }
  });

  _exports.default = _default;
});