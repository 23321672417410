define("darcy/_pods/components/sidebar-content-filter/component", ["exports", "darcy/_pods/research/index/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FilterItem = Ember.Object.extend({
    selected: null,
    // need to bind this
    label: null,
    value: null,
    isSelected: Ember.computed('selected.[]', function () {
      var selected = this.get('selected');
      return !!selected.findBy('value', this.get('value'));
    })
  });

  var _default = Ember.Component.extend({
    tagName: '',
    title: 'Filters',
    selected: null,
    onChange: null,
    // handles all irregularities of the filter list
    items: Ember.computed('', function () {
      var _this = this;

      return _controller.contentTypes.map(function (contentType) {
        var BoundFilterItem = FilterItem.extend({
          selected: Ember.computed.readOnly('component.selected'),
          // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
          component: _this
        });
        return BoundFilterItem.create(contentType);
      });
    }),
    actions: {
      toggleItem: function toggleItem(item) {
        // don't mutate original array
        var selected = this.selected ? this.selected.toArray() : [];

        if (selected.includes(item)) {
          selected.removeObject(item);
        } else {
          selected.addObject(item);
        } // pass _full_ array of selected contentTypes back to controller


        this.onChange(selected);
      }
    }
  });

  _exports.default = _default;
});