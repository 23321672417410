define("darcy/_pods/innovators/view/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: [{
      preserveScrollPosition: 'psp'
    }],
    preserveScrollPosition: false,
    contentFilter: 'all',
    filteredContent: Ember.computed('model.contents.@each.fileType', 'contentFilter', function () {
      var contents = this.model.contents;

      switch (this.contentFilter) {
        case 'presentations':
          return contents.filterBy('fileType', 'pdf');

        case 'videos':
          return contents.filterBy('fileType', 'video');

        default:
          return contents;
      }
    }),
    incumbents: Ember.computed.readOnly('model.companyProfile.productOverviews.firstObject.competitors'),
    actions: {
      transitionToRelatedInnovator: function transitionToRelatedInnovator(company) {
        if (this.currentUser.classification === 'innovator') {
          this.send('showNoAccessModal');
          this.get('currentUser') && this.analytics.captureEvent('free_account_notification', {
            url: window.location.href
          });
          return;
        }

        this.transitionToRoute('innovators.view', company.slug);
      }
    }
  });

  _exports.default = _default;
});