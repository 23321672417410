define("darcy/_pods/components/carousel-gallery/component", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['photo-gallery', 'swiper-container'],
    showModal: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('sortedItems', this.photos.sortBy('displayOrder'));
      this.set('primaryItem', this.sortedItems.firstObject);
      var galleryID = this.name ? "carousel-gallery-".concat(this.name) : 'carousel-gallery';
      this.set('galleryID', galleryID);
      this.set('galleryIDSelector', ".".concat(galleryID));
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.swiper = new _swiper.default(this.galleryIDSelector, {
        observer: true,
        slidesPerView: 4,
        spaceBetween: 6,
        navigation: {
          nextEl: '.right-nav',
          prevEl: '.left-nav'
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.swiper.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      slideLeft: function slideLeft() {
        this.swiper.slidePrev();
      },
      slideRight: function slideRight() {
        this.swiper.slideNext();
      },
      onThumbnailClick: function onThumbnailClick(item) {
        this.set('primaryItem', item);

        if (this.get('showModal') === false) {
          this.send('onEnlarge');
        }
      },
      onEnlarge: function onEnlarge() {
        var _this = this;

        this.toggleProperty('showModal');
        this.onOpen();
        this.swiper.destroy(); //need the modal to have been added/rmd from dom

        setTimeout(function () {
          _this.swiper = new _swiper.default(_this.galleryIDSelector, {
            observer: true,
            slidesPerView: 4,
            spaceBetween: 12,
            navigation: {
              nextEl: '.right-nav',
              prevEl: '.left-nav'
            }
          });
        }, 75);
      }
    }
  });

  _exports.default = _default;
});