define("darcy/_pods/components/title-box/base/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJrrO84I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full bg-white rounded-lg flex flex-col\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"h-12 w-full text-white text-lg flex flex-row items-center rounded-t-lg px-4 font-semibold \",[23,\"titleClasses\"]]]],[9],[0,\"\\n    \"],[1,[23,\"title\"],false],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"titleInfo\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"pb-3\"],[9],[0,\"\\n        \"],[1,[29,\"information-tooltip\",null,[[\"color\",\"text\"],[\"white\",[25,[\"titleInfo\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/title-box/base/template.hbs"
    }
  });

  _exports.default = _default;
});