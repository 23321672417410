define("darcy/_pods/components/modal/take-a-tour/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nZ19UZ6J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"modal-dialog\",null,[[\"translucentOverlay\",\"containerClass\"],[true,\"bg-white md:max-w-6xl mx-auto rounded-lg shadow-lg z-50 overflow-y-auto\"]],{\"statements\":[[4,\"click-outside\",null,[[\"action\"],[[29,\"action\",[[24,0,[]],[29,\"route-action\",[\"hideAllModals\"],null]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-30\"],[11,\"data-test-button\",\"close-modal\"],[9],[0,\"\\n      \"],[1,[29,\"svg-jar\",[\"x\"],[[\"class\"],[\"fill-current text-black\"]]],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"closeModal\"]],[10],[0,\"\\n\\n    \"],[7,\"p\"],[11,\"class\",\"text-center text-3xl font-semibold mb-4 mt-12\"],[9],[0,\"You have officially joined the Connect Community!\"],[10],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"text-center font-light mx-40 pt-4 border-t border-black \"],[9],[0,\"Take a virtual tour of the platform with our team or start exploring on your own!\"],[10],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"flex justify-center text-center py-6 px-8\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"mx-8\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"w-72 h-48\"],[9],[0,\"\\n          \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/welcome-attend.png\"],null]]]],[11,\"class\",\"border border-gray-600 hover:border-blue-500 rounded-lg shadow py-4 cursor-pointer\"],[11,\"alt\",\"Events\"],[11,\"title\",\"Events\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"text-2xl font-semibold mt-8 uppercase cursor-pointer\"],[9],[0,\"Take A Tour\"],[10],[0,\"\\n      \"],[3,\"action\",[[24,0,[]],\"goToStartTour\"]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/modal/take-a-tour/template.hbs"
    }
  });

  _exports.default = _default;
});