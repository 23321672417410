define("darcy/_pods/components/modal/start-tour/component", ["exports", "darcy/tours/events", "darcy/tours/innovators", "darcy/tours/research"], function (_exports, _events, _innovators, _research) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    router: Ember.inject.service(),
    analytics: Ember.inject.service(),
    appTour: Ember.inject.service(),
    initTour: function initTour(tour) {
      if (tour === "events") {
        this.appTour.start(_events.default, 'events');
        this.set('appTour.isStartTourModalOpen');
      } else if (tour === "innovators") {
        this.appTour.start(_innovators.default, 'innovators');
        this.set('appTour.isStartTourModalOpen');
      } else {
        this.appTour.start(_research.default, 'research');
        this.set('appTour.isStartTourModalOpen');
      }
    },
    actions: {
      startTour: function startTour(tour) {
        this.initTour(tour);
        this.analytics.captureEvent('select_user_tour', {
          meta: {
            'value': tour
          }
        });
      }
    }
  });

  _exports.default = _default;
});