define("darcy/_pods/forgot-password/route", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service(),
    ajax: Ember.inject.service(),
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var email = controller.get('forgotEmail');
      controller.setProperties({
        email: email,
        forgotEmail: null
      });
    },
    actions: {
      submit: function submit(rawEmail, e) {
        var _this = this;

        e.preventDefault();
        var email = rawEmail.toLowerCase().trim();
        this.set('loading', true);
        this.ajax.post("".concat(API.host, "/password_reset"), {
          data: {
            email: email
          }
        }).then(function () {
          _this.transitionTo('login', {
            queryParams: {
              email: null
            }
          });

          _this.notifications.success("Thanks, we sent your reset email to ".concat(email));
        }).catch(function (err) {
          if (err.status == 404) {
            _this.controller.set('showEmailNotFound', true);
          } else {
            _this.notifications.error('There was an error, please try again.');
          }
        }).finally(function () {
          _this.set('loading', false);
        });
      }
    }
  });

  _exports.default = _default;
});