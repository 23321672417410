define("darcy/_pods/components/advanced-filter/event-type/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "lodash"], function (_exports, _toConsumableArray2, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    analytics: Ember.inject.service(),
    specialityOptions: ['Technical', 'Strategy', 'Executive', 'Innovator'],
    selectedSpecialties: [],
    actions: {
      selectSpeciality: function selectSpeciality(speciality) {
        var selectedSpecialties = (0, _toConsumableArray2.default)(this.selectedSpecialties);
        selectedSpecialties = (0, _lodash.xor)(selectedSpecialties, [speciality]);
        this.onSpecialityChange(selectedSpecialties);

        if (selectedSpecialties.includes(speciality)) {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'event_type': speciality,
              'target': 'advanced-filter'
            }
          });
        }
      },
      clearSpeciality: function clearSpeciality() {
        this.onSpecialityChange();
      }
    }
  });

  _exports.default = _default;
});