define("darcy/_pods/components/forum-preview-tile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6KnrFTfM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-white flex flex-col\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"h-12 bg-orange-300 text-black text-xl flex flex-row items-center px-6 lg:px-4 font-bold\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"forum\",\"isToday\"]]],null,{\"statements\":[[0,\"      Today\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Upcoming\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"px-6 lg:px-4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-xl mt-5 font-semibold\"],[9],[0,\" \"],[1,[25,[\"forum\",\"name\"]],false],[0,\" \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-sm md:text-base mt-1\"],[9],[0,\"\\n      \"],[1,[29,\"moment-format\",[[25,[\"forum\",\"startDate\"]],\"MMMM DD, YYYY\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-sm lg:text-base text-highlight_green font-semibold uppercase my-5 hover:underline\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"forum\",[25,[\"forum\",\"slug\"]]]],{\"statements\":[[0,\"        Go To Event >\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/forum-preview-tile/template.hbs"
    }
  });

  _exports.default = _default;
});