define("darcy/helpers/timezone-abbr", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timezoneAbbr = timezoneAbbr;
  _exports.default = void 0;

  function timezoneAbbr(params
  /*, hash*/
  ) {
    var _params = (0, _slicedToArray2.default)(params, 1),
        timezone = _params[0];

    if (Ember.isBlank(timezone)) return null;
    return (0, _moment.default)().tz(timezone).format('z');
  }

  var _default = Ember.Helper.helper(timezoneAbbr);

  _exports.default = _default;
});