define("darcy/_pods/components/interest-rating/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tCVw61wa",
    "block": "{\"symbols\":[\"index\",\"@rating\"],\"statements\":[[7,\"h3\"],[11,\"class\",\"text-lg font-semibold mb-1\"],[9],[0,\"Interest\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex justify-start\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"array\",[1,2,3,4,5,6,7,8,9,10],null]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"eq\",[[24,2,[]],\"low\"],null],[29,\"lte\",[[24,1,[]],3],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1 bg-blue-400\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"eq\",[[24,2,[]],\"medium\"],null],[29,\"lte\",[[24,1,[]],6],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1 bg-blue-400\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"eq\",[[24,2,[]],\"high\"],null],[29,\"lte\",[[24,1,[]],10],null]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1 bg-blue-400\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"lte\",[[24,1,[]],[24,2,[]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1 bg-blue-400\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1 bg-light-grey\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/interest-rating/template.hbs"
    }
  });

  _exports.default = _default;
});