define("darcy/_pods/account/edit/route", ["exports", "ember-changeset", "ember-changeset-validations", "darcy/validations/edit-profile"], function (_exports, _emberChangeset, _emberChangesetValidations, _editProfile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EXCLUDED_EXPERTISE_TOPIC_TAG_IDS = [1, 9, 11, 20, 26, 27, 28];

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('account', model.user);
      var userChangeset = new _emberChangeset.default(model.user, (0, _emberChangesetValidations.default)(_editProfile.default), _editProfile.default);
      controller.set('changeset', userChangeset);
      !userChangeset.get('preferredLanguage') && controller.set('changeset.preferredLanguage', 'english');
      this.store.findAll('topicTag').then(function (topicTags) {
        var expertiseTopicTags = topicTags.filter(function (topicTag) {
          return EXCLUDED_EXPERTISE_TOPIC_TAG_IDS.includes(Number(topicTag.id)) === false;
        }).sortBy('name').reject(function (topicTag) {
          return topicTag.name === 'Other';
        }).concat({
          id: null,
          name: 'Other'
        });
        controller.set('expertiseTopicTags', expertiseTopicTags);

        if (userChangeset.get('topicTag.id')) {
          controller.set('selectedTopicTag', userChangeset.get('topicTag'));
        } else {
          controller.set('selectedTopicTag', otherOption);
        }
      });
    }
  });

  _exports.default = _default;
});