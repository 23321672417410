define("darcy/_pods/components/advanced-filter/topic-tags/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var promotedTopicTags = ['Innovation Management'];
  var FilterItem = Ember.Object.extend({
    topicTag: null,
    // can be null
    selected: null,
    // need to bind this
    expanded: null,
    // need to bind this
    init: function init() {
      this._super.apply(this, arguments);

      this.children = this.children || [];
    },
    label: Ember.computed.oneWay('topicTag.name'),
    id: Ember.computed.oneWay('topicTag.id'),
    parent: null,
    children: null,
    isRoot: Ember.computed.equal('depth', 0),
    hasChildren: Ember.computed.gt('children.length', 0),
    depth: Ember.computed('parent.depth', function () {
      var parent = this.get('parent');
      if (!parent) return 0;
      return parent.get('depth') + 1;
    }),
    isShowingSubindex: Ember.computed('expanded.[]', 'topicTag', function () {
      var topicTag = this.get('topicTag');
      var expanded = this.get('expanded');

      if (topicTag && this.get('children').length) {
        this.get('children').isAny('isSelected');
        return expanded ? expanded.includes(topicTag) : false;
      }
    }),
    hasSelectedChildren: Ember.computed('children.@each.isSelected', function () {
      return this.get('children').isAny('isSelected');
    }),
    isSelected: Ember.computed('selected.[]', 'topicTag', 'children.@each.isSelected', function () {
      var topicTag = this.get('topicTag');
      var selected = this.get('selected');

      if (topicTag) {
        if (Ember.isPresent(selected)) {
          return selected ? selected.includes(topicTag) : false;
        }
      }

      if (this.get('children').length) {
        // "general" group case; status is determined by children
        return this.get('children').isEvery('isSelected');
      }
    }),
    affectedTopicTags: Ember.computed('topicTag', 'chidren.@each.affectedTopicTags', function () {
      var topicTag = this.get('topicTag');
      if (topicTag) return [topicTag]; // "general" group case; toggle action descends to children

      return this.get('children').reduce(function (arr, item) {
        arr.addObjects(item.get('affectedTopicTags'));
        return arr;
      }, []);
    })
  });

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    store: Ember.inject.service(),
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);

      var topicTags = this.store.findAll('topicTag');
      this.set('topicTagOptions', topicTags);
    },
    title: 'Filters',
    topicTags: null,
    expandedTopicTags: null,
    selected: null,
    onTopicTagsChange: null,
    onExpandedTopicTagsChange: null,
    blacklist: null,
    // handles all irregularities of the filter list
    options: Ember.computed('topicTagOptions.@each.{name,categoryOrder,tagOrder}', 'blacklist', function () {
      var _this = this;

      if (Ember.isEmpty(this.topicTagOptions)) return [];
      var blacklist = this.blacklist || [];
      var remainingTopicTags = this.topicTagOptions.reject(function (topicTag) {
        return blacklist.includes(topicTag.name);
      }); // classic binding hack

      var BoundFilterItem = FilterItem.extend({
        selected: Ember.computed.readOnly('component.topicTags'),
        expanded: Ember.computed.readOnly('component.expandedTopicTags'),
        // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
        component: this
      });
      var serviceLineItems = this.topicTagOptions.filterBy('category', 'Service Lines').sortBy('tagOrder').map(function (serviceLineTopicTag) {
        remainingTopicTags.removeObject(serviceLineTopicTag);
        var serviceLineItem = BoundFilterItem.create({
          topicTag: serviceLineTopicTag
        });

        _this.topicTagOptions.filterBy('category', serviceLineTopicTag.name).sortBy('tagOrder').forEach(function (childTopicTag) {
          remainingTopicTags.removeObject(childTopicTag);
          var child = BoundFilterItem.create({
            parent: serviceLineItem,
            topicTag: childTopicTag
          });
          serviceLineItem.children.push(child);
        });

        return serviceLineItem;
      });
      var remainingItems = remainingTopicTags.sortBy('tagOrder').sortBy('categoryOrder').map(function (topicTag) {
        return BoundFilterItem.create({
          topicTag: topicTag
        });
      });
      return [].concat((0, _toConsumableArray2.default)(serviceLineItems), (0, _toConsumableArray2.default)(remainingItems));
    }),
    captureAnalyticsAddFilter: function captureAnalyticsAddFilter(item) {
      var _this2 = this;

      if (item.topicTag) {
        Ember.run.later(function () {
          _this2.analytics.captureEvent('add_filter', {
            meta: {
              'topic': item.label,
              'target': 'advanced-filter'
            }
          });
        }, 1);
      }
    },
    captureAnalyticsRemoveFilter: function captureAnalyticsRemoveFilter(item) {
      var _this3 = this;

      if (item.topicTag) {
        Ember.run.later(function () {
          _this3.analytics.captureEvent('remove_filter', {
            meta: {
              'topic': item.label,
              'target': 'advanced-filter'
            }
          });
        }, 1);
      }
    },
    filterTopicTags: Ember.computed('filterTopicTagIds.[]', function () {
      var _this4 = this;

      if (!this.topicTagOptions) return [];
      return this.topicTagOptions.filter(function (topicTag) {
        return _this4.filterTopicTagIds.includes(topicTag.id);
      });
    }),
    toggleSubItems: function toggleSubItems(item) {
      var expandedTopicTags = this.expandedTopicTags ? this.expandedTopicTags.toArray() : [];

      if (item.get('isShowingSubindex')) {
        expandedTopicTags.removeObject(item.topicTag);
      } else {
        expandedTopicTags.addObject(item.topicTag);
      }

      this.onExpandedTopicTagsChange(expandedTopicTags);
    },
    actions: {
      toggleItem: function toggleItem(item) {
        // don't mutate original array
        var topicTags = this.topicTags ? this.topicTags.toArray() : [];
        var affectedTopicTags = item.get('affectedTopicTags');
        var parent = item.get('parent');

        if (item.get('isSelected')) {
          topicTags.removeObjects(affectedTopicTags);

          if (parent && topicTags.includes(parent.topicTag)) {
            topicTags.removeObjects([parent.topicTag]);
          }

          if (item.get('hasChildren')) {
            topicTags.removeObjects(item.get('children').map(function (c) {
              return c.get('affectedTopicTags')[0];
            }));
          }

          this.captureAnalyticsRemoveFilter(item);
        } else {
          topicTags.addObjects(affectedTopicTags);

          if (item.get('hasChildren')) {
            topicTags.addObjects(item.get('children').map(function (c) {
              return c.get('affectedTopicTags')[0];
            }));
          }

          if (Ember.isBlank(parent)) {
            this.toggleSubItems(item);
          }

          this.captureAnalyticsAddFilter(item);
        } // pass _full_ array of selected topic tags back to controller


        this.onTopicTagsChange(topicTags);
      },
      toggleSubItems: function toggleSubItems(item) {
        this.toggleSubItems(item);
      },
      clearAll: function clearAll() {
        this.onTopicTagsChange([]);
      }
    }
  });

  _exports.default = _default;
});