define("darcy/_pods/components/advanced-filter/topic-tags/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/u5h40IQ",
    "block": "{\"symbols\":[\"child\"],\"statements\":[[7,\"li\"],[12,\"class\",[30,[\"pb-2 \",[29,\"if\",[[25,[\"item\",\"isRoot\"]],\"pb-4\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"flex items-center\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"block text-base flex items-start\"],[9],[0,\"\\n      \"],[1,[29,\"square-checkbox\",null,[[\"onToggle\",\"isActive\"],[[29,\"action\",[[24,0,[]],[25,[\"onToggle\"]],[25,[\"item\"]]],null],[25,[\"item\",\"isSelected\"]]]]],false],[0,\"\\n\\n      \"],[7,\"div\"],[12,\"data-test-topic-tag-filter\",[25,[\"item\",\"id\"]]],[12,\"class\",[30,[\"cursor-pointer ml-2 mt-1 font-light \",[29,\"if\",[[29,\"or\",[[25,[\"item\",\"isRoot\"]],[25,[\"item\",\"hasChildren\"]]],null],\"font-normal\"],null]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"onToggle\"]],[25,[\"item\"]]],null]],[9],[0,\"\\n\\n        \"],[1,[25,[\"item\",\"label\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"item\",\"hasChildren\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"sub-filters mb-2\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"item\",\"children\"]]],null,{\"statements\":[[0,\"        \"],[1,[29,\"advanced-filter/topic-tags/item\",null,[[\"item\",\"onToggle\"],[[24,1,[]],[25,[\"onToggle\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/topic-tags/item/template.hbs"
    }
  });

  _exports.default = _default;
});