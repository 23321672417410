define("darcy/_pods/components/notification-settings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tBuwUZoI",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[25,[\"unsubscribeToken\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"notification-settings/app\",null,[[\"user\"],[[25,[\"user\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"notification-settings/email\",null,[[\"user\",\"onToggleNotification\"],[[25,[\"user\"]],[25,[\"onToggleNotification\"]]]]],false],[0,\"\\n\\n\"],[1,[29,\"notification-settings/newsletter\",null,[[\"user\",\"onToggleNotification\"],[[25,[\"user\"]],[25,[\"onToggleNotification\"]]]]],false],[0,\"\\n\\n\"],[1,[29,\"notification-settings/event\",null,[[\"user\",\"visibleServiceLines\",\"isShowingDisableAutoInvitesModal\",\"currentServiceLine\",\"onToggleServiceLine\",\"onToggleNotification\"],[[25,[\"user\"]],[25,[\"visibleServiceLines\"]],[25,[\"isShowingDisableAutoInvitesModal\"]],[25,[\"currentServiceLine\"]],[25,[\"onToggleServiceLine\"]],[25,[\"onToggleNotification\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/notification-settings/template.hbs"
    }
  });

  _exports.default = _default;
});