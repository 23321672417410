define("darcy/helpers/simple-mde-preview", ["exports", "ember-simplemde/helpers/simple-mde-preview"], function (_exports, _simpleMdePreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _simpleMdePreview.default;
    }
  });
  Object.defineProperty(_exports, "simpleMdePreview", {
    enumerable: true,
    get: function get() {
      return _simpleMdePreview.simpleMdePreview;
    }
  });
});