define("darcy/models/carousel-item", ["exports", "ember-data/attr", "ember-data/model", "ember-data/relationships", "darcy/config/environment"], function (_exports, _attr, _model, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    url: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    contentType: (0, _attr.default)('string', {
      defaultValue: "photo"
    }),
    videoType: (0, _attr.default)('string', {
      defaultValue: "vimeo"
    }),
    displayOrder: (0, _attr.default)('number'),
    isDarcy: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    imageAlt: (0, _attr.default)('string'),
    company: (0, _relationships.belongsTo)('company'),
    isPhoto: Ember.computed.equal('contentType', 'photo'),
    isVimeo: Ember.computed.equal('videoType', 'vimeo'),
    rootURL: _environment.default.rootURL,
    thumbnailUrl: Ember.computed('isPhoto', 'isVimeo', function () {
      var isPhoto = this.isPhoto,
          isVimeo = this.isVimeo,
          url = this.url;

      if (isPhoto) {
        return url;
      } else {
        // Videos - url is video ID
        if (isVimeo) {
          return this.rootURL + 'images/video-player.png';
        } else {
          // YouTube - https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api
          return "https://img.youtube.com/vi/".concat(url, "/mqdefault.jpg");
        }
      }
    })
  });

  _exports.default = _default;
});