define("darcy/_pods/account/index/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    analytics: Ember.inject.service(),
    isEditingInterests: false,
    selectedCommuntiesByGroup: Ember.computed('accountChangeset.communities', function () {
      var result = [];
      this.get('accountChangeset.communities').forEach(function (community) {
        var hasGroup = result.findBy('id', community.get('communityGroup.id'));
        if (!hasGroup && !Ember.isEmpty(community.get('communityGroup.name'))) result.pushObject(Ember.Object.create({
          id: community.get('communityGroup.id'),
          name: community.get('communityGroup.name'),
          communities: []
        }));
        !community.get('isHidden') && result.findBy('id', community.get('communityGroup.id')).get('communities').pushObject(community);
      });
      return result;
    }),
    communtiesByGroup: Ember.computed('model.communities', function () {
      var result = [];
      var orderedGroupIds = ['1', '2', '9', '3'];
      this.get('model.communities').forEach(function (community) {
        var hasGroup = result.findBy('id', community.get('communityGroup.id'));
        if (!hasGroup && !Ember.isEmpty(community.get('communityGroup.name'))) result.pushObject(Ember.Object.create({
          id: community.get('communityGroup.id'),
          name: community.get('communityGroup.name'),
          communities: []
        }));
        !community.get('isHidden') && result.findBy('id', community.get('communityGroup.id')).get('communities').pushObject(community);
      });
      return orderedGroupIds.map(function (id) {
        return result.findBy('id', id);
      });
    }),
    actions: {
      toggleUserCommunity: function toggleUserCommunity(community) {
        var isSelected = this.get('accountChangeset.communities').toArray().map(function (c) {
          return c.id;
        }).includes(community.id);
        this.analytics.captureEvent(isSelected ? 'deselect_community' : 'select_community', {
          url: window.location.href,
          meta: {
            'community_id': community.id
          }
        });
        this.set('accountChangeset.communities', (0, _lodash.xor)(this.get('accountChangeset.communities').toArray(), [community]));
      },
      toggleEditInterests: function toggleEditInterests() {
        this.toggleProperty('isEditingInterests');
      },
      save: function save() {
        var _this = this;

        var user = this.get('accountChangeset');
        user.save().then(function () {
          _this.notifications.success("Interests Updated");

          _this.send('toggleEditInterests');
        }).catch(function () {
          _this.notifications.error('There was an error updating your account information, please try again.');

          _this.send('cancel');
        }).finally(function () {
          if (_this.controller) {
            _this.controller.set('isLoading', false);
          }
        });
      },
      cancel: function cancel() {
        this.send('toggleEditInterests');
        this.get('accountChangeset').rollback();
      }
    }
  });

  _exports.default = _default;
});