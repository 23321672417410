define("darcy/_pods/components/line-chart/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['relative'],
    labels: [],
    datasets: [],
    color: '#000000',
    options: Ember.computed('', function () {
      var _this = this;

      return {
        layout: {
          padding: {
            top: 16
          }
        },
        legend: {
          display: false
        },
        tooltips: {
          enabled: true,
          displayColors: false,
          backgroundColor: '#FFFFFF',
          titleFontColor: '#000000',
          titleAlign: 'center',
          titleFontFamily: 'Open Sans, sans-serif',
          titleFontStyle: '600',
          titleFontSize: 16,
          bodyFontColor: '#000000',
          bodyAlign: 'center',
          bodyFontFamily: 'Open Sans, sans-serif',
          bodyFontSize: 12,
          bodySpacing: 4,
          titleMarginBottom: 8,
          xPadding: 20,
          yPadding: 16,
          cornerRadius: 0,
          shadowOffsetX: 3,
          shadowOffsetY: 3,
          shadowBlur: 7,
          shadowColor: 'rgba(0, 0, 0, 0.3)',
          caretSize: 12,
          caretPadding: 16,
          callbacks: {
            title: function title(tooltipItem, data) {
              var index = tooltipItem.firstObject.index;
              return _this.labels[index].format('MMMM YYYY');
            },
            label: function label(tooltipItem, data) {
              if (_this.onTooltipLabelRender) return _this.onTooltipLabelRender(tooltipItem);
              return _this.datasets.map(function (dataset) {
                return dataset.label + ': ' + tooltipItem.yLabel;
              });
            }
          }
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999'
            },
            ticks: {
              offset: true,
              maxTicksLimit: 3,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: 16,
              fontStyle: '500',
              padding: 16
            }
          }],
          xAxes: [{
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999'
            },
            ticks: {
              offset: true,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: 16,
              fontStyle: '500',
              padding: 16,
              callback: function callback(value) {
                return moment(value).format('MMM');
              }
            }
          }]
        },
        onClick: function onClick(e, elements) {
          if (_this.onClick === undefined) return;
          if (Ember.isEmpty(elements)) return _this.onClick(null);

          _this.onClick(elements.firstObject._index);
        },
        // Disable since chartjs-plugin-datalabels globally enables.
        plugins: {
          datalabels: {
            display: false
          }
        }
      };
    }),
    chartData: Ember.computed('labels', 'datasets', 'color', function () {
      var _this2 = this;

      return {
        labels: this.labels,
        datasets: this.datasets.map(function (dataset) {
          return (0, _lodash.merge)(dataset, {
            pointRadius: 9,
            pointBackgroundColor: _this2.color,
            pointBorderWidth: 4.5,
            pointBorderColor: '#4A4A4A',
            pointHoverRadius: 9,
            pointHoverBorderWidth: 4.5,
            borderColor: _this2.color,
            borderWidth: 4.5,
            fill: false,
            lineTension: 0
          });
        })
      };
    })
  });

  _exports.default = _default;
});