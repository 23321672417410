define("darcy/_pods/innovators/view/darcy-activity/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    model: function model() {
      var _this$modelFor = this.modelFor('innovators.view'),
          companyProfile = _this$modelFor.companyProfile;

      return companyProfile;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.fetchRecords.perform();
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});