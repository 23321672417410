define("darcy/helpers/truncate", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "lodash"], function (_exports, _slicedToArray2, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        string = _ref2[0],
        _ref2$ = _ref2[1],
        length = _ref2$ === void 0 ? 140 : _ref2$;

    if (Ember.String.isHTMLSafe(string)) {
      string = string.string;
    }

    if (string && string.length > length) {
      return (0, _lodash.truncate)(string, {
        length: length,
        separator: ' '
      });
    } else {
      return string;
    }
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});