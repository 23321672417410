define("darcy/_pods/getting-started/step-2/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model() {
      var _this = this;

      return this.store.findAll('topic-tag').then(function (topicTags) {
        var topicTagIds = topicTags.filter(function (topicTag) {
          return _this.currentUser.communities.mapBy('name').includes(topicTag.name);
        }).mapBy('id');
        return _this.store.query('company-profile', {
          page: 1,
          per_page: 6,
          filter_topics: topicTagIds,
          sort: 'viewsCount',
          dir: 'desc'
        }).then(function (companyProfiles) {
          if (Ember.isEmpty(companyProfiles)) {
            return _this.store.query('company-profile', {
              page: 1,
              per_page: 6,
              sort: 'viewsCount',
              dir: 'desc'
            });
          } else {
            return companyProfiles;
          }
        });
      });
    }
  });

  _exports.default = _default;
});