define("darcy/_pods/components/svg-innovators-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S70JAci+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"svg\"],[11,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[11,\"data-name\",\"Layer 1\"],[11,\"viewBox\",\"0 0 100 100\"],[11,\"x\",\"0px\"],[11,\"y\",\"0px\"],[9],[7,\"title\"],[9],[0,\"12\"],[10],[7,\"g\"],[11,\"data-name\",\"Group\"],[9],[7,\"path\"],[11,\"data-name\",\"Compound Path\"],[11,\"d\",\"M94.55,8H5.45V19.09H3.37v6.56a9.39,9.39,0,0,0,2.08,5.89V92h89.1V31.54a9.39,9.39,0,0,0,2.08-5.89V19.09H94.55ZM9.45,12h81.1v7.11H9.45ZM92.63,25.65a5.44,5.44,0,0,1-10.88,0V23.09H92.63Zm-14.88,0V27.3a7.92,7.92,0,0,1-15.83,0V23.09H77.75ZM57.92,27.3a7.92,7.92,0,0,1-15.83,0V23.09H57.92ZM22.25,25.65V23.09H38.08V27.3a7.92,7.92,0,1,1-15.83,0Zm-14.88,0V23.09H18.25v2.56a5.44,5.44,0,1,1-10.88,0ZM57.92,88H42.08V61.5H57.92Zm4,0V57.5H38.08V88H9.45V34.46a9.37,9.37,0,0,0,10-2.07A11.9,11.9,0,0,0,40.08,33.9a11.9,11.9,0,0,0,19.83,0A11.9,11.9,0,0,0,80.6,32.39a9.37,9.37,0,0,0,10,2.07V88Z\"],[12,\"fill\",[23,\"fill\"]],[9],[10],[7,\"path\"],[11,\"data-name\",\"Compound Path\"],[11,\"d\",\"M14.48,74.53h18v-17h-18Zm4-13h10v9h-10Z\"],[12,\"fill\",[23,\"fill\"]],[9],[10],[7,\"path\"],[11,\"data-name\",\"Compound Path\"],[11,\"d\",\"M67.48,74.53h18v-17h-18Zm4-13h10v9h-10Z\"],[12,\"fill\",[23,\"fill\"]],[9],[10],[10],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/svg-innovators-icon/template.hbs"
    }
  });

  _exports.default = _default;
});