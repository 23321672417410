define("darcy/models/search", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    query: (0, _attr.default)('string'),
    searchEvents: (0, _relationships.hasMany)('searchEvent'),
    searchCompanies: (0, _relationships.hasMany)('searchCompany'),
    searchInnovators: (0, _relationships.hasMany)('searchInnovator'),
    searchInsights: (0, _relationships.hasMany)('searchInsight'),
    searchPresentations: (0, _relationships.hasMany)('searchPresentation'),
    searchFrameworks: (0, _relationships.hasMany)('searchFramework'),
    searchContents: (0, _relationships.hasMany)('searchContent')
  });

  _exports.default = _default;
});