define("darcy/_pods/components/sticky-sidebar/component", ["exports", "sticky-sidebar-v2"], function (_exports, _stickySidebarV) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    media: Ember.inject.service(),
    elId: null,
    topSpacing: 170,
    bottomSpacing: 28,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!this.media.isMobile) {
        var topSpacing = this.topSpacing,
            bottomSpacing = this.bottomSpacing;
        this.stickySidebar = new _stickySidebarV.default("#".concat(this.elId), {
          containerSelector: '.main-content',
          topSpacing: topSpacing,
          bottomSpacing: bottomSpacing
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (!this.media.isMobile) {
        var _this$stickySidebar;

        (_this$stickySidebar = this.stickySidebar) === null || _this$stickySidebar === void 0 ? void 0 : _this$stickySidebar.destroy();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});