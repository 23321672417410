define("darcy/_pods/components/popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D0KN3mPC",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"click-outside\",[[13,\"data-test\",[23,\"dataTestName\"]],[13,\"class\",\"inline-block\"]],[[\"@action\"],[[29,\"action\",[[24,0,[]],[24,0,[\"closePopup\"]]],null]]],{\"statements\":[[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[24,0,[\"buttonClass\"]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,0,[\"togglePopup\"]]],null]],[12,\"type\",[24,0,[\"buttonType\"]]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"content\",\"close\"],[[29,\"component\",[\"popup-content\"],[[\"popup\"],[[24,0,[]]]]],[29,\"action\",[[24,0,[]],[24,0,[\"closePopup\"]]],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/popup/template.hbs"
    }
  });

  _exports.default = _default;
});