define("darcy/_pods/network/route", ["exports", "darcy/config/environment", "uuid"], function (_exports, _environment, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    ajax: Ember.inject.service(),
    modals: Ember.inject.service(),
    queryParams: {
      acceptConnectionRequestId: {
        as: 'a',
        replace: true
      },
      ignoreConnectionRequestId: {
        as: 'i',
        replace: true
      }
    },
    beforeModel: function beforeModel() {
      var isDarcyUser = this.get('currentUser.classification') === 'darcy';
      var isOperatorUser = this.get('currentUser.classification') === 'operator';

      if (!isDarcyUser && !isOperatorUser) {
        return this.transitionTo('index');
      }
    },
    setupController: function setupController(controller) {
      var _this = this;

      this._super.apply(this, arguments);

      if (controller.acceptConnectionRequestId) {
        controller.ajax.put("".concat(_environment.default.API.host, "/connection_requests/").concat(controller.acceptConnectionRequestId, "/accept")).then(function (res) {
          if (res.data) {
            _this.modals.open('modal/success', {
              title: "You are now connected to ".concat(res.data.attributes.name),
              autoCloseDuration: 3000
            });

            var connecter = _this.store.peekRecord('user', res.data.attributes.connecter_id);

            if (Ember.isPresent(connecter)) {
              connecter.set('connection_status', 'connected');
            }
          }
        });
        controller.set('acceptConnectionRequestId', null);
      }

      if (controller.ignoreConnectionRequestId) {
        controller.ajax.put("".concat(_environment.default.API.host, "/connection_requests/").concat(controller.ignoreConnectionRequestId, "/ignore"));
        controller.set('ignoreConnectionRequestId', null);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this.controller.clearAll();
      }
    }
  });

  _exports.default = _default;
});