define("darcy/_pods/components/notifications-sidebar/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notificationsService: Ember.inject.service('api-notifications'),
    notifications: Ember.computed.readOnly('notificationsService.notifications'),
    tagName: '',
    isDragging: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var sidebar = document.querySelector('.notification-sidebar');
      var manager = new Hammer.Manager(sidebar);
      manager.add(new Hammer.Pan({
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 0
      }));

      var handleDrag = function handleDrag(el) {
        if (!_this.isDragging) {
          _this.set('isDragging', true);
        }

        if (el.deltaX > 0) {
          sidebar.style.transform = "translateX(".concat(el.deltaX, "px)");
        }

        if (el.isFinal) {
          _this.set('isDragging', false);

          if (sidebar.offsetWidth / 4 < el.deltaX) {
            _this.closeSidebar();

            Ember.run.later(function () {
              sidebar.style.transform = "translateX(0px)";
            }, 1000);
          } else {
            sidebar.style.transform = "translateX(0px)";
          }
        }
      };

      manager.on("pan", handleDrag);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('html').removeClass('lock-scroll');
    },
    onOpenStateChange: Ember.observer('isOpen', function () {
      var _this2 = this;

      if (this.isOpen) {
        (0, _jquery.default)('html').addClass('lock-scroll');
        this.notificationsService.fetchNotifications();
      } else {
        Ember.run.later(function () {
          (0, _jquery.default)('html').removeClass('lock-scroll');

          _this2.notificationsService.markAllNotificationsRead();
        }, 1000);
      }
    }),
    actions: {
      onNotificationClick: function onNotificationClick(notification) {
        notification.set('readAt', new Date());
        notification.save();
      }
    }
  });

  _exports.default = _default;
});