define("darcy/models/search-content", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    description: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    publishedAt: (0, _attr.default)('date'),
    contentType: (0, _attr.default)('string'),
    fileType: (0, _attr.default)('string'),
    searchExcerpt: (0, _attr.default)('string', {
      defaultValue: ''
    }),
    pspdfkitId: (0, _attr.default)('string'),
    jwt: (0, _attr.default)('string'),
    innovators: (0, _relationships.hasMany)('search-company'),
    forums: (0, _relationships.hasMany)('search-event'),
    isPdf: Ember.computed.equal('fileType', 'pdf')
  });

  _exports.default = _default;
});