define("darcy/_pods/events/view/intro/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});