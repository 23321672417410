define("darcy/_pods/components/fake-pagination/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/nSKJUIR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"pagination-centered\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"pagination\"],[9],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"arrow prev disabled\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"«\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"li\"],[11,\"class\",\"active page-number\"],[9],[0,\"\\n      \"],[7,\"a\"],[9],[0,\"1\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"li\"],[11,\"class\",\"page-number\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"2\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"li\"],[11,\"class\",\"page-number\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"3\"],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n    \"],[7,\"li\"],[11,\"class\",\"arrow next\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"»\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/fake-pagination/template.hbs"
    }
  });

  _exports.default = _default;
});