define("darcy/services/ajax", ["exports", "darcy/config/environment", "ember-ajax/errors", "ember-ajax/services/ajax"], function (_exports, _environment, _errors, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super();

      this.trustedHosts = [_environment.default.API.host];
    },
    host: _environment.default.API.host,
    contentType: 'application/vnd.api+json; charset=utf-8',
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var result = this._super.apply(this, arguments);

      if ((0, _errors.isUnauthorizedError)(result) && !isNylasRequest(requestData)) {
        this.session.invalidate();
      }

      return result;
    },
    headers: Ember.computed('session.data.authenticated', {
      get: function get() {
        var headers = {};

        var _this$get = this.get('session.data.authenticated'),
            token = _this$get.token;

        if (Ember.isPresent(token)) {
          headers.Authorization = "Bearer ".concat(token);
        }

        return headers;
      }
    })
  });

  _exports.default = _default;

  function isNylasRequest(requestData) {
    var url = requestData.url || '';
    return url && url.includes('api.nylas.com');
  }
});