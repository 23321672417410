define("darcy/_pods/components/table/cell/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contentState: Ember.computed('row.content', function () {
      var today = moment().startOf('day');
      var eventDate = moment(this.get('rawValue'));

      if (eventDate.isBefore(today) && moment(eventDate).add(7, 'days').isAfter(today)) {
        return 'past7';
      } else if (moment(eventDate).add(7, 'days').isBefore(today)) {
        return 'past';
      } else if (eventDate.isAfter(today.endOf('day'))) {
        return 'Upcoming';
      } else if (eventDate.isSame(today, 'day')) {
        return 'live';
      }
    })
  });

  _exports.default = _default;
});