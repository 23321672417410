define("darcy/_pods/components/search-request-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pRgXS8yg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"font-bold text-xl mb-5\"],[9],[0,\"Search Request\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"grid grid-cols-1 \",[24,0,[\"twoColsAt\"]],\":grid-cols-2 gap-x-12 gap-y-4 mb-6\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Name\"],[10],[0,\"\\n    \"],[5,\"input\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"],[13,\"disabled\",true]],[[\"@value\"],[[24,0,[\"requestorName\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Email\"],[10],[0,\"\\n    \"],[5,\"input\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"],[13,\"disabled\",true]],[[\"@value\"],[[24,0,[\"requestorEmail\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"Company\"],[10],[0,\"\\n    \"],[5,\"input\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"],[13,\"disabled\",true]],[[\"@value\"],[[24,0,[\"requestorCompany\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[24,0,[\"twoColsAt\"]],\":col-span-2\"]]],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"block mb-1\"],[9],[0,\"What are you looking for?\"],[10],[0,\"\\n    \"],[5,\"textarea\",[[13,\"class\",\"border border-gray-600 rounded p-2 w-full\"]],[[\"@value\"],[[24,0,[\"details\"]]]]],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"flex justify-end\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"bg-highlight_green text-white font-bold rounded px-4 py-2\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[29,\"perform\",[[24,0,[\"submitFormTask\"]]],null]],null]],[11,\"type\",\"button\"],[9],[0,\"Submit\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-request-form/template.hbs"
    }
  });

  _exports.default = _default;
});