define("darcy/_pods/components/table/column/with-count/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    count: Ember.computed('table.rows.@each.id', 'column.valuePath', 'column.counter', function () {
      var valuePath = this.column.valuePath;
      var counter = this.column.counter;
      return this.table.rows.reduce(counter, 0);
    })
  });

  _exports.default = _default;
});