define("darcy/services/social-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isCommenting: false,
    setCommentingState: function setCommentingState(state) {
      this.set('isCommenting', state);
    }
  });

  _exports.default = _default;
});