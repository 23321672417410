define("darcy/_pods/components/video/youtube/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_TYPE = 'youtube';
  var YOUTUBE_URL_REGEX = /(youtu\.be|youtube\.com)/;
  var EMBED_PREFIX = 'https://www.youtube.com/embed/';

  var _default = Ember.Component.extend({
    video: Ember.inject.service(),
    url: null,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    src: Ember.computed('url', function () {
      if (Ember.isBlank(this.url)) return null;
      var id = YOUTUBE_URL_REGEX.test(this.url) ? this.video.getVideoID(VIDEO_TYPE, this.url) : this.url;
      return "".concat(EMBED_PREFIX).concat(id);
    })
  });

  _exports.default = _default;
});