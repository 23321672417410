define("darcy/_pods/innovators/view/notes/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u65XmB/I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bg-white w-full h-32 py-6 px-4 mb-4 rounded-r-lg rounded-bl-lg\"],[9],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/innovators/view/notes/index/template.hbs"
    }
  });

  _exports.default = _default;
});