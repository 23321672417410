define("darcy/models/register", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    password: (0, _attr.default)('string'),
    passwordConfirmation: (0, _attr.default)('string'),
    confirmedAt: (0, _attr.default)('date'),
    companyName: (0, _attr.default)('string'),
    companyType: (0, _attr.default)('string'),
    isCompanyInnovator: (0, _attr.default)('boolean'),
    authSession: (0, _relationships.belongsTo)('auth-session'),
    name: Ember.computed('firstName', 'lastName', {
      get: function get() {
        return "".concat(this.get('firstName') || '', " ").concat(this.get('lastName') || '');
      }
    })
  });

  _exports.default = _default;
});