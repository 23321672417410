define("darcy/serializers/company-profile", ["exports", "darcy/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    normalize: function normalize()
    /* typeClass, hash */
    {
      var profile = this._super.apply(this, arguments);

      var fieldStr;

      if (typeof profile.data.attributes.markets === 'string') {
        fieldStr = profile.data.attributes.markets.trim();
        profile.data.attributes.markets = fieldStr.length ? fieldStr.split(',') : [];
      }

      if (typeof profile.data.attributes.techTags === 'string') {
        fieldStr = profile.data.attributes.techTags.trim();
        profile.data.attributes.techTags = fieldStr.length ? fieldStr.split(',') : [];
      }

      if (typeof profile.data.attributes.geographicalFootprint === 'string') {
        fieldStr = profile.data.attributes.geographicalFootprint.trim();
        profile.data.attributes.geographicalFootprint = fieldStr.length ? fieldStr.split(',') : [];
      }

      return profile;
    }
  });

  _exports.default = _default;
});