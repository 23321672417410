define("darcy/_pods/account/notification-settings/route", ["exports", "ember-changeset"], function (_exports, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    analytics: Ember.inject.service(),
    model: function model() {
      var _this$modelFor = this.modelFor('account'),
          user = _this$modelFor.user;

      return user;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var changeset = new _emberChangeset.default(model);
      controller.set('accountChangeset', changeset);
      controller.set('user', model);
      this.store.findAll('service-line').then(function (serviceLines) {
        controller.set('serviceLines', serviceLines);
      });

      var _this$paramsFor = this.paramsFor('account'),
          unsubscribeToken = _this$paramsFor.unsubscribeToken;

      controller.set('unsubscribeToken', unsubscribeToken);
    },
    afterModel: function afterModel() {
      var _this$paramsFor2 = this.paramsFor('account'),
          unsubscribeToken = _this$paramsFor2.unsubscribeToken;

      if (unsubscribeToken) {
        this.analytics.captureEvent('visit_one_click_unsubscribe_link', {
          url: window.location.href,
          destinationUrl: 'account/notification-settings',
          meta: {
            'page': 'notification-settings',
            'unsubscribe_token': unsubscribeToken
          }
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var hasChanges = this.get('controller.accountChangeset.isDirty');

        if (hasChanges && !confirm('You have unsaved data. Are you sure you want to continue?')) {
          transition.abort();
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});