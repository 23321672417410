define("darcy/_pods/components/advanced-filter/employees/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    selectedEmployees: 100,
    isTooltipShown: false,
    actions: {
      selectEmployees: function selectEmployees(employees) {
        this.set('selectedEmployees', employees);
        this.onEmployeesChange(employees);
        this.analytics.captureEvent('add_filter', {
          meta: {
            'employees': employees,
            'target': 'advanced-filter'
          }
        });
      },
      toggleTooltip: function toggleTooltip() {
        this.toggleProperty('isTooltipShown');
      }
    }
  });

  _exports.default = _default;
});