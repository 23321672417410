define("darcy/_pods/support/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isExpandedFaq1: false,
    isExpandedFaq2: false,
    isExpandedFaq3: false,
    isExpandedFaq4: false,
    isExpandedFaq5: false,
    isExpandedFaq6: false,
    isExpandedFaq7: false,
    isExpandedFaq8: false,
    isExpandedFaq9: false,
    isExpandedFaq10: false,
    actions: {
      toggleProperty: function toggleProperty(propertyName) {
        this.toggleProperty(propertyName);
      }
    }
  });

  _exports.default = _default;
});