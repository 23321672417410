define("darcy/models/revision", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    status: (0, _attr.default)('string'),
    dataNew: (0, _attr.default)(),
    revisableId: (0, _attr.default)('number'),
    revisableType: (0, _attr.default)('string'),
    createdAt: (0, _attr.default)('date'),
    isApprovable: (0, _attr.default)('boolean'),
    isRestored: (0, _attr.default)('boolean'),
    user: (0, _relationships.belongsTo)('user')
  });

  _exports.default = _default;
});