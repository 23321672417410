define("darcy/_pods/components/search/event-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'article',
    classNames: ['w-full', 'cursor-pointer', 'mb-4'],
    analytics: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    safeExcerpt: Ember.computed('excerpt', function () {
      return Ember.String.htmlSafe(this.excerpt);
    }),
    highlightedExcerpt: Ember.computed('excerpt', 'query', function () {
      var search = this.query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      var re = new RegExp(search, 'gi');

      if (this.excerpt && search.length > 0) {
        return Ember.String.htmlSafe(this.excerpt.replace(re, "<strong class=\"text-black\">$&</strong>"));
      } else {
        return this.excerpt;
      }
    }),
    click: function click() {
      var destinationUrl = "events/".concat(this.get('event.id'));
      this.analytics.captureEvent('visit_search', {
        destinationUrl: destinationUrl,
        meta: {
          search_query: this.query,
          search_result_id: this.get('event.id'),
          search_result_type: 'event'
        }
      });
      this.onVisit(this.get('event.id'));
    },
    mouseEnter: function mouseEnter() {
      this.set('isHovering', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovering');
    },
    actions: {
      onClickInnovatorshowNoAccessModal: function onClickInnovatorshowNoAccessModal() {
        this.showNoAccessModal();
      }
    }
  });

  _exports.default = _default;
});