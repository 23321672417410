define("darcy/_pods/components/advanced-filter/event-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ugTAtYjU",
    "block": "{\"symbols\":[\"eventStatus\"],\"statements\":[[7,\"div\"],[11,\"class\",\"pt-2\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"font-semibold\"],[9],[0,\"Event Status\"],[10],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"pl-4 pt-2\"],[9],[0,\"\\n\"],[4,\"each\",[[24,0,[\"eventStatusOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"pb-2\"],[9],[0,\"\\n        \"],[7,\"label\"],[11,\"class\",\"block text-base flex\"],[9],[0,\"\\n          \"],[1,[29,\"square-checkbox\",null,[[\"onToggle\",\"isActive\"],[[29,\"action\",[[24,0,[]],\"onEventStatusChange\",[24,1,[\"value\"]]],null],[29,\"includes\",[[25,[\"eventStatuses\"]],[24,1,[\"value\"]]],null]]]],false],[0,\"\\n\\n          \"],[7,\"div\"],[11,\"class\",\"cursor-pointer ml-2 mt-1\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"onEventStatusChange\",[24,1,[\"value\"]]],null]],[9],[0,\"\\n            \"],[1,[24,1,[\"label\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/advanced-filter/event-status/template.hbs"
    }
  });

  _exports.default = _default;
});