define("darcy/validations/registration", ["exports", "ember-changeset-validations/validators", "darcy/validations/user", "darcy/validations/password"], function (_exports, _validators, _user, _password) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.assign({
    phone: [(0, _validators.validateFormat)({
      regex: /^\d+$/,
      allowBlank: true,
      message: 'Phone must only contain numbers'
    })],
    companyType: [(0, _validators.validatePresence)({
      presence: true,
      message: 'How you plan on using this platform required'
    })]
  }, _user.default, _password.default);

  _exports.default = _default;
});