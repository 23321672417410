define("darcy/adapters/application", ["exports", "darcy/config/environment", "ember-inflector", "ember-data/adapters/json-api", "ember-ajax/mixins/ajax-support"], function (_exports, _environment, _emberInflector, _jsonApi, _ajaxSupport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_ajaxSupport.default, {
    router: Ember.inject.service(),
    host: _environment.default.API.host,
    headers: Ember.computed('router.currentURL', function () {
      return {
        'Referer-Full': _environment.default.APP.host + this.router.currentURL
      };
    }),
    normalizeErrorResponse: function normalizeErrorResponse(status, headers, payload) {
      if (payload.errors && !Array.isArray(payload.errors)) {
        return [payload.errors];
      } else {
        return this._super.apply(this, arguments);
      }
    },
    pathForType: function pathForType(type) {
      return Ember.String.underscore((0, _emberInflector.pluralize)(type));
    }
  });

  _exports.default = _default;
});