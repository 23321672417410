define("darcy/initializers/user-agent", ["exports", "ember-useragent/initializers/user-agent"], function (_exports, _userAgent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember UserAgent initializer
   *
   * Supports auto injection of the userAgent service app-wide.
   *
   * Generated by the ember-useragent addon.
   * Customize to change injection.
   */
  var _default = {
    name: 'user-agent',
    initialize: _userAgent.initialize
  };
  _exports.default = _default;
});