define("darcy/_pods/components/table/cell/text-capitalize/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v4DYwHbV",
    "block": "{\"symbols\":[\"@rawValue\"],\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"capitalize\"],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"---\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/text-capitalize/template.hbs"
    }
  });

  _exports.default = _default;
});