define("darcy/_pods/components/modal/success/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fOczEC3Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ember-promise-modal w-96\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"px-5\"],[9],[1,[25,[\"data\",\"title\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"data\",\"body\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"text-base font-normal mt-3\"],[9],[1,[25,[\"data\",\"body\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/modal/success/template.hbs"
    }
  });

  _exports.default = _default;
});