define("darcy/_pods/components/carousel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G1kFs547",
    "block": "{\"symbols\":[\"item\",\"@onItemClick\"],\"statements\":[[7,\"div\"],[11,\"class\",\"swiper-wrapper\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],[24,2,[]],[24,1,[]]],null]],[11,\"class\",\"swiper-slide\"],[9],[0,\"\\n      \"],[1,[29,\"carousel/event-item\",null,[[\"model\"],[[24,1,[]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"left-nav\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-chevron-left\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"right-nav\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-chevron-right\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/carousel/template.hbs"
    }
  });

  _exports.default = _default;
});