define("darcy/_pods/components/modal/fast-track-storefront/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    companyId: null,
    isFastTrackingCompany: Ember.computed('currentUserService.fasttrackedCompanyMap', 'companyId', function () {
      return this.currentUserService.isFasttracking(this.companyId);
    })
  });

  _exports.default = _default;
});