define("darcy/mixins/confirmed-user-route-mixin", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_REDIRECT = 'confirm';

  function runIfUnconfirmed(owner, transition, callback) {
    var currentUserSvc = owner.lookup('service:currentUser');
    var sessionSvc = owner.lookup('service:session');

    if (!currentUserSvc.get('user.confirmedAt')) {
      sessionSvc.set('attemptedTransition', transition);
      callback();
      return true;
    }
  }

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    redirects: (0, _emberLocalStorage.storageFor)('redirects'),
    beforeModel: function beforeModel(transition) {
      var _this = this;

      var didRedirect = runIfUnconfirmed(Ember.getOwner(this), transition, function () {
        var routeIfNotConfirmed = _this.getWithDefault('routeIfNotConfirmed', DEFAULT_REDIRECT);

        var transitionToInfo = transition.to;
        var confirmRedirect = {};
        confirmRedirect.name = transitionToInfo.name; // This will only go one level up. Could use recursion to grab all params

        if (transitionToInfo.params) {
          confirmRedirect.params = transitionToInfo.params;
        }

        if (transitionToInfo.parent.params) {
          confirmRedirect.params = transitionToInfo.parent.params;
        }

        _this.redirects.set('confirm', confirmRedirect);

        _this.transitionTo(routeIfNotConfirmed);
      });

      if (!didRedirect) {
        return this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});