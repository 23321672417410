define("darcy/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    load: function load() {
      var _this = this;

      var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      return Ember.RSVP.resolve().then(function () {
        var user = _this.get('user');

        if (user) {
          _this.identifyFullstoryUser(user);

          if (reload) {
            return user.reload();
          }

          return user;
        }

        var userId = _this.get('session.data.authenticated.userId');

        if (userId) {
          return _this.store.find('user', userId).then(function (user) {
            _this.set('user', user);

            _this.identifyFullstoryUser(user);

            return user;
          });
        }

        return null;
      });
    },
    followedCompanyMap: Ember.computed('user.followedCompanyIds.[]', function () {
      if (!this.user) {
        return {};
      } else {
        return this.user.followedCompanyIds.reduce(function (map, id) {
          map[id] = true;
          return map;
        }, {});
      }
    }),
    fasttrackedCompanyMap: Ember.computed('user.fasttrackedCompanyIds.[]', function () {
      if (!this.user) {
        return {};
      } else {
        return this.user.fasttrackedCompanyIds.reduce(function (map, id) {
          map[id] = true;
          return map;
        }, {});
      }
    }),
    isFollowing: function isFollowing(companyId) {
      return this.followedCompanyMap.hasOwnProperty(companyId);
    },
    isFasttracking: function isFasttracking(companyId) {
      return this.fasttrackedCompanyMap.hasOwnProperty(companyId);
    },
    identifyFullstoryUser: function identifyFullstoryUser(user) {
      if (window.FS) {
        window.FS.identify(user.id, {
          displayName: user.name,
          email: user.email
        });
      }
    }
  });

  _exports.default = _default;
});