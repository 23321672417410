define("darcy/tours/scrollHandler", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.scrollHandler = void 0;

  var scrollHandler = function scrollHandler(el, position) {
    var winHeight = (0, _jquery.default)(window).height();
    var elementTop = (0, _jquery.default)(el).offset().top;
    var elementHeight = (0, _jquery.default)(el).height();
    var offset = 100;
    var targetPos;

    if (elementHeight > winHeight - offset && position !== 'top' && position !== 'bottom') {
      targetPos = elementTop - winHeight / 2;
    } else {
      if (position === 'bottom') {
        targetPos = elementTop - offset;
      } else if (position === 'top') {
        targetPos = elementTop + winHeight - elementHeight - offset;
      } else {
        targetPos = elementTop - (winHeight - elementHeight) / 2;
      }
    }

    window.scrollTo(0, targetPos);
  };

  _exports.scrollHandler = scrollHandler;
});