define("darcy/_pods/-calendar/forum/attendees/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      this.transitionTo('events.view.attendees', model.slug);
    }
  });

  _exports.default = _default;
});