define("darcy/_pods/components/carousel-item-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    contentTypeOptions: ["photo", "video"],
    videoTypeOptions: {
      vimeo: "Vimeo",
      youtube: "YouTube"
    },
    actions: {
      onFileChange: function onFileChange(e) {
        if (e.target.files.length > 0) {
          this.changeset.set('file', e.target.files[0]);
        }
      }
    }
  });

  _exports.default = _default;
});