define("darcy/_pods/components/advanced-filter/event-status/component", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "lodash"], function (_exports, _toConsumableArray2, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVENT_STATUS_OPTIONS = [{
    label: 'Invited',
    value: 'invited'
  }, {
    label: 'Registered',
    value: 'registered'
  }, {
    label: 'Attended',
    value: 'attended'
  }];

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    tagName: '',
    eventStatusOptions: EVENT_STATUS_OPTIONS,
    eventStatuses: [],
    actions: {
      onEventStatusChange: function onEventStatusChange(eventStatus) {
        var eventStatuses = (0, _toConsumableArray2.default)(this.eventStatuses);
        eventStatuses = (0, _lodash.xor)(eventStatuses, [eventStatus]);
        this.onEventStatusesChange(eventStatuses);

        if (eventStatuses.includes(eventStatus)) {
          this.analytics.captureEvent('add_filter', {
            meta: {
              'event_status': eventStatus,
              'target': 'advanced-filter'
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});