define("darcy/_pods/components/search-suggestions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HITItXQH",
    "block": "{\"symbols\":[\"suggestion\"],\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"not\",[[25,[\"fetchTask\",\"isRunning\"]]],null],[29,\"gt\",[[25,[\"meta\",\"suggestions\",\"length\"]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-full flex flex-wrap bg-white rounded-lg px-5 py-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"w-full pt-2\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Suggested Searches:\"],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"w-full flex py-3\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"flex-1\"],[9],[0,\"\\n          \"],[7,\"div\"],[12,\"class\",[30,[\"grid gap-4 \",[29,\"if\",[[29,\"media\",[\"isMobile\"],null],\"grid-cols-2\",[29,\"concat\",[\"grid-cols-\",[29,\"or\",[[25,[\"cols\"]],\"2\"],null]],null]],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"meta\",\"suggestions\"]]],null,{\"statements\":[[0,\"              \"],[7,\"div\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"setSearchQuery\",[24,1,[]]],null]],[11,\"class\",\"flex items-center text-center bg-gray-300 rounded-full cursor-pointer mr-2 pl-1 pr-2 py-1\"],[9],[0,\"\\n                \"],[1,[29,\"ember-tooltip\",null,[[\"text\"],[[24,1,[]]]]],false],[0,\"\\n\\n                \"],[7,\"div\"],[11,\"class\",\"w-4 h-4 mr-1\"],[9],[0,\"\\n                  \"],[1,[29,\"svg-jar\",[\"search\"],[[\"class\"],[\"h-full\"]]],false],[0,\"\\n                \"],[10],[0,\"\\n\\n                \"],[7,\"span\"],[11,\"class\",\"capitalize ml-2 truncate text-sm\"],[9],[1,[24,1,[]],false],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/search-suggestions/template.hbs"
    }
  });

  _exports.default = _default;
});