define("darcy/_pods/welcome/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model() {
      return this.store.query('company-profile', {
        page: 1,
        per_page: 10,
        sort: 'trending_last_7_days'
      });
    }
  });

  _exports.default = _default;
});