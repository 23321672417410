define("darcy/_pods/components/table/row/tooltip/component", ["exports", "ember-light-table/components/lt-row"], function (_exports, _ltRow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ltRow.default.extend({
    topicTags: Ember.computed('row', function () {
      return this.row.content.topicTags.split(',').map(function (tag) {
        return tag.trim();
      });
    })
  });

  _exports.default = _default;
});