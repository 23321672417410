define("darcy/_pods/usage/users/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        users: this.store.query('user', {
          filter: 'sponsor_dash',
          limit: 'false'
        }),
        invites: this.store.query('invite', {
          filter: 'sponsor_dash'
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties(model);
    }
  });

  _exports.default = _default;
});