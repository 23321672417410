define("darcy/_pods/components/publisher-profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GDk46R4W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"flex w-full ml-4 mb-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mr-2 flex-none\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"rounded-full cursor-pointer\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"user\",\"photoUrl\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\"],[12,\"src\",[30,[[25,[\"user\",\"photoUrl\"]]]]],[11,\"class\",\"rounded-full h-10 w-10\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"text-2xl text-white font-semibold uppercase rounded-full h-10 w-10 flex items-center justify-center\"],[12,\"style\",[30,[\"background-color:\",[25,[\"user\",\"avatarBackgroundColor\"]]]]],[9],[0,\"\\n          \"],[1,[25,[\"user\",\"initials\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"flex-1 justify-between\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"text-sm text-dark-gray items-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"inline-block\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"font-semibold cursor-pointer hover:underline\"],[9],[0,\"\\n          \"],[1,[25,[\"user\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"user\",\"companyName\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\"],[11,\"class\",\"italic text-xs\"],[9],[1,[25,[\"user\",\"companyName\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/publisher-profile/template.hbs"
    }
  });

  _exports.default = _default;
});