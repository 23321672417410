define("darcy/models/invite", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "darcy/config/environment"], function (_exports, _model, _attr, _relationships, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var API = _environment.default.API;

  var _default = _model.default.extend({
    ajax: Ember.inject.service(),
    sponsorName: (0, _attr.default)('string'),
    acceptedAt: (0, _attr.default)('date'),
    declinedAt: (0, _attr.default)('date'),
    isVideoIncluded: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isSurveyIncluded: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isCallIncluded: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    isCallScheduleOptional: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    arrivalTime: (0, _attr.default)('time', {
      defaultValue: '07:00'
    }),
    departureTime: (0, _attr.default)('time', {
      defaultValue: '19:00'
    }),
    arrivalComments: (0, _attr.default)('string'),
    isAttendingPreForum: (0, _attr.default)('boolean', {
      defaulValue: false
    }),
    isLogisticsComplete: (0, _attr.default)('boolean'),
    isProfileComplete: (0, _attr.default)('boolean'),
    isSurveyComplete: (0, _attr.default)('boolean'),
    isCallScheduleComplete: (0, _attr.default)('boolean'),
    isRsvpComplete: (0, _attr.default)('boolean'),
    isVideoWatched: (0, _attr.default)('boolean'),
    isCheckedIn: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    analyticsEventMetaTarget: (0, _attr.default)('string'),
    isInstant: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    createdAt: (0, _attr.default)('date'),
    user: (0, _relationships.belongsTo)(),
    forum: (0, _relationships.belongsTo)(),
    skipCallSchedule: function skipCallSchedule() {
      var _this = this;

      return this.ajax.put("".concat(API.host, "/invites/").concat(this.id, "/skip_call_schedule")).then(function (res) {
        return _this.store.pushPayload(res);
      });
    },
    confirm: function confirm() {
      var _this2 = this;

      if (this.isRsvpComplete) {
        return Promise.resolve();
      }

      return this.ajax.put("".concat(API.host, "/invites/").concat(this.id, "/confirm")).then(function (res) {
        return _this2.store.pushPayload(res);
      });
    }
  });

  _exports.default = _default;
});