define("darcy/_pods/self-register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IfQcwHf7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\"],[11,\"class\",\"my-8 lg:mt-20 mx-auto lg:max-w-xl w-full h-full lg:h-auto flex flex-col flex-1 justify-center px-2\"],[9],[0,\"\\n  \"],[7,\"h2\"],[11,\"class\",\"leading-none font-bold text-2xl lg:text-3xl text-center mb-4 px-1\"],[9],[0,\"Create Account\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"domain\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bg-white mx-auto my-4\"],[9],[0,\"\\n      \"],[5,\"scaled-logo\",[[13,\"class\",\"mr-3 lg:mr-0 mb-4 inline-block\"]],[[\"@imgUrl\",\"@imgName\",\"@size\"],[[25,[\"domain\",\"logoUrl\"]],[25,[\"domain\",\"name\"]],\"medium\"]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[5,\"user-form\",[],[[\"@email\",\"@showTerms\",\"@domain\",\"@isNewRegistration\",\"@showInterests\",\"@showCompanyTypeCheck\",\"@changeset\",\"@communities\",\"@communityGroups\",\"@onToggleUserCommunity\",\"@onSubmit\"],[[24,0,[\"email\"]],true,[23,\"domain\"],true,[29,\"not\",[[25,[\"domain\"]]],null],[29,\"not\",[[25,[\"domain\"]]],null],[24,0,[\"changeset\"]],[24,0,[\"communities\"]],[24,0,[\"communityGroups\"]],[29,\"action\",[[24,0,[]],\"toggleUserCommunity\"],null],[29,\"route-action\",[\"register\"],null]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/self-register/template.hbs"
    }
  });

  _exports.default = _default;
});