define("darcy/validators/time", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-validators/utils/validation-error", "moment"], function (_exports, _validationErrors, _validationError, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateTime;

  function validateTime() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return function (key, newValue, oldValue, changes, content) {
      var result = validation(newValue, options, changes, content, key);
      return result === true ? true : (0, _validationErrors.default)(key, result);
    };
  }

  function validation(value, options, changes, model, attribute) {
    var after = Ember.get(options, 'after');
    var before = Ember.get(options, 'before');
    (true && !(Ember.isPresent(before) || Ember.isPresent(after)) && Ember.assert("[validator:time] [".concat(attribute, "] option 'after' or 'before' is required"), Ember.isPresent(before) || Ember.isPresent(after)));

    if (after) {
      var afterTime = value;
      var beforeTime = Ember.get(changes, after) || Ember.get(model, after);
      Ember.set(options, 'afterAttr', humanize(after));

      if ((0, _moment.default)(afterTime, "HH:mm").diff((0, _moment.default)(beforeTime, "HH:mm")) < 0) {
        return (0, _validationError.default)('timeAfter', value, options);
      }
    }

    if (before) {
      var _afterTime = Ember.get(changes, before) || Ember.get(model, before);

      var _beforeTime = value;
      Ember.set(options, 'beforeAttr', humanize(before));

      if ((0, _moment.default)(_afterTime, "HH:mm").diff((0, _moment.default)(_beforeTime, "HH:mm")) < 0) {
        return (0, _validationError.default)('timeBefore', value, options);
      }
    }

    return true;
  }

  function humanize(str) {
    return Ember.String.decamelize(str).replace(/_/g, ' ').trim().replace(/\b[A-Z][a-z]+\b/g, function (word) {
      return word.toLowerCase();
    });
  }
});