define("darcy/_pods/components/information-tooltip/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "byqzMg1W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ml-1\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"color\"]],\"white\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/information-white.svg\"],null]]]],[11,\"class\",\"w-3\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"src\",[30,[[29,\"img-src\",[\"images/information.svg\"],null]]]],[11,\"class\",\"w-3\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[29,\"ember-tooltip\",null,[[\"side\",\"text\"],[[25,[\"side\"]],[25,[\"text\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/information-tooltip/template.hbs"
    }
  });

  _exports.default = _default;
});