define("darcy/_pods/components/fake-pagination/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    analytics: Ember.inject.service(),
    click: function click(event) {
      event.preventDefault();
      event.stopPropagation();
      this.showNoAccessModal();
      this.get('currentUser') && this.analytics.captureEvent('free_account_notification', {
        url: window.location.href
      });
    }
  });

  _exports.default = _default;
});