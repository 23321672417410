define("darcy/_pods/getting-started/step-2/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    analytics: Ember.inject.service(),
    usersFollowedCompanies: Ember.computed('currentUser.followedCompanyIds.[]', function () {
      return this.currentUser.followedCompanyIds;
    }),
    actions: {
      onCompanyToggle: function onCompanyToggle(companyId) {
        var currentUser = this.currentUser;

        if (currentUser && companyId) {
          currentUser.set('followedCompanyIds', (0, _lodash.xor)(currentUser.followedCompanyIds, [companyId]));
          currentUser.save();
        }
      },
      goToNextPage: function goToNextPage() {
        this.analytics.captureEvent('getting_started_follow_innovators');
        this.analytics.set('referrerLinkId', 'next');
        this.transitionToRoute('getting-started.step-3');
      },
      goBackAPage: function goBackAPage() {
        this.analytics.set('referrerLinkId', 'back');
        this.transitionToRoute('getting-started.step-1');
      }
    }
  });

  _exports.default = _default;
});