define("darcy/tailwind/config", ["@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2"], function (_defineProperty2, _objectSpread2) {
  "use strict";

  var _extend;

  /*global module, require*/
  var _require = require("tailwindcss/defaultTheme"),
      colors = _require.colors,
      height = _require.height;

  module.exports = {
    future: {
      removeDeprecatedGapUtilities: true
    },
    theme: {
      fontFamily: {
        sans: ['Open Sans', 'sans-serif'],
        body: ['Open Sans', 'sans-serif'],
        montserrat: ['Montserrat', 'sans-serif']
      },
      extend: (_extend = {
        colors: {
          'titlebox-blue': '#2D3748',
          'dark-gray': '#4C5566',
          'social-actions-grey': '#BBBBBB',
          'bookmark-orange': '#F6AD55',
          'medium-gray': '#979797',
          navy: '#0f3b5e',
          light_navy: '#0F3B5E',
          navy_nav: '#314965',
          lightgray: '#BBBBBB',
          darkgray: '#282a2b',
          highlight_green: '#89C334',
          highlight_red: '#ff3f5b',
          highlight_light_green: '#76AD26',
          'presenter-blue': '#455f7c',
          blue_nav: '#2F5597',
          blue_profile: '#003551',
          low_rating: '#D9D9D9',
          med_rating: '#B4C7e7',
          high_rating: '#f8cbad',
          'cool-grey': '#A2B0BA',
          'light-grey': '#D8D8D8',
          'pink-powder': '#ED828F',
          'event-red': '#DB4F41',
          'peach': '#EDB9A0',
          blue_gray: '#7F8E9F',
          gray: (0, _objectSpread2.default)({}, colors.gray, {
            '250': '#F2F2F2',
            '300': '#EAEAEA',
            '600': '#979797',
            '650': '#656565',
            'membership': '#44546A',
            'membership_light': '#C4C4C4',
            'nav': '#7F7F7F'
          }),
          yellow: (0, _objectSpread2.default)({}, colors.yellow, {
            '600': '#94CA3B'
          }),
          orange: (0, _objectSpread2.default)({}, colors.orange, {
            '300': '#F9CA79',
            'darcy': '#EF8205'
          })
        },
        boxShadow: {
          'tooltip': '0 3px 15px 0 rgb(0 0 0 / 50%)',
          'md-t': '0 -2px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)'
        },
        gridTemplateColumns: {
          'related-innovators': 'minmax(0, 200px) minmax(0, 1fr) 163px'
        },
        minHeight: {
          '2/3-screen': '66vh'
        },
        minWidth: {
          '40': '10rem'
        },
        maxWidth: {
          '8xl': '88rem',
          '10xl': '100rem'
        },
        maxHeight: {
          '8': '2rem'
        },
        height: {
          '9': '2.25rem',
          '15': '3.75rem',
          '22': '5.5rem',
          '24': '6rem',
          '28': '7rem',
          '40': '10rem',
          '96': '24rem',
          '116': '29rem',
          '132': '33rem',
          '140': '35rem'
        },
        width: {
          '9': '2.25rem',
          '11': '2.75rem',
          '15': '3.75rem',
          '22': '5.5rem',
          '24': '6rem',
          '28': '7rem',
          '36': '9rem',
          '40': '10rem',
          '44': '11rem',
          '60': '15rem',
          '72': '18rem',
          '80': '20rem',
          '96': '24rem',
          'sidebar': '290px'
        },
        padding: {
          '36': '9rem'
        },
        borderRadius: {
          'xl': '1rem',
          '3xl': '2.5rem'
        },
        borderWidth: {
          '3': '3px'
        },
        fontSize: {
          'title': '1.65rem',
          'h5': '0.8rem',
          'xxxs': '.5rem'
        },
        lineHeight: {
          '2': '0.5rem'
        },
        transitionProperty: {
          width: 'width',
          padding: 'padding',
          top: 'top'
        },
        zIndex: {
          '-1': '-1',
          '60': '60'
        },
        spacing: {
          '2px': '2px',
          '3/5': '0.6rem',
          '7': '1.75rem',
          '15': '3.75rem'
        }
      }, (0, _defineProperty2.default)(_extend, "borderWidth", {
        '3': '2.5px'
      }), (0, _defineProperty2.default)(_extend, "screens", {
        'xs': {
          'min': '390px',
          'max': '767px'
        },
        '3xl': {
          'min': '1440px'
        }
      }), _extend)
    },
    variants: {
      width: ['responsive', 'focus'],
      opacity: ['responsive', 'focus-within', 'hover', 'focus']
    },
    plugins: [require("@tailwindcss/custom-forms"), require("@tailwindcss/line-clamp")]
  };
});