define("darcy/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.get('/agenda_items');
    this.get('/agenda_items/:id');
    this.get('/auth_sessions');
    this.get('/auth_sessions/:id');
    this.get('/attendee_surveys');
    this.get('/attendee_surveys/:id');
    this.get('/attendee_calls');
    this.get('/attendee_calls/:id');
    this.get('/invites');
    this.get('/invites/:id');
    this.patch('/invites/:id');
    this.put('/invites/:id/skip_call_schedule', function (schema, request) {
      var invite = schema.invites.find(request.params.id);
      invite.update('isCallScheduleSkipped', true);
      invite.update('isCallScheduleComplete', true);
      invite.update('isRsvpComplete', true);
      return this.serialize(invite);
    });
    this.get('/calls');
    this.get('/calls/:id');
    this.get('/users');
    this.get('/users/:id');
    this.get('/forums');
    this.get('/forums/:id');
    this.put('/invites/:id/confirm', function (schema, request) {
      var invite = schema.invites.find(request.params.id);
      invite.update('isProfileComplete', true);
      invite.update('isLogisticsComplete', true);
      invite.update('isSurveyComplete', true);
      invite.update('isCallScheduleComplete', true);
      invite.update('isRsvpComplete', true);
      invite.update('isVideoWatched', true);
      invite.update('acceptedAt', new Date());
      return this.serialize(invite);
    });
    this.get('/contents');
    this.get('/contents/:id');
  }
});