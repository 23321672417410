define("darcy/_pods/events/view/agenda/route", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel() {
      if (!this.currentUser) this.transitionTo('events.view.index');
    },
    model: function model() {
      var _this$modelFor = this.modelFor('events.view'),
          forum = _this$modelFor.forum;

      return this.store.query('agenda-item', {
        forum_id: forum.id
      });
    },
    setupController: function setupController(controller, model) {
      var _this$modelFor2 = this.modelFor('events.view'),
          forum = _this$modelFor2.forum;

      var startDate = (0, _moment.default)(forum.startDate);
      model = model.sortBy('index').map(function (item) {
        var duration = item.duration;
        item.set('startTime', (0, _moment.default)(startDate));
        startDate.add('minutes', duration);
        item.set('endTime', (0, _moment.default)(startDate));
        return item;
      });
      controller.set('forum', forum);
      controller.set('agendaItems', model);
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});