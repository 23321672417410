define("darcy/_pods/components/reply-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qewpwF5T",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"flex flex-col w-full\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex text-lightgray p-3 mb-3 rounded bg-light-gray\"],[9],[0,\"\\n      \"],[1,[29,\"suggestion/textarea\",null,[[\"value\",\"mentionedUsers\",\"class\",\"placeholder\",\"isReply\",\"addUser\",\"removeUser\"],[[25,[\"reply\",\"message\"]],[25,[\"reply\",\"mentionedUsers\"]],\"shadow appearance-none rounded w-full text-gray-700 leading-tight focus:outline-none border-none bg-light-gray shadow-none resize-none\",\"Add a reply…\",true,[29,\"action\",[[24,0,[]],\"addMentionedUser\"],null],[29,\"action\",[[24,0,[]],\"removeMentionedUser\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[25,[\"reply\",\"message\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"flex pl-1\"],[9],[0,\"\\n          \"],[7,\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"save\",[25,[\"comment\"]],[25,[\"reply\"]]],null]],[11,\"class\",\"self-end text-sm text-white rounded-md bg-highlight_green hover:bg-highlight_light_green px-3 py-px\"],[9],[0,\"\\n            Post\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"loading-spinner\",null,[[\"class\"],[\"small\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/reply-box/template.hbs"
    }
  });

  _exports.default = _default;
});