define("darcy/_pods/innovators/view/notes/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    queryParams: {
      preserveScrollPosition: {
        refreshModel: true
      }
    },
    beforeModel: function beforeModel(transition) {
      var _this$currentUser, _this$currentUser2;

      var isCurrentUserDarcy = ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.classification) === 'darcy';
      var isCurrentUserContractor = ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.classification) === 'contractor';

      if (!isCurrentUserDarcy && !isCurrentUserContractor) {
        var companyProfile = this.modelFor('innovators.view').companyProfile;
        return this.transitionTo('innovators.view', companyProfile.id);
      }
    },
    resetController: function resetController(controller) {
      controller.set('preserveScrollPosition', false);
    }
  });

  _exports.default = _default;
});