define("darcy/_pods/innovators/view/admin/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    model: function model(params, transition) {
      var company = this.modelFor('innovators.view.admin');
      var profile = company.get('companyProfile');
      var productOverview = company.get('productOverviews.firstObject');
      var carouselItems = company.get('carouselItems');
      var isDarcy = this.currentUser.classification === 'darcy';
      var innovatorContractor = this.currentUser.isInnovatorContractor;
      var revisionStatus = ['draft', 'proposed', 'rejected', 'approved'];
      var revisions = {};

      if (innovatorContractor || isDarcy) {
        revisions.changeset = this.store.query('revision', {
          revisable_id: profile.get('id'),
          revisable_type: 'CompanyProfile',
          status: revisionStatus
        }).then(function (revision) {
          var rev = revision.sortBy('createdAt').lastObject;
          return rev && rev.isRestored ? null : rev;
        });
        revisions.companyChangeset = this.store.query('revision', {
          revisable_id: company.get('id'),
          revisable_type: 'Company',
          status: revisionStatus
        }).then(function (revision) {
          var rev = revision.sortBy('createdAt').lastObject;
          return rev && rev.isRestored ? null : rev;
        });

        if (Ember.isPresent(productOverview)) {
          revisions.productChangeset = this.store.query('revision', {
            revisable_id: productOverview.get('id'),
            revisable_type: 'ProductOverview',
            status: revisionStatus
          }).then(function (revision) {
            var rev = revision.sortBy('createdAt').lastObject;
            return rev && rev.isRestored ? null : rev;
          });
        }
      }

      var scrollToManageCalendar = false;

      try {
        scrollToManageCalendar = transition && transition.from && transition.from.name === 'nylas-login';
      } catch (_unused) {}

      return Ember.RSVP.hash({
        company: company,
        profile: profile,
        productOverview: productOverview,
        carouselItems: carouselItems,
        scrollToManageCalendar: scrollToManageCalendar,
        revisions: Ember.RSVP.hash(revisions)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setChangesets();
    },
    actions: {
      willTransition: function willTransition(transition) {
        var _this$controller = this.controller,
            changeset = _this$controller.changeset,
            productChangeset = _this$controller.productChangeset,
            companyChangeset = _this$controller.companyChangeset;
        var hasChanges = changeset.get('isDirty') || productChangeset.get('isDirty') || companyChangeset.get('isDirty');

        if (hasChanges && !confirm('You have unsaved data. Are you sure you want to continue?')) {
          transition.abort();
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});