define("darcy/_pods/rsvp/intro/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      var _this$modelFor = this.modelFor('rsvp'),
          invite = _this$modelFor.invite,
          forum = _this$modelFor.forum;

      if (!invite.isVideoIncluded & !invite.isRsvpComplete || !forum.isVideoIncluded) {
        this.transitionTo('forum.index');
      }
    },
    actions: {
      next: function next() {
        var _this = this;

        var _this$modelFor2 = this.modelFor('rsvp'),
            invite = _this$modelFor2.invite;

        Ember.set(invite, 'isVideoWatched', true);
        invite.save().then(function () {
          _this.transitionTo('rsvp');
        });
      }
    }
  });

  _exports.default = _default;
});