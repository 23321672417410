define("darcy/components/epm-modal-container", ["exports", "ember-promise-modals/components/modal-container"], function (_exports, _modalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _modalContainer.default;
    }
  });
});