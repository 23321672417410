define("darcy/_pods/components/horizontal-bar-chart/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['relative'],
    media: Ember.inject.service(),
    labels: [],
    datasets: [],
    colors: [],
    hoverColor: '#000000',
    isTooltipShown: false,
    tooltipBody: '',
    tooltipWidth: 2,
    tooltipHeight: 1.5,
    tooltipTop: '0px',
    tooltipLeft: '0px',
    options: Ember.computed('', function () {
      var _this = this;

      return {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false,
          custom: function custom(tooltipModel) {
            if (tooltipModel.opacity === 0) {
              return _this.set('isTooltipShown', false);
            }

            var tooltipBody = tooltipModel.body[0].lines[0];

            if (_this.onTooltipLabelRender) {
              tooltipBody = _this.onTooltipLabelRender(tooltipModel);
            }

            _this.set('tooltipBody', tooltipBody);

            _this.set('isTooltipShown', true);

            _this.set('tooltipTop', tooltipModel.caretY - 10 + 'px');

            _this.set('tooltipLeft', tooltipModel.caretX - 3 + 'px');
          }
        },
        scales: {
          yAxes: [{
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999'
            },
            ticks: {
              offset: true,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: this.media.isMobile ? 12 : 16,
              fontStyle: '500',
              padding: this.media.isMobile ? 20 : 16,
              callback: function callback(label, index, labels) {
                if (/\s/.test(label) && _this.media.isMobile) {
                  return label.split(" ");
                } else {
                  return label;
                }
              }
            }
          }],
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'ACTIVE MEMBERS',
              fontColor: '#4A4A4A',
              fontSize: 18,
              fontStyle: '500'
            },
            gridLines: {
              drawOnChartArea: false,
              drawTicks: false,
              color: '#999999',
              fontStyle: '500'
            },
            ticks: {
              offset: true,
              fontFamily: 'Open Sans, sans-serif',
              fontColor: '#000000',
              fontSize: 16,
              fontStyle: '500',
              padding: 16
            }
          }]
        },
        onClick: function onClick(e, elements) {
          if (_this.onClick === undefined) return;
          if (Ember.isEmpty(elements)) return _this.onClick(null);

          _this.onClick(elements.firstObject._index);
        },
        // Disable since chartjs-plugin-datalabels globally enables.
        plugins: {
          datalabels: {
            display: false
          }
        }
      };
    }),
    chartData: Ember.computed('labels', 'datasets', 'colors', 'hoverColor', function () {
      var _this2 = this;

      return {
        labels: this.labels,
        datasets: this.datasets.map(function (dataset) {
          return (0, _lodash.merge)(dataset, {
            backgroundColor: _this2.colors,
            hoverBackgroundColor: _this2.hoverColor
          });
        })
      };
    }),
    tooltipStyle: Ember.computed('tooltipWidth', 'tooltipHeight', 'tooltipTop', 'tooltipLeft', function () {
      return Ember.String.htmlSafe("width: ".concat(this.tooltipWidth, "rem;\n       height: ").concat(this.tooltipHeight, "rem;\n       top: ").concat(this.tooltipTop, ";\n       left: ").concat(this.tooltipLeft, ";"));
    })
  });

  _exports.default = _default;
});