define("darcy/_pods/usage/users/controller", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    tableSortDir: 'desc',
    tableSortName: 'user.confirmedAt',
    tableFilter: null,
    company: Ember.computed.readOnly('currentUserService.user.company'),
    chartLabels: (0, _lodash.reverse)((0, _lodash.times)(6, function (i) {
      return moment().startOf('month').subtract(i, 'months');
    })),
    chartDatasets: Ember.computed('users', function () {
      var _this = this;

      var data = this.chartLabels.map(function (month) {
        return _this.users.filterBy('confirmedAt').filter(function (user) {
          return moment(user.confirmedAt).isSameOrBefore(month, 'month');
        }).length;
      });
      return [{
        label: 'Users',
        data: data
      }];
    }),
    tableColumns: Ember.computed(function () {
      return [{
        label: 'Name',
        valuePath: 'user.formalName',
        cellClassNames: 'highlight truncate',
        cellComponent: 'table/cell/mailto',
        width: '30%'
      }, {
        label: 'Title',
        cellClassNames: 'truncate',
        valuePath: 'user.title',
        width: '25%'
      }, {
        label: 'Events',
        align: 'center',
        valuePath: 'seatCount',
        width: '15%'
      }, {
        label: 'Active Since',
        align: 'right',
        cellComponent: 'table/cell/date',
        valuePath: 'user.confirmedAt',
        width: '15%'
      }, {
        label: 'Last Event',
        align: 'right',
        cellComponent: 'table/cell/date',
        valuePath: 'lastEvent',
        width: '15%'
      }];
    }),
    tableModel: Ember.computed('users', 'tableFilter', function () {
      var _this2 = this;

      return this.get('users').filter(function (user) {
        if (Ember.isPresent(_this2.tableFilter)) {
          return _this2.tableFilter.isSame(moment(user.confirmedAt), 'month');
        }

        return true;
      }).map(function (user) {
        var lastEvent = _this2.get('invites').filterBy('user.id', user.get('id')).mapBy('forum').sortBy('startDate').lastObject;

        return {
          user: user,
          seatCount: _this2.get('invites').filterBy('user.id', user.get('id')).length,
          lastEvent: lastEvent ? lastEvent.get('startDate') : null
        };
      });
    }),
    tableFilterName: Ember.computed('tableFilter', function () {
      return this.tableFilter.format('MMMM YYYY');
    }),
    actions: {
      onFilter: function onFilter(barIndex) {
        if (barIndex === null) {
          return this.set('tableFilter', null);
        }

        var month = this.chartLabels[barIndex];
        this.set('tableFilter', month);
      },
      onClearFilter: function onClearFilter() {
        this.set('tableFilter', null);
      },
      onTableSort: function onTableSort(isAscending, sort) {
        var dir = isAscending ? 'asc' : 'desc';
        this.setProperties({
          tableSortName: sort,
          tableSortDir: dir
        });
      }
    }
  });

  _exports.default = _default;
});