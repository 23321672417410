define("darcy/_pods/components/list-item/network/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    notifications: Ember.inject.service(),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    analytics: Ember.inject.service(),
    userPreview: Ember.inject.service(),
    connectionRequest: Ember.inject.service(),
    modals: Ember.inject.service(),
    tagName: '',
    isAddingNote: false,
    message: null,
    isAccessible: false,
    actions: {
      openProfilePreview: function openProfilePreview(user) {
        if (this.isAccessible) {
          this.userPreview.setModel(user);
        } else {
          this.showNoAccessModal();
        }
      },
      openUserPreviewPopover: function openUserPreviewPopover(user) {
        if (this.isAccessible) {
          this.userPreview.setModel(user);
          this.userPreview.openEmailForm();
        } else {
          this.showNoAccessModal();
        }
      },
      openCreateConnectionRequestModal: function openCreateConnectionRequestModal(connectee) {
        if (this.isAccessible) {
          this.connectionRequest.setConnectee(connectee, 'list-item');
          this.userPreview.clearModel();
        } else {
          this.showNoAccessModal();
        }
      },
      cancel: function cancel() {
        this.connectionRequest.reset();
      }
    }
  });

  _exports.default = _default;
});