define("darcy/_pods/components/table/cell/invite-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YRCl6R94",
    "block": "{\"symbols\":[\"@rawValue\"],\"statements\":[[4,\"text-overflow\",null,null,{\"statements\":[[4,\"if\",[[24,1,[]]],null,{\"statements\":[[0,\"    \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    <held seat>\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/table/cell/invite-name/template.hbs"
    }
  });

  _exports.default = _default;
});