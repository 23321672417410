define("darcy/_pods/components/list-item/home/content-publish/default/content-body/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rIEGlGOM",
    "block": "{\"symbols\":[\"@content\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex flex-col pl-3 pr-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"text-base text-gray-700 pb-2\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"flex flex-row justify-between\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"font-semibold\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[24,1,[\"description\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"line-clamp-2\"],[9],[0,\"\\n        \"],[1,[24,1,[\"description\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[29,\"eq\",[[24,1,[\"fileType\"]],\"video\"],null],[29,\"eq\",[[24,1,[\"videoType\"]],\"vimeo\"],null]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex-none w-full items-center self-center justify-center py-4 md:py-2 px-6 md:px-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n        \"],[5,\"video/vimeo\",[],[[\"@model\",\"@url\"],[[24,1,[]],[24,1,[\"url\"]]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"flex-none w-full items-center self-center justify-center py-4 md:py-2 px-6 md:px-20\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"w-full\"],[9],[0,\"\\n        \"],[5,\"content/thumbnail\",[],[[\"@content\"],[[24,1,[]]]]],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[1,[29,\"social-reactions\",null,[[\"model\",\"class\",\"micro\",\"isSocialReactionsHidden\"],[[24,1,[]],\"w-full\",true,false]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/list-item/home/content-publish/default/content-body/template.hbs"
    }
  });

  _exports.default = _default;
});