define("darcy/_pods/components/invite-user/component", ["exports", "ember-changeset-validations", "darcy/validations/invitee", "ember-changeset"], function (_exports, _emberChangesetValidations, _invitee, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMAIL_REGEX = /^[a-z0-9!#$%&'*\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

  var _default = Ember.Component.extend({
    invitee: null,
    tagName: '',
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.selectUser(this.invitee.get('id') ? this.invitee : this.store.createRecord('user', {}));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var invitee = this.invitee;

      if (invitee && Ember.get(invitee, 'isNew')) {// invitee.destroyRecord ? invitee.destroyRecord() : invitee.content.destroyRecord()
      }
    },
    searchEmail: function searchEmail(val) {
      var _this = this;

      var emailSearch = val.toLowerCase().trim(); // return EMAIL_REGEX.test(emailSearch) ?
      //   this.store.query('user', { emailSearch }) : Promise.resolve([]);

      return this.store.query('user', {
        emailSearch: emailSearch
      }).then(function (res) {
        return _this.filter(res.toArray());
      });
    },
    selectUser: function selectUser() {
      var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.store.createRecord('user', {});
      this.set('invitee', user);
      var changeset = new _emberChangeset.default(user, (0, _emberChangesetValidations.default)(_invitee.default), _invitee.default);
      this.set('changeset', changeset);
    },
    actions: {
      onKeyDown: function onKeyDown(select, ev) {
        if (ev.key === "Tab" && select.highlighted) {
          this.selectUser(select.highligted);
          select.actions.select(select.highlighted, ev);
          select.actions.close(ev);
        }
      },
      onEmailInput: function onEmailInput(val) {
        var _this2 = this;

        var invitee = this.invitee,
            changeset = this.changeset;

        if (!Ember.get(invitee, 'isNew') && (!val || changeset.get('email') !== val)) {
          this.selectUser(this.store.createRecord('user', {
            email: val
          }));
        } else {
          invitee.set('email', val);
        }

        if (!EMAIL_REGEX.test(val)) {
          return;
        }

        this.searchEmail(val).then(function (users) {
          if (users.length === 1 && _this2.changeset.get('email') === users.firstObject.email) {
            _this2.selectUser(users.firstObject);
          }
        });
      },
      selectUser: function selectUser(user) {
        this.selectUser(user);
      },
      searchEmail: function searchEmail(val) {
        return this.searchEmail(val);
      },
      inviteUser: function inviteUser() {
        var _this3 = this;

        var changeset = this.changeset,
            invitee = this.invitee;
        this.set('loading', true);
        changeset.validate().then(function () {
          if (!changeset.isValid) {
            var errors = changeset.errors.mapBy('validation').join(', ');

            _this3.notifications.warning(errors);

            return;
          }

          changeset.execute();
          return _this3.attrs.inviteUser(invitee); // .then(()=> this.selectUser())
        }).finally(function () {
          if (!_this3.isDestroyed) {
            _this3.set('loading', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});