define("darcy/_pods/events/view/content/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    groupedContent: Ember.computed('model', function () {
      var _this = this;

      if (Ember.isBlank(this.model)) return [];
      var companyIds = this.model.mapBy('company.id').uniq().sort(function (a
      /*, b */
      ) {
        // ensure Darcy Partners is at the top
        if (a === '2') {
          return -1;
        } else {
          return 1;
        }
      });
      return companyIds.map(function (companyId) {
        var contents = _this.model.filterBy('company.id', companyId);

        return Ember.Object.create({
          company: contents.get('firstObject.company'),
          contents: contents
        });
      });
    })
  });

  _exports.default = _default;
});