define("darcy/_pods/innovators/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    loading: Ember.inject.service(),
    analytics: Ember.inject.service(),
    queryParams: {
      showNoAccessModal: {
        replace: true
      },
      unpublishedCompany: {
        replace: true
      }
    },
    model: function model() {
      var topicTags = this.store.findAll('topic-tag');
      return Ember.RSVP.hash({
        topicTags: topicTags
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var selectedTagIds = controller.get('topicTagIds');
      var selectedTags = controller.model.topicTags.reject(function (a) {
        if (selectedTagIds.indexOf(a.id) >= 0) {
          return false;
        } else {
          return true;
        }
      });
      controller.set('topicTags', selectedTags);
      controller.fetchProfilesTask.perform(true);
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.to.name === 'innovators.view.index') {
          this.set('loading.isActive', true);
        }
      },
      didTransition: function didTransition() {
        var controller = this.controller;

        if (controller.showNoAccessModal) {
          this.send('showNoAccessModal');
          controller.set('showNoAccessModal', null);
        }

        if (controller.unpublishedCompany) {
          this.send('showFastTrackStorefrontModal', controller.unpublishedCompany);
          controller.set('unpublishedCompany', null);
        }
      }
    }
  });

  _exports.default = _default;
});