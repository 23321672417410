define("darcy/_pods/usage/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUserService: Ember.inject.service('current-user'),
    media: Ember.inject.service(),
    analytics: Ember.inject.service(),
    showQuestions: false,
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    company: Ember.computed.readOnly('currentUserService.user.company'),
    activeUsersCount: Ember.computed('usersChart.datasets', function () {
      return this.usersChart.datasets.firstObject.data.lastObject.toLocaleString();
    }),
    eventAttendanceCount: Ember.computed('eventParticipationChart.datasets', function () {
      return this.eventParticipationChart.datasets.firstObject.data.lastObject.toLocaleString();
    }),
    usersChartLabels: Ember.computed('usersChart.labels', function () {
      return this.usersChart.labels.map(function (date) {
        return moment(date);
      });
    }),
    eventParticipationChartLabels: Ember.computed('eventParticipationChart.labels', function () {
      return this.eventParticipationChart.labels.map(function (date) {
        return moment(date);
      });
    }),
    eventParticipationChartDatasets: Ember.computed('eventParticipationChart.datasets', function () {
      return this.eventParticipationChart.datasets.filter(function (dataset) {
        return dataset.label === 'Participants';
      });
    }),
    communityMembersChartFormattedLabels: Ember.computed('media.isMobile', 'communityMembersChart.labels', function () {
      if (this.media.isMobile) {
        return this.communityMembersChart.labels.map(function (label) {
          return label.split(' ');
        });
      } else {
        return this.communityMembersChart.labels;
      }
    }),
    communityMembersChartStyle: Ember.computed('media.isMobile', 'communityMembersChart.labels', function () {
      var multiplier = this.media.isMobile ? 54 : 32;
      return 'height: ' + this.communityMembersChart.labels.length * multiplier + 'px';
    }),
    communityMembersChartColors: Ember.computed('communityMembersChart.labels', function () {
      var privateCommunityNamePattern = /\*$/;
      return this.communityMembersChart.labels.map(function (label) {
        if (privateCommunityNamePattern.test(label)) return 'rgba(94, 164, 208, .75)';
        return '#5EA4D0';
      });
    }),
    innovationConciergeChartLabels: Ember.computed('innovationConciergeChart.labels', function () {
      return this.innovationConciergeChart.labels.map(function (date) {
        return moment(date);
      });
    }),
    actions: {
      onEventParticipationTooltipLabelRender: function onEventParticipationTooltipLabelRender(tooltipItem) {
        return this.eventParticipationChart.datasets.map(function (dataset) {
          return dataset.label + ': ' + dataset.data[tooltipItem.index];
        });
      },
      onInnovationConciergeTooltipLabelRender: function onInnovationConciergeTooltipLabelRender(tooltipItem) {
        if (tooltipItem.datasetIndex == 0) {
          var cumulative = this.innovationConciergeChart.datasets[0];
          var monthly = this.innovationConciergeChart.datasets[1];
          var totalNumber = cumulative.data[tooltipItem.index] + monthly.data[tooltipItem.index];
          return cumulative.label + ': ' + totalNumber;
        }

        return '';
      },
      onCommunityMembersTooltipLabelRender: function onCommunityMembersTooltipLabelRender(tooltipModel) {
        return tooltipModel.body[0].lines[0].match(/\d+/)[0];
      }
    }
  });

  _exports.default = _default;
});