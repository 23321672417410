define("darcy/models/notification", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "moment", "darcy/config/environment"], function (_exports, _model, _attr, _relationships, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var hostnameRegex = /^https?\:\/\/[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:(\.|-)[a-zA-Z]{2,})*/i;

  var _default = _model.default.extend({
    body: (0, _attr.default)('string'),
    notificationType: (0, _attr.default)('string'),
    targetUrl: (0, _attr.default)('string'),
    readAt: (0, _attr.default)('date'),
    createdAt: (0, _attr.default)('date'),
    originatorAvatarBackgroundColor: (0, _attr.default)('string'),
    originatorInitials: (0, _attr.default)('string'),
    createdAtFromNow: Ember.computed('createdAt', function () {
      if (this.get('createdAt')) {
        return (0, _moment.default)(this.get('createdAt')).fromNow();
      }
    }),
    isRead: Ember.computed('readAt', function () {
      return Ember.isPresent(this.readAt);
    }),
    url: Ember.computed('targetUrl', function () {
      var targetUrl = this.get('targetUrl');
      var hosts = ['localhost', 'staging.darcypartners.com', 'connect.darcypartners.com', 'darcypartners.com'];
      var host = hosts.find(function (host) {
        return targetUrl.includes(host);
      });

      if (_environment.default.isMobile) {
        if (host) {
          var regex = new RegExp("^https?://\\b".concat(host, "\\b(:[0-9]+)?"), 'i');
          return '#' + targetUrl.replace(regex, '');
        } else if (!hostnameRegex.test(targetUrl)) {
          return '#' + targetUrl;
        }
      }

      return targetUrl;
    })
  });

  _exports.default = _default;
});