define("darcy/_pods/components/progress-indicator/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gMEKGT84",
    "block": "{\"symbols\":[\"step\",\"@currentStepName\",\"@steps\"],\"statements\":[[4,\"if\",[[25,[\"registrationCompleted\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"data-test-step-name\",\"completed\"],[11,\"class\",\"mb-8 text-xl text-gray-700 font-bold w-full text-center flex items-center justify-center\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"Registration Completed\"],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-check-circle text-green-500 text-3xl ml-4\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"ol\"],[11,\"class\",\"flex progress-indicator flex-wrap md:justify-center mt-4 mb-8 md:mb-4 mx-4 md:mx-0 list-decimal list-inside\"],[9],[0,\"\\n\"],[4,\"each\",[[24,3,[]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[12,\"data-test-step-name\",[24,1,[\"name\"]]],[12,\"class\",[30,[\"mr-8 mb-2 \",[29,\"if\",[[29,\"eq\",[[24,3,[\"length\"]],1],null],\"list-none\"],null],\" text-gray-700 font-bold w-full md:w-auto \",[29,\"if\",[[24,1,[\"isComplete\"]],\"is-complete\"],null],\" \",[29,\"if\",[[29,\"eq\",[[24,2,[]],[24,1,[\"name\"]]],null],\"is-current\"],null],\" \"]]],[9],[0,\"\\n        \"],[7,\"span\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[24,1,[\"isComplete\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-check-circle text-green-500\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/progress-indicator/template.hbs"
    }
  });

  _exports.default = _default;
});