define("darcy/_pods/components/take-action/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analytics: Ember.inject.service(),
    isMessageVendorModalOpen: false,
    isVendorMeetingModalOpen: false,
    isUserConnectionModalOpen: false,
    actions: {
      openMessageVendorModal: function openMessageVendorModal(companyProfile) {
        this.set('isMessageVendorModalOpen', true);
        this.analytics.captureEvent('storefront_message_vendor_view', {
          companyProfile: companyProfile
        });
      },
      openMeetVendorModal: function openMeetVendorModal(companyProfile) {
        this.analytics.captureEvent('storefront_vendor_meeting_view', {
          companyProfile: companyProfile
        });
        this.set('isVendorMeetingModalOpen', true);
      },
      openUserConnectionModal: function openUserConnectionModal(companyProfile) {
        this.set('isUserConnectionModalOpen', true);
        this.analytics.captureEvent('storefront_request_view', {
          companyProfile: companyProfile
        });
      },
      openAskDarcyModal: function openAskDarcyModal(companyProfile) {
        this.set('isAskDarcyModalOpen', true);
        this.analytics.captureEvent('ask_darcy_view', {
          companyProfile: companyProfile
        });
      }
    }
  });

  _exports.default = _default;
});