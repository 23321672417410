define("darcy/_pods/components/scaled-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IEWAZyQ8",
    "block": "{\"symbols\":[\"@imgName\",\"@imgUrl\"],\"statements\":[[4,\"if\",[[24,2,[]]],null,{\"statements\":[[4,\"if\",[[29,\"and\",[[25,[\"linkSlug\"]],[29,\"not\",[[29,\"eq\",[[25,[\"companyType\"]],\"operator\"],null]],null],[25,[\"isPublished\"]]],null]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[[13,\"onclick\",[29,\"set-referrer-link-id\",[[25,[\"referrerLink\"]]],null]]],[[\"@route\",\"@model\"],[\"innovators.view\",[23,\"linkSlug\"]]],{\"statements\":[[0,\"\\n      \"],[7,\"img\"],[12,\"class\",[23,\"class\"]],[12,\"style\",[29,\"html-safe\",[[29,\"concat\",[\"width:\",[25,[\"imgWidth\"]],\";height:\",[25,[\"imgHeight\"]]],null]],null]],[12,\"src\",[24,2,[]]],[12,\"alt\",[24,1,[]]],[12,\"title\",[24,1,[]]],[9],[10],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\"],[12,\"class\",[23,\"class\"]],[12,\"style\",[29,\"html-safe\",[[29,\"concat\",[\"width:\",[25,[\"imgWidth\"]],\";height:\",[25,[\"imgHeight\"]]],null]],null]],[12,\"src\",[24,2,[]]],[12,\"alt\",[24,1,[]]],[12,\"title\",[24,1,[]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-full text-center text-sm\"],[9],[0,\"\\n    \"],[1,[24,1,[]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "darcy/_pods/components/scaled-logo/template.hbs"
    }
  });

  _exports.default = _default;
});