define("darcy/_pods/components/trial-banner/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    tagName: '',
    daysBeforeTrialExpiration: Ember.computed('currentUser.freeTrialMembershipAgreements.[]', function () {
      var trialAgreements = this.get('currentUser.freeTrialMembershipAgreements');
      if (!trialAgreements) return;
      var today = (0, _moment.default)().startOf('day');
      var expirationDate = (0, _moment.default)(trialAgreements.mapBy('endDate').sort()[0]).startOf('day');
      return expirationDate.diff(today, 'days');
    })
  });

  _exports.default = _default;
});