define("darcy/_pods/events/view/content/view/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var params = this.paramsFor('events.view');
      this.transitionTo('research.view', transition.to.params.id, {
        queryParams: {
          sourceType: 'forum',
          sourceId: params.forum_id
        }
      });
    }
  });

  _exports.default = _default;
});