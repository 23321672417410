define("darcy/_pods/components/modal/contracts/component", ["exports", "darcy/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    currentUserService: Ember.inject.service('current-user'),
    currentUser: Ember.computed.readOnly('currentUserService.user'),
    contracts: [],
    contractNames: Ember.computed('contracts', function () {
      return this.contracts.map(function (c) {
        return c.name;
      }).join(' and ');
    }),
    isTermsOfUseShown: Ember.computed('contracts', function () {
      return Ember.isPresent(this.contracts.find(function (c) {
        return c.name === 'Terms Of Use';
      }));
    }),
    isPrivacyPolicyShown: Ember.computed('contracts', function () {
      return Ember.isPresent(this.contracts.find(function (c) {
        return c.name === 'Privacy Policy';
      }));
    }),
    actions: {
      agree: function agree() {
        var _this = this;

        return Promise.all(this.contracts.map(function (contract) {
          var payload = {
            data: {
              attributes: {
                contract_id: contract.id,
                revision_id: contract.revisionId
              }
            }
          };
          return _this.ajax.post("".concat(_environment.default.API.host, "/contract_acceptances"), {
            data: payload
          });
        })).then(function () {
          // Clear contracts locally rather than reload user.
          _this.currentUser.set('contracts', []);
        });
      },
      signOut: function signOut() {
        var _this2 = this;

        return this.get('session').invalidate().then(function () {
          return _this2.router.transitionTo('login');
        });
      }
    }
  });

  _exports.default = _default;
});