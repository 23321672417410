define("darcy/_pods/components/video/vimeo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VIDEO_TYPE = 'vimeo';
  var EMBED_PREFIX = 'https://player.vimeo.com/video/';
  var VIMEO_URL_REGEX = /vimeo\.com/;

  var _default = Ember.Component.extend({
    video: Ember.inject.service(),
    player: Ember.inject.service(),
    analytics: Ember.inject.service(),
    url: null,
    iframePlayer: null,
    model: null,
    willDestroyElement: function willDestroyElement() {
      var _this$iframePlayer;

      this._super.apply(this, arguments);

      this.player.off('play');
      this.player.removePlayer();
      (_this$iframePlayer = this.iframePlayer) === null || _this$iframePlayer === void 0 ? void 0 : _this$iframePlayer.destroy();
    },
    src: Ember.computed('url', function () {
      if (Ember.isBlank(this.url)) return null;
      var id = VIMEO_URL_REGEX.test(this.url) ? this.video.getVideoID(VIDEO_TYPE, this.url) : this.url;
      return "".concat(EMBED_PREFIX).concat(id);
    }),
    createAnalyticsEvent: function createAnalyticsEvent() {
      this.analytics.captureEvent('content_video_play', {
        content: this.model
      });
    },
    actions: {
      initPlayer: function initPlayer() {
        if (!this.$('iframe')) return;
        var iframe = this.$('iframe')[0];
        this.iframePlayer = new window.Vimeo.Player(iframe);
        this.iframePlayer.on('play', this.createAnalyticsEvent.bind(this));
        this.player.setPlayer(this.iframePlayer);
      }
    }
  });

  _exports.default = _default;
});