define("darcy/_pods/components/logo-carousel/component", ["exports", "swiper"], function (_exports, _swiper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['logo-gallery', 'swiper-container', 'flex', 'justify-center'],
    slidesPerView: 4,
    spaceBetween: 16,
    enableBreakpoints: false,
    loop: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.swiper = new _swiper.default(this.element, {
        observer: true,
        speed: 500,
        loop: this.loop,
        slidesPerView: this.slidesPerView,
        spaceBetween: this.spaceBetween,
        navigation: {
          nextEl: '.right-nav',
          prevEl: '.left-nav'
        },
        autoplay: {
          delay: 5000
        },
        breakpoints: this.enableBreakpoints ? {
          320: {
            spaceBetween: 100,
            slidesPerView: 3
          },
          640: {
            spaceBetween: 100
          },
          991: {
            spaceBetween: 100
          }
        } : {}
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.swiper.destroy();

      this._super.apply(this, arguments);
    },
    actions: {
      slideLeft: function slideLeft() {
        this.swiper.slidePrev();
      },
      slideRight: function slideRight() {
        this.swiper.slideNext();
      }
    }
  });

  _exports.default = _default;
});