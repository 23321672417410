define("darcy/services/user-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    analytics: Ember.inject.service(),
    model: null,
    isFormShowing: false,
    analyticsEventMetaTarget: null,
    isModalOpen: Ember.computed('model', function () {
      return Ember.isPresent(this.model);
    }),
    setModel: function setModel(model, eventTarget) {
      this.set('model', model);
      if (eventTarget) this.set('analyticsEventMetaTarget', eventTarget);
    },
    clearModel: function clearModel(model) {
      var _this = this;

      Ember.run.later(function () {
        _this.set('model');

        _this.set('isFormShowing', false);
      }, 100);
    },
    clearAnalyticsEventMetaTarget: function clearAnalyticsEventMetaTarget(model) {
      this.set('analyticsEventMetaTarget', null);
    },
    openEmailForm: function openEmailForm() {
      this.analytics.captureEvent('mini_profile_send_message', {
        meta: {
          user_id: this.get('recipient.id')
        }
      });
      this.set('isFormShowing', true);
    }
  });

  _exports.default = _default;
});